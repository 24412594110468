.profile-page {
  &__moderation-panel {
    width: 100%;
    background-color: $yellow;
    padding-top: 10px;
    padding-bottom: 10px;
    margin: 0;
    border-bottom: 4px solid $background-color;
  }

  &__moderation-panel-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 25px;
    width: 100%;
    align-items: center;
  }

  &__moderation-text {
    strong {
      font-weight: 700;
    }
  }

  &__moderation-actions {
    display: flex;
    flex-direction: row;

    & > button {
      font-weight: 600;

      &.profile-page__activate_button {
        background-color: white;
        color: $base-dark;

        &:hover:not(.btn-text):not(:disabled):not(.disabled) {
          background-color: white;
          color: $base-dark;
        }
      }

      &.profile-page__reject_button {
        background-color: rgba(255, 255, 255, 0.15);
        color: $base-dark;
      }

      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
}

.postback-url-copy, .secret-key-url-copy {
  color: $base-dark;
  cursor: pointer;

  &:hover {
    color: $link-color;
  }
}

.profile-resend-email {
  display: block;
  width: 100%;
}

.profile-roles-card {
  height: 100%;

  & > .widget-layout, .tabs, .tabs-content, .tabs-tabpane, .card-body, .profile-roles-list {
    height: 100%;
  }

  .tabs-content-holder {
    height: calc(100% - #{$tab-height});
  }
}

.profile-form-title {
  font-size: 15px;
  line-height: 16px;
  margin-top: 35px;
  margin-bottom: 16px;
  font-weight: 600;
}
