.react-tags {
  @include control();
  position: relative;
  background-color: $input-tags-background-color;
  border-color: $input-tags-border-color;
  padding: $input-tags-wrapper-padding;

  /* clicking anywhere will focus the input */
  cursor: text;
  @include default-transition();

  &.is-focused, &:focus, &:active {
    border: $control-focus-border;
    box-shadow: $control-focus-box-shadow;
  }

  &__selected {
    display: inline;
  }

  &__selected-tag {
    display: inline-flex;
    box-sizing: border-box;
    margin: $input-tags-tag-margin;
    padding: $input-tags-tag-padding;
    border: none;
    border-radius: $input-tags-tag-border-radius;
    background-color: $input-tags-tag-background-color;
    color: $input-tags-tag-color;
    max-width: $input-tags-tag-max-width;
    font-size: 85%;


    span {
      display: inline-flex;
      margin: $input-tags-tag-text-padding;
      max-width: calc(100% - 17px);
      box-sizing: border-box;
      overflow: hidden;
      text-align: start;
    }

    &:after {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 20px;
      content: '';
      background: url('data:image/svg+xml, <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" clip-rule="evenodd" d="M9.54587 9.54587C9.74113 9.3506 9.74113 9.03402 9.54587 8.83876L5.65681 4.9497L9.54587 1.06063C9.74113 0.865371 9.74113 0.548788 9.54587 0.353525C9.35061 0.158263 9.03403 0.158263 8.83876 0.353526L4.9497 4.24259L1.06058 0.353478C0.865323 0.158216 0.54874 0.158216 0.353478 0.353478C0.158216 0.54874 0.158216 0.865323 0.353478 1.06059L4.24259 4.9497L0.353483 8.83881C0.158221 9.03407 0.158221 9.35065 0.353483 9.54591C0.548746 9.74117 0.865329 9.74117 1.06059 9.54591L4.9497 5.65681L8.83876 9.54587C9.03402 9.74113 9.3506 9.74113 9.54587 9.54587Z" fill="black"/></svg>');
      background-position: center;
      background-size: 8px 8px;
      background-repeat: no-repeat;
      width: 11px;
      opacity: 0.5;
    }

    &:hover, &:focus {
      &:after {
        opacity: 0.75;
      }
    }
  }

  &__search {
    display: inline-block;
    margin: $border-width calc(#{$input-tags-padding-x} + #{$border-width}) calc(#{$input-tags-padding-y} + #{$border-width}) $border-width;

    /* prevent autoresize overflowing the container */
    max-width: calc(100% - #{$input-tags-padding-x});

    &-wrapper {
      max-width: calc(100%);
    }

    input {
      min-width: 50px;
      max-width: calc(100% - 1rem);
      overflow: hidden;
      border: none;
      outline: none;
      padding: 0 0.5rem;
      box-sizing: content-box;
      background-color: inherit;

      &::placeholder {
        color: $text-muted;
        font-weight: 400;
      }
    }
  }

  &__suggestions {
    position: absolute;
    box-shadow: 0 0 50px 0 #523f6926;
    border: 0;
    outline: 0;
    padding: 0.75rem 0;
    background: $white;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    left: 0;
    z-index: 2;

    @include default-scrollbar();

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      box-shadow: none;
      overflow: auto;
      cursor: default;

      li {
        color: $base-dark;
        border-radius: 0;
        padding: 0.75rem 1.5rem;
        margin: 0;
        box-shadow: none;
        outline: 0;

        mark {
          padding: 0;
          background: none;
          text-decoration: underline;
        }

        &:hover {
          background: $light;
          color: $primary;

          mark {
            padding: 0;
            background: none;
            color: $primary;
          }
        }
      }
    }
  }
}

.input-tags {
  &--list-top {
    .react-tags {
      &__suggestions {
        border-radius: $border-radius $border-radius 0 0;
        bottom: calc(100% + #{$border-width});
      }
    }
  }

  &:not(&--list-top) {
    .react-tags {
      &__suggestions {
        border-radius: 0 0 $border-radius $border-radius;
        top: calc(100% + #{$border-width});
      }
    }
  }
}
