// App theme

$colors: (
        "primary": $primary,
        "success": $success,
        "warning": $warning,
        "danger": $danger,
        "secondary": $secondary,
        "dark": $dark,
        "orange": $orange,
        "azure": $azure,
        "field": $white,
        "carrot": $carrot,
        "violet": $violet,
);

$light-theme-colors: (
        "light-primary": $light-primary,
        "light-success": $light-success,
        "light-danger": $light-danger,
        "light-secondary": $light-secondary,
        "light-info": $light-info,
        "light-dark": $light-dark,
        "light-warning": $light-warning,
        "light-azure": $light-azure,
        "light-carrot": $light-carrot,
        "light-violet": $light-violet,
);
$theme-colors: $colors;
$default-theme-colors: map-merge(
(
        "primary":    $primary,
        "secondary":  $secondary,
        "success":    $success,
        "info":       $info,
        "warning":    $warning,
        "danger":     $danger,
        "light":      $light,
        "dark":       $dark,
        "muted":      $muted,
        "gray":       $text-gray,
        "orange":     $orange,
        "yellow":     $yellow,
        "azure":      $azure,
        "violet":     $violet,
),
$theme-colors
);
$theme-colors: map-merge($default-theme-colors, $light-theme-colors);

// Buttons maps

$btn-font-color: (
        "primary": $white,
        "secondary": $base-dark,
        "field": $base-dark,
        "success": $white,
        "danger": $white,
        "warning": $white,
        "info": $white,
        "light": $dark-50,
        "dark": $white,
        "light-primary": $primary,
        "light-success": $success,
        "light-danger": $danger,
        "light-warning": $warning,
        "light-dark": $dark,
        "light-info": $info,
        "light-secondary": $base-dark,
        "light-azure": $azure,
        "light-violet": $violet,
);

// Badges maps

$light-badge-colors: (
        "light-primary": $primary,
        "light-success": $success,
        "light-danger": $danger,
        "light-secondary": $text-gray,
        "light-info": $info,
        "light-warning": $orange,
        "light-azure": $azure,
        "light-carrot": $carrot,
        "warning": $white,
        "light-dark": $base-dark,
        "transparent": $base-dark,
        "dark": $white,
        "muted": $text-gray,
        "gray": $white,
        "light-violet": $violet,
);

$badge-sizes: (
        "sm": $badge-font-size-sm,
        "md": $badge-font-size,
        "lg": $badge-font-size-lg,
        "xl": $badge-font-size-xl
);

$badge-heights: (
        "sm": $badge-height-sm,
        "md": $badge-height,
        "lg": $badge-height-lg,
        "xl": $badge-height-xl
);

$badge-line-heights: (
        "sm": $badge-line-height-sm,
        "md": $badge-line-height,
        "lg": $badge-line-height-lg,
        "xl": $badge-line-height-xl
);

// Switches maps

$switch-colors: (
(
        "primary":    $light-primary,
        "secondary":  $light-secondary,
        "success":    $light-success,
        "info":       $info,
        "warning":    $warning,
        "danger":     $light-danger,
        "light":      $gray-100,
        "dark":       $dark,
));

// Symbols maps

$symbol-sizes: (
  "20": $h6-font-size,
  "25": $h6-font-size,
  "30": $h6-font-size,
  "35": $h6-font-size,
  "40": $h6-font-size,
  "45": $h5-font-size,
  "50": $h5-font-size,
  "55": $h5-font-size,
  "60": $h5-font-size,
  "65": $h4-font-size,
  "70": $h4-font-size,
  "75": $h4-font-size,
  "80": $h4-font-size,
  "85": $h3-font-size,
  "90": $h3-font-size,
  "95": $h3-font-size,
  "100": $h3-font-size,
  "110": $h2-font-size,
  "120": $h2-font-size,
  "130": $h2-font-size,
  "140": $h2-font-size,
  "150": $h2-font-size,
);

// Dashboard widget maps

$dashboard-widget-colors: (
        "black": $base-dark,
        "white": $white,
        "red": $danger,
        "orange": $carrot,
        "green": $success,
        "blue-green": $info,
        "purple": #CC61FF,
        "blue": $primary,
        "yellow": #FFD335,
        "gray": #A8B0C6,
        "light-red": rgb(255, 163, 177),
        "light-orange": rgb(244, 175, 165),
        "light-green": rgb(133, 236, 194),
        "light-blue-green": rgb(115, 222, 232),
        "light-purple": rgb(239, 170, 255),
);

// Notifications types

$notification-types: ('success': $success, 'danger': $danger, 'warning': $warning);

// Alert message a side line color

$alert-message-line-colors: (
        "orange": $yellow,
);
