@use "sass:math";

$drawer-padding: 32px;
$drawer-form-padding-x: 24px;
$drawer-form-padding-y: 18px;
$drawer-form-gradient-height: 50px;
$drawer-form-border-color: #dbdcdf;
$drawer-form-close-icon-width: 17px;

.drawer {
  z-index: 200;
  outline: none;
  overflow: auto;

  &__header {
    position: relative;

    display: flex;
    justify-content: space-between;
    flex-direction: column;

    padding: $drawer-padding;
    padding-bottom: math.div($drawer-padding, 2);
  }

  &__title {
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: $base-dark;
    width: calc(100% - 24px);
  }

  &__sub-header {
    margin-top: 30px;
  }

  &__close {
    position: absolute;
    right: 20px;
    top: 20px;
    color: $base-dark;
    font-size: 18px;
  }

  &__content {
    padding: $drawer-padding;
    padding-top: 0;
  }

  &__footer {
    display: flex;
    justify-content: space-between;

    padding: 0 2.5rem 2.5rem 2.5rem;
  }

  &--form {
    .drawer__content {
      padding: 0;
    }

    .drawer-content {
      @include default-scrollbar();
    }
  }

  .textarea {
    @include default-scrollbar();
  }
}

.drawer-form {
  min-height: 100dvh;
  position: relative;

  .drawer-form__gradient {
    display: block;
    width: 100%;
    height: $drawer-form-gradient-height;
    pointer-events: none;
  }

  &__header {
    display: block;
    position: sticky;
    top: 0;
    z-index: 2;
    width: 100%;
    pointer-events: none;

    .drawer-form__gradient {
      border-top: 1px solid $drawer-form-border-color;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
    }
  }

  &__subtitle {
    font-size: 15px;
    line-height: 16px;
    font-weight: 600;
    margin-top: 32px;
    margin-bottom: 16px;
  }

  &__header-content {
    display: flex;
    background: $white;
    padding: $drawer-form-padding-y $drawer-form-padding-x;
    align-items: flex-start;
  }

  &__title {
    font-size: 18px;
    line-height: 35px;
    font-weight: 500;
  }

  &__close {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 9px $drawer-form-padding-x 9px 0;
    pointer-events: all;

    .button {
      font-size: 11px;
      width: $drawer-form-close-icon-width;
      height: $drawer-form-close-icon-width;
    }
  }

  &__content {
    min-height: calc(100vh - 220px);
    padding: 0 $drawer-form-padding-x;
    margin: -15px 0;
  }

  &__footer {
    display: block;
    position: sticky;
    bottom: 0;
    z-index: 2;
    width: 100%;
    pointer-events: none;

    .drawer-form__gradient {
      border-bottom: 1px solid $drawer-form-border-color;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
    }
  }

  &__footer-content {
    background: $white;
    padding: $drawer-form-padding-y $drawer-form-padding-x;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    grid-gap: $drawer-form-padding-x;
    pointer-events: all;
  }
}

@include tabletLayout() {
  .drawer__header {
    padding: 12px;

    .drawer__title {
      font-size: 17px;
      line-height: 28px;
    }

    .drawer__close {
      font-size: 11px;
      width: 24px;
      height: 24px;
      top: 8px;
      right: 8px;
    }
  }

  .drawer__content {
    padding: 12px;
    padding-top: 0;
  }

  .drawer__footer {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    height: $horizontal-menu-height;
    padding: 0 12px 12px;
  }

  &.drawer--form {
    .drawer__content {
      padding: 0;
    }
  }
}

@include mobileLayout() {
  .drawer-content-wrapper {
    width: min(480px, 100dvw) !important;
  }

  .drawer__content {
    height: calc(100% - 92px);
    max-height: calc(100% - 92px);
    overflow-y: auto;
  }

  &.drawer--no-header.drawer--no-footer {
    .drawer__content {
      height: 100%;
      max-height: 100%;
    }
  }

  .drawer-form__gradient {
    display: none;
  }

  .drawer-form__footer {
    height: 40px;
  }

  .drawer-form__content {
    min-height: calc(100dvh - 112px);
    margin: 0;
    padding: 24px;
  }

  .drawer__footer, .drawer-form__footer-content {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    height: 40px;

    .button:not(:last-child) {
      margin-right: 8px;
    }

    @media (max-width: $mobile-max-width) {
      padding: 0 !important;

      .button {
        height: 100%;
        width: 50%;
        border-radius: 0;
        margin: 0;
        height: 40px;

        &:not(:last-child) {
          margin-right: 0;
        }

        &:first-child:last-child {
          width: 100%;
        }
      }
    }
  }

  .drawer-form__header {
    background: $white;
    border-bottom: 1px solid $background-color;
    pointer-events: auto;
  }

  .drawer-form__footer-content {
    grid-gap: 0;
    height: 40px;
  }
}
