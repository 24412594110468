.hidden-panel {
  opacity: 0;
  height: 0;
  overflow: hidden;
  @include default-transition((opacity, height));

  &--show {
    opacity: 1;
    height: auto;
    overflow: visible;
    @include default-transition((opacity, height));
  }
}
