.payments-filter, .invoices-filter {
  .filter__fields {
    display: grid;
    grid-template-columns: repeat(5, minmax(15%, 1fr));
    grid-gap: 10px;
  }

  &--partner {
    .filter__content {
      display: flex;
    }

    .filter__fields {
      grid-template-columns: repeat(2, minmax(15%, 1fr));
      width: 50%;
    }

    .filter__footer {
      margin-top: 0;
      margin-left: 16px;

      .button {
        padding: 15px 35px;
      }
    }
  }
}

