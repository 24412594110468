.radio-button {
  .btn:hover {
    z-index: 0;
  }

  .btn:focus {
    z-index: 1;
  }

  &.btn-group-toggle {
    .button:not(.active) ~ .button:not(.active) {
      border-left: 1px solid #ccd0e1;
    }
  }
}
