.error-page {
  flex-grow: 1;
  padding: 115px;

  background: url("../../media/error-bg.jpg") no-repeat center center;
  background-size: cover;

  &__title {
    font-size: 150px;
    line-height: 180px;
    font-weight: bold;
    color: $base-dark;
  }

  &__description {
    font-size: 20px;
    color: $base-dark;
  }
}
