.html-viewer {
  position: relative;

  &__html {
    overflow: hidden;
    box-shadow: 0px -5px 15px -5px $white inset;

    word-break: break-word;
  }

  &__more {
    position: relative;
    display: flex;
    padding-top: 8px;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 16px;
    font-size: 11px;
    line-height: 16px;

    box-shadow: 0px -10px 14px 4px $white;

    &--opened {
      box-shadow: none;
      padding-top: 0;
    }

    .button {
      height: 16px;
      width: 24px;
      font-size: 10px;
      margin-right: 8px;

      .button__icon {
        margin-left: 0;
      }
    }
  }

  &__more-btn {
    padding: 0;
  }
}
