.wallet__balance {
  background-color: $base-dark;
  color: $white;
}

.wallet__balance-message {
  padding: 16px 20px;

  .button {
    margin-top: 8px;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid transparent;

    &:active, &:hover, &:focus {
      background: rgba(255, 255, 255, 0.2) !important;
      border: 1px solid transparent !important;
    }
  }

  .balance__header {
    padding-bottom: 0;
  }
}

.balance {
  padding: 12px 20px 16px 20px;

  z-index: 10;
  position: relative;

  &__header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    cursor: pointer;

    .icon {
      display: flex;
      align-items: center;
      height: 16px;
      width: 13px;
      justify-content: center;
      transform: rotateX(0deg);
      @include default-transition(transform);
    }

    &--opened {
      .icon {
        transform: rotateX(180deg);
      }
    }
  }

  &__currency-toggle {
    z-index: 12;

    & button {
      padding: 3px 10px;

      font-size: $base-font-size;
      line-height: $base-line-height;
      color: $white;

      background-color: rgba(255,255,255,0.2) !important;
      border: none;

      &:hover, &:active, &:focus  {
        background-color: rgba(255,255,255,0.2) !important;
        box-shadow: 0 0 0 0.2rem rgba(255,255,255,0.3) !important;
      }

      &:after {
        height: 7px;
        width: 7px;
        border-top: none;
        border-right: 1.7px solid;
        border-bottom: 1.7px solid;
        border-left: none;
        transform: rotate(45deg);
        margin-left: 8px;
      }
    }
  }

  &__title {
    display: flex;
    align-items: flex-end;
    font-size: $base-font-size;
    line-height: $base-line-height;
    color: $white;
    width: calc(100% - 20px);
  }

  &__value {
    font-size: 21px;
    line-height: 24px;
    font-weight: bold;
    color: $white;
  }

  &__item {
    display: block;
    padding: 0 20px 8px 20px;

    background-color: $base-dark;
    color: $white;

    &-title {
      display: flex;
      align-items: center;
      font-size: 13px;
      line-height: 16px;

      .icon {
        margin-left: 4px;
        height: 16px;
        font-size: 16px;

        svg {}
      }
    }

    &-value {
      font-size: 15px;
      line-height: 20px;
      font-weight: 600;
    }

    &--muted {
      color: $text-light-gray;
    }

    &:last-child {
      padding-bottom: 12px;
    }
  }

  &__lock-icon {
    color: $yellow;

    & svg {
      width: 21px;
      height: 24px;
    }
  }

  &__referral-icon svg {
    width: 24px;
    height: 24px;
  }
}
