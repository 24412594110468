$invoice-generating-message-font-size: 15px;

.invoices-generate-form {
  .date-picker__separated-fields, .date-picker__separated-fields-labels {
    grid-gap: 16px;
  }

  &__threshold {
    display: flex;
    align-items: center;

    strong {
      margin-left: 4px;
    }

    .icon {
      font-size: 17px;
      margin-left: 4px;
    }
  }

  &__source {
    margin-bottom: 0;

    .radio-group.control {
      display: flex;
      flex-wrap: wrap;

      .radio-group__option {
        margin-right: 2rem;
      }
    }
  }
}

.invoice-generating-message {
  display: flex;
  align-items: center;
  padding: 12px 20px;
  width: 100%;
  font-size: $invoice-generating-message-font-size;

  & > span {
    display: inline-flex;
    position: relative;
    width: $invoice-generating-message-font-size;
    min-width: $invoice-generating-message-font-size;
    height: $invoice-generating-message-font-size;
    margin-right: 8px;

    .loading {
      height: $invoice-generating-message-font-size;
      width: $invoice-generating-message-font-size;
    }
  }
}
