.roles-list {
  .rc-collapse-header {
    font-size: 13px;
    font-weight: 600;
  }

  .rc-collapse-content {
    padding-left: 5px;
  }

  &__label,
  & .field {
    margin-bottom: 10px;
  }

  &__rule-hint {
    font-size: 11px;
    line-height: 13px;
    margin-top: -5px;
    cursor: default;
    padding-left: 24px;
  }
}
