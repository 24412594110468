.two-column-block {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  &__left {
    display: block;
    padding-right: 32px;
    width: max-content;
    @include text-overflow-ellipsis();
  }

  &__left-wrapper {
    display: block;
    max-width: 50%;
  }

  &__right {
    display: block;
    text-align: end;
    @include text-overflow-ellipsis();
  }
}
