$rounded-icon-height: 38px;

.country-label {
  display: flex;
  align-items: center;

  &__icon {
    display: flex;
    align-items: center;
    width: 20px;
    min-width: 20px;
    height: 20px;
    margin-right: 0.5rem;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  &--rounded-icon {
    .country-label__icon {
      height: 24px;
      width: 24px;
      border-radius: 50%;
      margin-right: 0;
      justify-content: center;
      overflow: hidden;

      .icon, .icon svg {
        width: $rounded-icon-height;
        height: $rounded-icon-height;
      }

      .icon {
        overflow: unset;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: auto;
          height: 26px;
          object-position: center;
        }
      }
    }
  }

  &--icon {
    .country-label__icon {
      margin-right: 0;
    }
  }
}

.filter__preview-field {
  .country-label {
    display: inline-flex;

    &__icon {
      height: 16px;

      svg {
        height: 16px;
      }
    }
  }
}
