$offer-page-condition-goals-column-width: 350px;
$offer-page-condition-reward-column-width: 140px;

.offer-page {
  .rc-table-row-expand-icon-cell {
    display: none;
  }

  &__field {
    margin-bottom: 20px;
  }

  &__field:not(:last-child) {
    margin-bottom: 35px;
  }

  &__field-name-text {
    font-size: 1.5rem;
    font-weight: 600;
    margin-right: 9px;
    margin-bottom: 15px;
    display: inline-block;
  }

  &__badge {
    margin-right: 10px;
  }

  &__tracking-link {
    color: $text-gray;
  }

  &__country-badge {
    height: 24px;
    width: 24px;
    padding: 2px 8px !important;
    margin-right: 5px;
    margin-bottom: 5px;
  }

  &__index-column {
    width: 65px;
    text-align: center;
  }

  &__goals-table {
    margin-bottom: -1px;
  }

  &__conditions-table {
    tr:not([data-row-key]), tr[data-row-key=''] {
      &:hover {
        background: none;
        cursor: default;
      }

      & > td {
        padding: 0;
        border-top: none;
      }
    }

    .offer-view-form__expanded_row > td {
      background-color: #f7f8fa;
    }
  }

  &__tags {
    .tag-list {
      color: $text-gray;
      font-size: 11px;
      line-height: 16px;
    }
  }

  &__request-message {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    .icon {
      width: 17px;
      height: 17px;
      font-size: 16px;
      margin-right: 6px;
    }
  }

  &__sub-row {
    width: calc(100% - 65px - 1.54rem);
    margin-left: calc(65px + 1.54rem);
  }

  &__condition-badges {
    display: flex;
    flex-direction: row;
    padding: 13px 22px;
    margin-bottom: 10px;

    &-label {
      flex-shrink: 0;
      margin-right: 5px;
      padding-top: 3px;

      font-size: 13px;
      line-height: 20px;
      text-transform: uppercase;
      color: $text-gray;
    }

    & .badge {
      margin-right: 5px;
      margin-bottom: 10px;
    }
  }

  .tabs-ink-bar {
    display: none;
  }

  &__params-card {
    border-top: 4px solid $background-color;
  }

  &__partners-list {
    padding: 25px 30px 15px 25px;

    &-title {
      display: flex;
      align-items: center;

      margin-bottom: 10px;
    }

    .badge {
      margin-bottom: 10px;
      margin-right: 10px;
    }
  }

  &__form {
    width: 40%;
    min-width: 570px;
    margin: 0 auto;
  }

  &__form-poster {
    display: flex;
    flex-wrap: wrap;

    .field__label-container {
      width: 100%;
    }

    .input-image {
      margin: 0;
      margin-right: 12px;
    }

    .field__hint {
      margin-top: 0;
    }

    .symbol {
      background-color: $control-background-color;
    }

    .input-image__edit {
      display: none !important;
    }

    .input-image__actions {
      background: transparent !important;
    }

    img {
      max-width: 120px;
      max-height: 120px;
      width: 120px;
      height: 120px;
      object-fit: contain;
    }
  }

  .conditions-table {
    margin-bottom: -1px;
    width: calc(100% + 2px);

    thead th {
      background: $table-head-bg;
      font-weight: 600;
      text-transform: uppercase;
      font-size: 11px;
      line-height: 12px;
      vertical-align: middle;
      padding: 10px 12px;

      &:not(:last-child) {
        border-right: 1px solid #E1E5ED;
      }
    }

    &__empty {
      padding: 20px;
    }

    &__goals-column {
      padding-left: 20px;
    }

    th:last-child, td:last-child {
      padding-right: 20px;
    }

    &__goals-column, &__countries-column {
      width: #{$offer-page-condition-goals-column-width};
    }

    &__to-partner-column, &__from-advertiser-column, &__partners-column {
      width: #{$offer-page-condition-reward-column-width};
    }

    &__from-advertiser-column {
      width: calc(100% - #{$offer-page-condition-goals-column-width * 2} - #{$offer-page-condition-reward-column-width})
    }

    &__partners-column {
      width: calc(100% - #{$offer-page-condition-goals-column-width * 2} - #{$offer-page-condition-reward-column-width * 2})
    }
  }

  .offer-tab-mobile-button {
    display: none;
  }
}

.partner-offer-page {
  .conditions-table {
    &__to-partner-column {
      width: auto !important;
    }
  }
}

.offer-page-filter .filter__fields {
  display: grid;
  grid-template-columns: repeat(4, minmax(15%, 1fr));
  grid-gap: 10px;
}

.offer-page-filter--partner .filter__fields {
  grid-template-columns: repeat(5, minmax(10%, 1fr));

  .field__label-container {
    align-items: flex-start;
  }
}

.offer-partners-requests {
  display: flex;

  &__select {
    height: 100%;

    .input-select__control {
      z-index: 1;
      min-height: 0;
      height: 100%;
      width: 0;
      opacity: 0;
      border: none !important;
    }

    .input-select__menu {
      width: 400px;
      margin: 0;
    }

    .input-select__option {
      min-height: 42px;
      display: flex;
      width: 100%;
      align-items: center;
      box-shadow: none !important;

      &:focus-visible {
        outline: none;
      }

      &--is-selected {
        box-shadow: none;
      }
    }

    .input-select__option-content {
      display: flex;
      align-items: center;
      max-width: calc(100% - 5px);
      width: 100%;
    }
  }

  &__button.btn.button {
    background: rgba(73, 113, 255, 0.15);
    border-color: transparent;
    margin-bottom: 8px;
    padding: 6px 8px;
    border: 1px solid transparent;
    z-index: 2;

    &:not(:disabled):not(.disabled) {
      @media (hover: hover) {
        &:hover, &:focus, &:active {
          background: rgba(73, 113, 255, 0.20);
          background-color: rgba(73, 113, 255, 0.20);
          border-color: transparent;
        }
      }

      @media not (hover: hover) {
        &:hover, &:focus, &:active {
          background: rgba(73, 113, 255, 0.15);
          background-color: rgba(73, 113, 255, 0.15);
          border-color: transparent;
        }
      }
    }

    &:disabled, &.disabled {
      background: rgba(73, 113, 255, 0.15);
    }
  }

  &__count {
    background: $danger;
    color: $white;
    font-size: 12px;
    line-height: 16px;
    border-radius: 10px;
    padding: 0 4px;
    margin-left: 4px;
  }
}

.offer-partners-request {
  display: flex;
  align-items: center;
  width: 100%;

  &__label {
    display: block;
    width: calc(100% - 100px);

    @include text-overflow-ellipsis();
  }

  &__loading {
    position: relative;
    width: 19px;
    height: 19px;
  }

  &__actions {
    width: 100px;
    display: flex;
    justify-content: flex-end;

    .button {
      padding: 6px;
      margin-left: 8px;
      font-size: 12px;
      width: fit-content;
      height: fit-content;

      &__icon {
        width: 12px;
        min-width: 12px;
        height: 12px;
        min-height: 12px;
      }
    }

    &-accept {
      background: $green;
    }

    &-reject {
      background: $danger;
    }
  }
}

@include tabletLayout() {
  .offer-page {
    .card-body {
      padding-bottom: 0;
    }

    .page-header {
      border-bottom: 2px solid $background-color;
    }

    .offer-header {
      width: calc(100% - 24px);
      margin: 0 12px;
      padding: 12px 0;
      border-bottom: 1px solid $background-color;

      &__id {
        justify-content: space-between;
      }

      &__logo {
        width: 70px;
        height: 70px;
        margin-right: 12px;
      }

      &__info {
        width: calc(100% - 82px);
        border-bottom: none;

        .tag-list {
          margin-bottom: 0;
          margin-top: 8px;
        }
      }

      &__title {
        margin: 6px 0;

        .title {
          font-size: 19px;
          line-height: 20px;
        }
      }

      &__dropdown-toggle {
        border: none;
        padding: 8px;
        margin: -8px 0 -8px 0;
        color: $text-gray;
      }

      &__offer-dropdown {
        &.show, &:active, &:focus {
          .offer-header__dropdown-toggle {
            color: $base-dark;
          }
        }
      }

      .offer-active-switch {
        margin-bottom: 0;

        .switch.switch--with-bg {
          border-radius: 20px;
          padding: 4px 7px;
        }
      }
    }

    .offer-card {
      .card-body {
        margin: 0;
        padding: 0;
        width: 100%;
        flex-wrap: wrap;
      }

      &__main {
        width: 100%;
        padding-right: 0;
        margin-right: 0;
        border-right: 0;
      }

      &__list, &__links {
        padding: 12px 0;
        margin: 0 12px;
        width: calc(100% - 24px);
      }

      &__list {
        border-bottom: 1px solid $background-color;

        .titled-text__text {
          width: calc(100% - 120px - 12px);
          margin-left: 12px;
        }
      }

      &__links {
        border-top: 0;
        border-bottom: 1px solid $background-color;
      }

      &__header {
        margin-bottom: 0;
      }

      &__link {
        padding-top: 0;

        &:not(:last-child) {
          padding-bottom: 12px;
        }

        .titled-text {
          &__title {
            color: $base-dark;
            font-weight: 600;

            .button {
              padding: 4px !important;
              color: $base-dark;
              background-color: $control-disabled-background-color;
              width: 24px;
              height: 24px;

              svg {
                color: $base-dark;
              }
            }
          }
        }
      }

      &__info {
        width: 100%;
        max-width: 100%;
        padding: 12px 12px;
      }

      &__descriptions {
        padding-top: 12px;

        .tabbed-text__title {
          margin-bottom: 12px;
        }

        .tabbed-text__header {
          margin-bottom: 8px;
        }

        .tabbed-text__tabs {
          .tabbed-text__tab {
            line-height: 16px;

            &:not(.tabbed-text__tab--active) {
              background: $background-color;
            }
          }
        }

        .html-viewer__html {
          & > p:last-child {
            margin-bottom: 0;
          }
        }
      }

      &__referral-link-button {
        padding-bottom: 12px;
        border-bottom: 1px solid $background-color;
      }

      &__partners-list {
        padding-top: 12px;
        margin-bottom: 0;

        .more-cell {
          .badge {
            display: block;
            line-height: 16px;
            padding: 6px 8px;
            height: 28px;

            @include text-overflow-ellipsis();
          }
        }
      }

      &__note {
        padding-top: 12px;
      }
    }

    .table {
      table-layout: auto !important;

      .table__cell:not(.word-break-keep):not(.word-break-word) {
        word-break: keep-all;
      }
    }

    &__params-card {
      border: 0;
      border-top: 2px solid $background-color;

      .tabs__nav {
        padding: 8px 12px;
        height: 32px;
        box-sizing: content-box;
        overflow-x: auto;
        overflow-y: hidden;
        border-bottom: 0;
        background: none;

        @include default-scrollbar();
      }

      .tabs-tab-btn {
        white-space: nowrap;
        display: flex;
        align-items: center;
      }

      .tabs-nav {
        margin-right: 0;
      }

      .tabs-tab {
        margin-right: 0;

        &:not(:first-child) {
          margin-left: 8px;
        }
      }

      .tabs-tab-active {
        .offer-tab-mobile-button {
          display: inline-flex;
          height: 24px;
          width: 24px;
          color: $white !important;
          background-color: #7A97FF !important;
          border-color: #7A97FF !important;
          border-radius: 2px;
          box-sizing: content-box;
          margin: -5px -5px -5px 8px;

          .button__icon .icon svg {
            font-size: 22px;
            width: 1em !important;
            height: 1em !important;
          }
        }
      }
    }

    .offer-form-conditions-table--individual, .conditions-table-individual__empty {
      margin-top: 44px;
    }

    .conditions-search-hidden {
      .offer-form-conditions-table--individual, .conditions-table-individual__empty {
        margin-top: 0;
      }
    }

    .conditions-tabs__search {
      left: 12px !important;
      top: 50px !important;
      width: 400px !important;

      .conditions-tabs__search-label {
        display: none;
      }
    }

    .offer-partners-requests__select .input-select__menu {
      width: min(400px, calc(100dvw - 24px));
    }

    .countries-flags.countries-flags--stacked {
      min-height: 20px;
      min-width: 20px;
      margin-left: 4px;

      .badge {
        height: 16px;
        width: 16px;

        .country-label__icon {
          height: 16px;
          width: 16px;
          min-width: 16px;

          .icon, .icon svg {
            height: 16px;
            width: 16px;
          }
        }
      }

      .more-cell {
        margin-right: 0;
        flex-wrap: nowrap;
        align-items: center;
      }

      .more-cell__more-badge {
        width: 16px;

        &:after {
          display: block;
          content: '...';
          font-size: 18px;
          margin: -10px 0 0 -2px;
        }
      }

      .more-cell__more-label {
        display: none;
      }
    }

    .offer-form-conditions-table__countries-column {
      width: 100px;
      min-width: 100px;
    }

    .table thead tr th {
      white-space: nowrap;
    }

    .promo-materials-page__preview {
      max-width: 200px;
      max-height: 100px;
    }

    .promo-materials-page__common-table {
      .countries-flags.countries-flags--stacked {
        .more-cell__more-badge {
          &:after {
            margin: -10px 0 0 0px;
          }
        }
      }
    }
  }

  .offer-form-conditions-table {
    &--partner {
      .offer-form-conditions-table__reward-column {
        width: 250px;
        min-width: 250px;
      }
    }
  }

  .partner-offer-page {
    .offer-card__title {
      margin: 0;
      margin-top: 6px;
    }

    .offer-header__bottom {
      margin-bottom: 0;

      .button, .offer-page__request-message {
        margin-top: 8px;
      }
    }

    .offer-page__actions {
      flex-wrap: wrap;
    }

    .offer-card__descriptions {
      padding-top: 12px;
    }

    .html-viewer__html {
      p:last-child {
        margin-bottom: 0;
      }
    }

    .offer-form-conditions-table__icon-expand {
      position: initial;
    }
  }

  .offer-page__form {
    width: 100%;
    min-width: unset;
    max-width: $mobile-max-width;
    .widget-layout {
      & > .loading {
        margin: -12px;
        width: calc(100% + 24px);
        height: calc(100% + 24px);
        z-index: 101;
      }
    }

    .input-group-text {
      padding: 7px 11px 7px 7px;
    }

    .offer-page__form-poster {
      .field__hint {
        width: calc(100% - 132px);
      }
    }

    .text-editor__block-type {
      width: 100px;
    }

    .text-editor__button {
      width: 24px;
      height: 24px;
      padding: 4px;
      margin: 0 3px;
    }

    hr {
      display: none;
    }
  }

  .partner-offer {
    &__promo-codes {
      .promo-codes-page--partner {
        .promo-codes-page__title {
          align-items: flex-start;
          padding: 8px 12px;
        }

        .promo-codes-page__title-text {
          line-height: 32px;
        }

        .promo-codes-page__title-right {
          flex-direction: column-reverse;
          align-items: flex-end;

          .button {
            margin-left: 0;
          }
        }

        .promo-codes-help-tooltip {
          margin-top: 8px;

          & > span {
            text-align: right;
            width: 100%;
            display: inline-block;
          }
        }
      }
    }
  }

  .promo-codes-table {
    &__offer-empty-block-arrow {
      display: none;
    }

    &__offer-empty-block {
      height: auto;
    }
  }

}

@include mobileLayout() {
  .offer-page__form-buttons {
    display: flex;
    justify-content: unset !important;
    margin: 24px -13px 0;
    position: sticky;
    bottom: 0;
    background: $white;
    z-index: 110;

    & > * {
      width: 50%;
    }

    a .button {
      width: 100%;
    }

    .button {
      border-radius: 0;
      height: 40px;
    }
  }

  .offer-page {
    .conditions-tabs__search {
      width: calc(100% - 24px) !important;
    }
  }
}
