.fieldset {
  &__header {
    display: flex;
    align-items: center;
  }

  &__label {
    font-size: 1rem;
    font-weight: 400;
    color: #3F4254;
  }

  &__help {
    margin-left: 0.75rem;
  }
}
