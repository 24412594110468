$view-form-font-size: 13px;
$view-form-line-height: 20px;
$view-form-info-width: 413px;
$view-form-info-margin: 20px;
$view-form-data-margin: 16px;
$view-form-data-margin-x: 20px;

.view-form-new {
  display: flex;
  flex-wrap: wrap;
  background: $white;
  font-size: $view-form-font-size;
  line-height: $view-form-line-height;

  &__badges {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 6px;
  }

  &__info {
    width: $view-form-info-width;
    border-right: 1px solid $background-color;
    box-sizing: border-box;
    padding: 20px;

    .two-column-block {
      &:not(:last-child) {
        margin-bottom: 12px;

      }
      &__left {
        color: #6E7A83;
      }
    }

    .tags-view-field__tags {
      @extend .view-form-new__badges;
    }
  }

  &__badge {
    padding: 6px 8px;
    height: auto;
    overflow: hidden;
    white-space: normal;
    word-break: break-word;
    text-align: left;
  }

  &__info-badge.badge {
    background: $background-color;
    color: $base-dark;
  }

  &__status {
    .badge.bg-secondary {
      background-color: $text-light-gray !important;
    }

    &:not(:empty) {
      margin-top: 6px;
    }
  }

  &__info-block {
    padding-bottom: $view-form-info-margin;
    width: 100%;
    position: relative;

    &--divider {
      border-bottom: 1px solid $background-color;
      margin-bottom: $view-form-info-margin;
    }

    &--icon {
      padding-left: 36px;
    }
  }

  &__info-block-icon {
    font-size: 20px;
    padding: 2px;
    position: absolute;
    left: 0;
    top: 0;
  }

  &__info-header {
    font-size: 15px;
    line-height: 16px;
    font-weight: 600;
    margin-bottom: $view-form-data-margin;
  }

  &__data {
    width: calc(100% - #{$view-form-info-width});

    .table.table--light {
      margin-left: -20px;
      margin-right: -12px;
      width: calc(100% + 32px);

      .empty-table {
        margin: 0 0 -8px 0;
      }
    }
  }

  &__data-block {
    width: 100%;
    padding: 10px $view-form-data-margin-x 16px $view-form-data-margin-x;
    position: relative;

    &--divider {
      border-bottom: 1px solid $background-color;
    }
  }

  &__block {
    &--ignore-padding {
      width: calc(100% + #{$view-form-data-margin-x * 2});
      margin-left: -$view-form-data-margin-x;
    }
  }

  &__data-block-action {
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    top: 10px;
    right: 20px;
    height: 30px;

    .input-text__wrapper {
      width: 300px;

      & + .input-group-append {
        .icon {
          color: $control-placeholder-color;
        }
      }
    }

    .button.button--square {
      height: 30px;
      width: 30px;
      font-size: 20px;

      .icon {
        font-size: 20px;
      }
    }
  }

  &__data-header {
    font-size: 18px;
    line-height: 30px;
    font-weight: 500;
    margin-bottom: $view-form-data-margin;
  }

  &__edit-button {
    font-size: 20px;
    height: 30px;
    width: 30px;
    min-width: 30px;
    margin-left: 8px;

    .icon {
      font-size: 20px;
    }
  }

  &__info-title {
    display: block;
    font-size: 18px;
    line-height: 30px;
    font-weight: 500;
    @include text-overflow-ellipsis();
  }

  &__status-switcher {
    .switch {
      &--label-start {
        .switch__label {
          padding-right: 8px;
        }
      }

      &--label-end {
        .switch__label {
          padding-left: 8px;
        }
      }

      .form-check-input:not(:checked) {
        --bs-form-switch-bg: var(--switch-white-circle);
        border-color: transparent;
        background-color: $danger;
      }
    }
  }

  &__actions-button {
    width: 24px;
    height: 24px;
    background: $background-color;
    display: flex;
    align-items: center;

    & > span {
      display: flex;
      align-items: center;
    }

    &:hover {
      .icon svg path {
        fill: $primary;
      }
    }
  }

  &__links {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 6px;

    a {
      text-decoration: none;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__dropdown {
    .dropdown-menu {
      .button {
        .icon {
          font-size: 16px;
        }

        &.btn-transparent {
          .icon {
            color: $text-gray;
          }
        }
      }
    }
  }
}
