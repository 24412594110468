.card-cell {
  display: flex;
  align-items: center;

  &__image {
    flex-shrink: 0;

    width: 40px;
    height: 40px;

    margin-right: 17px;

    border-radius: $border-radius;

    &--empty {
      background-color: $text-light-gray;
    }
  }

  &__text {
    &--bold {
      font-weight: 600;
    }
  }

  &__sub-text {
    color: $text-gray;
    font-weight: 400;
  }

  &.header-cell {
    .card-cell__sub-text {
      color: $table-header-color;
    }
  }
}
