.textarea {
  @include control();
  transition: none;
}

.field--control-Textarea {
  .field__control {
    position: relative;
  }
}

@mixin textarea-floating-label-padding($padding-left) {
  .textarea__floating-label {
    left: $padding-left;
  }

  .textarea--floating-label {
    padding-left: calc(#{$padding-left} - 2px);
  }

  .textarea--floating-label:not(:placeholder-shown) {
    padding-left: $padding-left;
  }
}

.textarea--floating-label::placeholder {
  visibility: hidden;
  opacity: 0.01;
}

.textarea--floating-label {
  padding-top: 15px;
  padding-bottom: 15px;
  @include default-transition((padding-top, padding-bottom, line-height, padding-left), ease-in-out);
  transition-duration: 0.1s;
}

.textarea--floating-label:not(:placeholder-shown) {
  padding-top: 23px;
  padding-bottom: 7px;
  line-height: 16px;
}

.textarea__floating-label {
  position: absolute;
  color: $text-gray;
  @include default-transition((top, font-size, line-height), ease-in-out);
  transition-duration: 0.1s;
  cursor: text;
}

.textarea:placeholder-shown ~ .textarea__floating-label {
  top: 14px;
  font-size: 13px;
  line-height: 20px;
}

.textarea:not(:placeholder-shown) ~ .textarea__floating-label {
  top: 8px;
  font-size: 11px;
  line-height: 16px;
}

@include textarea-floating-label-padding($input-btn-padding-x);
