.wallet-fields {
  display: block;
  width: 100%;

  &__field {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }
  &__field-name {
    display: flex;
    max-width: 100%;
    overflow: hidden;
    word-break: break-word;
    padding-right: 10px;
  }
  &__field-value {
    display: flex;
    max-width: 100%;
    overflow: hidden;
    word-break: break-word;
  }
}
