$condition-form-max-height: 95vh;
$condition-form-title-right-width: 180px;
$condition-form-individual-title-right-width: 450px;
$condition-form-individual-qualification-title-right-width: 290px;
$goal-edit-modal-z-index: $modal-z-index + 10;
$goal-edit-modal-mobile-padding: 4px;

.conditions-edit-page {
  margin-top: 0;

  .react-tags__search-input::placeholder {
    font-size: $base-font-size;
  }

  .input-group-text {
    color: $base-dark;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background: $white;
    padding: $conditions-footer-padding;
    border-top: $horizontal-menu-border-bottom;
    height: $conditions-footer-height;

    position: fixed;
    bottom: 0;
    z-index: #{$input-select-menu-z-index - 1};

    a {
      border: none;
    }
  }
}

.conditions-edit-form {
  display: flex;
  flex-direction: row;
  width: 100%;

  &__modal {
    .modal-dialog {
      max-width: calc(min(1000px, 90vw));
      max-height: $condition-form-max-height;
      align-items: flex-start;
      margin: calc((99vh - #{$condition-form-max-height}) / 2) auto;
    }

    .modal__body {
      padding: 0;
      height: calc(100% - 33px);
    }

    .modal-content {
      max-height: $condition-form-max-height;
    }
  }

  .field {
    margin-bottom: 20px;
  }

  &__card {
    padding: 0;
    max-height: calc(#{$condition-form-max-height} - 33px);
    border-radius: 0;
    overflow: auto;
    position: relative;
    @include default-scrollbar(6px, 4px, 0.15);

    .loading {
      //margin: -#{$conditions-card-padding + 1px};
      //padding: #{$conditions-card-padding + 1px};
      box-sizing: content-box;
    }
  }

  &__fields-block {
    .field {
      margin-bottom: 8px;
    }

    .group-append--field .input-group-append .field {
      margin-bottom: 0;
    }
  }

  &__full-page {
    width: 100%;
    height: 50vh;
    display: flex;
    align-items: center;
    position: relative;
  }

  .empty-data-block {
    .icon {
      color: $text-gray;
    }

    &__text {
      font-size: 17px;
      line-height: 20px;
    }

    .button {
      margin-top: 8px;
    }
  }

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    font-weight: 600;
    font-size: 17px;
    line-height: 24px;
    position: relative;
    flex-wrap: wrap;
    margin-bottom: 10px;

    .button {
      width: 24px;
      min-width: 24px;
      height: 24px;
    }
  }

  .input-select__menu {
    z-index: 1 !important;
  }

  &__benefit-card {
    .input-select__menu {
      z-index: 50 !important;
    }
  }

  &__condition-wrapper {
    box-shadow: 0px 10px 30px rgba(46, 62, 154, 0);
    @include default-transition(box-shadow);
  }

  &__condition-wrapper--active {
    box-shadow: 0px 10px 30px rgba(46, 62, 154, 0.1);
  }

  &__title-left, &__title-right {
    display: flex;
    position: relative;
    align-items: center;
  }

  &__title-left {
    align-items: flex-start;
    width: calc(100% - #{$condition-form-title-right-width});

    &--individual {
      width: calc(100% - #{$condition-form-individual-title-right-width});
    }

    &--qualification {
      width: calc(100% - #{$condition-form-individual-qualification-title-right-width});
    }
  }

  &__title-right {
    display: flex;
    justify-content: flex-end;
    white-space: nowrap;
    padding-left: 20px;
    height: 28px;
    width: $condition-form-title-right-width;

    &--individual {
      flex-direction: row-reverse;
      justify-content: flex-start;
      width: $condition-form-individual-title-right-width;

      .field {
        height: 28px;
        line-height: 28px;
      }
    }

    &--qualification {
      flex-direction: row;
      justify-content: flex-end;
      flex-wrap: wrap;
      width: $condition-form-individual-qualification-title-right-width;
      height: 56px;

      .field {
        height: 28px;
        line-height: 28px;
      }
    }

    .field {
      margin-bottom: 0;

      &__label {
        font-weight: 400;
      }
    }
  }

  &__title-counter {
    position: absolute;
    right: calc(100% + 10px);
  }

  &__title-formula {
    margin-top: 8px;
    font-weight: 400;
    width: 100%;

    .formula-label__part--goal {
      color: $base-dark;
    }

    .formula-label__part--coefficient {
      color: $base-dark;
      font-weight: 600;
    }
  }

  &__title-formula-coefficients {
    width: 100%;
    padding-bottom: 24px;
    border-bottom: 1px solid $background-color;
    margin-bottom: 16px;
    margin-top: -5px;

    .field {
      margin-bottom: 0;
    }

    .field__hint {
      margin-top: 0;
      font-size: 12px;
      line-height: 20px;
    }
  }

  &__goals-type {
    margin-bottom: 8px;

    .badge {
      text-transform: uppercase;

      &-light-secondary {
        color: $base-dark;
      }
    }
  }

  &__header {
    width: 100%;
    background-color: $white;
    position: sticky;
    top: 0;
    z-index: 4;
    padding: 0 $conditions-card-padding-x;
  }

  &__actions {
    width: 100%;
    background-color: $white;
    position: sticky;
    bottom: 0;
    z-index: 4;
    padding: 14px 52px 33px;
    border-top: 1px solid transparent;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  &__shadow {
    width: 100%;
    padding-bottom: 12px;
    box-shadow: 0px 2px 6px rgba(46, 62, 154, 0.1);
    z-index: 2;
    position: sticky;
    background: $white;
  }

  &__footer-shadow {
    box-shadow: 0px -2px 10px rgba(46, 62, 154, 0.1);
  }

  &__cover {
    //position: absolute;
    display: block;
    height: 10px;
    min-height: 10px;
    background: $white;
    width: 100%;
    z-index: 3;
  }

  &__overflow {
    height: 100%;
    width: 100%;
    padding: $conditions-card-padding;
  }

  &__goals {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    white-space: break-spaces;

    & > .icon {
      font-size: 16px;
      padding: 4px 6px;
      margin-bottom: 6px;
    }

    .button {
      width: 24px;
      height: 24px;
    }
  }

  &__goals-title {
    display: contents;

    & > span:last-child {
      margin-right: 8px;
    }
  }

  &__goal {
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 6px;
  }

  &__plus {
    padding: 0 6px;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 6px;
  }

  &__benefit-card-content {
    .benefit-webmaster-payment-help {
      padding-left: 15px;
    }
  }

  &__benefit-card-title {
    margin-top: 24px;
    margin-bottom: 12px;
    font-weight: 600;
  }

  &__benefit-title {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
    font-size: 15px;
    font-weight: 600;
    margin: 24px 0 12px 0;

    .field {
      margin-bottom: 0;
    }
  }

  .benefit-remove-button {
    cursor: pointer;
    color: $dark-50;
    font-size: 16px;
    margin-left: 8px;
  }

  &__limit-action {
    .input-select__menu {
      min-width: 450px;
    }
  }

  .sub-id-field {
    width: #{100%/3};
    position: relative;
    margin: 0 5px;

    .field--control-InputTags {
      position: relative;

      .field__label-container {
        display: flex;
        position: absolute;
        pointer-events: none;
        z-index: 2;
        left: 11px;
        top: 6px;

        .field__label {
          color: $text-gray;
        }
      }

      .react-tags {
        padding-left: 65px;
      }
    }
  }

  .row {
    margin-right: -#{$conditions-column-padding};
    margin-left: -#{$conditions-column-padding};

    div[class*=' col-'], div[class^='col-'] {
      padding-left: $conditions-column-padding;
      padding-right: $conditions-column-padding;
    }
  }

  &__error {
    margin-bottom: $conditions-card-padding;
  }

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;

    & .conditions-edit-form__card {
      width: 100%;
      border: none;
      border-bottom: $conditions-border;
    }
  }

  &__hidden-fields {
    display: flex;
    flex-direction: row;

    .field {
      margin-bottom: 0;
    }

    & > *:first-child {
      margin-right: $conditions-card-padding;
    }
  }

  &__list {
    display: block;
    width: 100%;
    height: 500px;
  }

  .loading-condition {
    display: block;
    width: 100%;
    height: 100%;
    background: red;
    margin: 10px 0;
  }
}

.goal-edit-form-modal {
  z-index: $goal-edit-modal-z-index;

  .modal-dialog {
    max-width: calc(min(max($mobile-min-width, 100dvw), 870px) - 1rem);

    .row {
      margin-right: -#{$conditions-column-padding};
      margin-left: -#{$conditions-column-padding};

      div[class*=' col'], div[class^='col'] {
        padding-left: $conditions-column-padding;
        padding-right: $conditions-column-padding;
      }
    }

    .field {
      margin-bottom: 1rem;
    }
  }
}

.goal-edit-form {
  &__event-block-wrapper {
    overflow: hidden;
  }

  &__event-block {
    margin-top: -100%;
    transform-origin: top;
    transition: margin-top 0.5s;

    &--visible {
      margin-top: 0;
    }
  }

  &__presets {
    margin-bottom: 1rem;
  }
}

.goal-edit-form-modal-backdrop {
  z-index: $goal-edit-modal-z-index - 10;
}

#page-end-element {
  height: 40px;
}

@include tabletLayout() {
  &.conditions-edit-form__modal.conditions-edit-form {
    .modal-header {
      padding: 0;
      height: 0;
      z-index: 10;
      border-bottom: none;
    }

    .modal-body {
      padding: 0;
      max-height: 100%;
    }

    .modal-content {
      overflow: auto;
      max-height: 95dvh;
    }

    .modal-body {
      overflow: unset;
      max-height: unset;
    }

    .conditions-edit-form {
      &__header {
        padding: 12px 12px 0 12px;
        position: initial;
      }

      &__overflow {
        padding: 0 12px;
      }

      &__shadow, &__cover {
        display: none;
      }

      &__title {
        flex-direction: column;
      }

      &__title-left, &__title-right {
        width: 100%;
        padding-left: 0;
        justify-content: flex-start;
      }

      &__title-right {
        display: flex;
        flex-wrap: wrap;
        min-height: 28px;
        height: auto;
        flex-direction: row;
        margin-top: 8px;

        .field {
          margin-right: 12px;
        }
      }

      &__card {
        max-height: 100dvh;
        overflow-x: hidden;

        @include default-scrollbar();

        .sub-id-field {
          margin: 0;
        }
      }

      &__card {
        max-height: unset;
        overflow: unset;
      }

      &__actions {
        margin-top: 12px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        padding: 8px 20px;
        z-index: 100;

        .button:not(:last-child) {
          margin-right: 8px !important;
          border: none !important;
          background-color: $control-disabled-background-color;
        }

        @media (max-width: $mobile-max-width) {
          padding: 0;

          .button {
            height: 100%;
            width: 50%;
            border-radius: 0;
            margin: 0;

            &:not(:last-child) {
              margin-right: 0 !important;
            }

            &:first-child:last-child {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .goal-edit-form {
    &__event-block {
      margin-top: -120%;
    }

    &__event-block--visible {
      margin-top: 0;
    }
  }
}


@include mobileLayout() {
  &.conditions-edit-form__modal.conditions-edit-form {
    .modal-content {
      max-height: 100%;
    }

    .conditions-edit-form {
      &__limit-action {
        .input-select__menu {
          min-width: unset;
        }
      }

      &__actions {
        padding: 0;
        height: $horizontal-menu-height;
      }

      &__card {
        max-height: unset;
        overflow: unset;
      }
    }
  }

  .goal-value-from, .goal-value-to {
    width: 50%;
  }
}


@include mobileLayout(450px) {
  &.goal-edit-form-modal {
    .field__label {
      font-size: 11px;
    }

    .row {
      margin-right: -$goal-edit-modal-mobile-padding;
      margin-left: -$goal-edit-modal-mobile-padding;

      div[class^=col], .goal-edit-form__event-block-switcher {
        padding-left: $goal-edit-modal-mobile-padding;
        padding-right: $goal-edit-modal-mobile-padding;
      }
    }
  }
}
