$base-color: #4971FF;
$gray-100: #F7F8FA;
$green: #31CCA7;
$text-gray: #9099B1;
$link: $base-color;
$background-color: #EFF1F5;
$azure-color: #628AF1;

$enable-shadows: true;

$mobile-min-width: 375px;
$mobile-max-width: 640px;
$tablet-max-width: 1366px;

$body-bg: #ffffff;
$base-font-size: 13px;
$base-line-height: 16px;
$hover-bg: #eef0f8;

$box-shadow: 0px 10px 30px rgba(63, 66, 84, 0.1);

// Color system

$white: #FFFFFF;
$primary: $base-color;
$success: #25CC86;
$danger: #F64E60;
$warning: #FF8049;
$info: #00B4CD;
$secondary: #7c86a4;
$purple: #FF28D0;
$orange: #FFB660;
$azure: $azure-color;
$light: #f3f6f9;
$dark: #181c32;
$base-dark: #3F4254;
$muted: $hover-bg;
$link-color: $base-color;
$carrot: #FF8049;
$green: #25CC86;
$violet: #CC61FF;

$dark-75: #3f4254;
$dark-65: #5e6278;
$dark-50: #7e8299;
$dark-25: #d1d3e0;

$light-primary: #E4EAFF;
$light-success: rgb(211, 248, 238);
$light-danger: rgb(255, 219, 225);
$light-warning: rgb(255, 238, 222);
$light-dark: rgb(242, 242, 244);
$light-carrot: rgba(255, 222, 209);
$light-azure: rgba(98, 138, 241, 0.1);
$light-secondary: rgb(231, 233, 234);
$light-info: rgba(0, 180, 205, 0.15);
$light-violet: rgb(230, 209, 245);

$transparent-success: rgba(27,197,189,.1);
$transparent-primary: rgba(54,153,255,.1);
$transparent-danger: rgba(246,78,96,.1);
$transparent-warning: rgba(255,168,0,.1);
$transparent-white: rgba(255,255,255,.1);

$gray-100: #f8f9fa;
$gray-200: #e9ecef;

// Custom color

$text-light-gray: #B9BFCF;
$condition-text-gray: #7E8299;
$yellow: #FFDF6D;
$border-gray: #E3EAFA;
$gray-semi-dark: #E1E5ED;
$table-head-bg: $gray-200;

// Define common padding and border radius sizes and more.

$border-radius: 4px;
$border-width: 1px;

// Typography

$font-family-base: Montserrat, Helvetica, "sans-serif";
$font-size-root: 13px;
$font-size-base: 1rem;
$font-size-lg: 1.08rem;
$font-size-sm: 12px;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 600;
$font-weight-bolder: 700;

$h1-font-size: 2rem;
$h2-font-size: 1.75rem;
$h3-font-size: 1.5rem;
$h4-font-size: 1.35rem;
$h5-font-size: 1.25rem;
$h6-font-size: 1.175rem;

$display1-size: 5.5rem;
$display2-size: 4.5rem;
$display3-size: 3.5rem;
$display4-size: 2.5rem;

$text-muted: #b5b5c3;

$blockquote-small-color: $dark-50;

// Buttons + Forms
$input-btn-padding-y: 7px;
$input-btn-padding-x: 11px;
$input-btn-line-height: 1.23;

$input-btn-focus-color: #69b3ff;

$input-btn-padding-y-sm: 7px;
$input-btn-padding-x-sm: 11px;
$input-btn-font-size-sm: 1rem;
$input-btn-line-height-sm: 1.23;

$input-btn-padding-y-lg: 11px;
$input-btn-padding-x-lg: 18px;
$input-btn-font-size-lg: 15px;
$input-btn-line-height-lg: 1.23;

$input-btn-border-radius: 4px;

// Nav

$nav-link-padding-y: 0.75rem;
$nav-link-padding-x: 1.9rem;
$nav-link-color: #a2a3b7;
$nav-link-hover-color: $white;
$nav-link-hover-bg-color: #1a1a27;
$nav-link-active-bg: #1a1a27;
$nav-link-active-color: $white;
$nav-link-icon-color: #494b74;
$nav-link-hover-icon-color: #494b74;
$nav-link-icon-margin-right: 0.9rem;

// Header

$header-height: 60px;
$header-bg: $white;
$header-padding: 16px 20px;
$header-breadcrumbs-padding-y: 8px;
$header-with-breadcrumbs-padding: $header-breadcrumbs-padding-y 20px $header-breadcrumbs-padding-y 20px;
$header-with-breadcrumbs-title-padding: $header-breadcrumbs-padding-y 20px 16px 20px;
$header-font-size: 12px;

// Footer

$footer-bg: none;
$footer-padding-y: 6px;
$footer-padding-x: 0;
$footer-padding: $footer-padding-y $footer-padding-x;
$footer-font-size: 12px;
$footer-line-height: 16px;

// Horizontal Menu

$horizontal-menu-bg: transparent;
$horizontal-menu-color: $base-dark;
$horizontal-menu-height: 44px;
$horizontal-menu-padding: 0 20px;
$horizontal-menu-border-bottom: 1px solid $background-color;
$horizontal-menu-nav-item-padding: 12px 0;
$horizontal-menu-nav-item-margin: 0 12px;

// Cards

$card-spacer-x: 1.5rem;
$card-border-color: $white;
$card-cap-border-color: rgba(#000000, .125);
$card-cap-bg: $white;
$card-cap-color: $dark;
$card-cap-padding: 1.5rem;

// Dropdowns

$dropdown-link-color: $base-dark;
$dropdown-link-hover-bg: $light-primary;
$dropdown-link-hover-color: $base-dark;
$dropdown-link-active-bg: $light-primary;
$dropdown-link-active-color: $base-dark;
$dropdown-font-size: $base-font-size;
$dropdown-item-padding-y: 8px;
$dropdown-item-padding-x: 12px;
$dropdown-inner-border-radius: 4px;
$dropdown-border-color: transparent;
$dropdown-border-radius: 4px;
$dropdown-box-shadow: 0px 10px 30px 0px #3F42541A;
$dropdown-spacer: 10px;
$dropdown-min-width: 160px;
$dropdown-padding-x: 0;
$dropdown-padding-y: 4px;
$dropdown-bg: $white;

// Controls

$control-padding: $input-btn-padding-y $input-btn-padding-x;
$control-font-size: $base-font-size;
$control-line-height: $base-line-height;
$control-min-height: 32px;
$control-color: $base-dark;
$control-background-color: #F4F6FB;
$control-border: $border-width solid $control-background-color;
$control-border-radius: $input-btn-border-radius;
$control-focus-background-color: $control-background-color;
$control-focus-border-color: $primary;
$control-focus-border: $border-width solid $control-focus-border-color;
$control-focus-box-shadow: none;
$control-label-color: $base-dark;
$control-disabled-color: $text-gray;
$control-disabled-background-color: #F6F6F9;
$control-disabled-label-color: $control-label-color;
$control-disabled-opacity: 1;
$control-margin-bottom: 12px;
$control-placeholder-color: $text-gray;

$control-error-color: $danger;
$control-error-background-color: $control-background-color;
$control-error-font-size: 0.9rem;
$control-error-margin-top: 0.25rem;

$control-help-color: $text-muted;
$control-help-font-size: 14px;
$control-hint-font-size: 0.9rem;

// Buttons

$btn-box-shadow: none;
$btn-focus-box-shadow: none;
$btn-active-box-shadow: none;

$btn-font-size: 1rem;
$btn-line-height: $base-line-height;
$btn-border-radius: $input-btn-border-radius;
$btn-border-radius-sm: $btn-border-radius;

$btn-border-radius-lg: $btn-border-radius;

// Tables

$table-border-color: $background-color;
$table-header-border-color: $gray-semi-dark;
$table-border: 1px solid $table-border-color;
$table-header-border: 1px solid $gray-semi-dark;
$table-color: $base-dark;
$table-header-color: $base-dark;
$table-header-font-size: 11px;
$table-header-line-height: 12px;
$table-header-border-color: $gray-semi-dark;
$table-header-font-weight: $font-weight-bold;
$table-header-background-light: $background-color;
$table-active-background-light: $table-header-background-light;
$table-inner-background: #F6F6F9;
$table-accent-bg: transparent;
$table-hover-bg: $table-header-background-light;
$table-checked-bg: rgb(222, 228, 255);
$table-checked-hover-bg: rgb(199, 210, 255);
$table-th-padding-top: 6px;
$table-th-padding-left: 12px;
$table-th-padding: $table-th-padding-top $table-th-padding-left;
$table-td-padding-top: 6px;
$table-td-padding-left: 12px;
$table-td-padding: $table-td-padding-top $table-td-padding-left;

$empty-color: #595D6E;

$table-dark-bg: $dark;

// Badges

$badge-font-size: 11px;
$badge-font-weight: 400;
$badge-height: 20px;
$badge-line-height: 16px;
$badge-padding-y: 2px;
$badge-padding-x: 5px;
$badge-border-radius: $border-radius;

$badge-pill-border-radius: 1rem;

$badge-font-size-sm: 10px;
$badge-height-sm: 16px;
$badge-line-height-sm: 13px;

$badge-font-size-lg: 13px;
$badge-height-lg: 24px;
$badge-line-height-lg: 20px;

$badge-font-size-xl: 13px;
$badge-height-xl: 24px;
$badge-line-height-xl: 20px;

// Forms

$input-group-addon-color: $base-dark;
$input-group-addon-bg: $control-background-color;
$input-group-addon-border-color: transparent;
$input-box-shadow: none;
$input-disabled-bg: $control-background-color;
$input-placeholder-color: $text-muted;
$input-plaintext-color: $base-dark;

$custom-checkbox-width: 16px;
$custom-checkbox-height: 16px;
$custom-control-indicator-checked-color: $white;
$custom-checkbox-indicator-border-radius: 4px;
$custom-checkbox-indicator-border-width: 2px;
$custom-checkbox-indicator-background-sizes: 55% / 66% 66% no-repeat;
$custom-checkbox-indicator-icon-checked: url("data:image/svg+xml,<svg width='11' height='9' viewBox='0 0 11 9' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M10.3135 1.00725C10.704 1.39777 10.704 2.03094 10.3135 2.42146L4.24253 8.49242C3.85201 8.88295 3.21884 8.88295 2.82832 8.49242L0.292893 5.957C-0.0976311 5.56647 -0.0976311 4.93331 0.292893 4.54278C0.683417 4.15226 1.31658 4.15226 1.70711 4.54278L3.53542 6.3711L8.89928 1.00725C9.2898 0.616724 9.92297 0.616724 10.3135 1.00725Z' fill='#{$custom-control-indicator-checked-color}'/></svg>");
$form-check-input-checked-bg-image: $custom-checkbox-indicator-icon-checked;

$custom-radio-width: 16px;
$custom-radio-height: 16px;

// Modals

$modal-padding-y: 12px;
$modal-padding-x: 32px;
$modal-padding-mobile-y: 24px;
$modal-padding-mobile-x: 24px;
$modal-footer-margin-between: 6px;
$modal-z-index: 1050;
$modal-padding: $modal-padding-y $modal-padding-x;
$modal-header-border-color: rgba(63, 66, 84, 0.15);
$modal-header-font-size: 24px;
$modal-header-font-weight: 600;
$modal-title-line-height: 28px;
$modal-header-border-color: transparent;
$modal-footer-border-color: transparent;
$modal-inner-padding: $modal-padding;
$modal-header-padding: #{$modal-padding-x - 1px} $modal-padding-x 0 $modal-padding-x;
$modal-footer-padding: 0 $modal-padding-x $modal-padding-x $modal-padding-x;
$modal-content-border-radius: 4px;

// Profile card

$profile-card-container-padding: 1.3rem 2.3rem;

// Tabs

$tab-height: 44px;
$tab-padding: 10px 0;
$tab-font-size: 1rem;
$tab-font-weight: 500;
$tab-line-height: 20px;
$tab-margin: 0 12px;
$tab-color: $base-dark;
$tab-border: 1px solid $background-color;
$tab-active-color: $primary;
$tab-active-border-bottom: 2px solid $tab-active-color;

$tab-button-height-md: 28px;
$tab-button-padding-md: 6px 8px;
$tab-button-height-lg: 32px;
$tab-button-padding-lg: 8px 8px;
$tab-button-height-sm: 23px;
$tab-button-padding-sm: 4px 8px;
$tab-button-line-height: 16px;

$tab-button-title-padding-md: 6px 15px;
$tab-button-title-padding-lg: 8px 15px;
$tab-button-title-padding-sm: 4px 15px;
$tab-button-title-height: 32px;
$tab-button-title-line-height: 20px;

// Tooltip

$trigger-color: #628AF1;
$trigger-color-hover: rgb(0, 115, 233);
$tooltip-font-size: 12px;
$tooltip-max-width: 500px;
$tooltip-color: $white;
$tooltip-bg: $base-dark;
$tooltip-border-radius: $border-radius;
$tooltip-padding-y: 8px;
$tooltip-padding-x: 12px;
$tooltip-arrow-color: $tooltip-bg;
$tooltip-box-shadow: $box-shadow;
$tooltip-border: none;
$tooltip-opacity: 1;

// Title

$title_color: $dark;
$title_bold_width: 600;

// Loading

$loading-z-index: 100;

// Input Tags
$input-tags-padding-x: 11px;
$input-tags-padding-y: 5px;
$input-tags-background-color: $control-background-color;
$input-tags-border-color: transparent;
$input-tags-wrapper-padding: $input-tags-padding-y #{$input-tags-padding-x - $input-tags-padding-y} 0 $input-tags-padding-x;
$input-tags-tag-margin: 0 $input-tags-padding-y $input-tags-padding-y 0;
$input-tags-tag-padding: 0 5px 0 0;
$input-tags-tag-border-radius: 4px;
$input-tags-tag-max-width: calc(100% - 1rem);
$input-tags-tag-color: $dark-75;
$input-tags-tag-button-color: $dark-50;
$input-tags-tag-background-color: #ebedf3;
$input-tags-tag-text-padding: 2px 5px;
$input-tags-list-max-height: 200px;

// Statistics
$conversions-color: #C2A4FD;
$conversions-color-light: #DECEFE;
$conversions-border: 2px $conversions-color solid !important;
$events-color: #96CAFF;
$events-color-light: #C7E2FF;
$events-border: 2px $events-color solid !important;

// Popover
$popover-border-width: 0;
$popover-border-color: transparent;
$popover-border-radius: $border-radius;
$popover-inner-border-radius: calc(#{$popover-border-radius} - #{$popover-border-width});
$popover-arrow-width: 10px;
$popover-arrow-height: 5px;
$popover-bg: none;
$popover-box-shadow: none;
$popover-max-width: none;

// Input Select
$custom-select-indicator-padding: 13px;
$input-select-menu-z-index: $loading-z-index + 10;
$input-select-option-focused-background-color: #E4EAFF !important;
$input-select-option-selected-background-color: $white !important;
$input-select-option-selected-box-shadow: inset 0 0 0 2px $primary;

// Offer Conditions Form
$conditions-benefit-card-max-width: 940px;
$conditions-footer-height: 72px;
$conditions-footer-padding: 16px 20px 16px 20px;
$conditions-card-padding-x: 52px;
$conditions-card-padding: 0 $conditions-card-padding-x 0 $conditions-card-padding-x;
$conditions-card-margin: 4px;
$conditions-border: 4px solid $background-color;
$conditions-column-padding: 8px;

// Dashboard
$dashboard-header-height: 28px;
$dashboard-body-padding-y: 8px;
$dashboard-body-padding-x: 20px;
$dashboard-header-padding-y: 8px;
$dashboard-columns: 24;
$dashboard-cell-z-index: 2;
$dashboard-cell-dragging-z-index: 4;
$dashboard-width: var(--dashboard-width);

// Dashboard widget
$widget-form-left-width: 480px;
$widget-form-footer-height: 74px;
$widget-form-padding: 16px 20px;
$widget-header-height: 32px;
$widget-cell-width: calc((#{$dashboard-width} - #{$dashboard-body-padding-x} * 2) / #{$dashboard-columns});
$widget-cell-width-percent: calc((100% - 1px) / #{$dashboard-columns});
$widget-cell-height: 48px;
$widget-padding: 8px;
$widget-z-index: 3;
$widget-dragging-z-index: 5;

// Side Menu

$side-menu-width: 200px;
$side-menu-background: $white;
$side-menu-color: $base-dark;
$side-menu-z-index: #{$modal-z-index - 1};
$side-menu-margin-down: 8px;
$side-menu-link-padding-x: 12px;
$side-menu-link-padding-y: 4px;
$side-menu-backdrop-z-index: #{$modal-z-index - 2};
$side-menu-backdrop-background: $base-dark;
