$custom-scheme-form-max-height: 95vh;
$custom-scheme-buttons-width: 95px;
$custom-scheme-buttons-color: $text-gray;
$custom-scheme-border-color: $border-gray;
$custom-scheme-group-padding: 18px;

.custom-statistic-scheme-form-modal {
  z-index: #{$modal-z-index - 10};

  .modal-dialog {
    max-width: calc(min(900px, 90vw));
    max-height: $custom-scheme-form-max-height;
    align-items: flex-start;
    margin: calc((99vh - #{$custom-scheme-form-max-height}) / 2) auto;
  }

  .modal-body {
    overflow: auto;
    padding: 0;
    height: calc(100% - 34px);

    @include default-scrollbar-with-background();
  }

  .modal-content {
    max-height: $custom-scheme-form-max-height;
  }
}

.custom-statistic-scheme-form-modal-backdrop {
  z-index: #{$modal-z-index - 20};
}

.custom-statistic-scheme-form-button {
  color: $base-dark;
  font-size: 20px;
  cursor: pointer;
  padding: 0.5rem;
  margin: -0.5rem;
  margin-right: 0.5rem;

  &--last {
    margin-right: -0.5rem;
  }
}

.custom-statistic-scheme-form {
  @include default-scrollbar();

  &__column {
    max-height: calc(#{$custom-scheme-form-max-height} - 34px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    padding: 0 20px 0 10px;
    height: 40px;
    border-radius: $border-radius;
    border: 1px solid $border-gray;
    width: 100%;
    background: $white;

    &--hidden {
      .custom-statistic-scheme-form__column-name {
        opacity: 0.3;
      }
    }

    &--dragging {
      border-color: $primary;
      border-width: 2px;

      & > * {
        visibility: hidden;
      }
    }

    &--grouping {
      border-color: $primary;
    }
  }

  &__column-gap {
    padding: 5px 0;
  }

  &__column-drag {
    display: flex;
    align-items: center;
    margin: 0 10px 0 0;
    color: $text-gray;
    cursor: move;

    .icon {
      font-size: 16px;
    }
  }

  &__column-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  &__column-name {
    display: block;

    font-weight: 600;
    width: calc(100% - #{$custom-scheme-buttons-width});
    line-height: 16px;
    @include text-overflow-ellipsis();
  }

  &__column-group {
    background: $white;
  }

  &__column-group-drop {
    position: absolute;
    width: 100%;
    height: 20px;
    margin-left: -11px;
    z-index: 2;
  }

  &__column-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: $text-gray;
    font-size: 16px;

    width: $custom-scheme-buttons-width;

    & > * {
      cursor: pointer;
    }

    & > *:not(:last-child) {
      margin-right: 10px;
    }
  }

  &__group-columns {
    margin-left: $custom-scheme-group-padding;
    padding-left: $custom-scheme-group-padding;

    .custom-statistic-scheme-form__column-wrapper {
      &::before {
        content: '';
        display: block;
        position: absolute;
        left: -$custom-scheme-group-padding;
        height: 100%;
        width: 1px;
        border-left: 1px solid $custom-scheme-border-color;
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        left: -$custom-scheme-group-padding;
        height: 25px;
        width: $custom-scheme-group-padding;
        border-bottom: 1px solid $custom-scheme-border-color;
      }

      &:last-child {
        &::before {
          height: 25px;
          width: $custom-scheme-group-padding;
          border-bottom: 1px solid $custom-scheme-border-color;
          border-bottom-left-radius: $border-radius;
        }

        &::after {
          display: none;
        }
      }

      &:first-child {
        &::before {
          height: calc(100% + 5px);
          top: -5px;
        }
      }

      &:first-child:last-child {
        &::before {
          height: calc(25px + 5px);
        }
      }

      &--dragging {
        &::before, &::after {
          border-color: $primary !important;
          border-width: 2px !important;
        }
      }
    }
  }

  &__header {
    width: 100%;
    background-color: $white;
    position: sticky;
    top: 0;
    z-index: 4;
    padding: 0 $conditions-card-padding-x 10px;
  }

  &__actions {
    width: 100%;
    background-color: $white;
    position: sticky;
    bottom: 0;
    z-index: 4;
    padding: 14px 52px 33px;
    border-top: 1px solid transparent;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  &__shadow {
    width: 100%;
    padding-bottom: 12px;
    box-shadow: 0px 2px 6px rgba(46, 62, 154, 0.1);
    z-index: 2;
    position: sticky;
    background: $white;
  }

  &__footer-shadow {
    box-shadow: 0px -2px 10px rgba(46, 62, 154, 0.1);
  }

  &__cover {
    position: relative;
    display: block;
    height: 10px;
    min-height: 10px;
    background: $white;
    width: 100%;
    z-index: 3;
  }

  &__overflow {
    height: 100%;
    width: 100%;
    padding: $conditions-card-padding;
    max-height: calc(#{$custom-scheme-form-max-height} - 158px);
    overflow: auto;

    @include default-scrollbar();
  }

  &__title {
    font-weight: $modal-header-font-weight;
    font-size: $modal-header-font-size;
    line-height: $modal-title-line-height;
  }
}

.custom-statistic-scheme-form-button-tooltip {
  max-width: 300px;
  position: unset;
  opacity: 1;
}

.statistics-popover-arrow {
  margin-left: -6px;
}
