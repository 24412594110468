.wizard-step {
  margin-bottom: 0.75rem;

  cursor: pointer;

  &__link {
    display: block;
    padding: 13px 20px;

    font-size: $font-size-base;
    line-height: $line-height-base;
    color: $text-light-gray;
    font-weight: 600;
    white-space: nowrap;

    border-radius: $border-radius;

    &:hover {
      text-decoration: none;
      color: $link-color;
    }

    &.active {
      background-color: $light;
      color: $link-color;
    }

    &.finished {
      color: $base-dark;
    }
  }

  &__icon {
    margin-right: 15px;
  }

  &--finished {
    color: #3F4254 !important;
  }
}

.wizard-step-list {
  width: min-content;
  min-width: min-content;

  &__content {
    padding: 3.8rem 2.3rem;
    border-right: 1px solid #EBEDF3;
    height: 100%;
  }

  &__items {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__title {
    margin-bottom: 1.75rem;

    font-size: 2.25rem;
    font-weight: 700;
    white-space: nowrap;
  }
}

.wizard-content {
  &__container {
    height: 100%;
    padding: 3.8rem 2.3rem 2.3rem 2.3rem;
  }

  &--form {
    max-width: 100%;
    width: 480px;
  }

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 33%;
    min-height: 35px;
    padding: 0;
    margin-bottom: 1.25rem;

    font-size: 1.5rem;
    font-weight: 700;
  }
}

@media (max-width: $mobile-max-width) {
  .wizard {
    .wizard-step-list {
      flex: 0 0 30%;
      max-width: 30%;

      &__items {
        margin: 0 -20px;

        .wizard-step {
          &__icon {
            display: none;
          }
        }
      }
    }

    .navi .navi-item .navi-link {
      padding: 0.65rem 1rem;

      .navi-text {
        overflow: hidden;
      }
    }

    .wizard-content {
      width: 70% !important;

      &__container {
        .wizard-content__title {
          width: 100%;
          min-width: 100%;
        }
      }

      &__container {
        .wizard.row {
          display: flex;
          flex-direction: column;

          & > * {
            width: 100%;

            &:last-child:not(:first-child) {
              padding: 0 !important;
              margin: 0 !important;
              border: none !important;

              padding-top: 2.25rem !important;
              margin-top: 2.25rem !important;
              border-top: 1px solid #EBEDF3 !important;
            }
          }
        }
      }
    }

    .table {
      overflow: auto;
    }
  }
}
