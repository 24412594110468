$dashboard-page-min-height: calc(100dvh - #{$horizontal-menu-height + $footer-line-height + 2 * $footer-padding-y});

.dashboard-page {
  --widget-left: 0;
  --widget-top: 0;
  --widget-width: 3;
  --widget-height: 3;
  --dashboard-width: max(100%, 100vw);

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  min-height: $dashboard-page-min-height;
  position: unset;

  .empty-data-block {
    align-self: center;
    justify-self: center;
    background: $white;
    min-height: $dashboard-page-min-height;
  }

  .dashboard-name-input {
    margin-bottom: 24px;

    input {
      padding: 11px $input-btn-padding-x;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $dashboard-header-padding-y 20px;
    width: 100%;
    height: min-content;
  }

  &__body {
    min-height: calc(100dvh - #{$horizontal-menu-height + $footer-line-height + 2 * $footer-padding-y + $dashboard-header-height + 2 * $dashboard-header-padding-y + 2});
    width: $dashboard-width;
    background-color: $background-color;
    padding: $dashboard-body-padding-y $dashboard-body-padding-x;
    position: relative;
  }

  &__modal-message {
    & > span {
      font-weight: 600;
    }
  }

  &__widget-create-help {
    display: block;
    position: absolute;
    background: $white;
    border-radius: $border-radius;
    top: 8px;
    right: 90px;
    padding: 16px;

    &-title {
      width: 100%;
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
    }

    &-text {
      font-size: 13px;
      line-height: 16px;

      .icon {
        font-size: 16px;
        color: $primary;
      }
    }
  }

  &__disabled-widget-button.button.btn:not(:disabled):not(.disabled) {
    opacity: 0.5;
    cursor: default;
    background: $white;
    box-shadow: none !important;
    border: none !important;

    &:hover {
      @media (hover: hover) {
        background: $white !important;
      }
    }
  }

  &__header-actions {
    display: flex;
    flex-wrap: wrap;
  }

  &__header-action {
    padding: 6px;
    font-size: 16px;
    margin-left: 8px;

    width: 28px !important;
    height: 28px !important;

    & > span {
      display: flex;
    }
  }

  &__modal-complex-message {
    display: block;

    .dashboard-page__modal-message {
      display: inline;
    }
  }
}

.dashboards-dropdown {
  &__toggle {
    padding: 0;
    font-size: 24px;
    font-weight: 600;
    line-height: $dashboard-header-height;

    .icon {
      font-size: 13px;
    }
  }

  &__menu {
    min-width: 300px;
    max-width: 300px;
    margin-left: -12px !important;
  }

  &__group-title {
    font-weight: 600;
    padding: 8px 12px;
  }

  &__button {
    margin: 8px 12px 12px 12px;
    background: #F6F6F9;
    width: calc(100% - 24px);
    border-color: transparent !important;
  }

  &__dashboard {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 8px 12px;

    &:hover {
      @media (hover: hover) {
        background: $light-primary;
      }
    }

    &--active {
      box-shadow: $input-select-option-selected-box-shadow;
    }
  }

  &__data {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% - 24px);
    cursor: pointer;
  }

  &__name {
    width: 100%;
    word-break: break-word;

    .badge {
      //margin-left: 8px;
      text-transform: uppercase;
    }
  }

  &__author {
    width: 100%;
    color: $text-gray;
    font-size: 11px;
  }

  &__actions {
    .dropdown-toggle > .button {
      font-size: 11px;
      padding: 2px;
      margin: -2px;
      height: 20px;
      width: 20px;
      border-radius: 4px;
      background: $white;

      & > span {
        display: flex;
      }

      svg path {
        fill: #74788D
      }

      &:hover {
        @media (hover: hover) {
          svg path {
            fill: $base-dark;
          }
        }
      }
    }

    & > .dropdown.show {
      .dropdown-toggle {
        svg path {
          fill: $base-dark;
        }
      }
    }
  }

  &__dropdown-container {
    z-index: $modal-z-index - 11;
  }
}

.dashboard-page__message-modal {
  .confirm-modal__btn {
    width: 100%;
    margin-left: 0 !important;
  }

  .dashboard-page__message-modal-backdrop {

  }
}

.dashboard-edit-modal {
  .modal-dialog {
    max-width: calc(min(max($mobile-min-width, 100dvw), 390px) - 1rem);
  }

  &__data-group {
    width: 100%;
    margin-bottom: 8px;
  }

  &__data-title {
    font-size: 12px;
    color: $text-gray;
  }
}

.dashboard-option {
  font-size: 13px;
  line-height: 16px;

  &__author {
    font-size: 11px;
    color: $text-gray;
  }

  &__name-text {
    display: block;
    max-width: 100%;
    @include text-overflow-ellipsis();
  }

  &__name {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    @include text-overflow-ellipsis();

    &--partner {
      .dashboard-option__name-text {
        max-width: calc(100% - 120px);
      }
    }
  }

  .badge {
    text-transform: uppercase;
    margin-left: 8px;
  }
}


.dashboards-field {
  margin-bottom: 0;

  .input-select__control {
    min-height: 48px;
  }

  .input-select__value-container {
    min-height: 47px;
  }

  .input-select__menu-list {
    height: auto !important;
    max-height: 350px !important;
  }

  .field__label {
    font-weight: 600;
    font-size: 13px;
  }
}

.dashboard-buttons-dropdown-menu {
  .button.dropdown-item {
    .icon {
      color: $primary;
      font-size: 16px;
    }
  }
}


@media (max-width: 1366px) {
  .dashboard-page {
    --dashboard-width: max(100%, 100vw, 1366px);
  }
}

@include tabletLayout() {
  .dashboard-page {
    --dashboard-width: max(100%, 100dvw, 1366px);
    overflow-x: auto;
    overflow-y: hidden;
    min-height: calc(100dvh - 72px);
    @include default-scrollbar();

    .empty-data-block {
      .button {
        display: none;
      }
    }

    &__body {
      min-height: calc(100dvh - 124px);
      padding: 8px 12px;
    }

    &__header {
      padding: 8px 12px;
      position: sticky;
      left: 0;
    }

    .widget {
      left: calc(calc((var(--dashboard-width) - 12px * 2) / 24) * var(--widget-left) + 1px);
      width: calc(calc((var(--dashboard-width) - 12px * 2) / 24) * var(--widget-width) - 8px);
    }

    .widget-resizable-box {
      min-width: calc(calc((var(--dashboard-width) - 12px * 2) / 24) * var(--widget-min-width) - 8px - 1px);
      max-width: calc(calc((var(--dashboard-width) - 12px * 2) / 24) * var(--widget-max-width) - 8px - 1px);
    }

    &--partner {
      .dashboard-page__body {
        min-height: calc(100dvh - 72px);
      }
    }
  }

  &.dashboard-edit-modal, &.dashboard-confirm-modal {
    .modal-footer {
      padding: 0 24px 24px;

      & > div {
        width: 100%;
        justify-content: space-between !important;
      }

      .button {
        width: calc(50% - 8px);
      }
    }

    &.confirm-modal {
      .modal-footer {
        justify-content: space-between;
        width: 100%;
      }
    }
  }

  .dashboards-dropdown__button {
    display: none;
  }

  .dashboards-dropdown__menu {
    max-height: 400px;
    overflow-y: auto;
    background: white;
    @include default-scrollbar();
  }

  .dashboard-page__widget-create-help {
    right: unset;
  }
}

@include mobileLayout() {
  .dashboard-page {
    --dashboard-width: 100%;
    overflow-x: hidden;

    &__header-dropdown {
      max-width: calc(100% - 36px);
      min-width: calc(100% - 36px);
    }

    .button.dashboards-dropdown__toggle {
      width: 100%;
      max-width: 100%;

      & > span {
        display: flex;
        align-items: center;
        width: 100%;
        max-width: 100%;
      }

      .dashboards-dropdown__title {
        max-width: calc(100% - 20px);
        display: inline-block;
        @include text-overflow-ellipsis();
        font-size: 17px;
        line-height: 20px;
      }
    }

    .dashboard__box {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      height: max-content;
      min-height: 100%;
    }

    .dashboard {
      height: auto;
    }

    .widget {
      position: unset;
      width: 100%;
      margin-right: 0;
    }

    .widget__header-actions {
      display: none;
    }

    .widget-resizable-box {
      min-width: 100%;
      max-width: 100%;
    }

    &__widget-create-help {
      position: initial;
      &-text {
        display: none;
      }
    }
  }

  .dashboards-dropdown__menu {
    margin-left: 0 !important;
  }

  &.dashboard-edit-modal {
    .modal-dialog {
      max-width: calc(max($mobile-min-width, min(100dvw, 390px)) - 1rem);
    }
  }
}
