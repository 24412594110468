.legal-documents-agreement-page {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &__panel {
    width: 620px;
    padding: 24px;
    border: 1px solid $background-color;
    border-radius: $border-radius;
  }

  &__title {
    font-size: 24px;
    line-height: 28px;
    font-weight: 600;
    text-align: center;
    width: 100%;
    padding: 0 60px;
    margin-bottom: 24px;
  }

  &__text {
    font-size: 13px;
    line-height: 20px;
    font-weight: 400;
    margin-bottom: 24px;
  }

  &__checkbox > label {
    display: block;
    font-size: 13px;
    line-height: 20px;
    font-weight: 400;
    margin-bottom: 12px;
    white-space: break-spaces;

    .checkbox {
      padding-top: 3px;
    }
  }

  &__lang {
    position: absolute;
    top: 20px;
    right: 20px;

    button {
      padding: 8px;
    }
  }
}
