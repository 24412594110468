$promo-material-modal-width: 950px;
$promo-material-form-width: 415px;
$promo-material-form-image-min-height: 288px;
$promo-material-form-margin: 20px;
$promo-material-link-modal-width: 900px;
$promo-material-link-subids-width: 300px;
$promo-material-link-subids-margin: 10px;

.promo-materials-page {
  &__filter-fields {
    display: grid;
    grid-template-columns: repeat(3, minmax(10%, 1fr)) repeat(4, minmax(5%, 0.5fr));
    grid-gap: 20px;
  }

  &__preview {
    max-width: 415px;
    max-height: 200px;
    width: auto;
    height: auto;
    cursor: zoom-in;
  }

  &__countries-cell {
    max-width: 350px;
  }
}

.promo-material-form {
  .modal-dialog {
    max-width: calc(min(max($mobile-min-width, 100dvw), $promo-material-modal-width) - 1rem);
  }
  .form {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
  }
  &__fields {
    width: calc(100% - #{$promo-material-form-width + $promo-material-form-margin});
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-self: flex-start;
  }
  &__image-field {
    max-width: $promo-material-form-width;
    height: 100%;
    margin-left: $promo-material-form-margin;
    display: flex;
    justify-content: center;
    width: 100%;

    .input-image {
      width: $promo-material-form-width;
      height: $promo-material-form-image-min-height;
      align-items: center;
      justify-content: center;
      margin: 20px 0;
    }

    .field {
      margin-bottom: 0;
    }

    .input-image__placeholder {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }

    .input-image__placeholder-icon {
      position: static;
      font-size: 50px;
      opacity: 0.5;
    }

    .input-image__placeholder {
      min-height: $promo-material-form-image-min-height;
      min-width: $promo-material-form-width;
    }

    .input-image__preview {
      max-height: $promo-material-form-image-min-height;
      max-width: $promo-material-form-width;
      display: flex;
      justify-content: center;
      width: auto;
      height: fit-content;

      img {
        max-height: $promo-material-form-image-min-height;
        max-width: 100%;
        width: auto;
        height: auto;
      }
    }
  }
}

.promo-materials-link {
  &__modal {
    .modal-dialog {
      max-width: calc(min(max($mobile-min-width, 100dvw), $promo-material-link-modal-width) - 1rem);

      .modal-content {
        height: auto;
      }
    }
  }

  #field-promo-link {
    min-height: calc(100% - 21px);
    max-height: calc(100% - 21px);
  }

  &__form {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-height: 60vh;
    overflow: hidden;
  }

  &__textarea {
    width: calc(100% - #{$promo-material-link-subids-width} - #{$modal-padding-x * 2});
    min-height: 240px;
  }
  &__subids {
    width: $promo-material-link-subids-width;
    box-sizing: content-box;
    padding: 0 0 0 $modal-padding-x;
    margin: 0 0 0 $modal-padding-x;
    border-left: 1px solid $modal-header-border-color;

    .tracking-link__sub-ids {
      margin-top: 20px;
      overflow: auto;
      max-height: calc(100% - 80px);
      @include default-scrollbar(3px, 4px, 0.15);

      &:not(:empty) {
        border-top: 1px solid $background-color;
      }
    }
  }
  &__title {}
  &__add-sub-id {}

  .tracking-link__sub-ids {}
}

.promo-materials-card--offer-variant {
  .card-body {
    padding: 0;
  }
}

@media (min-width: 1000px) {
  .layout--adaptive, .modal--adaptive, .drawer--adaptive, .popover--adaptive {
    &.promo-material-form {
      .modal-dialog {
        max-width: 950px !important;
      }
    }
  }
}

@include tabletLayout() {
  &.promo-material-form {
    .form {
      justify-content: space-between;
      align-items: flex-start;
    }

    .modal-dialog {
      max-width: 95dvw;
    }

    .modal-body {
      padding: 0 24px;
    }

    .modal-header {
      padding: 24px;
      z-index: 5;
    }

    .modal-footer {
      padding: 24px !important;

      .button {
        height: 40px;
        min-width: calc(50% - 8px);
      }
    }

    .promo-material-form__fields {
      width: calc(50% - 8px);
    }

    .promo-material-form__image-field {
      width: calc(50% - 8px);
      margin-left: 0;
      margin: 20px 0;

      .field, .field__control, .input-image {
        width: 100%;
        height: 100%;
      }

      .input-image {
        margin: 0;
        min-height: 224px;
      }

      .input-image__placeholder {
        min-height: 224px;
        min-width: 100%;
      }

      .input-image__preview {
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;

        img {
          max-height: 224px;
        }
      }
    }
  }
}

@include mobileLayout(575px) {
  &.promo-material-form {
    .form {
      flex-direction: column-reverse;
    }

    .promo-material-form__fields {
      width: 100%;
    }

    .promo-material-form__image-field {
      width: 100%;
      max-width: 100%;
      max-height: 220px;
      margin: 0 0 12px 0;

      .input-image {
        min-height: 200px;
      }

      .input-image__placeholder {
        min-height: 200px;
      }

      .input-image__preview {
        img {
          max-height: 200px;
        }
      }
    }
  }

  &.promo-materials-link__modal  {
    .modal-dialog {
      max-height: 90dvh;
    }

    .modal-header {
      padding: 24px;
    }

    .modal-footer:not(:empty) {
      padding: 24px;
    }

    .modal-body {
      padding: 0 24px;
    }
  }

  .promo-materials-link {
    &__form {
      flex-wrap: wrap;
      max-height: unset;
      overflow: auto;
    }

    &__textarea {
      width: 100%;
      margin-bottom: 12px;
      min-height: unset;

      #field-promo-link {
        max-height: unset;
        min-height: 100px;
      }
    }

    &__subids {
      width: 100%;
      margin-left: 0;
      padding: 0;
      margin-top: 12px;
      padding-top: 12px;
      border-top: 1px solid $background-color;

      .tracking-link__sub-ids {
        overflow: hidden;
        max-height: unset;
        border-top: none;
        margin-top: 12px;
      }

      .tracking-link__sub-id-control {
        padding: 0 0 8px 0;
        border-bottom: none;
      }
    }
  }
}
