.countries-flags {
  display: flex;

  .more-cell {
    grid-gap: unset;
    margin-right: 13px;
  }

  .badge {
    height: 26px;
    width: 26px;
    padding: 1px;
    background-color: $background-color;
    border-radius: 50%;
    margin: 2px 4px 2px 0;
    z-index: 1;

    &.more-label {
      font-size: 8px;
      padding-right: 2px;

      b {
        padding-left: 0 !important;
        padding-right: 0 !important;
        font-weight: 500;
      }
    }
  }

  &--stacked {
    .badge {
      margin: 0 -12px 0 0;
    }
  }

  &--row {
    .badge:not(.more-label) {
      background-color: transparent;
    }

    .badge {
      &.more-label {
        height: fit-content;
        width: fit-content;
        border-radius: 10px;
        padding: 0 4px;
        align-self: center;
        font-size: 12px;
        line-height: 16px;

        b {
          font-weight: 400;
        }
      }
    }
  }
}

.more-countries-tooltip {
  background: $tooltip-bg;
  display: grid;
  grid-gap: 8px;
  grid-column-gap: 25px;
  width: 900px;
  padding: 8px 12px;
  margin: -8px -12px;
  border-radius: 4px;

  &--sm {
    grid-template-columns: 1fr 1fr 1fr;
    width: 450px;
  }
  &--md {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    width: 700px;
  }
  &__has-more {
    //font-size: 24px;
  }
}


@include tabletLayout() {
  .countries-flags {
    &--stacked {
      .badge {
        margin: 0 0 0 -4px;

        &:not(:first-child) {
          box-shadow: -1px 0px 1px 0px rgba(0, 0, 0, 0.1);
        }
      }
    }
  }

  .more-countries-tooltip--md {
    grid-template-columns: 1fr 1fr 1fr;
    width: auto;
  }
}

@include mobileLayout() {
  .more-countries-tooltip--md {
    grid-template-columns: 1fr 1fr;
    width: auto;
  }
}
