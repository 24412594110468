$payment-system-form-modal-max-width: 650px;
$payment-system-form-field-required-width: 170px;
$payment-system-form-field-margin: 20px;
$payment-system-form-field-remove-width: 50px;
$payment-system-form-field-remove-padding: 10px;

.payment-system-form {
  max-height: 70vh;
  overflow: auto;
  @include default-scrollbar(3px, 5px, 0.3);

  &__modal {
    .modal-dialog {
      max-width: calc(min(max($mobile-min-width, 100dvw), $payment-system-form-modal-max-width) - 1rem);
    }
  }

  &__wallet-field {
    display: flex;
    width: 100%;
  }

  &__wallet-field-name {
    width: calc(100% - #{$payment-system-form-field-required-width + $payment-system-form-field-remove-width + (2 * $payment-system-form-field-margin)});
  }

  &__wallet-field-required {
    display: flex;
    align-items: center;

    margin: $payment-system-form-field-margin 0 $control-margin-bottom $payment-system-form-field-margin;
    width: $payment-system-form-field-required-width;
    max-width: $payment-system-form-field-required-width;
    height: $control-min-height;

    .field {
      margin-bottom: 0;
    }
  }

  &__wallet-field-remove {
    display: flex;
    align-items: center;
    justify-content: center;

    margin: $payment-system-form-field-margin 0 $control-margin-bottom $payment-system-form-field-margin;
    width: $payment-system-form-field-remove-width;
    max-width: $payment-system-form-field-remove-width;
    height: $control-min-height;

    .icon {
      padding: $payment-system-form-field-remove-padding;
      cursor: pointer;
      opacity: 0.5;

      &:hover, &:active, &:focus {
        opacity: 0.8;
      }
    }
  }
}


@include mobileLayout() {
  &.payment-system-form__modal {
    .payment-system-form__wallet-field {
      &-remove {
        margin-left: 0;
        width: 32px;
        max-width: 32px;
      }

      &-required {
        margin-left: 8px;
        width: 160px;
        max-width: 160px;
      }

      &-name {
        width: calc(100% - 200px);
      }
    }
  }
}
