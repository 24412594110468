.badge {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  word-break: keep-all;
  padding: $badge-padding-y $badge-padding-x;
  font-weight: $badge-font-weight;
  line-height: $badge-line-height;
  border-radius: $badge-border-radius;

  &-pill, &.rounded-pill {
    border-radius: $badge-pill-border-radius;
    padding: $badge-padding-y #{$badge-padding-x + 4px};
  }

  .badge-sm {
    padding: 0 4px;
  }

  @each $name, $color in $light-badge-colors {
    &-#{$name}, &.bg-#{$name} {
      color: $color;
    }
  }

  @each $name, $color in $theme-colors {
    &-#{$name}, &.bg-#{$name} {
      background: $color;
    }
  }

  @each $size, $value in $badge-sizes {
    &-#{$size} {
      font-size: $value;
    }
  }

  @each $height, $value in $badge-heights {
    &-#{$height} {
      height: $value;
    }
  }
}
