.layout {
  display: flex;
  flex-direction: column;
  min-width: $tablet-max-width + 1;
  width: 100%;
  min-height: 100dvh;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 100dvh;
  @include default-scrollbar();
  position: relative;

  // helps with z-index of a scroll in iOS
  // but fixed elements will not work anymore
  //-webkit-backface-visibility: hidden;
  //backface-visibility: hidden;
  //transform: translateZ(0px);
}

.layout--adaptive {
  min-width: $mobile-min-width;
}

@include tabletLayout() {
  &.layout--adaptive-automate,
  &.modal--adaptive-automate,
  &.drawer--adaptive-automate {
    //width: 100dvw;
    overflow-x: auto;

    .main {
      min-width: $tablet-max-width;
      width: $tablet-max-width;
    }

    .horizontal-menu {
      width: 100%;
      position: sticky;
      left: 0;
    }

    .table-footer {
      position: sticky;
      width: 100%;
      left: 0;
    }

    .table-pagination {
      position: sticky;
      width: calc(100dvw - 6px);
      left: 0;

      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: -20px;
        width: 20px;
        height: 100%;
        background: #EFF1F5;
      }
    }

    #footer {
      position: sticky;
      bottom: unset;
      left: 0;
    }

    .dashboard-page {
      min-height: calc(100vh - 80px);

      .dashboard-page__body {
        min-height: calc(100vh - 124px);
      }

      .full-screen-block {
        position: fixed;
      }

      .widget-form__body {
        width: 100%;
        overflow-x: auto;
      }

      .widget-form__form {
        min-width: var(--dashboard-width);
      }
    }

    &.custom-statistic-column-form-modal, &.formula-form-modal {
      .modal-dialog {
        width: calc(#{$tablet-max-width} - 200px);
      }

      .modal__footer:not(:empty) {
        padding: 16px 24px 24px 24px;
      }
    }

    &.drawer-right.drawer-open {
      .drawer-content-wrapper {
        width: 100dvw !important;
      }
    }

    .header {
      padding-left: 0;
      padding-right: 0;
    }

    &.promo-material-form {
      .modal-dialog {
        min-width: 950px;
      }
    }

    &.conditions-edit-form__modal {
      .modal-body {
        padding: 0;
      }

      .modal-header {
        padding: 31px 32px 0 32px;

        .modal__close {
          z-index: 10;
        }
      }

      .modal-dialog {
        min-width: 1000px;
        margin: calc((99vh - 95vh) / 2) auto;
      }
    }

    .modal-header {
      .modal__close {
        z-index: 10 !important;
      }
    }

    &.confirm-modal--small-width {
      .modal-dialog-centered {
        margin: auto;
      }
    }

    div.input-select__placeholder--fixed {
      position: absolute;
    }

    #page-alerts {
      max-width: 100dvw;
      position: sticky;
      left: 0;
    }

    .partners-page {
      min-width: 1600px;
    }

    &.invoice-form-modal {
      .modal-header {
        padding: 32px 32px 0;
      }

      .modal-body {
        padding: 0;
      }

      .table-footer {
        .table-pagination {
          width: 100%;
        }
      }
    }

    .promo-codes-page {
      &__filter {
        .filter__footer {
          width: 250px;
        }

        .filter__fields {
          width: calc(100% - 250px);
        }
      }

      &__filter-fields {
        grid-template-columns: minmax(5%, 2fr) minmax(5%, 1.5fr) minmax(5%, 1.5fr) minmax(5%, 1.5fr) minmax(5%, 1.5fr);
      }
    }
  }

  &.promo-code-type-form {
    .modal-header {
      padding-right: 40px !important;
    }
  }

  .widget-form__body {
    overflow: auto;

    .widget-form__form {
      min-width: $tablet-max-width;
    }
  }
}

@media (max-width: $tablet-max-width) {
  #root {
    & > .loading--full-screen {
      & > .icon, & > img {
        max-width: 250px !important;
      }
    }
  }
}
