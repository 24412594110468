@mixin horizontal-menu-nav-item-active() {
  background-color: $horizontal-menu-bg;
  color: $primary;
  position: relative;

  .main-nav-item__label {
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      border-bottom: 2px solid $primary;
      left: 0;
      bottom: 0;
    }
  }
}

.horizontal-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: $horizontal-menu-height;
  min-height: $horizontal-menu-height;
  background: $horizontal-menu-bg;
  border-bottom: $horizontal-menu-border-bottom;
  padding: $horizontal-menu-padding;
  color: $horizontal-menu-color;

  &__right, &__left {
    display: flex;
    align-items: center;
    height: 100%;
  }

  &__left {
    flex-grow: 1;
  }

  &__manager {
    display: flex;
    align-items: center;
    height: 100%;
  }

  &__user {
    display: flex;
    align-items: center;
    height: 100%;
  }


  &__menu {
    display: flex;
    flex-grow: 1;
    height: 100%;
    padding-left: 16px;
  }

  &__main-nav {
    flex-wrap: nowrap;
    flex-grow: 1;
    flex-direction: row;
    height: 100%;

    .main-nav__nav-item--multiline {
      .main-nav-item__link {
        .main-nav-item__label {
          @media (max-width: 1500px) {
            word-break: break-word;
            max-width: 100px;
            text-align: center;
            padding: 8px 0;
            line-height: 14px;
          }
        }
      }
    }

    .main-nav-item__link {
      color: $horizontal-menu-color;
      padding: $horizontal-menu-nav-item-margin;
      line-height: 20px;
      height: 100%;

      @media (max-width: 1500px) {
        padding: 0 6px;
      }

      &--active {
        @include horizontal-menu-nav-item-active();
      }

      .main-nav-item__label {
        position: relative;
        font-weight: 500;
        padding: $horizontal-menu-nav-item-padding;
      }

      .main-nav-item__icon {
        display: none;
      }
    }

    .main-nav-item:hover {
      background: $light-primary;

      .main-nav-item__link {
        background: $light-primary;
      }
    }
  }

  &__aside-toggle {
    display: none;
    align-items: center;
    justify-items: center;
    font-size: 24px;
    width: 24px;
    height: 24px;
    margin-right: 16px;
    cursor: pointer;
  }
}

.horizontal-menu-manager {
  &__icon {
    margin-right: 8px;
  }

  .dropdown-toggle {
    height: 100%;
  }

  &__panel {
    display: flex;
    height: 100%;
    padding: 8px;
    align-items: center;
    font-weight: 600;
    cursor: pointer;

    &:hover {
      @media (hover: hover) {
        background: $light-primary;
      }
    }
  }

  &__dropdown {
    height: 100%;

    &.show {
      .horizontal-menu-manager__panel {
        background: $light-primary;
      }
    }
  }

  &__managers {
    max-height: 80vh;
    overflow-y: auto;
    overflow-x: hidden;
    @include default-scrollbar()
  }

  &__menu {
    min-width: 250px;
    width: 250px;
    max-width: 350px;
    border-radius: 0 0 $border-radius $border-radius;
    padding: 0;
    border-color: transparent;
  }

  &__card-icon {
    display: flex;
    justify-content: center;
    width: 0;
    height: 0;
    box-sizing: content-box;
    border-radius: 50%;
    overflow: hidden;
    background: $white;
    border: 0 solid $white;
    border-width: 0;
    position: relative;
    margin-bottom: 0;
    transform: scale(0);

    @include default-transition((width, height, transform, margin-bottom, border-width));
    will-change: transform;

    .icon {
      margin-top: 2px;
    }
  }

  &__card-label {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    @include default-transition((font-size, line-height, font-weight, justify-content));
  }

  &__card-dropdown-icon {
    position: absolute;
    font-size: 16px;
    right: 16px;
    top: 16px;
    width: 10px;
    height: 10px;
    visibility: visible;
    @include default-transition((width, height, visible));
  }

  &__card-name {
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    word-break: break-word;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    @include default-transition((font-size, line-height, font-weight, justify-content));
  }

  &__card-icon-circle {
    background: $base-dark;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    position: absolute;
    top: 25px;
  }

  &__card {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    cursor: pointer;
    background: $background-color;

    &--open {
      cursor: default;

      .horizontal-menu-manager__card-icon {
        width: 40px;
        height: 40px;
        margin-bottom: 12px;
        border-width: 8px;
        transform: scale(1);
      }

      .horizontal-menu-manager__card-dropdown-icon {
        width: 0;
        height: 0;
        visibility: hidden;
      }

      .horizontal-menu-manager__card-label {
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        justify-content: center;
      }

      .horizontal-menu-manager__card-name {
        font-weight: 600;
        font-size: 15px;
        line-height: 16px;
        justify-content: center;
      }
    }
  }

  &__manager-card {
    &:not(:last-child) {
      border-bottom: 1px solid rgba($base-dark, 0.3);
    }
  }

  &__fields {
    background: $white;
    padding: 12px 0;
  }

  &__title {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
  }

  &__link {
    display: flex;
    align-items: center;

    a {
      max-width: 100%;
      white-space: nowrap;
      word-break: keep-all;
      max-height: 100%;
      overflow: hidden;
      display: block;
      line-height: 16px;
      font-weight: 600;
      font-size: 12px;
      color: $primary;
      @include text-overflow-ellipsis();
    }
  }
}

.horizontal-menu-manager__dropdown {
  .profile-card-list-item {
    line-height: 16px;

    &__icon {
      display: none;
    }

    &__title {
      display: none;
    }

    &__contact {
      padding: 8px 12px;
      font-weight: 600;
      cursor: pointer;

      &:hover {
        background: $light-primary;
      }
    }

    &__contact-value {
      color: $base-dark;
      font-weight: 400;
      margin-bottom: 0;
    }
  }
}

.horizontal-menu {
  &__user {
    display: flex;
    align-items: center;
    height: 100%;
  }


  &__menu {
    display: flex;
    flex-grow: 1;
    height: 100%;
    padding-left: 16px;
  }

  &__main-nav {
    flex-wrap: nowrap;
    flex-grow: 1;
    flex-direction: row;
    height: 100%;

    .main-nav__nav-item--multiline {
      .main-nav-item__link {
        .main-nav-item__label {
          @media (max-width: 1500px) {
            word-break: break-word;
            max-width: 100px;
            text-align: center;
            padding: 8px 0;
            line-height: 14px;
          }
        }
      }
    }

    .main-nav-item__link {
      color: $horizontal-menu-color;
      padding: $horizontal-menu-nav-item-margin;
      line-height: 20px;
      text-decoration: none;

      @media (max-width: 1500px) {
        padding: 0 6px;
      }

      &--active, &.active {
        @include horizontal-menu-nav-item-active();
      }

      .main-nav-item__label {
        position: relative;
        font-weight: 500;
        padding: $horizontal-menu-nav-item-padding;
      }

      .main-nav-item__icon {
        display: none;
      }
    }

    .main-nav-item:hover {
      background: $light-primary;

      .main-nav-item__link {
        background: $light-primary;
      }
    }
  }
}

@include tabletLayout() {
  .horizontal-menu {
    padding: 0 $side-menu-link-padding-x;

    &__aside-toggle {
      display: flex;
    }
  }

  .horizontal-menu__menu {
    padding-left: 8px;

    .main-nav-item__link {
      padding: 0 6px;
    }
  }
}

@include mobileLayout() {
  .horizontal-menu-manager {
    &__title {
      display: none;
    }

    &__icon {
      margin-right: 0;
      color: $text-gray;
    }
  }

  .partner-balance {
    &__content {
      display: none;
    }

    &__icon {
      margin-right: 0;
      color: $text-gray;
    }
  }
}
