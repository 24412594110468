.breadcrumb-trail {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: $header-breadcrumbs-padding-y;
  color: $base-dark;
  align-items: center;
  flex-wrap: wrap;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  cursor: default;

  &__arrow {
    margin-bottom: 1px;
    overflow: visible !important;
    font-size: 10px;
  }

  &__item-wrapper {
    display: flex;
    margin-right: 5px;
    align-items: center;
    max-width: 100%;
  }

  &__item {
    margin: 0 5px;
    white-space: pre;
    overflow: hidden;
    max-width: 100%;

    @include text-overflow-ellipsis();

    a {
      color: $base-dark;
      text-decoration: none;
      font-weight: 400;
    }

    &:not(.breadcrumb-trail__item--disabled):hover {
      @media (hover: hover) {
        a {
          color: $primary;
          text-decoration: none;
        }
      }
    }

    &--disabled {
      font-weight: 400;
      color: $base-dark;
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
