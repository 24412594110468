.mobile {
  display: none;
}

.tablet {
  display: none;
}

@include tabletLayout() {
  .laptop {
    display: none;
  }

  .header, .header.header--breadcrumbs:not(.header--title), .tabs--header .tabs__nav {
    padding-left: 12px;
    padding-right: 12px;
  }

  .header.header--breadcrumbs.header--title {
    padding: 8px 12px;
  }

  .header:not(.header--custom) {
    border-bottom: 2px solid $background-color;
  }

  .page-header--bb {
    border-bottom: 2px solid $background-color;
  }

  .user-box {
    margin-right: -8px;
  }

  .tablet-icon-button {
    @include icon-only-button();
  }

  .statistics-page__second-options {
    width: 100%;
    justify-content: space-between;
  }

  .filters-button-container {
    margin-right: 8px;
    margin-left: 8px;
  }

  .tablet {
    display: initial;
  }
}

@include mobileLayout() {
  #root > .loading {
    z-index: 1500;
  }

  .search-input {
    font-size: 12px !important;
  }

  .tabs-ink-bar {
    //display: none;
  }

  .mobile-icon-button {
    @include icon-only-button();
  }

  .table .empty-table {
    width: 80vw;
    left: 10%;
  }

  .mobile {
    display: initial;
  }
}
