$filter-actions-width: 200px;
$filter-margin: 1.5rem;
$filter-padding-x: 20px;
$filter-padding-y: 12px;
$filter-preview-icon-width: 16px;
$filter-preview-button-width: 160px;
$filter-button-padding: 10px 35px;

.filter {
  border: none;
  border-radius: 0;
  border-bottom: 1px solid $background-color;

  & > .widget-layout {
    width: 100%;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-top: 20px;
  }

  &__actions {
    display: flex;
  }

  .input-select__menu {
    z-index: $loading-z-index + 1 !important;
  }

  &__btn {
    &--reset {
      background: transparent;
      border: none;
      color: #9099B1;
      padding: $filter-button-padding;
    }

    &--submit {
      padding: $filter-button-padding;
    }
  }

  &--line {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .filter {
      &__fields {
        display: flex;
        width: 100%;

        & > * {
          flex-grow: 1;
          width: 100%;
          margin-right: $filter-margin;
        }
      }
      &__actions {
        width: $filter-actions-width;
        margin-left: $filter-margin;
        margin-top: 25px;
      }
    }
  }

  &__content {
    padding: $filter-padding-y $filter-padding-x;
    height: auto;
    @include default-transition(padding height);
    transition-duration: 0.3s;
  }

  &__preview {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    padding: 6px $filter-padding-x 6px #{$filter-padding-x - 2px};
    border-bottom: 1px solid rgba(63, 66, 84, 0.15);
    color: $base-dark;
    min-height: 44px;

    &-icon {
      display: none;
      align-items: center;
      width: $filter-preview-icon-width;
      height: $filter-preview-icon-width;
      margin-right: $filter-margin;
    }

    &-fields {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      width: calc(100% - #{$filter-preview-icon-width} - #{$filter-preview-button-width} - #{$filter-margin * 2});
    }

    &-button {
      margin-left: $filter-margin;
      width: $filter-preview-button-width;
      & > div {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        max-width: $filter-preview-button-width;
        cursor: pointer;
        font-weight: $font-weight-bolder;
        user-select: none;

        .icon:first-child {
          margin-right: 10px;
        }

        .icon:last-child {
          margin-left: 10px;
        }
      }
    }

    .react-loading-skeleton {
      width: 100px;
      border-radius: $border-radius;
      --base-color: #{$light-secondary};
    }
  }

  &__preview-field {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    background-color: #EFF1F5;
    border-radius: $border-radius;
    padding: 6px 8px;
    margin: 2px;
    position: relative;
    max-width: 100%;
    line-height: 16px;

    &-error-icon {
      display: flex;
      align-items: center;
      margin: 0 6px 0 2px;

      &:before {
        content: '';
        top: 0;
        left: 0;
        height: 100%;
        width: 4px;
        background: $danger;
        display: block;
        position: absolute;
        border-radius: 4px 0px 0px 4px;
      }
    }

    &-label {
      display: flex;
      align-items: center;
      font-weight: $font-weight-bold;
      margin-right: 8px;
    }

    &-value {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
      white-space: pre-wrap;
    }

    &-buttons {
      display: flex;
      align-items: center;
      color: $text-gray;
      margin-left: 10px;
      height: 16px;

      .icon {
        cursor: pointer;
      }
    }
  }

  &--dropdown:not(.filter--opened) {
    .filter__content {
      padding: 0 $filter-padding-x;
      height: 0;
      overflow: hidden;
    }
    .filter__preview {
      border-bottom: none;
    }
  }

  &--opened {
    .filter__preview {
      &-button {
        color: $primary;
      }
    }
  }

  .field {
    margin-bottom: 0;
  }

  .custom-fields {
    .field__label {
      max-width: 100%;
      @include text-overflow-ellipsis();
    }
  }
}

.boolean-filter {
  margin-top: 28px;
}

.filters-button-container {
  .button:not(:disabled) {
    &, &:hover, &:active, &:focus {
      background: $control-background-color !important;
      border: 1px solid $control-background-color !important;
    }
  }
}

.filter-mobile-button {
  .button__icon + span {
    margin-left: 6px;
    white-space: nowrap;
  }
}

.filter-modal__close-button {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;

  .icon {
    margin-right: 5px;
  }
}

.modal--adaptive.modal.filter-modal {
  .modal-header {
    justify-content: flex-end;
    padding: 14px 24px;
  }
}
