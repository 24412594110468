$wpx-start: 0;
$wpx-end: 1000;
$wpx-step: 50;

$fields-grid-start: 2;
$fields-grid-end: 10;
$fields-grid-step: 1;

$fields-grid-two-start: 1;
$fields-grid-two-end: 5;
$fields-grid-two-step: 1;

hr, .divider {
  border-top: 1px solid $background-color;
  opacity: 1;
}

.divider--large {
  border-top-width: 4px;
}

.text-muted {
  color: $text-muted !important;
}

.gray-icon {
  color: $text-light-gray !important;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }
}

.link {
  color: $primary;
  cursor: pointer;
  padding: 0;

  &:hover {
    text-decoration: underline;
  }
}

span.link {
  display: inline;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.icon.alert-icon {
  color: $danger;

  svg {
    height: 17px;
    width: 17px;
  }
}

.text-green {
  color: $green;
}

.ml-10-px, .ms-10-px {
  margin-left: 10px;
}

.badges-word-break {
  .badge {
    word-break: break-word;
    white-space: normal;
    height: auto;
  }
}

.date-word-break {
  .date-label {
    word-break: break-word;
    white-space: normal;
    height: auto;
  }
}

.word-break-all {
  word-break: break-all;
}

.word-break-word {
  word-break: break-word;
}

.word-break-keep {
  word-break: keep-all;
}

.white-space-nowrap {
  white-space: nowrap;
}

.disabled {
  opacity: 0.6;
  pointer-events: none;
}

.input-disabled {
  opacity: 0.5;
  pointer-events: none;
}

.text-overflow-ellipsis {
  max-width: calc(100% - 10px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cell-paddingless-horizontal {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.white-space-nowrap {
  white-space: nowrap;
}

a.link {
  color: #628AF1;
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;

  &:hover {
    color: #628AF1;
  }
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.overflow-x-auto {
  overflow-x: auto;
}

.dark-50 {
  color: $dark-50;
}

.white-space-pre {
  white-space: pre;
}

.border-transparent {
  border-color: transparent;
}

.only-error-field .field__control {
  display: none;
}

textarea.script-sample, input.script-sample {
  font-family: 'JetBrainsMono', 'Courier New', Courier, monospace, 'Montserrat';

  &:disabled, &.disabled {
    color: $base-dark;
    opacity: 1;
    cursor: text;
  }
}

.one-line-text {
  //display: block;
  max-width: 100%;
  @include text-overflow-ellipsis();
}

$i: $wpx-start;
@while $i <= $wpx-end {
  .wpx-#{$i} {
    width: #{$i}px;
  }

  $i: $i + $wpx-step;
}

@each $name, $color in $theme-colors {
  .color-#{$name} {
    color: $color;
  }
  .bg-color-#{$name} {
    background-color: $color;
  }
}

.react-loading-skeleton {
  --base-color: #EFF1F5;
  border-radius: 8px;
}

.icon-only-button {
  @include icon-only-button();
}

.counter {
  font-size: 11px;
  font-weight: 600;
  line-height: 16px;
  text-align: center;
  padding: 0 4px;
  border-radius: 16px;
  min-width: 16px;
  height: 16px;
  display: inline-flex;
  justify-content: center;

  @each $name, $color in $theme-colors {
    &--#{$name} {
      color: map-get($btn-font-color, $name);
      background: $color;
    }
  }
}
