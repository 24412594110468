$offer-card-main-info-width: 340px;
$offer-card-logo-width: 120px;
$offer-card-text-max-width: 800px;
$offer-card-text-max-height: 64px;
$offer-card-actions-width: 430px;
$offer-card-value-column-width: 200px;
$offer-card-reward-column-width: 350px;
$offer-card-column-width: 60%;
$offer-card-small-column-width: 40%;
$offer-card-column-width-2: 40%;
$offer-card-small-column-width-2: 30%;
$offer-card-margin: 20px;
$offer-card-padding: 8px;

.offer-card {
  .card-body {
    display: flex;
    padding-top: 0;
  }

  &__main {
    width: $offer-card-main-info-width;
    box-sizing: content-box;
    flex-shrink: 0;

    padding-right: $offer-card-margin;
    margin-right: $offer-card-margin;
    border-right: 1px solid $background-color;
  }

  &__main-info {
    width: calc(100% - #{$offer-card-logo-width} - #{$offer-card-margin});
    margin-left: $offer-card-margin;
  }

  &__first-line {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__first-line-text {
    display: flex;
    flex-wrap: wrap;
  }

  &__first-line-button {
    .dropdown-toggle {
      display: flex;
      padding: 6px 6px;
      color: $text-gray;
      path {
        fill: $text-gray;
      }

      &:hover, &:focus {
        background: #F6F6F9;
        color: $base-dark;
        path {
          fill: $base-dark;
        }
      }
    }

    .dropdown-menu {
      .dropdown-item {
        .icon {
          margin-right: 9px;
        }

        &:hover {
          color: $base-dark;
        }
      }
    }
  }

  &__header {
    display: flex;
    margin-bottom: 20px;
    flex-wrap: wrap;

    &-text {
      color: $text-gray;
      height: 20px;
      display: flex;
      align-items: center;
    }
  }

  &__nnco-partners {
    display: inline-block;
    width: fit-content;
    //font-size: 12px;
    line-height: 16px;
    padding: 0 4px;

    &:hover {
      background: #E1E5ED;
      border-radius: 10px;
    }
  }

  &__logo {
    width: $offer-card-logo-width;

    .symbol {
      border: 1px solid rgba(63, 66, 84, 0.05);
      border-radius: $border-radius;
    }
  }

  &__links {
    margin-top: 16px;
    width: 100%;
    border-top: 1px solid $background-color;
  }

  &__link {
    padding-top: 16px;

    .titled-text {
      &__title {
        color: $text-gray;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        justify-content: flex-start;
        align-items: center;

        .icon {
          color: $text-gray;
          font-size: 16px;
        }
      }
    }
  }

  &__offer-status {
    display: inline-flex;
    height: 20px;
    margin-right: $offer-card-padding;

    .badge {
      font-size: 10px;
      line-height: 16px;
      text-transform: uppercase;
      padding: 2px 5px;
      border-radius: 2px;
      height: 16px;
      font-weight: 600;
      box-sizing: content-box;
    }
  }

  &__offer-id-divider {
    font-size: 18px;
    margin: 0 5px;
  }

  &__title {
    margin: $offer-card-padding 0;

    .title {
      font-size: 24px;
      line-height: 28px;
      word-break: break-word;
    }

    &-sub {
      margin-top: 5px;
      color: $text-gray;
    }
  }

  .tabbed-text {
    max-width: $offer-card-text-max-width;

    &__title {
      font-weight: 600;
    }
  }

  &__info {
    max-width: calc(100% - #{$offer-card-main-info-width} - #{1px + ($offer-card-margin * 2)});
    flex-grow: 1;

    .titled-text {
      &__title {
        color: $base-dark;
        font-weight: 600;
        margin-bottom: $offer-card-padding;
      }
    }
  }

  &__content {
    width: 100%;
  }

  &__list {
    display: flex;
    flex-direction: column;

    &-item-title {
      display: flex;
      align-items: center;
      justify-content: space-between;

      color: $text-gray;
    }

    .titled-text {
      display: flex;
      width: 100%;
      font-weight: 400;
      line-height: 16px;

      &:not(:last-child) {
        margin-bottom: $offer-card-padding;
      }

      &__title {
        width: $offer-card-logo-width;
        margin-bottom: 0;
        position: relative;
      }
      &__title-label {
        display: block;
        width: 100%;
        word-break: break-word;
      }
      &__text {
        width: calc(100% - #{$offer-card-logo-width} - #{$offer-card-margin});
        margin-left: $offer-card-margin;
        box-sizing: content-box;
      }
    }
  }

  &__partners-list {
    margin-bottom: 12px;
    &-title {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      font-weight: 600;

      & > .icon {
        margin-right: 6px;
        font-size: 16px;
      }
    }

    &-edit-btn {
      width: 24px;
      height: 24px;

      margin-left: 10px;
    }
  }

  &__note {
    max-width: $offer-card-text-max-width;
  }
}

.offer-header {
  display: flex;
  width: 100%;
  margin: 0 $offer-card-margin 16px;

  &__logo {
    width: $offer-card-logo-width;
    height: $offer-card-logo-width;
    margin-right: $offer-card-margin;
    position: relative;
  }

  &__offer-is-top{
    position: absolute;
    top: 4px;
    left: 4px;
  }

  &__info {
    width: calc(100% - #{$offer-card-logo-width + ($offer-card-margin * 3) + $offer-card-actions-width});
    display: flex;
    flex-direction: column;
    min-height: 100%;
    border-bottom: 1px solid $background-color;
  }

  &__id {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $text-gray;
    font-size: 11px;
    line-height: 16px;

    .badge {
      padding: 0 4px;
      font-size: 10px;
      line-height: 16px;
      text-transform: uppercase;
      border-radius: 2px;
      height: 16px;
      font-weight: 600;
      box-sizing: content-box;
    }
  }

  &__offer-id-divider {
    height: 16px;
    margin-top: -3px;
  }

  &__title {
    width: 100%;
    max-width: 100%;

    &--inactive .title {
      color: $text-gray;
    }
  }

  .offer-active-switch {
    margin-bottom: 8px;
  }

  &__bottom {
    margin-bottom: 8px;

    .offer-page__request-message {
      margin-bottom: 0;
      margin-right: 16px;
    }

    .offer-page__actions {
      display: flex;
      align-items: center;
    }
  }

  .tag-list {
    color: $text-gray;
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    margin-bottom: 8px;
  }

  &__offer-status {
    display: inline-flex;
    height: 16px;
    margin-right: 8px;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    width: $offer-card-actions-width;
    padding-left: $offer-card-margin;
    min-height: 100%;
    border-bottom: 1px solid $background-color;

    .button {
      height: 32px;
      margin-left: 8px;

      &--square {
        width: 32px;
      }

      .icon {
        font-size: 16px;
      }
    }
  }
}

.offer-form-conditions-table {
  td {
    vertical-align: top;
    line-height: 16px;
  }

  .more-cell {
    display: block;
    line-height: 16px;

    &__more-badge {
      padding: 0 6px 0 4px;
      height: 16px;
      font-size: 12px;
      line-height: 16px;
      border-radius: 10px;
    }

    &__more-label {
      b {
        font-weight: 400;
        padding: 0 !important;
      }
    }
  }

  &__goal {
    font-weight: 600;

    &--qualification {
      color: $success;
    }
    &--formula {
      color: $info;
    }
  }

  &__icon-expand {
    display: flex;
    align-items: center;
    font-size: 13px;
    line-height: 16px;
    word-break: keep-all;
    white-space: nowrap;

    span {
      word-break: keep-all;
      white-space: nowrap;
    }

    .button {
      height: 16px;
      width: 24px;
      font-size: 10px;
      margin-left: 8px;

      .button__icon {
        margin-left: 0;
      }
    }
  }

  &__limited-column {
    max-width: 350px;
  }

  &__payout-column, &__reward-column, &__limit-column {
    width: $offer-card-value-column-width;
    min-width: $offer-card-value-column-width;
  }

  .rc-table-expanded-row {
    tr, td {
      background: $table-inner-background !important;
    }

    & > td {
      padding: 0;
      padding-left: 0 !important;
    }

    tr:last-child td {
      border-bottom: none;
    }

    thead {
      display: none;
    }
  }

  &__device-column {
    width: 200px;
  }

  &__partners-column {
    width: 230px;

    .badge {
      color: $base-dark;
      background-color: $background-color;

      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      padding: 0px 4px;
      border-radius: 10px;
    }
  }

  &__os-column {
    width: 200px;
  }

  &__goals-column {
    width: $offer-card-column-width;
  }

  &__countries-column {
    width: $offer-card-small-column-width;
    min-width: 250px;
  }

  &__cities-column {
    width: $offer-card-small-column-width-2;
    min-width: 250px;
  }

  &--cities {
    .offer-form-conditions-table__goals-column {
      width: $offer-card-column-width-2;
    }
    .offer-form-conditions-table__countries-column {
      width: $offer-card-small-column-width-2;
    }
  }

  &__actions-column {
    width: 70px;
    min-width: 70px;
    box-sizing: content-box;

    .icon {
      color: $text-gray;
      cursor: pointer;
      font-size: 16px;
      padding: 4px;
      margin: -3px 0;

      &:not(:last-child) {
        margin-right: 4px;
      }

      &:disabled, &.disabled, &[disabled] {
        opacity: 0.4;
        cursor: default;
      }
    }
  }

  &__payout-column, &__reward-column {
    position: relative;

    .offer-form-conditions-table__icon-expand {
      position: absolute;
      right: $table-td-padding-left;
      top: $table-td-padding-top;
    }
  }

  .os-types-list {
    .os-type-label {
      .icon {
        font-size: 16px;
      }
    }

    .badge {
      color: $base-dark;
      font-size: 12px;
      line-height: 16px;
      height: 16px;
      padding: 0 4px;
      border-radius: 10px;
    }

    & > *:not(:last-child) {
      margin-bottom: 4px;
    }
  }

  .countries-flags {
    .more-cell {
      display: flex;
      grid-gap: unset;
      margin-right: 0;
    }

    .badge {
       height: 11px;

       &:not(.more-label) {
         width: 16px;
       }

       .country-label {
         &__icon {
           height: 11px;
           width: 16px;
           min-width: 16px;

           .icon, .icon svg {
             height: 26px;
             width: 26px;
           }
         }
       }
     }
  }

  .partners-tooltip {
    max-height: 300px;
    overflow: auto;
    @include default-scrollbar();
  }

  .individual-badge {
    font-size: 10px;
    line-height: 16px;
    height: 16px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 0 4px;
    //color: $base-dark;
    //background: $background-color;
  }

  &--partner {
    .offer-form-conditions-table__reward-column {
      width: $offer-card-reward-column-width;
      min-width: $offer-card-reward-column-width;
    }

    .offer-form-conditions-table__icon-expand {
      right: unset;
      left: 12px;
    }
  }
}

.offer-condition-partners-list {
  &__partner {
    display: block;
    @include text-overflow-ellipsis();
    margin-bottom: 1px;
  }

  &__more {
    display: flex;

    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    margin-top: 4px;
  }

  .button {
    height: 16px;
    width: 24px;
    font-size: 10px;
    margin-right: 8px;

    .button__icon {
      margin-left: 0;
    }
  }
}

.offer-form {
  &__payout {
    display: flex;
    white-space: pre-wrap;
  }

  &__payout-number {
    display: inline;
    color: $text-gray;
    white-space: pre-wrap;
  }

  &__payout-sub-ids {
    display: inline;
    white-space: pre-wrap;
  }
}
