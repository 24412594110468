.alert-message {
  position: relative;
  display: flex;
  box-shadow: $dropdown-box-shadow;
  background: $white;
  padding: 8px 8px 8px 12px;
  border-radius: $border-radius;
  max-width: 100%;
  width: fit-content;

  &__error-icon {
    display: flex;
    align-items: center;
    margin: 0 10px;
  }

  &__text {
    &:before {
      content: '';
      top: 0;
      left: 0;
      height: 100%;
      width: 4px;
      background: $danger;
      display: block;
      position: absolute;
      border-radius: 5px 0px 0px 5px;
    }
  }

  @each $name, $color in $theme-colors {
    &--#{$name} {
      .alert-message__text {
        &:before {
          background: $color;
        }
      }

      .alert-message__main-text {
        color: $color;
      }
    }
  }

  @each $name, $color in $alert-message-line-colors {
    &--#{$name} {
      .alert-message__text {
        &:before {
          background: $color;
        }
      }
    }
  }
}
