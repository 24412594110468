body {
  overflow: hidden;
}

img {
  width: 100%;
  height: auto;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

a:hover {
  text-decoration: underline;
}

ul {
  margin-bottom: 0;
  padding-inline-start: 30px;
}
