.notification-container {
  position: fixed;
  height: 0;
  bottom: 60px;
  right: 10px;

  display: flex;
  flex-direction: column-reverse;


  width: 460px;

  z-index: 1100;
}

.notification {
  display: flex;
  align-items: center;

  padding: $base-font-size $base-line-height;
  margin: 10px 10px 0 10px;

  font-size: $font-size-lg;
  font-weight: 600;
  line-height: $base-line-height;
  color: #ffffff;

  border-radius: 5px;
  box-shadow: 0 10px 30px rgba(63, 66, 84, 0.1);

  animation-name: fade-animation, slide-up;
  animation-duration: 300ms, 300ms;
  animation-delay: 0ms, 50ms;
  animation-fill-mode: backwards;

  &__icon {
    margin-right: 10px;
  }

  &__close {
    margin-left: auto;

    cursor: pointer;
  }

  &__close-icon {
    svg * {
      fill: #ffffff;
    }
  }

  @each $name, $glyph in $notification-types {
    &--#{$name} {
      background-color: $glyph;

      &.notification--outline {
        color: $glyph;
        background-color: $white;
        border-left: 3px solid $glyph;
      }
    }
  }
}

@include tabletLayout() {
  .notification-container {
    width: min(460px, calc(100vw - 20px));
  }
}
