.titled-text {
  &__title {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    margin-bottom: 5px;

    color: $text-gray;
    word-break: break-word;

    & > span:last-child {
      margin-left: 10px;
    }
  }

  &__text {
    word-break: break-word;
  }
}
