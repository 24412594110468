$condition-list-element-label-width: 9rem;
$condition-list-element-height: 42px;
$condition-list-element-button-width: 41px;
$condition-list-element-padding: 8px 0;
$condition-list-element-margin-left: 20px;
$condition-list-element-margin-right: 0px;
$condition-list-element-countries-width: 115px;

.conditions-list {
  &__add-button {
    width: 100%;
    height: $condition-list-element-height;
  }

  .conditions-list-element {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: $condition-list-element-padding;
    width: 100%;
    border-radius: $border-radius;
    cursor: pointer;
    height: $condition-list-element-height;
    position: relative;

    &__icons {
      display: flex;
      align-items: center;
      margin-right: 10px;

      .alert-icon {
        &:before {
          content: '';
          top: 0;
          left: 0;
          height: $condition-list-element-height;
          width: 4px;
          background: $danger;
          display: block;
          position: absolute;
          border-radius: 5px 0px 0px 5px;
        }
      }
    }

    &__title {
      display: flex;
      align-items: center;
      width: calc(100% - #{$condition-list-element-margin-left} - #{$condition-list-element-countries-width} - #{$condition-list-element-button-width});
      margin-left: $condition-list-element-margin-left;
    }

    &__label {
      font-weight: $font-weight-bolder;
      font-size: 1.07rem;
      width: $condition-list-element-label-width;
      max-width: $condition-list-element-label-width;
    }

    &__countries {
      width: $condition-list-element-countries-width;
    }

    &__menu {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 13px;
      width: $condition-list-element-button-width;
      margin-right: $condition-list-element-margin-right;
    }

    &--active {
      background-color: #EFF1F5;
    }
  }

  & > *:not(:last-child) {
    margin-bottom: 10px;
  }
}


.s2s-badge, .s2s-postback {
  cursor: pointer;

  .icon {
    font-size: 13px;
    margin-left: 2px;
    margin-top: -2px;
  }
}

.s2s-badge {
  text-transform: uppercase;
}

.s2s-postback-block {
  font-size: $base-font-size;
  font-weight: $font-weight-base;
}
