.field {
  margin-bottom: $control-margin-bottom;

  &__label-container {
    display: flex;
    align-items: flex-end;
  }

  &__label {
    margin-bottom: 0;
    font-size: 12px;
    line-height: 20px;
    color: $control-label-color;

    &:last-child {
      width: 100%;
    }
  }

  &__extra {
    margin-left: auto;
  }

  &__copy {
    margin-left: auto;
  }

  &--label-right, &--label-left {
    .field__label {
      margin-bottom: 0;
      line-height: 16px;
    }
  }

  &--label-right {
    display: flex;

    .field__label-container {
      order: 10;
      margin-left: 8px;
      align-items: flex-start;
    }
  }

  &--label-left {
    display: flex;

    .field__label-container {
      margin-right: 8px;
    }
  }

  &__required-mark {
    color: $danger;
    margin-left: 0.3rem;
  }

  &__error {
    font-size: $control-error-font-size;
    margin-top: $control-error-margin-top;
    color: $control-error-color;
    white-space: pre-line;
  }

  &__excluding {
    display: flex;
    align-items: center;
    margin-left: auto;

    label {
      display: flex;
      align-items: center;
      margin-bottom: 0;
      font-size: 11px;
      line-height: 20px;
      cursor: pointer;
    }
  }

  &.field--has-error {
    .date-picker {
      .input-text {
        background-color: $control-error-background-color;
        border-color: $danger;
      }
    }
  }

  &.field--has-error.field--has-group-append {
    input {
      border-right: none;
    }
    .input-group-append {
      .input-group-text {
        border: 1px solid $danger;
        border-left: none;
      }
    }
  }

  &__hint {
    color: $control-help-color;
    font-size: $control-hint-font-size;
    margin-top: 5px;

    .alert-message {
      margin-top: 11px;
    }
  }

  &__help {
    display: flex;
    align-self: center;
    color: $control-help-color;
    font-size: $control-help-font-size;
    margin-left: 5px;
    cursor: pointer;
  }

  &--disabled {
    .field__label {
      color: $control-disabled-label-color;
    }

    .field__control {
      opacity: $control-disabled-opacity;
    }
  }

  &--control-InputsByLanguages {
    & > .field__error {
      display: none;
    }
  }

  &--placeholder {
    &-currentColor {
      input::placeholder {
        color: $control-color !important;
        opacity: 1 !important;
      }
    }
  }

  &__counter {
    color: $text-gray;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;

    &--error {
      color: $danger;
    }

    span {
      margin: 0 2px;
    }
  }

  &--disabled {
    &.field--has-group-append {
      .input-group-append {
        .input-group-text {
          color: $control-disabled-color;
        }
      }
    }
  }
}

.field--label-right {
  &.field--control-Switch {
    .field__label-container {
      align-items: center;
    }

    .field__control {
      display: flex;
      align-items: center;
    }

    .field__error {
      position: absolute;
      margin-top: 20px;
      margin-left: 42px;
    }

    &.field--has-error {
      margin-bottom: 30px;
    }
  }
}

.field__control {
  .react-loading-skeleton {
    --base-color: $control-background-color;
    border-radius: $control-border-radius;
    height: $control-min-height;
  }
}

.control-skeleton {
  .react-loading-skeleton {
    &__TextEditor {
      height: $text-editor-min_height;
    }
  }
}
