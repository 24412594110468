.action-cell {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: 1rem;
  }

  .icon {
    color: $text-light-gray;
    font-size: 16px;
    cursor: pointer;

    &.disabled, &:disabled, &[disabled] {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }
}
