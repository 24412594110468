$news-widget-datetime-width: 48px;
$news-widget-datetime-padding: 12px;

.widget {
  position: absolute;
  display: flex;
  flex-direction: column;
  border-radius: $border-radius;
  margin-bottom: $widget-padding;
  margin-right: $widget-padding;
  left: calc(#{$widget-cell-width} * var(--widget-left) + 1px);
  top: calc(#{$widget-cell-height} * var(--widget-top) + 1px);
  width: calc(#{$widget-cell-width} * var(--widget-width) - #{$widget-padding});
  height: calc(#{$widget-cell-height} * var(--widget-height) - #{$widget-padding});
  //overflow: hidden;
  z-index: $widget-z-index;

  transition-property: left, top, width, height;
  transition-duration: 0.1s;
  transition-timing-function: ease;

  &__header-title {
    cursor: default;
    display: block;
    max-width: 100%;
    font-size: 13px;
    line-height: 16px;

    @include text-overflow-ellipsis();
  }

  &__header-actions {
    display: none;
    position: absolute;
    padding: 4px;
    border-radius: 4px;

    top: 4px;
    right: 4px;

    .icon {
      font-size: 16px;
      cursor: pointer;
      margin-left: 6px;

      &:hover {
        color: $primary;
      }
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    height: $widget-header-height;
    width: 100%;
    position: relative;
  }

  &:hover {
    .widget__header-actions {
      display: flex;
    }
  }

  &__body {
    padding: 8px;
    padding-top: 0;
    height: calc(100% - #{$widget-header-height});
    width: 100%;
  }

  &--mode-edit {
    &:hover {
      .widget__header {
        cursor: grab;
        background: $light-primary;

        .widget__header-actions {
          background: $light-primary;
        }
      }
    }

    .widget__header-title {
      cursor: grab;
    }
  }

  &--dragging {
    border: 2px solid $primary;
    background: none !important;
    z-index: $widget-dragging-z-index;

    .widget-resizable-box, .widget__header, .widget__body {
      opacity: 0;
    }
  }

  &--mode-preview {
    left: calc(#{$widget-cell-width} * var(--widget-left));
    width: calc(#{$widget-cell-width} * var(--widget-width) - #{$widget-padding});
  }

  &--resizing {
    transition-property: none;
  }
}

.indicators-widget {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  &__value {
    font-size: 30px;
    line-height: 1.06;
    font-weight: 600;
    min-width: 50%;
    max-width: 100%;
    text-align: center;
  }

  &__period {
    font-size: 12px;
    line-height: 16px;
    min-width: 30%;
    text-align: center;
  }
}

.widget-PartnerSearch {
  z-index: $widget-z-index + 1;
}

.graph-linear-widget {
  height: 100%;
  width: 100%;
  padding: 0 16px 16px;
  display: flex;
  justify-content: center;
  align-items: center;

  &__slice {
    background: $white;
    padding: 10px 12px;
    box-shadow: $tooltip-box-shadow;
    max-width: 400px;
  }

  &__slice-date {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
  }

  &__slice-point {
    display: flex;
    width: 100%;
    align-items: center;
    margin-top: 4px;
  }

  &__slice-point-color {
    width: 5px;
    height: 5px;
    border-radius: 5px;
    margin-right: 6px;
    margin-left: 2px;
  }

  &__slice-point-text {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 20px;
    overflow: hidden;
  }

  &__slice-point-name {
    display: block;
    max-width: 150px;
    @include text-overflow-ellipsis();
  }
}

.pie-chart-widget, .graph-linear-widget {
  .spinner-border {
    width: 32px;
    height: 32px;
    border-width: 5px;
    color: $background-color !important;
  }

  svg {
    overflow: visible;

    text {
      font-family: 'Montserrat', sans-serif !important;
    }
  }
}

.pie-chart-widget {
  height: 100%;
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__value {
    font-size: 30px;
    line-height: 1.06;
    font-weight: 600;
    min-width: 50%;
    max-width: 100%;
    text-align: center;
  }

  &__period {
    font-size: 12px;
    line-height: 16px;
    min-width: 30%;
    text-align: center;
  }

  &__chart {
    padding: 8px 0;
    width: 100%;
    height: 100%;
  }

  .graph-linear-widget__slice-point {
    margin-top: 0;
  }

  #pie-chart-value {
    font-size: 17px;
    stroke: $white;
    stroke-width: 3;
    font-weight: 600;
    pointer-events: none;
    paint-order: stroke fill;
  }
}

.widget--active {
  z-index: $widget-dragging-z-index;
}

.widget-border-preview {
  content: '';
  position: absolute;
  display: flex;
  flex-direction: column;
  border-radius: $border-radius;
  margin-bottom: $widget-padding;
  margin-right: $widget-padding;
  left: calc(#{$widget-cell-width} * var(--widget-left));
  top: calc(#{$widget-cell-height} * var(--widget-top));
  width: calc(#{$widget-cell-width} * var(--widget-width) - #{$widget-padding} + 1px);
  height: calc(#{$widget-cell-height} * var(--widget-height) - #{$widget-padding} + 1px);
  border: 2px solid $primary;
  //overflow: hidden;
  z-index: $widget-dragging-z-index - 1;

  transition-property: left, top, width, height;
  transition-duration: 0.1s;
  transition-timing-function: ease;
}

.widget-resizable-box {
  border-radius: $border-radius;
  //overflow: hidden;
  overflow: visible;
  min-width: calc(#{$widget-cell-width} * var(--widget-min-width) - #{$widget-padding} - 1px);
  min-height: calc(#{$widget-cell-height} * var(--widget-min-height) - #{$widget-padding} - 1px);
  max-width: calc(#{$widget-cell-width} * var(--widget-max-width) - #{$widget-padding} - 1px);
  max-height: calc(#{$widget-cell-height} * var(--widget-max-height) - #{$widget-padding} - 1px);
}

.widget-News {
  .widget__body {
    padding: 8px 16px 16px;
  }
}

.news-widget {
  &__news-link {
    position: absolute;
    bottom: 16px;

    a {
      padding: 0;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;

      &:hover {
        color: $primary;
      }
    }
  }

  &__datetime {
    width: $news-widget-datetime-width;
    margin-right: $news-widget-datetime-padding;
    font-size: 11px;
    line-height: 16px;
  }

  &__text {
    width: calc(100% - #{$news-widget-datetime-width + $news-widget-datetime-padding});
  }

  &__title a, &__body a, &__title, &__body {
    display: block;
    width: 100%;
    @include text-overflow-ellipsis();
    text-align: left;
  }

  &__title, &__title a {
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    padding: 0;
    border: none;

    &:hover {
      color: $primary;
    }
  }

  &__body, &__body a {
    font-size: 12px;
    line-height: 16px;
    padding: 0;
    border: none;
  }

  &__news {
    display: flex;
    align-items: flex-start;
    width: 100%;
    padding-bottom: 16px;

    &:first-child {
      height: 49px;
      box-sizing: content-box;

      .news-widget__body a {
        display: -webkit-box;
        white-space: unset;
        line-height: 16px; /* fallback */
        max-height: 32px; /* fallback */
        -webkit-line-clamp: 2; /* number of lines to show */
        -webkit-box-orient: vertical;
      }
    }
  }

  &__news--skeleton {
    .news-widget__title {
      width: 70%;
    }
    .news-widget__body {
      width: 90%;
    }
    .news-widget__date {
      width: 100%;
    }
    .news-widget__time {
      width: 75%;
    }
  }
}

.partner-search-widget {
  padding: 15px 20px 0 20px;

  .search-bar__container {
    position: relative;
    display: flex;
    align-items: center;
  }

  &__search-bar.field {
    flex-grow: 1;
    margin: 0;
    background: transparent;
    border: 1px solid #D2D4D9;
    border-radius: 5px;

    .input-select__control {
      height: 52px;
      padding-right: 90px;
      background: transparent;

      .input-select__placeholder {
        white-space: nowrap;
      }
    }
  }

  &__open-btn {
    position: absolute;
    width: 80px;
    right: 10px;

    &:disabled {
      background-color: #AFAFB6;
      color: #E2E2E7;
    }
  }
}
