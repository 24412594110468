$promo-code-type-padding: 16px;
$promo-code-type-poster-width: 120px;
$promo-code-type-actions-width: 25px;
$promo-code-type-actions-margin: 10px;
$promo-codes-filter-actions-width: 300px;

.promo-code-types {}

.promo-code-type {}

.promo-code-type-form {
  .modal-dialog {
    max-width: calc(min(max($mobile-min-width, 100dvw), 540px) - 1rem);
  }

  &__poster {
    .symbol {
      background-color: $background-color;

      .icon svg {
        color: $white;
      }
    }

    .field {
      display: flex;
      flex-wrap: wrap;

      &__label-container {
        width: 100%;
      }

      &__hint {
        width: calc(100% - 120px);
        padding-left: 12px;
        margin-top: 0;
      }

      .input-image {
        width: 120px;
        height: 120px;
        margin: 0;

        &--empty {
          .input-image__actions {
            display: none;
          }
        }

        &__actions {
          border-radius: 4px;
        }

        &__edit {
          display: none !important;
        }

        &__preview {
          display: flex;
          width: 120px;
          max-height: 120px;
          justify-content: center;

          img {
            max-width: 120px;
            max-height: 120px;
            width: 120px;
            height: 120px;
            object-fit: contain;
          }
        }
      }
    }

    button {
      margin-top: 4px;

      .icon {
        font-size: 16px;
      }
    }
  }

  &__poster-hint {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: $text-gray;
  }
}

.promo-codes-page {
  .filters-button-container {
    display: none;
  }

  &__add-button {
    .btn {
      display: flex;
      align-items: center;
      font-weight: 600;
    }

    .icon {
      font-size: 16px;
    }

    .dropdown-menu {
      min-width: 100%;
    }
  }

  &__filter {

    .filter {
      &__content {
        display: flex;
        align-items: flex-start;
        flex-direction: row;
        width: 100%;
      }

      &__fields {
        width: calc(100% - #{$promo-codes-filter-actions-width});
      }

      &__footer {
        width: $promo-codes-filter-actions-width;
        padding-left: 15px;
        margin-top: 0;
      }

      &__btn--submit, &__btn--reset {
        padding: 15px 20px;
        height: 16px;
        box-sizing: content-box;
      }
    }
  }

  &__filter-fields {
    display: grid;
    grid-template-columns: minmax(5%, 2fr) minmax(5%, 1fr) minmax(5%, 1.5fr) minmax(5%, 1.5fr) minmax(5%, 1.5fr);
    grid-gap: 15px;
  }

  &__assignment-tabs {
    .tabs__nav {
      padding: 12px 20px;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 12px 20px 8px 20px;
  }

  &__title-text {
    font-weight: 600;
    font-size: 17px;
    line-height: 24px;
  }

  &__title-right {
    display: flex;
    align-items: center;

    button {
      margin-left: 16px;
    }
  }

  .filter__preview {
    display: none;
  }
}

.promo-codes-table {
  .card-cell {
    &__text {
      font-size: 13px;
      line-height: 16px;
      font-weight: 400;

      &--bold {
        font-weight: 600;
      }
    }

    &__sub-text {
      opacity: 1;
      font-size: 11px;
      line-height: 16px;
      font-weight: 400;
    }
  }

  &__promo-code-header {
    .badge {
      text-transform: uppercase;
      font-weight: 600;
      font-size: 10px;
      height: 16px;
      padding: 0 4px;
    }
  }

  &__offer-empty-block {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
  }


  &__offer-empty-block-arrow {
    position: absolute;
    border-bottom: 1px dashed #A8B0C6;
    border-right: 1px dashed #A8B0C6;
    border-bottom-right-radius: 100%;
    width: calc(50vw - 320px);
    height: 280px;
    top: 0;
    left: 500px;
  }

  &__promo-code-dates-label {
    margin-right: 4px;
  }

  &__error {
    color: $danger;
    font-weight: 600;
    margin-right: 4px;
  }

  &__limit {
    span {
      padding: 2px 4px;
      background-color: $background-color;
      font-size: 13px;
      line-height: 16px;
      font-weight: 400;

      &:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        margin-right: 2px;
      }

      &:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }

    &--danger {
      span {
        background-color: rgba($danger, 0.1);
      }
    }
  }

  &__type {
    .card-cell__image {
      width: 32px;
      height: 32px;
      margin-right: 11px;
    }

    .card-cell__text {
      font-weight: 400;
    }

    .symbol {
      width: 32px;
      height: 32px;

      img {
        object-fit: contain;
      }

      .icon {
        color: $white;
      }
    }

    &--placeholder {
      .symbol {
        background-color: $background-color;
      }
    }
  }

  &__promo-code-dates {
    div {
      display: inline-flex;
    }
  }

  &__description {
    max-width: 500px;
    border-right: none !important;
  }

  &__actions {
    .dropdown-toggle.button {
      color: $text-light-gray;
      font-size: 15px;
      padding: 6px;

      &:hover {
        color: $base-dark;
        background: $background-color;
      }

      &:not(:hover) {
        svg path {
          fill: $text-light-gray;
        }
      }
    }

    .dropdown.show {
      .dropdown-toggle.button {
        background: $background-color;

        svg path {
          fill: $base-dark;
        }
      }
    }

    .dropdown-menu.show {
      position: fixed !important;
    }
  }

  &__promo-code-value {
    display: inline-flex;
    margin-right: 4px;

    .icon {
      width: 16px;
      height: 16px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: $primary;
      margin-left: 4px;
      margin-top: -2px;
    }
  }

  &__promo-code-dates-label {
    color: $base-dark;
  }
}

.promo-code-type-option {
  display: flex;
  align-items: center;

  &__image {
    margin-right: 8px;

    .symbol {
      width: 24px;
      height: 24px;
      background: none;

      img {
        object-fit: contain;
      }

      .icon svg {
        width: 16px;
        height: 16px;
      }
    }

    &--placeholder {
      .symbol {
        background: $background-color;
        color: $white;
      }
    }
  }

  &__label {}
}

.promo-code-form {
  .modal-dialog {
    max-width: calc(min(max($mobile-min-width, 100dvw), 470px) - 1rem);
  }

  hr {
    margin-top: 16px;
    margin-bottom: 16px;
    border-top: 1px solid $background-color;
  }

  &__fields {}

  &__type {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  &__type-image {
    color: $white;
    margin-right: 16px;

    .symbol {
      background: none;
    }

    img {
      object-fit: contain;
    }

    &--placeholder {
      .symbol {
        background: $background-color;
      }
    }
  }

  &__type-fields {
    width: calc(100% - 106px);
  }

  &__type-field {
    width: 100%;

    .input-select__placeholder {
      font-weight: 600;
      color: $text-light-gray;
    }

    .input-select__single-value {
      .promo-code-type-option__image {
        display: none;
      }

      .promo-code-type-option__label {
        font-weight: 600;
      }
    }
  }

  &__type-name {
    font-weight: 600;
    font-size: 17px;
    line-height: 24px;
    margin-bottom: 8px;
  }

  &__type-description {
    width: 100%;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    word-break: break-word;
  }

  &__static-fields {
    margin-top: 16px;
  }
  &__static-field {
    width: 100%;
    display: flex;
    line-height: 20px;

    &:not(:last-child) {
      margin-bottom: 7px;
    }
  }
  &__static-field-name {
    width: 90px;
    margin-right: 16px;
    font-size: 12px;
    color: $text-gray;
  }

  &__static-field-value {
    display: block;
    width: calc(100% - 106px);
    @include text-overflow-ellipsis();
  }

  &__fields-line, &__fields-limit-line {
    display: grid;
    width: 100%;
    grid-template-columns: minmax(15%,2fr) minmax(15%,1.2fr);
    grid-gap: 13px;
  }

  .field--control-Checkbox {
    margin-top: 28px;

    .field__label-container {
      margin-left: 0;
      align-items: flex-start;
    }

    .checkbox {
      margin-right: 8px;
    }
  }

  &--editing {
    .modal__footer > div {
      button {
        &:first-child {
          margin-right: 8px;
        }
        &:last-child {
          margin-left: 8px;
        }
      }
    }
  }

  &__error {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: $danger;
  }
}

.promo-code-delete-form {
  .modal__body {
    padding-bottom: 0;
  }
}

.promo-code-import-form {
  .modal-dialog {
    max-width: calc(min(max($mobile-min-width, 100dvw), 600px) - 1rem);
  }

  .input-file {
    box-shadow: 0 2px 10px 0 #2E3E9A1A;
    margin-top: 16px;
    padding: 12px 20px 12px 16px;
    border-radius: 4px;
  }

  &__error-label {
    font-size: $control-error-font-size;
    margin-top: $control-error-margin-top;
    color: $control-error-color;
    white-space: pre-line;
  }

  &__template {
    white-space: nowrap;
    color: $primary;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  &__actions {
    padding: 20px 0;

    button:not(:last-child) {
      margin-right: 16px;
    }
  }

  &__help-list {
    padding-top: 8px;
    padding-left: 20px;
    margin-bottom: 12px;

    li {
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      margin-bottom: 8px;
    }
  }

  &__confirm-text {
    white-space: pre;
  }

  &__error-dialog {
    .modal-dialog {
      max-width: calc(min(max($mobile-min-width, 100dvw), 900px) - 1rem);
    }

    .modal-footer {
      display: none;
    }

    .table-widget {
      margin: 0 -#{$modal-padding-x} -24px -#{$modal-padding-x};
      width: calc(100% + #{$modal-padding-x * 2});
      border-bottom-right-radius: 4px;
      white-space: break-spaces;
      word-break: break-word;

      td, .table-footer {
        font-size: $font-size-base;
      }
    }

    .table {
      max-height: 60vh;
      overflow-y: auto;
    }

    .promo-codes-import-errors-table__index {
      max-width: 100px;
    }
  }

  &__confirm-button {
    margin: 20px 0;
  }

  &__header-field {
    margin-bottom: 0;

    .field__control {
      display: none;
    }

    .field__error:not(:empty) {
      margin-bottom: 8px;
    }
  }
}

.promo-codes-page--partner {
  .promo-codes-table {
    &__copy {
      border-right: none;
      color: $primary;
      font-size: 12px;
      padding-right: 0;
      padding-left: 14px !important;

      .icon {
        cursor: pointer;
        border-radius: 4px;
        padding: 8px;

        &:hover {
          background: $background-color;
        }
      }
    }
  }

  .promo-codes-page__filter {
    .filter__fields {
      width: 350px;
    }

    .promo-codes-page__filter-fields {
      display: flex;

      &, .field {
        width: 100%;
      }
    }
  }
}

.promo-codes-help-tooltip {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  cursor: default;
  color: $primary;

  .tooltip {
    .arrow::before, .popover-arrow::before, .tooltip-arrow::before, .tooltip-arrow {
      display: none;
    }

    .tooltip-inner {
        padding: 16px;
        background: $white;
        color: $base-dark;
    }
  }

  &__row {
    display: flex;
    flex-wrap: nowrap;

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &__icon {
    width: 58px;
    margin-right: 14px;
    margin-top: 3px;
  }

  &__text {
    width: calc(100% - 72px);
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
  }
}

.promo-code-partner-form {
  &__info {
    margin: 0 0 24px 0;
    padding: 12px 16px;
    border-radius: $border-radius;
    width: 100%;
    line-height: 16px;

    .icon {
      font-size: 14px;
      color: $base-dark;
      margin-left: 4px;
      margin-bottom: 1px;

      circle {
        stroke: $base-dark;
      }

      rect {
        fill: $base-dark;
      }
    }

    &--success {
      background: #dffbef;
    }

    &--danger {
      background: #ffe6eb;
    }
  }

  .promo-code-form__type-name {
    font-size: 15px;
    line-height: 24px;
    margin-bottom: 0;
  }

  &__link {
    cursor: pointer;
    color: $primary;
  }

  &__empty-block {
    height: 500px;
  }

  .promo-code-partner-form__types {
    width: 100%;
    overflow: auto;
  }

  .promo-code-form__type {
    &:not(:last-child) {
      padding-bottom: 16px;
      border-bottom: 1px solid $background-color;
      margin-bottom: 14px;
    }
  }

  .promo-code-form__type-active-count {
    margin-top: 8px;
    color: $success;
  }

  .promo-code-form__type-get-button {
    margin-top: 8px;
  }

  &__code-modal {
    ul {
      padding-left: 20px;
      margin-top: 8px;
      margin-bottom: 8px;

      li::marker {
        font-size: 10px;
        line-height: 20px;
      }
    }
  }

  &__code {
    .promo-code-form__static-fields {
      padding-bottom: 16px;
      border-bottom: 1px solid $background-color;
      margin-bottom: 16px;
    }

    .promo-code-form__type {
      padding-bottom: 20px;
    }

    .promo-code-form__type-name {
      font-size: 13px;
      line-height: 20px;
      margin-bottom: 4px;
    }

    .promo-code-form__type-fields {
      width: calc(100% - 56px);
    }
  }

  &__code-text {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 24px;

    font-weight: 400;
    font-size: 30px;
    line-height: 32px;
    word-break: break-word;

    border: 2px dashed $success;
    border-radius: $border-radius;
  }

  &__code-button {
    margin: 16px 0 24px 0;
  }
}

.promo-codes-table__dropdown {
  z-index: 50;
}

@include tabletLayout() {
  .promo-codes-page {
    width: max(min(100%, 100dvw), #{$mobile-min-width});
    max-width: max(min(100%, 100dvw), #{$mobile-min-width});

    .tabs.tabs--header.tabs--mobile-inline {
      .tabs__nav {
        border-bottom: 0;
        padding-bottom: 8px;
      }
    }

    .page-header--bb {
      border-bottom: 4px solid $background-color;
    }

    .filters-button-container {
      margin-right: 0;
      margin-left: 0;
      padding: 0 12px 8px 12px;
      display: flex;
      justify-content: flex-end;
    }

    &__assignment-tabs {
      .tabs__nav {
        padding: 8px 12px;
      }
    }

    &__filter {
      display: none;
    }

    .filter-mobile-button {
      width: 100%;
    }

    .promo-codes-table {
      td, th {
        word-break: initial !important;
        white-space: nowrap;
      }

      &__promo-code-header {
        display: flex;
        flex-wrap: nowrap;
      }

      .copy-icon {
        display: flex;
        align-items: center;
        padding-left: 12px;
        font-size: 20px;
        color: $text-gray;
      }

      &__description.table__cell {
        white-space: initial !important;
        word-break: keep-all !important;
        max-width: unset;
        min-width: 250px;
      }
    }

    .promo-code-types {
      border-top: 4px solid $background-color;
    }

    .promo-codes-help-tooltip {
      text-align: end;
      padding-left: 16px;
    }

    .empty-data-block__subtext {
      white-space: initial;
    }

    .header__title:not(.input-select__single-value) {
      white-space: initial;
      min-width: 60dvw;
    }
  }

  .promo-codes-page--partner {
    .page-header {
      border-bottom: none !important;
    }
  }

  &.filter-modal {
    .promo-codes-page__filter {
      display: flex;

      .filter__fields {
        width: 100%;
      }

      .promo-codes-page__filter-fields {
        grid-template-columns: repeat(3, minmax(15%, 1fr));

        .field {
          margin-bottom: 0;
        }
      }
    }
  }

  &.promo-code-form {
    .modal-header {
      padding: 24px;
    }

    .modal-body {
      padding: 0 24px;
    }

    .modal-footer:not(:empty) {
      padding: 24px;
    }

    &:not(.promo-code-form--editing) {
      .promo-code-form__type-image {
        .symbol {
          width: 64px;
          height: 64px;
        }
      }

      .promo-code-form__type-fields {
        width: calc(100% - 80px);
      }
    }

    .date-picker {
      .input-text {
        font-size: 12px;
      }
    }
  }

  &.promo-code-import-form {
    .promo-code-import-form__actions {
      padding: 24px 0;
    }
  }

  &.promo-code-type-form {
    .modal-header {
      padding: 24px;
    }

    .modal-body {
      padding: 0 24px;
    }

    .modal-footer:not(:empty) {
      padding: 24px;
    }

    .input-image, .input-image .symbol {
      width: 64px;
      height: 64px;
    }

    .symbol {
      .icon {
        svg {
          width: 32px;
          height: 32px;
        }
      }
    }

    .promo-code-type-form__poster {
      .field__hint {
        width: calc(100% - 64px);
      }
      .field {
        .input-image__preview {
          img {
            max-width: 64px;
            max-height: 64px;
            width: 64px;
            height: 64px;
          }
        }
      }
    }
  }

  &.promo-code-import-form__error-dialog  {
    .promo-codes-import-errors-table {
      max-height: unset;
      overflow-y: unset;
      table-layout: auto;
      overflow-x: auto;

      &__field {
        word-break: keep-all;
        white-space: nowrap;
      }
      &__message {
        word-break: break-word;
        white-space: nowrap;
      }
    }

    .promo-code-import-form__confirm-text {
      white-space: pre-line;
    }
  }
}

@include mobileLayout(960px) {
  .promo-codes-page__filter .promo-codes-page__filter-fields {
    grid-template-columns: repeat(2, minmax(15%, 1fr));
  }
}

@include mobileLayout(540px) {
  &.layout--es {
    .promo-codes-page {
      &__add-button {
        padding: 7px;
        & > span:not(.button__icon) {
          display: none;
        }
      }
    }
  }
}

@include mobileLayout() {
  .promo-codes-page {
    .tabs.tabs--header.tabs--mobile-inline {
      .tabs__select {
        .input-select__single-value {
          font-size: 17px;
        }
      }
    }

    .filters-button-container {
      justify-content: flex-start;
    }

    &__assignment-tabs {
      .tabs-nav {
        margin-right: 0;
        width: 100%;
      }

      .tabs__nav-left {
        width: 100%;
      }

      .tabs-ink-bar {
        display: none;
      }

      .tabs-nav-list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
      }

      .tabs-tab {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0;

        .tabs-tab-btn {
          white-space: nowrap;
        }
      }
    }
  }

  &.promo-code-import-form__error-dialog {
    .table-widget {
      margin: 0 -24px -24px -24px;
      width: calc(100% + 48px);
    }
  }

  .promo-code-import-form__actions {
    justify-content: space-between !important;
  }

  &.filter-modal {
    .promo-codes-page__filter .promo-codes-page__filter-fields {
      display: flex;
      flex-direction: column;
    }
  }
}
