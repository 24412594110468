.localized-field {
  &__languages {
    display: flex;
    width: 100%;
    margin-bottom: 4px;
  }

  &__lang {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    text-transform: uppercase;
    padding: 0 4px;
    border-radius: $border-radius;
    transform: scale(0.85, 1);
    cursor: pointer;
    @include default-transition((color, background-color));
    transition-duration: 0.1s;

    &--error {
      color: $danger;
      font-weight: 600;
    }

    &--active {
      color: white;
      background-color: $primary;
      font-weight: 400;

      &.localized-field__lang--error {
        background-color: $danger;
      }
    }
  }

  &__label {
    margin-right: 8px;
    font-size: 12px;
    line-height: 20px;
    color: $control-label-color;
  }
}
