.goals-table {
  &__empty {
    &-icon {
      position: relative;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-icon-search {
      position: absolute;

      left: calc(50% - 35px);
      top: -15px;
    }

    &-text {
      font-size: $base-font-size;
      line-height: $base-line-height;
      font-weight: normal;

      & > b {
        margin-right: 5px;

        color: $danger;
        font-weight: normal;
      }
    }
  }
}
