$settings-groups-inner-table-max-height: 200px;
$settings-qr-size: 200px;
$settings-qr-margin: 0px;

.settings-page {
  .general-settings-page, .trafficback-settings-page, .security-settings-page {
    max-width: 540px;
  }

  .promo-codes-settings-page {
    max-width: 620px;
  }

  .tabs__nav {
    box-sizing: content-box;

    @include default-scrollbar-with-background();
  }

  .event-groups-table, .goal-groups-table {
    &__expand-icon {
      margin-left: 10px;
      visibility: hidden;
    }

    &__expanded-row {
      & > .table__cell {
        padding: 0;
      }

      position: relative;
    }

    &__expandable-row {
      .event-groups-table__expand-icon, .goal-groups-table__expand-icon {
        visibility: visible;
      }
    }

    &__expanded-row-block {
      max-height: $settings-groups-inner-table-max-height;
      overflow: auto;
    }

    .rc-table-row-expand-icon-cell {
      display: none;
    }

    &__events-table, &__goals-table {
      padding: 10px;


      .table {
        margin-bottom: 0;
      }
    }
  }

  .conversion-settings {
    .field__label {
      font-size: 13px;
    }

    .field--control-Switch.field--label-right {
      label {
        padding-left: 3px;
      }
    }

    &__setting {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      max-width: 600px;
    }

    &__setting-checkbox {
      padding: $input-btn-padding-y 0;
      margin-right: $input-btn-padding-x;
    }

    &__checkbox-group {
      margin-left: 24px;
      margin-top: -10px;
    }

    &__checkbox > label {
      cursor: pointer;
      display: flex;
      flex-wrap: nowrap;

      .checkbox {
        margin-right: 8px;
      }
    }

    &__checkbox--disabled > label  {
      color: $text-light-gray;
      cursor: default;

      input {
        cursor: default;
      }
    }

    &__radio-group {
      .field__error {
        margin-top: -6px;
        margin-bottom: 6px;
        margin-left: 24px;
      }
    }
  }

  .title {
    margin: 0 0 12px 0;
  }

  .tabs--buttons {
    .tabs-tab {
      .tab-label__text, .tabs-tab-btn {
        white-space: nowrap;
      }
    }
  }

  .tabs--inner .tabs__nav {
    padding: 0;
    padding-bottom: 16px;
    border-bottom: none;
  }

  .field--label-right {
    .field__label-container {
      display: flex;
      align-items: center;
    }

    .field__label {
      font-size: 13px;
    }

    .field__help {
      font-size: 17px;
    }
  }

  .ips-textarea {
    margin-bottom: 0;

    textarea {
      max-height: 400px;

      @include default-scrollbar(4px);

      &:not(:disabled) {
        background-color: $white;
        border-color: $dark-25;
      }
    }
  }
}

.docs-settings-page {
  .localized-field__label {
    font-size: 13px;
    font-weight: 600;
  }

  .text-editor {
    min-height: 400px;
    max-height: 400px;
    padding: 1rem 1rem;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      display: flex;
      align-items: center;
    }

    .icon {
      font-size: 16px;
      margin-left: 8px;
    }
  }

  &__gap {
    margin-top: 30px;
  }
}

.payment-thresholds-page {
  max-width: 800px;
  width: 800px;

  &__fields {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 8px;
    margin-bottom: 16px;
  }

  &__field {
    display: flex;
  }

  &__currency {
    width: 50px;
    line-height: 34px
  }

  &__input {
    .field {
      width: 140px;
      margin-bottom: 0;
    }
  }
}

.contacts-page {
  &__table-title {
    font-size: 13px;
    line-height: 16px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  &__field-values-name {
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 8px;
  }

  &__field-values-name-langs {
    display: flex;
    align-items: center;
    margin-left: 8px;
  }

  &__field-values-list {
    max-height: 270px;
    overflow-y: auto;

    @include default-scrollbar();
  }

  &__field-value {
    display: grid;
    grid-template-columns: 30px 1fr 30px;
    margin-bottom: 8px;
    height: 44px;
    box-sizing: content-box;

    .localized-field__languages {
      display: none;
    }
  }

  &__field-value-number {
  }

  &__field-value-field {
    width: 100%;
    height: 44px;
    box-sizing: content-box;

    .field__error {
      margin-top: 0;
    }
  }

  &__field-value-actions {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;

    .icon {
      font-size: 16px;
    }
  }

  &__field-values-action {
    margin-top: 8px;
  }
}

.user-contact-modal {
  .modal__body {
    max-height: 600px;
    overflow-y: auto;
  }
}

.payment-invoices-page {
  .field--control-Switch.field--label-right {
    .field__label-container {
      margin-left: 13px;
    }
  }

  &__field {
    width: 280px;

    &--mini {
      width: 180px;
    }
  }

  &__switch-padding {
    padding-left: 43px;
  }

  &__day-fields {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .field {
      margin-bottom: 0;

      &:first-child {
        margin-right: 8px;
      }

      &:last-child {
        margin-left: 8px;
      }
    }
  }
}

.tracking-domain {
  &__instructions {
    margin-bottom: 10px;
  }
  &__cname {
    padding: 5px 0;
  }
}

.security-settings-page {
  &__2fa-type {
    .field__control {
      min-width: 200px;
    }
  }

  &__ga-helper-connect {
    display: flex;
    margin-bottom: 16px;
  }

  &__ga-helper-qr {
    @include exact-sizes($settings-qr-size);
    margin-right: 16px;

    .react-loading-skeleton {
      height: 100%;
      width: 100%;
    }

    .qr-code {
      @include exact-sizes($settings-qr-size);
      margin-left: -$settings-qr-margin;
      margin-top: -$settings-qr-margin;
      overflow: hidden;

      svg {
        transform: scale(0.1);
        transform-origin: left top;
      }
    }
  }

  &__ga-helper-text {
    margin-bottom: 16px;

    .btn {
      margin-top: 10px;
    }
  }

  &__ga-secret-key {
    margin-left: 4px;

    .react-loading-skeleton {
      width: 200px;
    }
  }
}

.menu-settings {

  &__color-item {
    display: block;
    width: 15px;
    height: 15px;
    border: 1px solid transparent;
    border-radius: 50%;
  }
}

.domains-wide-modal .modal-dialog {
  max-width: calc(min(max($mobile-min-width, 100dvw), 600px) - 1rem);
}

.target-links-replace-form {
  .only-error-field {
    margin-bottom: 0;

    .field__error {
      margin-top: 0;
    }

    &.field--has-error {
      margin-top: -8px;
    }
  }
}
