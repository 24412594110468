#page-alerts {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 60px;
  padding: 0 20px;
  font-size: 15px;
  background-color: #E3EAFA;

  &__icon {
    display: flex;
    margin-right: 16px;
    font-size: 24px;
    color: $primary;
  }
  &__text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    strong {
      font-weight: 600;
    }
  }
  &__link {
    color: $primary;
    font-weight: 700;
    cursor: pointer;
    margin-left: 10px;
  }
}

@include tabletLayout() {
  #page-alerts {
    flex-wrap: wrap;
    height: auto;
    padding: 8px 12px;
    font-size: 13px;
    align-items: center;

    #page-alerts__icon {
      display: none;
    }

    #page-alerts__text {
      white-space: wrap;
      text-align: center;
    }

    #page-alerts__link {
      margin-left: 8px;
      margin-right: 8px;
      margin-top: 4px;
      margin-bottom: 4px;
    }
  }
}
