.qr-code-modal {
  z-index: #{$modal-z-index + 20};

  &__title {
    font-size: 17px;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  .modal-dialog {
    width: fit-content;
  }

  .modal__body {
    padding: 24px 32px;
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
  }

  .qr-code {
    width: fit-content;
    margin: auto;
  }

  .modal__footer {
    justify-content: space-between;

    .qr-modal__btn {
      box-sizing: content-box;
      min-width: 100px;
      padding: 12px 15px;
      justify-content: center;
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.qr-code-modal-backdrop {
  z-index: #{$modal-z-index + 10};
}

@include mobileLayout() {
  &.qr-code-modal {
    display: flex !important;
    justify-content: center;

    .qr-modal__btn {
      padding: 8px 12px;
      font-size: 12px;
    }
  }
}
