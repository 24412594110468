.tooltip {
  --bs-tooltip-font-size: $tooltip-font-size;
  --bs-tooltip-max-width: min(calc(100svw - 44px), 400px);

  &.show {
    opacity: 1;
  }

  .tooltip-arrow {
    width: 10px;
    height: 10px;

    path {
      fill: $base-dark;
    }
  }
}

.tooltip-inner {
  white-space: pre-line;
  text-align: left;
  font-size: $tooltip-font-size;
  font-weight: $font-weight-light;

  border: $tooltip-border;
  box-shadow: $tooltip-box-shadow;
  margin-top: -3px;
  max-height: 50vh;
  overflow-y: auto;
}

ul {
  margin-top: 8px;
  margin-bottom: 8px;

  li:not(:last-child) {
    margin-bottom: 4px;
  }
}
