$news-form-width: max(calc(100% / 24 * 10), 760px);
$news-actions-width: 40px;

.news-page {
  thead {
    display: none;
  }

  &__table {
    display: flex;
    justify-content: center;

    .table__cell {
      padding: 24px 0 16px 0;
      padding-left: 0 !important;
    }
  }

  .table-widget {
    width: $news-form-width;
    flex-grow: unset !important;

    .table-pagination {
      background: none;
      padding: 0;
      padding-top: 20px;

      .table-pagination__counter {
        display: none;
      }

      .table-pagination__right {
        display: none;
      }

      .pagination__page--prev, .pagination__page--next {
        .button {
          background: none !important;
          border: none !important;
        }
      }

      .button, a[role=button] {
        padding: 4px 8px;
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
      }

      .pagination__page {
        width: 24px;
        height: 24px;
      }
    }
  }

  &__news {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }

  &__actions {
    width: $news-actions-width;

    .icon {
      font-size: 16px;
      height: 16px;
      width: 16px;
      cursor: pointer;
      color: $text-gray;

      &:last-child {
        margin-left: 8px;
      }
    }
  }

  &__news-title {
    width: 100%;

    a {
      display: block;
      width: 100%;
      font-weight: 600;
      font-size: 17px;
      line-height: 16px;
      cursor: pointer;
      color: $base-dark;

      &:hover {
        color: $primary;
        text-decoration: none;
      }
      @include text-overflow-ellipsis();
    }
  }

  &__news-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-bottom: 8px;

    &--editable {
      .news-page__news-title {
        width: calc(100% - #{$news-actions-width});
        padding-right: 10px;
      }
    }
  }

  &__news-text {
    width: 100%;
    font-size: 15px;
    line-height: 20px;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    overflow: hidden;
    max-height: 60px;
    -webkit-box-orient: vertical;
    line-clamp: 3;
  }

  &__news-footer {
    width: 100%;
    padding-top: 16px;
    font-size: 11px;
    line-height: 16px;
    color: $text-gray;

    a {
      cursor: pointer;
      color: $text-gray;

      &:hover {
        color: $primary;
        text-decoration: none;
      }
    }
  }
}

.news-edit-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 24px;
  flex-wrap: wrap;

  .widget-layout {
    width: $news-form-width;
    flex-grow: unset !important;
  }

  .field .localized-field__languages {
    display: none;
  }

  .text-editor {
    min-height: 400px;
    background: $control-background-color;
  }

  &__actions {
    display: flex;
    flex-direction: row;
    width: $news-form-width;
    justify-content: flex-end;
    padding-top: 6px;

    .button {
      margin-left: 16px;
    }
  }
}

.news-view-page {
  display: flex;
  width: 100%;
  justify-content: center;

  &__content {
    width: $news-form-width;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 16px;
  }

  &__header-data {
    width: calc(100% - #{$news-actions-width});
    padding-right: 10px;
    padding-top: 0;
    font-size: 11px;
    line-height: 16px;
    color: $base-dark;

    a {
      color: $base-dark;
    }
  }

  &__title {
    font-weight: 600;
    font-size: 30px;
    line-height: 32px;
    padding: 16px 0;
    width: 100%;
    word-break: break-word;
  }

  &__text {
    word-break: break-word;

    * {
      word-break: break-word;
    }
  }
}
