.input-color {
  display: flex;
  align-items: center;
  font-size: 16px;
  width: 100%;

  &__preview {
    width: 1em;
    height: 1em;
    margin-right: 8px;
    border-radius: 1em;
    position: relative;
    cursor: pointer;

    &--active {
      &:before {
        display: block;
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: url('data:image/svg+xml, <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" clip-rule="evenodd" d="M13.3135 4.00725C13.704 4.39777 13.704 5.03094 13.3135 5.42146L7.24253 11.4924C6.85201 11.8829 6.21884 11.8829 5.82832 11.4924L3.29289 8.957C2.90237 8.56647 2.90237 7.93331 3.29289 7.54278C3.68342 7.15226 4.31658 7.15226 4.70711 7.54278L6.53542 9.3711L11.8993 4.00725C12.2898 3.61672 12.923 3.61672 13.3135 4.00725Z" fill="white"/> </svg>');
        background-position: center;
      }
    }
  }
}
