@use "sass:math";
$input-image-button-size: 24px;
$input-image-button-half-size: math.div($input-image-button-size, 2);

.input-image {
  display: flex;
  flex-wrap: wrap;
  margin: $input-image-button-half-size 0;
  position: relative;

  &__preview {
    position: relative;
    max-width: 300px;
    height: auto;
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    width: $input-image-button-size;
    height: $input-image-button-size;
    border-radius: 50%;
    background: $white;
    color: $text-light-gray;
    box-shadow: 0 9px 16px 0 rgba(24,28,50,.25);
    cursor: pointer;

    &:hover {
      background-color: $light;
    }
  }

  &__actions {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  &__edit {
    top: -$input-image-button-half-size;
    right: -$input-image-button-half-size;
  }

  &__remove {
    bottom: -$input-image-button-half-size;
    right: -$input-image-button-half-size;
  }

  .symbol .icon svg {
    color: $text-light-gray;
  }

  &__help {
    color: #b5b5c3;
    font-size: 0.9rem;
    margin-top: 5px;
    width: 100%;
  }

  &--blue {
    .input-image__preview {
      max-width: none;
      width: 100%;
    }

    .input-image__placeholder {
      width: 100%;
      height: 100%;
      position: relative;
    }

    .input-image__background {
      background: $primary;
      opacity: 0.15;
      width: 100%;
      height: 100%;
      position: absolute;
    }

    .input-image__gradient {
      background-image: linear-gradient(
                      45deg,
                      rgb(144, 153, 177) 6.25%,
                      transparent 6.25%,
                      transparent 50%,
                      rgb(144, 153, 177) 50%,
                      rgb(144, 153, 177) 56.25%,
                      transparent 56.25%,
                      transparent 100%
      );
      opacity: 0.2;
      background-size: 11.31px 11.31px;
      width: 100%;
      height: 100%;
      position: absolute;
    }

    .input-image__actions {
      display: flex;
      flex-direction: row;
      height: auto;
      width: auto;
      top: 15px;
      right: 15px;
      background: $white;
      border-radius: 4px;
    }

    .input-image__edit, .input-image__remove {
      display: flex;
      position: relative;
      top: initial;
      right: initial;
      bottom: initial;
      background: none;
      box-shadow: none;
    }
  }

  &__dropzone {
    display: none;
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background: $light-primary;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include default-transition(opacity);

    &-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 9px;
      background: $primary;
      border-radius: 50%;
      color: $white;
      font-size: 16px;
      width: 16px;
      height: 16px;
      box-sizing: content-box;
    }

    &-label {
      font-weight: 600;
      color: $primary;
      margin-top: 7px;
      text-align: center;
    }
  }

  &--drag {
    .input-image__actions {
      display: none;
    }

    .input-image__dropzone {
      display: flex;
      opacity: 1;
    }
  }

  &--square {
    .input-image__actions {
      background: transparent;
      @include default-transition(background);
      .input-image {
        &__edit {
          right: 36px;
        }
        &__button {
          display: none;
          opacity: 0;
          @include default-transition(opacity);
          top: 4px;
          left: unset;
          bottom: unset;

          font-size: 12px;
          padding: 8px;
          color: $base-dark;
          width: 28px;
          height: 28px;
          border-radius: 4px;

          &:last-child {
            right: 4px;
          }
        }
        &__edit {
          font-size: 16px;
          padding: 6px;
        }
      }

      &:hover {
        background: rgba($base-dark, 0.3);

        .input-image__button {
          display: flex;
          opacity: 1;
        }
      }
    }
  }
}
