.referral-link-form {
  z-index: $modal-z-index + 8;

  .modal-dialog {
    max-width: calc(min(max($mobile-min-width, 100dvw), 540px) - 1rem);
  }

  .modal__header {
    z-index: 50 !important;
  }

  .modal__body {
    padding-bottom: 16px;
  }

  .referral-link {
    line-height: 22px;
    min-height: 34px;
    padding: 6px 12px;
    margin-bottom: 12px;
    border-radius: 5px;
    word-break: break-all;
    background-color: rgba(98, 138, 241, 0.1);
  }

  .referral-link-tooltip {
    color: $text-gray;
  }

  .referral-link-partners {
    margin-bottom: 16px;
  }

  &__sub-id {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -4px;
  }

  &__sub-id-title {
    margin-bottom: 8px;
  }

  .copy-referral-link {
    width: 100%;
  }

  .open-qr-code-form-btn {
    width: 100%;
    padding: 11px 18px;
    margin-top: 5px;
    background: transparent;
    border: none;
    color: $primary;

    &.btn-light-secondary:hover,
    &.btn-light-secondary:focus {
      color: $primary;
      background-color: rgba(124, 134, 164, 0.15);
    }

    &.btn-light-secondary:disabled {
      color: #B9BFCF;
      background-color: #F6F6F9;
    }
  }

  .referral-link__landings {
    .input-select-container {
      margin-bottom: 24px;
    }
  }

  .sub-id-field {
    position: relative;
    padding: 4px;

    .field {
      margin-bottom: 0;
    }

    .field--control-InputText {
      position: relative;

      .field__label-container {
        display: flex;
        position: absolute;
        pointer-events: none;
        z-index: 2;
        left: 11px;
        top: 6px;

        .field__label {
          color: $text-gray;
        }
      }

      .input-text {
        padding-left: 70px;
      }
    }
  }
}

.referral-link-form-backdrop {
  z-index: $modal-z-index;
}

@include tabletLayout() {
  &.referral-link-form {
    .modal-dialog, .modal-content {
      max-height: 95dvh;
    }

    .modal__header {
      padding-bottom: $modal-padding-mobile-y;
      z-index: 50 !important;
    }

    .modal__footer {
      padding-top: $modal-padding-mobile-y !important;
    }

    .modal__body {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

@include mobileLayout() {
  &.referral-link-form {
    .sub-id-field {
      padding: 2px;
      width: 100%;
    }

    .modal__header {
      z-index: 50 !important;
    }

    .referral-link-form__sub-id {
      margin: 0 -2px;
    }
  }
}
