.tag-list {
  &--hashtag {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
  }

  &__hashtag {
    display: inline-block;
    max-width: 100%;
    margin-right: 0.5rem;
    @include text-overflow-ellipsis();
  }
}
