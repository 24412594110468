$partners-form-counter-width: 150px;
$partners-form-field-width: 250px;
$partners-form-field-margin: 12px;
$partners-form-note-field-width: 300px;
$partners-form-note-width: $partners-form-note-field-width;

.partners-page {
  &__login-button {
    height: 42px;
  }

  .user-filter {
    .user-filter__fields {
      grid-template-columns: repeat(5, minmax(10%, 1fr));
      margin-bottom: 16px;
    }
  }

  &__partners-status {
    min-width: 80px;
  }

  &__partner-admins-column {
    .more-cell__other {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .user-filter {
    .dropdown-block {
      &__content {
        @extend .user-filter__fields;
      }
    }
  }

  .on-moderation-tab {
    display: flex;
    align-items: center;
    .on-moderation-total {
      position: relative;
      min-width: 20px;
      height: 20px;
      line-height: 20px;
      margin-left: 4px;
    }
  }
}


.partners-form {
  height: auto;
  opacity: 1;
  @include default-transition((height, opacity));

  .input-select__menu {
    min-width: 210px;
  }

  &--hidden {
    height: 0;
    opacity: 0;
    overflow: hidden;
    @include default-transition((height, opacity));
  }

  .field {
    margin-bottom: 0;
  }

  &__warning {
    display: flex;
    align-items: center;
    background-color: $light-carrot;
    font-size: 12px;
    line-height: 16px;
    width: calc(100% + 40px);
    padding: 4px 20px;
    margin: -10px -20px 8px -20px;

    &-icon {
      display: flex;
      align-items: center;
      color: $carrot;
      font-size: 16px;
      height: 16px;
      width: 16px;
      margin-right: 8px;
    }
  }

  &__form {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    //border: 1px solid $border-gray;
    //border-radius: $border-radius;
    background-color: $gray-semi-dark;
    padding: 10px 20px;
  }
  &__counter {
    width: $partners-form-counter-width;
  }
  &__fields {
    display: flex;
    width: calc(100% - #{$partners-form-counter-width});
  }
  &__double-field {
    display: flex;
    flex-direction: column;
    width: $partners-form-field-width;
    margin-right: $partners-form-field-margin;
  }
  &__first-field {
    height: 16px;
    min-height: 16px;
    margin-bottom: 8px;
  }

  &__note-field {
    width: $partners-form-note-width;

    .partners-form__second-field {
      display: flex;
      align-items: flex-start;
      height: 100%;
    }

    .textarea {
      min-height: 56px;
    }

    .field, .input-text {
      display: block;
      width: $partners-form-note-field-width;
    }
  }

  &__button-field{
    width: auto;

    .partners-form__second-field {
      display: flex;
      align-items: flex-start;
    }
  }

  &__textarea-field {
    width: $partners-form-field-width * 2;
  }

  .textarea {
    &--floating-label {
      resize: none;
      height: 56px !important;
      padding-top: 18px;
      padding-bottom: 18px;

      &:not(:placeholder-shown) {
        padding-top: 23px;
        padding-bottom: 7px;
      }
    }

  }
  .textarea:placeholder-shown ~ .textarea__floating-label {
    top: 17px;
  }

  &__confirm-button {
    display: inline-flex;
  }

  .input-select__control, .input-number input, .input-text:not(.is-invalid) {
    background-color: $white;
  }

  .input-select__control.input-select__control--is-disabled, .input-number input:disabled {
    background-color: rgba(255, 255, 255, 0.5);
  }

  .input-number.is-invalid input {
    background-color: $control-error-background-color !important;
    min-height: #{$control-min-height - 2px};
  }
}
