.modal {
  z-index: $modal-z-index;
  width: max(100%, #{$mobile-min-width});

  &__header {
    padding: $modal-header-padding;
    position: relative;
    font-size: $modal-header-font-size;
    font-weight: $modal-header-font-weight;
    align-items: center;
  }

  &__title {
    font-weight: $modal-header-font-weight;
    font-size: $modal-header-font-size;
    line-height: $modal-title-line-height;
  }

  &__body {
    padding: $modal-inner-padding;
    word-break: break-word;
  }

  &-content {
    border: none;
  }

  &__footer {
    padding: $modal-footer-padding;

    & > * {
      margin: 0;
    }
  }

  &__close {
    position: absolute;
    padding: 0;
    box-shadow: none !important;
    outline: none;
    font-size: 18px;
    top: 19px;
    right: 19px;
    z-index: 10;

    & .button__icon {
      color: $text-gray;
    }
  }
}

.modal.modal--sticky {
  .modal-content {
    overflow: auto;
    height: 100%;
    @include default-scrollbar();
    max-height: 95dvh;

    &::-webkit-scrollbar-track {
      background: $white;
      outline: none;
      border-radius: 0 4px 4px 0;
    }
  }

  .modal-dialog {
    max-height: 95dvh;
  }

  .modal__header {
    position: sticky;
    top: 0;
    z-index: 2;
    background: white;
  }

  .modal__footer {
    position: sticky;
    bottom: 0;
    z-index: 2;
    background: white;
  }
}

.modal-backdrop {
  width: 100%;
  height: 100%;
  z-index: #{$modal-z-index - 10};
}

@each $name, $color in $colors {
  .modal--#{$name} {
    .modal-content {
      border-left: 4px solid $color;
    }
  }
}


@include tabletLayout() {
  &.modal.mobile-modal {
    .modal-dialog {
      max-width: max(100vw, 100%);
      width: max(100vw, 100%);
      max-height: max(100dvh, 100%);
      height: max(100dvh, 100%);
      min-height: max(100dvh, 100%);
      margin: 0;
    }

    .modal-content {
      min-height: 100%;
      border-radius: 0;
    }

    .modal-header {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      height: $horizontal-menu-height;
      border-bottom: 1px solid $background-color;
      padding: 14px 20px;

      .modal__close {
        font-size: 11px;
        width: 16px;
        height: 16px;
        top: 12px;
        right: 12px;
      }
    }

    .modal-body {
      padding: 12px 20px;
      max-height: calc(100dvh - #{2 * $horizontal-menu-height});
      overflow: auto;
      @include default-scrollbar();
    }

    .modal-title {
      font-size: 13px;
      line-height: 16px;
    }

    .modal-footer {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      height: $horizontal-menu-height;
      padding: 2px 20px;

      .button:not(:last-child) {
        margin-right: 8px;
      }

      @media (max-width: $mobile-max-width) {
        padding: 0 !important;

        .button {
          height: 100%;
          width: 50%;
          border-radius: 0;
          margin: 0;

          &:not(:last-child) {
            margin-right: 0;
          }

          &:first-child:last-child {
            width: 100%;
          }
        }
      }
    }

    &.mobile-modal--small {
      .modal-header {
        padding: 12px;
        border-bottom: 0;

        .modal-title {
          font-size: 17px;
          line-height: 28px;
        }
      }

      .modal-body {
        padding: 0 12px 12px;
      }
    }
  }
}

@media (max-width: 575px) {
  .modal-dialog {
    max-width: calc(max($mobile-min-width, 100dvw) - 1rem);
    margin-right: auto;
    margin-left: auto;
  }
}

@include tabletLayout() {
  &.modal {
    position: absolute;

    .modal-header {
      .modal__close {
        z-index: 1;
      }
    }
  }
}

@include mobileLayout() {
  &.modal {
    .modal-header {
      padding: $modal-padding-mobile-y $modal-padding-mobile-x 0;

      .modal__close {
        font-size: 14px;
        z-index: unset;
      }

      .modal-title {
        font-size: 18px;
        line-height: 18px;
      }
    }

    .modal-body {
      padding: $modal-padding-mobile-y $modal-padding-mobile-x;
    }

    &:not(.mobile-modal) {
      .modal-footer {
        padding: 0 $modal-padding-mobile-x $modal-padding-mobile-y $modal-padding-mobile-x;
      }
    }

    .modal__footer:not(:empty) {
      padding: 0 24px 24px 24px;
    }
  }
}
