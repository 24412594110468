@mixin table-border-spaced($direction) {
  background-image: linear-gradient(to $direction, $white 75%, $table-border-color 25%);
  z-index: $table-spaced-column-z-index + 2;
}

$table-group-actions-margin: 20px;
$table-spaced-header-min-height: 60px;
$table-spaced-row-margin: 3px;
$table-spaced-gradient-border-width: 4px;
$table-spaced-row-border-width: 1px;

$table-spaced-column-z-index: 10;
$table-spaced-column-position: sticky !important;
$table-spaced-column-box-sizing: content-box;
$table-spaced-column-expand-cell-width: 3rem;
$table-spaced-column-expand-cell-left: $table-spaced-gradient-border-width;

@mixin table-spaced-sticky-column() {
  position: $table-spaced-column-position;
  z-index: $table-spaced-column-z-index;
  box-sizing: $table-spaced-column-box-sizing;
}

.table > :not(caption) > * > * {
  padding: unset;
}

.table {
  margin-bottom: 0;

  table {
    border-collapse: separate;
    border-spacing: 0;
  }

  table, thead, tbody, thead tr, tbody tr, tfoot tr, thead th, tbody td, tfoot td {
    border-top: none;
    border-bottom: none;
    border-left: none;
    border-right: none;
  }

  thead {
    th {
      border-right: $table-header-border;
    }

    tr:not(:first-child) th {
      border-top: $table-header-border;
    }
  }

  tbody, tfoot {
    td {
      border-bottom: $table-header-border;
    }
  }

  th {
    vertical-align: middle;
    padding: $table-th-padding;
  }

  td {
    vertical-align: middle;
    padding: $table-td-padding;
  }

  &__row {
    &--clickable {
      cursor: pointer;
    }
  }

  &__cell {
    color: $base-dark;
  }

  thead {
    &.thead-light th {
      color: #3f4254;
      background-color: $light;
    }

    &.thead-dark th {
      color: $white;
      background-color: $dark;
    }
  }

  thead tr > &__header-cell {
    color: $table-header-color;
    font-size: $table-header-font-size;
    line-height: $table-header-line-height;
    background-color: $table-header-background-light;
    font-weight: $table-header-font-weight;
    word-break: keep-all;
    vertical-align: middle;
    text-transform: uppercase;
  }

  thead {
    .card-cell.header-cell {
      .card-cell__text {
        padding-bottom: $table-td-padding-top;
      }

      .card-cell__sub-text {
        padding-top: $table-td-padding-top;
      }
    }
  }

  tbody td {
    background: $white;
  }

  //thead > tr:not(:first-child) th {
  //  font-weight: 400;
  //}

  &__cell:not(.word-break-keep):not(.word-break-word) {
    word-break: break-all;
  }

  &__empty {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 420px;

    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.2px;
    font-weight: 600;
    color: $empty-color;
  }

  .table-row-actions {
    display: flex;
    flex-direction: row;
  }

  .rc-table-sticky-holder {
    position: sticky;
    z-index: 2;
  }

  .rc-table-content, .rc-table-sticky-holder + .rc-table-body {
    overflow: auto;
    position: relative;

    @include default-scrollbar(6px, 3px, 0.15);

    &::-webkit-scrollbar {
      background: $white;
    }
  }

  thead tr th {
    border-color: $table-header-border-color;
  }

  tr.table__row--checked td {
    background-color: $table-checked-bg;
    border-color: $table-header-border-color;
  }

  tr:hover td {
    background-color: $table-hover-bg;
    border-color: $table-header-border-color;
  }

  tr.table__row--checked:hover td {
    background-color: $table-checked-hover-bg;
    border-color: $table-header-border-color;
    box-shadow: none;
  }

  tr:first-child th:first-child, td:first-child:not(.column--not-first) {
    padding-left: 20px;
  }

  &__group-actions {
    display: flex;
    align-items: center;

    &-label {
      font-size: 15px;
      font-weight: 600;
      margin-right: $table-group-actions-margin;
    }

    &-buttons {
      display: flex;
      align-items: center;

      .button {
        margin-right: $table-group-actions-margin;
      }
    }

  }

  &--spaced {
    table {
      padding-left: $table-spaced-gradient-border-width;
      padding-right: $table-spaced-gradient-border-width;

      .rc-table-row-expand-icon-cell {
        padding: 0 1rem;
      }

      thead {
        position: relative;

        tr:first-child {
          &:last-child {
            th {
              height: $table-spaced-header-min-height;
            }
          }

          th {
            &:first-child:before, &:last-child:after {
              content: '';
              position: absolute;
              width: $table-spaced-gradient-border-width;
              height: calc(100% - #{$table-spaced-row-margin} + 2px);
              top: 0;
              margin-top: -1px;
              border: 1px solid $table-border-color;
              background: $table-header-background-light;
              z-index: 2;
            }

            &:first-child:before {
              left: 0;
              margin-left: -$table-spaced-gradient-border-width;
              border-right: none;
              border-radius: $border-radius 0 0 $border-radius;
            }

            &:last-child:after {
              right: 0;
              margin-right: -$table-spaced-gradient-border-width;
              border-left: none;
              border-radius: 0 $border-radius $border-radius 0;
            }
          }

          th:first-child {
            border-left: none;
          }

          th:last-child {
            border-right: none;
          }
        }

        th {
          position: relative;
        }

        tr:last-child:after {
          content: '';
          position: absolute;
          height: #{$table-spaced-row-margin + $table-spaced-row-border-width};
          bottom: 0;
          left: 0;
          @include table-border-spaced(top);
          margin-left: -1px;
          width: calc(100% + #{$table-spaced-row-border-width * 2});
        }

        tr:before {
          content: '';
        }
      }

      tbody {
        tr {
          position: relative;

          &:before, &:after {
            content: '';
            position: absolute;
            height: #{$table-spaced-row-margin + $table-spaced-row-border-width};
            width: 100%;
            left: 0;
            background: $white;
          }

          &:before {
            top: 0;
            @include table-border-spaced(bottom);
          }

          &:after {
            bottom: 0;
            @include table-border-spaced(top);
          }

          &:hover {
            td:first-child:before, td:last-child:after {
              background-color: $table-hover-bg;
            }
          }

          td:not(:first-child) {
            border-left: 1px solid transparent !important;
          }
        }

        .expanded-row-parent {
          td:first-child:before, td:last-child:after {
            height: calc(100% - #{$table-spaced-row-margin});
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            border-bottom: none;
            border-color: $primary !important;
            background: $primary !important;
          }

          tr, th, td {
            background-color: $table-active-background-light;
          }

          &:after {
            display: none;
          }
        }


        td:first-child, td:last-child {
          position: relative;

          &:before, &:after {
            content: '';
            position: absolute;
            width: $table-spaced-gradient-border-width;
            height: calc(100% - #{$table-spaced-row-margin * 2});
            top: 0;
            margin-top: $table-spaced-row-margin;
            border: 1px solid $table-border-color;
            z-index: 2;
            background: $white;
          }

          &:before {
            border-radius: $border-radius 0 0 $border-radius;
            border-right: none;
            margin-left: -$table-spaced-gradient-border-width;
            left: 0;
          }

          &:after {
            border-radius: 0 $border-radius $border-radius 0;
            border-left: none;
            margin-right: -$table-spaced-gradient-border-width;
            right: 0;
          }
        }

        td:first-child {
          &:after {
            display: none;
          }
        }

        td:last-child {
          &:before {
            display: none;
          }
        }
      }

      td {
        border: none;
        min-height: $table-spaced-header-min-height + ($table-spaced-row-margin * 2);
        box-sizing: border-box;
      }

      th, td {
        border-left: none !important;
      }
    }
  }

  &--light {
    table {
      thead {
        th {
          background: none;
          border: none;
          text-transform: none;
          font-weight: 400;
          color: #6E7A83;
          font-size: inherit;
          padding: 0 12px 8px 12px;
        }
      }

      tbody {
        td {
          padding: 8px 12px;
          border: none;
        }
      }
    }

    .rc-table-sticky-holder {
      background: white;
    }
  }

  .expanded-row {

    &:before {
      display: none;
    }

    .rc-table-content {
      overflow: visible;
    }

    table {
      margin: 0;
    }

    margin-bottom: 0;

    thead tr > .table__header-cell {
      background-color: $table-accent-bg;
      border-left: none;
      border-right: none;
    }

    thead, tr {
      &:before {
        display: none;
      }

      &:after {
        display: none;
      }
    }

    thead {
      th:first-child:before, th:last-child:after {
        border-radius: 0;
        border-top: none;
        height: calc(100% + 2px);
        margin-top: -1px;

        border-color: $primary !important;
        background: $primary !important;
      }
    }

    tbody {
      tr {
        &:not(:last-child) {
          td:first-child:before, td:last-child:after {
            border-radius: 0;
            border-top: none;
            height: calc(100% + 2px);
            margin-top: -1px;

            border-color: $primary !important;
            background: $primary !important;
          }
        }

        &:last-child {
          td:first-child:before, td:last-child:after {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-top: none;
            height: calc(100% - #{$table-spaced-row-margin});
            margin-top: 0;

            border-color: $primary !important;
            background: $primary !important;
          }
        }
      }
    }

    & > td {
      padding: 0;

      &:before {
        display: none;
      }
    }

    tr, th, td {
      background-color: $table-accent-bg !important;
    }

    th, tr:not(:last-child) td {
      border-bottom: $table-border;
    }

    th:not(:first-child), td:not(:first-child) {
      border-left: 1px solid $table-accent-bg !important;
    }
  }

  .empty-table {
    margin: 3rem 0;
    width: 32%;
    position: sticky;
    left: 34%;
    min-height: 120px;
  }

  &--empty {
    tbody tr td:last-child {
      border-bottom: none;
    }

    .table__cell {
      padding-left: 0 !important;
      padding-right: 0 !important;
      border-left: 0 !important;
      border-right: 0 !important;
    }
  }

  .rc-table-checkbox-cell {
    padding: 0 16px;
    box-sizing: border-box;
    padding-right: 0;
    width: 16px;
    border-right: none;
  }

  .rc-table-placeholder {
    &:hover, & td:hover {
      background: $white;
      cursor: default;
    }
  }
}

.rc-table-actions-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 0 20px;

  &--left, &--right {
    display: flex;

    & > *:not(:first-child) {
      margin-left: 5px;
    }
  }

  .button {
    &:not(:disabled):not(.disabled) {
      &:hover, &:focus, &:active {
        color: $base-dark;
      }
    }

    color: $base-dark;

    &__icon {
      font-size: 16px;
    }
  }
}

.table-widget {
  position: relative;
}

.table-footer {
  position: sticky;
  z-index: 100;
  background: $white;
  bottom: 0;
  width: 100%;

  &--bottom {
    position: unset;
  }
}

.table-sortable-column {
  &__title {
    display: flex;
    align-items: center;
    cursor: pointer;

    &--disabled {
      cursor: default;
    }
  }

  &__icon {
    padding: 6px;
    margin: -6px;
    margin-left: 0;
    font-size: $table-header-font-size;
  }
}


.rc-table-content {
  @include default-scrollbar-with-background();
}

.table--not-hover {
  tr:hover, tr:hover td {
    cursor: default;
    background: $white;
  }

  .table__row--clickable {
    cursor: default;
  }
}

.table-pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $background-color;
  padding: 4px 20px;

  &__load-more span {
    color: $base-dark;

    &:hover {
      color: $base-dark;
    }
  }

  &__counter {
    margin-right: 15px;
    line-height: 16px;
    display: flex;
    flex-wrap: wrap;

    & > span:first-child {
      white-space: nowrap;
    }
  }

  &__left, &__right {
    display: flex;
    align-items: center;
  }

  &__right {
    .table-pagination__counter {
      display: none;
    }
  }

  &__center {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  &__per-page {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-left: 15px;
  }

  .input-select {
    &__control {
      min-width: 75px;
    }

    &__menu {
      min-width: 90px;
    }

    &__menu-list {
      min-height: 50px;
    }

    &__no-options {
      display: flex;
      align-items: center;
      height: 100%;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .pagination {
    &__page {
      margin-bottom: 0;
    }
  }
}

@include tabletLayout() {
  .table-pagination {
    padding: 8px 12px;
  }
}

@include mobileLayout(960px) {
  .table-pagination {
    display: flex;
    flex-direction: column-reverse;

    &__left, &__right {
      width: 100%;
    }

    &__left {
      justify-content: center;

      .table-pagination__counter {
        display: none;
      }
    }

    &__right {
      display: grid;
      justify-content: space-between;
      grid-template-columns: auto 170px;

      .table-pagination__counter {
        display: flex;
        min-width: 50%;
      }

      .table-pagination__per-page {
        margin-left: 0;
      }
    }

    &:not(.table-pagination--hidden) {
      .table-pagination__right {
        padding-bottom: 8px;
        margin-bottom: 8px;
        border-bottom: 1px solid $gray-semi-dark;
      }
    }

    &.table-pagination--hidden {
      .table-pagination__right {
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: none;
      }
    }
  }

  .pagination__page--prev, .pagination__page--next {
    .button {
      background: none !important;
      border: none !important;
      width: 24px;
      height: 24px;

      .button__icon {
        font-size: 16px;
      }
    }
  }
}

@include mobileLayout(460px) {
  .pagination {
    &__page {
      margin: 0 2px;
    }
  }
}
