.loading {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  z-index: $loading-z-index;

  .icon, img {
    margin-bottom: 50px;
  }

  img {
    width: auto;
    height: auto;
  }

  &--translucent {
    background-color: rgba(243, 246, 249, 0.5);

    .spinner-border {
      position: absolute;
      left: calc(min(50%, 50vw) - 9px);
      top: calc(min(50%, 50vh) - 9px);
    }
  }

  &--full-screen {
    width: 100%;
    min-height: 100vh;

    background-color: $body-bg;
  }
}

.spinner {
  position: relative;
  width: 45px;
  height: 45px;
  display: inline-block;
  margin-left: 50%;
  margin-right: 50%;
  border-radius: 10px;

  &.sm {
    width: 32px;
    height: 32px;
    div {
      width: 1%;
    }
  }
}

@keyframes fade {
  0% {opacity: 1;}
  85% {opacity: 0;}
}

.spinner span {
  width: 2%;
  height: 16%;
  background: #535870;
  position: absolute;
  left: 49%;
  top: 43%;
  opacity: 0;
  border-radius: 50%;
  animation: fade 1s infinite;

  @for $i from 1 through 16 {
    &:nth-child(#{$i}) {
      transform:rotate(($i - 1) * 22.5deg) translate(0, -120%);
      animation-delay: -1 + $i * 0.0625s;
    }
  }
}

@include mobileLayout() {
  .loading.loading--translucent {
    justify-content: initial;
    align-items: initial;

    .spinner {
      position: sticky;
      margin-right: 0;
      margin-left: 0;
      top: calc(50dvh - 22px);
      left: calc(50dvw - 22px);
      margin-top: 50px;
      margin-bottom: 50px;

      &.sm {
        top: calc(50dvh - 16px);
        left: calc(50dvw - 16px);
      }
    }
  }
}
