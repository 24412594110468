@use "sass:math";

.radio {
  &.custom-radio {
    min-height: 16px;
    padding-left: 16px;
  }

  .form-check-input {
    font-size: 16px;
    border-width: $custom-checkbox-indicator-border-width;
    --bs-border-color: #cfd1d9;
    margin-top: 0;
  }

  .form-check-input:checked {
    border-width: 1px;
  }

  .custom-control-input, .form-check-input {
    width: $custom-radio-width;
    height: $custom-radio-height;
    z-index: 10;
    cursor: pointer;
  }

  .custom-control-label {
    position: relative;

    &::before,
    &::after {
      top: calc(50% - #{math.div($custom-radio-height, 2)});
      cursor: pointer;

      width: $custom-radio-width;
      height: $custom-radio-height;
    }

    &::before {
      top: 0;
      left: -16px;
      border-color: #cfd1d9;
      border-width: 2px;
    }
  }

  .custom-control-input:checked ~ .custom-control-label {
    &::after {
      background-image: none;
      content: '';
      background: $white;
      border-radius: 50%;
      width: calc(#{$custom-radio-width} / 2);
      height: calc(#{$custom-radio-width} / 2);
      top: calc(#{$custom-radio-width} / 4);
      left: calc(-#{$custom-radio-width} / 4 * 3);
      pointer-events: none;

      display: none;
    }

    &:before {
      border-width: 4px;
      background: $white;
    }
  }

  &-label {
    display: flex;
    align-items: flex-start;
    width: 100%;
    cursor: pointer;
    line-height: 16px;

    .radio {
      margin-right: 6px;
    }
  }
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label {
  &::before {
    background-color: $control-background-color;
    border-color: $primary;
    opacity: 0.6;
  }

  &::after {
    display: block;
    content: "";
    background-color: $control-background-color;
  }
}
