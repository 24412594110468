.widget-form {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow-x: hidden;

  &__menu {
    width: 100%;
    height: $horizontal-menu-height;
  }

  &__body {
    display: flex;
    width: 100%;
    min-height: calc(100% - #{$widget-form-footer-height + $horizontal-menu-height});
    padding-bottom: $widget-form-footer-height;
    box-sizing: content-box;
  }

  &__form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;

    .widget-layout {
      width: 100%;
      height: 100%;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-top: 1px solid $background-color;
    background: $white;
    position: fixed;
    width: 100%;
    height: $widget-form-footer-height;
    bottom: 0;
    left: 0;
    padding: $widget-form-padding;
    z-index: 2;
  }

  .form {
    display: flex;
  }

  &__name-field {
    .field__label {
      font-size: 15px;
      font-weight: 600;
      line-height: 16px;
      padding-bottom: 8px;
    }
  }

  &__left {
    display: flex;
    flex-direction: column;
    width: $widget-form-left-width;
    height: 100%;
    border-right: 1px solid #eeeeee;
    padding: $widget-form-padding;
    overflow-y: auto;

    @include default-scrollbar();
  }

  &__right {
    display: table;
    width: calc(100% - #{$widget-form-left-width});

    & > div {
      display: table-row;

      &:last-child {
        height: 100%;
      }
    }
  }

  &__filters {
    width: 100%;
    padding: $widget-form-padding;
  }

  &__preview {
    width: 100%;
    height: 100%;
    background: #eeeeee;
    padding: $widget-form-padding;
    padding-top: 0;
    display: table-cell;
    overflow-y: auto;

    .widget {
      z-index: unset;
    }

    .dashboard {
      .widget-resizable-box {
        max-width: unset;
      }
    }
  }

  &__filters-actions {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__breadcrumb {
    margin-top: -8px;
    font-size: 11px;
    line-height: 16px;

    span {
      cursor: pointer;
      &:hover {
        color: $primary;
      }
    }
  }

  &__title {
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    margin: 8px 0 16px 0;
  }

  .widget-form-field-label {
    display: flex;
    font-weight: 600;
    font-size: 15px;
    line-height: 16px;
    margin-bottom: 8px;

    .icon {
      font-size: 16px;
      margin-left: 8px;
      color: $text-gray;
      cursor: pointer;
    }
  }

  &__preview-title {
    padding: 16px 20px 8px;
    height: 16px;
    background: #eeeeee;
    margin-bottom: 0 !important;
    box-sizing: content-box;
  }

  &__formula-label {
    display: flex;
    align-items: center;
    font-size: 13px;
    line-height: 16px;
    margin-bottom: 4px;

    .icon {
      cursor: pointer;
      margin-left: 8px;
      font-size: 16px;
      color: $text-gray;
    }
  }

  &__formula-label-text {
    display: block;
    max-width: calc(100% - 50px);

    @include text-overflow-ellipsis();
  }

  .custom-switch, .form-switch {
    z-index: unset;
  }
}

@each $name, $color in $dashboard-widget-colors {
  .widget-color-#{$name} {
    color: $color;
  }
  .widget-background-color-#{$name} {
    background-color: $color;
  }
  .widget-border-color-#{$name} {
    border-color: $color;
  }
}

.widget-border-color-white {
  border-color: $background-color;
}

.color-option {
  display: flex;
  align-items: center;

  .color-option-icon {
    width: 13px;
    height: 13px;
    border-radius: 50%;
    display: inline-flex;
    border-width: 1px;
    border-style: solid;
    margin-right: 8px;
  }
}

.widget-formula-field {
  padding: 0 0 9px 9px;
  width: 100%;
  background-color: $control-background-color;

  &__wrapper {
    .field.is-invalid {
      margin-bottom: 0;
    }
  }

  .custom-statistic-column-form__formula-part:not(.custom-statistic-column-form__formula-part--product, .formula-form__formula-part--product) {
    margin: 14px 8px 0 0;
  }

  .custom-statistic-column-form__formula-part--action, .custom-statistic-column-form__formula-part--constant {
    font-size: 13px;
    line-height: 16px;
    height: 16px;
  }

  .custom-statistic-column-form__formula-part--variable:not(.custom-statistic-column-form__formula-part--product, .formula-form__formula-part--product):not(.custom-statistic-column-form__formula-part--group, .formula-form__formula-part--group) {
    margin: 6px 8px 0 0 !important;
    font-size: 13px;
    line-height: 16px;
    font-weight: 400;
    height: 16px;
    padding: 8px;
  }

  .custom-statistic-column-form__formula-part--statuses,
  .custom-statistic-column-form__formula-part--product,
  .custom-statistic-column-form__formula-part--event,
  .custom-statistic-column-form__formula-part--goal {
    padding: 0 !important;
    height: fit-content;
    min-height: 32px;
    margin: 6px 8px 0 0 !important;

    .custom-statistic-column-form__product-formula-part {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      max-width: 100%;
      padding: 8px;
      font-size: 12px;
    }

    .custom-statistic-column-form__product-formula-part-variable {
      display: inline-flex;
      padding: 0;
      padding-right: 4px;
      height: 16px;
    }

    .custom-statistic-column-form__product-formula-part-model {
      display: inline-flex;
      padding: 0;
      padding-right: 4px;
      height: 16px;
    }
  }

  .custom-statistic-column-form__product-formula-part {
    height: auto !important;
  }

  .custom-statistic-column-form__formula-part--statuses .custom-statistic-column-form__product-formula-part-variable {
    padding: 0;
    padding-right: 4px;
  }

  .custom-statistic-column-form__product-formula-part-statuses-text {
    display: inline-flex;
    font-weight: 400;
    color: $text-gray;
    height: 16px;
  }

  .custom-statistic-column-form__product-formula-part-variable {
    display: inline-block !important;
  }

  .variable-count {
    display: inline-block;
    color: #00B4CD;
  }

  .variable-amount {
    display: inline-block;
    color: #CC61FF;
  }

  .custom-statistic-column-form__formula-part--product {
    height: auto;
    min-height: auto;

    .custom-statistic-column-form__product-formula-part {
      padding: 4px 8px;

      .custom-statistic-column-form__product-formula-part-product {
        background: none;
        color: $primary;
        font-weight: 400;
        font-size: 11px;
        line-height: 12px;
        padding: 0;
        margin: 0;
        width: 100%;
      }

      .custom-statistic-column-form__product-formula-part-variable,
      .custom-statistic-column-form__product-formula-part-model,
      .custom-statistic-column-form__product-formula-part-statuses-text {
        font-size: 12px;
        line-height: 12px;
        height: 12px;
      }
    }
  }
}
