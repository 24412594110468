.duplicate-offer-form-modal .modal-dialog {
  .duplicate-offer_switch {
    margin-bottom: 12px;
  }
  .copy-offer-alert {
    color: red;
  }
  .duplicate-submit-btn {
    width: 100%;
  }
}
