.symbol {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $white;
  border-radius: $border-radius;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @each $name, $color in $theme-colors {
    &-#{$name} {
      color: map-get($btn-font-color, $name);
      background-color: $color;

      .icon svg {
        color: map-get($btn-font-color, $name);
      }
    }
  }

  @each $size, $value in $symbol-sizes {
    &-#{$size} {
      width: #{$size}px;
      height: #{$size}px;
      font-size: $value;
      line-height: $value * 1.5;

      .icon svg {
        width: calc(#{$size}px * 0.5);
        height: calc(#{$size}px * 0.5);
      }
    }
  }
}

