$checkbox-border-color: #cfd1d9;
$checkbox-disabled-color: rgba(63, 66, 84);

.checkbox {
  min-height: $custom-checkbox-height;
  margin-bottom: 0;
  padding-left: 0;
  line-height: 16px;
  display: flex;

  .form-check-input {
    height: $custom-checkbox-height;
    min-height: $custom-checkbox-height;
    width: $custom-checkbox-width;
    min-width: $custom-checkbox-width;
    border-radius: $custom-checkbox-indicator-border-radius;
    border-width: $custom-checkbox-indicator-border-width;
    --bs-border-color: #{$checkbox-border-color};
    background-position: 65% center;
    background-size: auto;
    margin-top: 0;

    &:checked:disabled {
      background-color: $checkbox-disabled-color;
      border-color: $checkbox-disabled-color;
      opacity: 0.2;
    }
  }

  .form-check-label {
    display: inline;
  }

  &.form-check {
    .form-check-input {
      margin-left: 0;

      & + label {
        padding-left: 8px;
        cursor: pointer;
      }
    }
  }

  & label {
    &::before {
      top: 0;
      left: 0;
      border-color: #cfd1d9;
      border-width: 2px;

      width: $custom-checkbox-width;
      height: $custom-checkbox-height;
    }

    &::after {
      top: 0;
      left: 0;

      width: $custom-checkbox-width;
      height: $custom-checkbox-height;
      background: $custom-checkbox-indicator-background-sizes;
    }
  }

  & input {
    width: $custom-checkbox-width;
    height: $custom-checkbox-height;

    z-index: 1;

    cursor: pointer;
  }

  &--label label {
    font-size: 1rem;
  }

  &--partial {
    label {
      &::before {
        color: $white;
        border-color: $primary;
        background-color: $primary;
      }

      &::after {
        height: 2px;
        top: calc(#{$custom-checkbox-height - 2px} / 2);
        width: 10px;
        left: calc(#{$custom-checkbox-width - 10px} / 2);

        background: $white;
        border-radius: 2px;
      }
    }
  }

  &.disabled {
    input:not(:checked) + label {
      &::before {
        background-color: $white !important;
      }
    }
    input:checked + label {
      &::before {
        background-color: #B9BFCF !important;
        border-color: #B9BFCF !important;
        opacity: 1;
      }
    }
  }
}

.custom-control-label::before {
  border-color: $border-gray;
}

.custom-checkbox {
  .custom-control-label {
    padding-left: calc(#{$custom-checkbox-width} + 0.5rem);
  }

  .custom-control-input:disabled + .custom-control-label {
    &::before {
      background-color: $white;
      border-color: $background-color;
    }
  }

  .custom-control-input:checked:disabled + .custom-control-label {
    &::before {
      background-color: $light-primary;
      border-color: $light-primary;
    }
  }
}

.field--control-Checkbox.field--label-right {
  .field__label {
    font-size: 1rem;
    cursor: pointer;
  }

  &.field--disabled {
    .field__label, input {
      cursor: default;
    }

    .field__label {
      color: $text-light-gray;
    }
  }
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.skeleton-checkbox {
  width: 100%;
  height: 16px;
  display: flex;
  align-items: center;
  height: 16px;

  &__input {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    border-radius: $border-radius;
  }

  &__label {
    height: 16px;
    width: 150px;
    border-radius: $border-radius;
  }
}
