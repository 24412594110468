.pagination {
  display: flex;

  padding: 0;
  margin: 0;
  list-style: none;

  &__page {
    min-width: 30px;
    height: 30px;

    margin: 0 5px;

    &--prev {
      margin-left: 0;

      .button__icon {
        transform: rotate(180deg);
      }
    }

    &--next {
      margin-right: 0;
    }
  }

  &__page-link {
    justify-content: center;

    width: 100%;
    height: 100%;
    padding: 0 8px;

    font-size: 13px;
    color: $base-dark;

    border-radius: $border-radius;
    outline: none;

    &.active {
      color: $white;
      text-decoration: none;
      background-color: $link;
    }

    &:hover {
      text-decoration: none;
      @media (hover: hover) {
        color: $white;
        background-color: $link;
      }
    }
  }

  &__prev,
  &__next {
    display: flex;
  }

 & a {
   display: flex;
   align-items: center;
   justify-content: center;
   width: 100%;
   height: 100%;

   transition: none;
   word-break: keep-all;
 }
}
