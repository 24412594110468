.dropdown {
  &.dropdown-toggle-hide {
    .dropdown-toggle::after {
      display: none;
    }
  }

  .dropdown-toggle-hide {
    .dropdown-toggle::after {
      display: none;
    }
    &.dropdown-toggle::after {
      display: none;
    }
  }
}

.popover-dropdown {
  border: 0;
}

.dropdown-menu, .custom-dropdown__menu {
  font-size: 1rem;
  line-height: 16px;
  min-width: 130px;
  border: 0;
  top: -2px !important;
  @extend .noselect;

  button.dropdown-item, .custom-dropdown__menu-item {
    display: flex;
    align-items: center;
    @extend .noselect;

    &:not(:hover) {
      background: $dropdown-bg;
    }

    .icon {
      font-size: 1.1rem;
      margin-right: 16px;
    }

    .navi-text {
      @extend .noselect;
    }
  }
}

.custom-dropdown {
  &__menu {
    position: unset;
    margin: 0;
  }

  &__menu-item {
    justify-content: flex-start;
    border-radius: 0;

    .button__icon + span {
      margin-left: 0;
    }
  }
}

.buttons-dropdown-menu {
  .button.dropdown-item {
    border-radius: 0;
    justify-content: flex-start;

    &:hover, &:active {
      background-color: $light-primary !important;
      box-shadow: none;
    }

    &:focus {
      background-color: $white !important;
      box-shadow: none;
    }

    .button__icon {
      margin-right: 10px;

      .icon {
        margin-right: 0;
      }
    }
  }
}

.dropdown-toggle.dropdown-toggle-hide {
  .link {
    white-space: normal;
  }
}

.dropdown-item.active, .dropdown-item:active, .dropdown-item:focus, .dropdown-item:hover {
  text-decoration: none;
}
