.more-cell {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  grid-gap: 5px;
  position: relative;

  &--expandable {
    .more-cell__more-badge {
      cursor: pointer;
    }
  }

  &--expandable-dropdown {
    .dropdown-toggle {
      .button {
        padding: 4px 8px;
        font-weight: 400;
        line-height: 16px;
        height: 28px;
        background-color: $control-background-color;
        border-radius: $border-radius;

        b {
          font-weight: 400;
        }

        &:after {
          display: none;
        }
      }
    }
  }

  &__expandable-dropdown {
    padding: 8px 8px 4px 12px;
    max-width: min(700px, calc(100dvw - 48px));
    max-height: 200px;
    overflow-y: auto;
    background: $white;
    @include default-scrollbar();

    .badge {
      margin: 0 4px 4px 0;
    }

    .more-cell__label {
      margin-bottom: 4px;
    }
  }

  &__other {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    grid-gap: 5px;
  }
}
