.offer {

  &__tracking-link {
    min-height: 48px;
    padding: 13px 20px;

    word-break: break-all;

    background-color: rgba(98, 138, 241, 0.1);
    border-radius: 5px;
  }

  &__actions {
    display: flex;
    justify-content: space-between;
  }
}

.offer-page-rules {
  display: grid;
}

.offer-page-rule {
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-gap: 20px;
  align-items: start;

  padding: 13px 0;

  &:not(:last-child) {
    border-bottom: 1px solid #EFF1F5;
  }

  &__label {
    width: 120px;
    padding-right: 10px;

    color: #9099B1;
  }
}

.offer-form-page {
  .wizard-step-list__title {
    font-size: 1.7rem;
  }

  .wizard-content {
    &__title {
      width: 250px;
      min-width: 250px;
    }
  }

  .offer__label {
    margin-bottom: 14px;
  }
}

.offer-conditions {
  div[role="tablist"] {
    width: 250px;
  }
  .offer-conditions-content {
    width: calc(100% - 250px - 5rem);
    padding-left: 2.25rem;
    margin-left: 2.25rem;
    border-left: 1px solid #EBEDF3;

    table {
      table-layout: fixed !important;
    }
  }
}

.offer-conditions-steps {
  padding: 0 0 0 15px;
  margin: 0;
  list-style: none;

  &__item {
    margin-bottom: 10px;
  }

  &__link {
    position: relative;

    padding-left: 15px;

    color: $base-dark;
    cursor: pointer;

    &.active {
      font-weight: 600;
      color: $link-color;
    }

    &.active::before {
      content: '';
      position: absolute;
      left: 0;
      top: calc(50% - 10px);

      width: 3px;
      height: 20px;

      background-color: $link-color;
      border-radius: $border-radius;
    }
  }
}

.offer-condition__hidden-fields {
  .field:not(:last-child) {
    margin-bottom: 0.5rem;
  }
}

.offer-condition-step {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;

    padding: 8px 20px;

    border-radius: $border-radius;

    &.active {
      background-color: $hover-bg;
    }
  }

  &__label {
    font-size: $font-size-base;
    line-height: $line-height-base;
    color: $base-dark;
    font-weight: 600;
  }

  &__icon {
    padding: 0;
  }
}

.offer-day-fields {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;

  .offer-day-field {
    width: 100%;
  }
}

@media (max-width: 1500px) {

  .offer-conditions {
    display: flex;
    flex-direction: column;

    .offer-conditions-content {
      width: 100%;
      padding: 0;
      margin: 0;
      border: none;

      padding-top: 2.25rem;
      margin-top: 2.25rem;
      border-top: 1px solid #EBEDF3;
    }
  }
}
