.statistics-schemes-dropdown {
  &__toggle {
    @include control();
    --bs-btn-active-bg: #{$control-background-color};

    .icon {
      font-size: 11px;
      margin-left: 8px;
    }

    span {
      display: flex;
      align-items: center;
    }
  }

  &__menu {
    min-width: min(calc(100vw - 24px), 400px);
    max-width: min(calc(100vw - 24px), 700px);
    padding: 8px 12px;
    max-height: min(50vh, 400px);
    overflow-y: auto;
    @include default-scrollbar();
  }

  &__button {
    margin: 8px 12px 12px 12px;
    background: #F6F6F9;
    width: calc(100% - 24px);
    border-color: transparent !important;
  }

  &__dropdown {
    z-index: 101;
    background: $white;
  }

  &__scheme {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 8px 12px;

    &:hover {
      background: $light-primary;
    }

    &--active {
      box-shadow: $input-select-option-selected-box-shadow;
    }
  }

  &__data {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% - 24px);
    cursor: pointer;
    padding: 8px 0 8px 12px;
    margin: -8px 0 -8px -12px;
  }

  &__name {
    width: 100%;
    line-height: 16px;
    word-break: break-word;

    .badge {
      text-transform: none;
    }
  }

  &__author {
    width: 100%;
    color: $text-gray;
    font-size: 11px;
  }

  &__actions {
    margin: -2px 0;

    .custom-dropdown__toggle {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 20px;
      width: 20px;

      .button {
        font-size: 10px;
        padding: 2px;
        margin: -2px;
        height: 20px;
        width: 20px;
        border-radius: 4px;
        background: $white;

        & > span {
          display: flex;
        }

        svg path {
          fill: #74788D
        }

        &:hover {
          svg path {
            fill: $base-dark;
          }
        }
      }
    }

    & > .dropdown.show {
      .dropdown-toggle {
        svg path {
          fill: $base-dark;
        }
      }
    }
  }

  &__group-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 12px;
  }

  &__group-title {
    font-weight: 600;
  }

  &__group-add {
    margin: -2px 0;

    .button {
      width: 20px;
      height: 20px;
      padding: 3px;
      font-size: 14px;

      &:not(:hover) {
        background-color: transparent;
        border-color: transparent;
        color: $text-gray;
      }

      &__icon {
        margin-left: 0;
      }
    }
  }

  &__dropdown--partner {
    .statistics-schemes-dropdown__menu {
      min-width: 250px;
    }
  }
}

.scheme-actions-dropdown {
  .button__icon {
    margin-right: 10px;
  }
}

@include mobileLayout() {
  .statistics-schemes-dropdown {
    &__toggle {
      --bs-btn-hover-bg: #{$control-background-color};
    }
  }
}
