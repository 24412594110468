$input-template-field-min-height: 48px;
$input-template-padding: 4px;
$input-template-template-padding-x: 8px;
$input-template-template-padding-y: 6px;
$input-template-template-margin-x: 2px;

.input-templates {
  &__field {
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow: auto;
    padding: 10px 13px;
    min-height: $input-template-field-min-height;
    background: $background-color;
    border-radius: $border-radius;
    margin: $input-template-padding 0 #{$input-template-padding * 2} 0;

    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
  }
  &__field-input {
    display: inline-flex;
    position: relative;
    min-width: auto;

    &:last-child {
      width: 100%;
    }
  }
  &__field-input-label {
    display: inline-flex;
    position: relative;
    opacity: 0.001;
    white-space: pre;
  }
  &__input {
    position: absolute;
    left: -$input-template-template-margin-x;
    top: 0;
    min-width: #{4px + $input-template-template-padding-x * 2};
    width: 100%;
    display: inline-flex;
    padding: 0;
    border: none;
    outline: none;
    background: transparent;
    font-weight: 500;
  }
  &__field-template {
    display: inline-flex;
    flex-direction: row;
    background: $white;
    border-radius: $border-radius;
  }
  &__field-template, &__field-input, &__input  {
    padding: $input-template-template-padding-y $input-template-template-padding-x;
    margin: 0 $input-template-template-margin-x;
  }

  &__field-input--empty {
    padding-left: $input-template-template-margin-x !important;
    padding-right: $input-template-template-margin-x !important;

    .input-templates__input {
      padding-left: $input-template-template-margin-x !important;
      padding-right: $input-template-template-margin-x !important;
      min-width: 8px;
    }
  }
  &__field-template-label {
    display: inline-flex;
    cursor: default;
    white-space: nowrap;
  }
  &__field-template-remove {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    margin-left: $input-template-template-padding-x;
    cursor: pointer;
  }
  &__templates {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    grid-gap: $input-template-padding;
  }
  &__template {
    font-size: 12px;
    line-height: 16px;
    cursor: pointer;
    padding: $input-template-template-padding-y $input-template-template-padding-x;
    background: $background-color;
    border-radius: $border-radius;

    &--disabled {
      cursor: not-allowed;
      background: #F6F6F9;
      color: $text-light-gray;
    }
  }
  &__template-label {
    display: inline-flex;
  }
}

.field--has-error {
  .input-templates__field {
    background: $light-danger;
    border: 1px solid $danger;
    border-radius: $border-radius;
  }
}
