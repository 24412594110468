.profile-roles-list {
  display: flex;

  &__roles {
    width: 40%;

    padding: 20px;

    border-right: 1px solid $hover-bg;
  }

  &__role {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 13px 20px 13px 10px;
    margin-bottom: 10px;

    cursor: pointer;

    &-text {
      word-break: break-all;
    }

    &:hover,
    &--selected {
      border-radius: $border-radius;
      background-color: $hover-bg;
    }

    &--selected {
      font-weight: bold;
    }
  }

  &__rules {
    width: 60%;
    padding: 20px;
  }

  &__rule {
    &-group-name {
      margin-bottom: 10px;
      font-weight: $font-weight-bold;
    }

    &-name {
      margin-bottom: 10px;
      font-size: $font-size-sm;
    }
  }
}
