.user-box {
  display: flex;
  align-content: center;
  height: 100%;

  cursor: pointer;
  @include default-transition(background-color);

  &.show {
    background-color: $light-primary;
  }

  &.dropdown:hover {
    @media (hover: hover) {
      background-color: $light-primary;
    }
  }

  &__panel {
    display: flex;
    align-items: center;
    height: 100%;

    padding: 0 8px;
  }

  &-item.button.dropdown-item > span {
    display: flex;
    align-items: center;

    .icon {
      font-size: 24px;
      margin-right: 0;
    }

    .navi-icon {
      display: inline-flex;
      align-items: center;
    }
  }

  &__panel-email {
    max-width: 400px;

    font-size: 13px;
    line-height: 20px;
    color: $base-dark;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__avatar-placeholder {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 28px;
    height: 28px;
    border-radius: 14px;
    background: $primary;
    color: $white;
    text-transform: capitalize;
    font-size: 15px;
    line-height: 20px;
    font-weight: 500;
  }

  &__export.button.dropdown-item > span {
    display: flex;
    align-items: center;

    .navi-icon {
      display: inline-flex;
      justify-content: center;
      align-items: center;

      width: 24px;
      height: 24px;
    }
    .icon {
      color: #B9BFCF;
      font-size: 18px;
    }
  }

  &__panel-icon {
    flex-shrink: 0;

    width: 35px;
    height: 35px;
    margin-left: 15px;

    border-radius: $border-radius;

    overflow: hidden;
  }

  &__menu {
    min-width: 190px;
    box-shadow: 0 0 50px rgba(63, 66, 84, 0.1);
  }

  .lang-switch {
    .button__icon {
      margin-right: 5px;
    }
  }
}
