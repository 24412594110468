.create-product-page {

  &__card {
    height: 100%;

    .card-body {
      width: 845px;
      display: flex;
      flex-direction: column;
      margin: 0 auto;

      padding: 40px 135px;
    }

    .form {
      display: flex;
      flex-direction: column;

      width: 520px;
    }
  }

  &__two-field-row {
    display: flex;
    justify-content: space-between;

    & > div {
      width: calc((100% - 25px) / 2);
    }
  }

  &__form-buttons {
    display: flex;
    justify-content: flex-end;

    .button {
      &:not(:last-child) {
        margin-right: 8px;
      }
    }
  }
}

@include tabletLayout() {
  .create-product-page {
    width: 100%;
    display: flex;
    justify-content: center;
    min-width: unset;

    &__card {
      border: 0;
      width: min($mobile-max-width, 100%);
    }

    .widget-layout {
      & > .loading {
        margin: -12px;
        width: calc(100% + 24px);
        height: calc(100% + 24px);
        z-index: 101;
      }
    }

    .card-body {
      width: min(100%, 100dvw);
      padding: 12px 12px 0 12px;

      & > .title {
        display: none;
      }
    }

    .form {
      min-height: calc(100dvh - 70px - 28px - #{$horizontal-menu-height} - 12px);
      width: 100%;
      justify-content: space-between;

      .field--control-Textarea {
        height: 100%;
        margin-bottom: 120px;
      }
    }

    //.create-product-page__card,
    //.card-body,
    //.widget-layout {
    //  display: grid;
    //}

    .widget-layout__top {
      display: none;
    }
  }
}

@include mobileLayout() {
  .create-product-page {
    hr {
      display: none;
    }

    &__form-buttons {
      display: flex;
      justify-content: unset !important;
      margin: 24px -13px 0;
      position: sticky;
      bottom: 0;
      background: $white;
      z-index: 110;

      & > * {
        width: 50%;
      }

      a .button {
        width: 100%;
      }

      .button {
        border-radius: 0;
        height: 40px;
        margin-right: 0 !important;
      }
    }
  }
}
