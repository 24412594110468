.confirm-modal {
  z-index: #{$modal-z-index + 20};

  &__btn {
    min-width: 100px;

    justify-content: center;
  }

  &__centered-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 300;
    text-align: center;
  }

  &__title {
    font-size: 17px;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  .modal__body {
    padding: 24px 32px;
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
  }

  .modal__footer {
    justify-content: flex-end;

    button:last-child {
      margin-left: 16px;
    }
  }

  &__subtext {
    color: $text-gray;
  }

  &--small-width {
    .modal-dialog {
      max-width: 330px;
    }
  }
}

.confirm-modal-backdrop {
  z-index: #{$modal-z-index + 10};
}
