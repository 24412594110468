.postback-settings-form {
  height: 100%;

  &__title {
    margin-bottom: 20px;
  }

  &__row:not(:last-child) {
    margin-bottom: 20px;
  }

  &__generate-btn {
    margin: 10px 0 0 0;

    font-size: $base-font-size;
    line-height: $base-line-height;
  }
}

.postback-settings-form-drawer {
  .drawer__content {
    height: calc(100% - 7rem);
  }
}
