$goal-list-element-min-width: 220px;
$goal-list-element-label-max-width: 350px;
$goal-list-element-button-width: 41px;
$goal-list-element-padding: 6px 10px;
$goal-list-element-margin-left: 20px;
$goal-list-element-margin-right: 0px;
$goal-list-element-icon-width: 10px;

.goals-list {

  &__error {
    display: flex;
    font-size: 13px;
    font-weight: 400;
    white-space: pre-wrap;
    margin: 0 12px 6px;
  }

  &__elements {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 10px;
    flex-wrap: wrap;
  }

  &__button {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .goals-list-element {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(239, 241, 245, 0.6);
    padding: $goal-list-element-padding;
    border-radius: $border-radius;
    margin-right: 10px;
    margin-bottom: 10px;
    min-height: 32px;

    &__label {
      @include text-overflow-ellipsis();
      margin-right: 10px;
    }

    &__actions {
      display: flex;
      flex-direction: row;
    }

    &__action {
      display: flex;
      color: $text-gray;

      &:not(:last-child) {
        margin-right: 10px;
      }

      .icon svg {
        width: $goal-list-element-icon-width;
        height: $goal-list-element-icon-width;
      }
    }
  }
}

.condition-goals-form {
  display: flex;
  flex-direction: column;
  max-height: 70vh;
  overflow: auto;
  margin-right: -10px;
  margin-left: -10px;
  padding: 10px 10px;
  @include default-scrollbar-with-background();

  &__type {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 82px;
    padding: 16px 24px;
    border: 1px solid $gray-semi-dark;
    border-radius: $border-radius;
    cursor: pointer;

    &:hover {
      box-shadow: 0px 2px 10px rgba(46, 62, 154, 0.1);
    }
  }

  &__type-wrapper {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
    &:last-child {
      margin-bottom: 16px;
    }
  }

  &__type-title {
    font-weight: 600;
    font-size: 15px;
    line-height: 16px;
  }

  &__type-description {
    margin-top: 8px;
    font-weight: 400;
    font-size: 11px;
    line-height: 12px;
  }

  &__qualification-mode {
    display: flex;
    align-items: center;
    margin-top: 8px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: $text-gray;
    cursor: pointer;
    height: 18px;
    margin-bottom: 0;

    & > span {
      display: flex;
      align-items: center;
      height: 18px;
    }

    .switch {
      margin-right: 8px;
    }

    .icon {
      font-size: 17px;
      margin-left: 6px;
    }
  }

  &__goal-label {
    display: flex;
    align-items: flex-start;
    width: 100%;
    cursor: pointer;
    line-height: 16px;
    padding: 12px;
    border: 1px solid $gray-semi-dark;
    border-radius: $border-radius;

    &--selected {
      border: 1px solid $primary;
      background: $light-primary;
    }

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &__goals-empty {
    width: 100%;
    text-align: center;
  }

  &__formula-label {
    margin-top: 4px;

    .formula-label {
      font-size: 11px;
      line-height: 12px;
    }
  }

  &__button {
    min-width: 100px;
  }

  &__goal {
    display: flex;
    margin-right: 6px;

    &--selected {
      border-color: $primary;
      color: $primary;
      background-color: rgba(54, 153, 255, 0.15);

      .condition-goals-form__goal-radio {
        border-color: $primary;
        background: $primary;

        .icon {
          display: flex;
          color: $white;
        }
      }
    }
  }

  &__goal-radio {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-right: 10px;
    width: 20px;
    height: 20px;
    border: 1px solid $text-light-gray;
    border-radius: 50%;

    .icon {
      display: none;
      margin: 0.5px 0.5px 0 0;
    }
  }


  &__goal-name {
    width: calc(100% - 30px);
    @include text-overflow-ellipsis();
  }

  &__add-new-goal-btn {
    width: 100%;
    margin-top: 10px;
  }
}

.goals-form-modal {
  z-index: $modal-z-index + 10;

  .modal-dialog {
    max-width: calc(min(max($mobile-min-width, 100dvw), 450px) - 1rem);
  }

  .modal__body {
    padding-top: 6px;
    padding-bottom: 6px;
  }
}

.goals-form-modal-backdrop {
  z-index: $modal-z-index + 10;
}
