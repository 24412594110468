$day-width: 30px;
$day-height: 30px;

.date-picker {
  &__toggle {
    &::after {
      display: none;
    }
  }

  .rdrMonth {
    width: 247px;
  }

  .rdrDay {
    width: $day-width;
    height: $day-height;
  }

  .rdrWeekDays .rdrWeekDay {
    width: $day-width;
    max-width: $day-width;
  }

  .rdrStartEdge,
  .rdrEndEdge {
    top: 0;
    left: 0;

    width: 100%;
    height: $day-height;

    background-color: $link;
  }

  .rdrInRange {
    top: 0;
    left: 0;

    width: 100%;
    height: $day-height;

    background-color: rgba(98, 138, 241, 0.2);
    opacity: 1;
  }

  .rdrMonthAndYearWrapper {
    justify-content: center;
  }

  .rdrDayInPreview {
    top: 0;
    left: 0;

    width: 100%;
    height: $day-height;

    border: none;
    background-color: rgba(98, 138, 241, 0.2);
  }

  .rdrDayStartPreview {
    top: 0;
    left: 0;

    width: 100%;
    height: $day-height;

    border: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: $link;

    z-index: 0;
  }

  .rdrDayEndPreview {
    top: 0;
    left: 0;

    width: 100%;
    height: $day-height;

    color: #ffffff;
    border: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

    background-color: $link;
    z-index: 0;
  }

  .rdrDayEndPreview + .rdrDayNumber > span,
  .rdrDayStartPreview + .rdrDayNumber > span {
    color: #ffffff;
  }

  .rdrInRange ~ .rdrDayNumber span {
    color: $base-dark !important;
  }

  .rdrDayHovered .rdrDayNumber span {
    color: #ffffff !important;
  }

  .rdrDayToday .rdrDayNumber span:after {
    bottom: 0;

    background-color: $link !important;
  }

  .rdrStartEdge ~ .rdrDayNumber span::after,
  .rdrEndEdge ~ .rdrDayNumber span::after {
    background-color: #ffffff !important;
  }

  .rdrMonthPicker, .rdrYearPicker {
    font-size: $base-font-size;
    line-height: $base-line-height;
    color: $base-dark;
  }

  .rdrMonthAndYearPickers {
    flex-grow: 0;
  }

  .rdrDayStartOfMonth .rdrDayInPreview, .rdrDayStartOfMonth .rdrDayEndPreview, .rdrDayStartOfWeek .rdrDayInPreview, .rdrDayStartOfWeek .rdrDayEndPreview {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .rdrDayEndOfMonth .rdrDayInPreview, .rdrDayEndOfMonth .rdrDayStartPreview, .rdrDayEndOfWeek .rdrDayInPreview, .rdrDayEndOfWeek .rdrDayStartPreview {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .rdrDayStartOfMonth .rdrInRange, .rdrDayStartOfMonth .rdrEndEdge, .rdrDayStartOfWeek .rdrInRange, .rdrDayStartOfWeek .rdrEndEdge, .rdrStartEdge {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .rdrDayEndOfMonth .rdrInRange, .rdrDayEndOfMonth .rdrStartEdge, .rdrDayEndOfWeek .rdrInRange, .rdrDayEndOfWeek .rdrStartEdge, .rdrEndEdge {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .rdrNextPrevButton {
    position: relative;
    background-color: transparent;

    & i {
      display: none;
    }

    &::before {
      content: '';
      position: absolute;
      top: calc(50% - 7px);
      left: calc(50% - 4px);
      width: 8px;
      height: 14px;

      background: url('../../media/chevron.svg') no-repeat center;
    }

    &.rdrNextButton {
      transform: rotate(180deg);
    }
  }

  .rdrCalendarWrapper {
    border-radius: $border-radius $border-radius 0 0;
  }

  &__helpers {
    display: flex;
    align-items: center;

    padding: 0 20px 20px 20px;
    background: $white;
    border-radius: 0 0 $border-radius $border-radius;
  }

  &__helper {
    display: flex;
    align-items: center;

    &-label {
      margin: 0 10px;

      font-size: 13px;
      line-height: 20px;
      color: $text-light-gray;
    }

    &-fn {
      margin-right: 10px;

      font-size: 13px;
      line-height: 20px;
      color: $link;

      cursor: pointer;
    }
  }
}

.date-picker__menu {
  box-shadow: $box-shadow;
  border-radius: $border-radius;
}

.date-picker__separated-fields, .date-picker__separated-fields-labels {
  width: 100%;
  display: grid;
  grid-template-columns: minmax(30%, 1fr) minmax(30%, 1fr);
  grid-gap: 6px;
}

.rdrCalendarWrapper {
  .rdrSelected {
    @extend .rdrDayStartPreview;
    @extend .rdrDayEndPreview;
  }

  .rdrDayHovered {
    .rdrDayNumber::after {
      border: none !important;
    }
  }
}

@include tabletLayout() {
  .date-picker__helpers {
    flex-wrap: wrap;
    grid-gap: 5px;
  }

  .date-picker.show, .date-picker__background {
    &:before {
      display: block;
      content: " ";
      width: 100dvw;
      height: 100dvh;
      position: fixed;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.2);
      z-index: 120;
    }
  }

  .date-picker__menu {
    max-width: 300px;
    width: 300px;
    height: 340px;
    max-height: 340px;
    position: fixed !important;
    top: calc(50% - 170px) !important;
    left: calc(50% - 150px) !important;
    transform: none !important;
    z-index: 125;

    .control, .date-picker__helpers {
      background: $white;
    }
  }

  .date-picker__helper-fn {
    margin-right: 0;
  }

  .rdrCalendarWrapper {
    align-items: center;
    width: 100%;
  }

  .rdrWeekDays, .rdrDays {
    display: flex;
    justify-content: center;
  }

  .date-picker__popover {
    transform: none !important;
  }
}
