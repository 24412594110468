$partner-balance-margin: 8px;
$partner-balance-height: 28px;
$partner-balance-width: 200px;
$partner-balance-loading-width: 15px;
$partner-balances-max-height: 150px;
$partner-balance-icon-width: 20px;
$partner-balance-icon-margin-x: 8px;
$partner-balance-icon-margin-y: 4px;
$partner-balance-toggle-width: 10px;
$partner-balance-toggle-margin: 10px;
$partner-balance-check-width: 12px;
$partner-balance-check-margin: 5px;
$partner-balance-balance-padding: 2px 10px;
$partner-balance-balances-margin: 2px;
$partner-balance-balance-margin: 10px;
$partner-balance-currency-margin: 4px;
$partner-balance-balance-border-radius: 5px;

.partner-balance {
  height: #{$partner-balance-height + 2 * $partner-balance-margin};
  max-height: #{$partner-balance-height + 2 * $partner-balance-margin};
  z-index: 20;
  position: relative;

  .dropdown-toggle {
    height: 100%;
  }

  &__panel {
    display: flex;
    flex-direction: row;
    background: $white;
    padding: $partner-balance-margin;
    z-index: 20;
    cursor: pointer;
    height: 100%;
    box-sizing: border-box;

    &:hover {
      @media (hover: hover) {
        background: $light-primary;
      }
    }
  }

  &__toggle {
    display: none;
  }

  &__dropdown {
    height: 100%;

    &.show {
      .partner-balance__panel {
        background: $light-primary;
      }
    }
  }

  &__icon {
    display: flex;
    width: $partner-balance-icon-width;
    height: $partner-balance-icon-width;
    font-size: $partner-balance-icon-width;
    margin-top: $partner-balance-icon-margin-y;
    margin-right: $partner-balance-icon-margin-x;
    color: $green;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__toggle {
    font-size: $partner-balance-toggle-width;
    margin-left: $partner-balance-toggle-margin;
    transition-duration: 0.15s;

    svg {
      @include default-transition(transform);
    }
  }

  &__menu {
    transform: none !important;
    top: 44px !important;
  }

  &__title {
    display: flex;
    align-items: center;
    width: 100%;
    font-weight: 400;
    font-size: 11px;
    line-height: 12px;
    cursor: pointer;
  }

  &__current-balance {
    display: flex;
    cursor: pointer;
    position: relative;
    width: 100%;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;

    .loading {
      width: $partner-balance-loading-width;
      height: $partner-balance-loading-width;
      margin-top: 1px;
    }
  }

  &__balance-value {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &__value {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    max-width: 110px;
    @include text-overflow-ellipsis();
  }

  &__currency {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    margin-left: $partner-balance-currency-margin;
  }

  &__dropdown {
    .balance {
      min-width: 200px;
      padding: 0;
      padding-top: 8px;

      &__item {
        padding: 0 12px 8px;
        background: $white;
        color: $base-dark;

        &--muted {
          color: $text-gray;
        }
      }
    }
  }
}

.partner-balance-overflow {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 15;
}

