.sub-ids-fields {
  margin: 20px 0 25px 0;

  .rc-collapse-header {
    font-size: 13px;
    line-height: 20px;
    color: $light-secondary;
    font-weight: $font-weight-bold;
  }
}
