$text-editor-min_height: 90px;

.text-editor {
  min-height: $text-editor-min_height;
  padding: $input-btn-padding-x $input-btn-padding-y;

  border: 1px solid $hover-bg;
  border-top: none;
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;

  &__toolbar {
    margin-bottom: 0;

    border: 1px solid $hover-bg;
    border-radius: $border-radius $border-radius 0 0;
  }

  &__dropdown {
    &:hover {
      box-shadow: none;
    }

    .rdw-dropdownoption-default {
      font-size: 12px;
    }
  }

  &__block-type {
    width: 150px;
    border: 1px solid transparent;

    &:hover {
      border: 1px solid $hover-bg;
      box-shadow: none;
    }

    a {
      font-size: 12px;
      color: $black;
    }
  }

  &__button {
    width: 28px;
    height: 28px;

    border: 1px solid transparent;

    &:hover {
      box-shadow: none;
      border: 1px solid $hover-bg;
    }

    &--bold img {
      width: 14px;
    }
  }

  &__popup {
    font-size: 1rem;
    line-height: 20px;
    height: auto;
    border-radius: $border-radius;

    label {
      display: flex;
      align-items: center;
      font-size: 1rem;
    }

    input {
      margin-top: 0;
      line-height: 25px;
    }

    button {
      width: 85px;
    }
  }

  .rdw-link-decorator-icon {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 0;
    right: 0;
    left: unset;
    bottom: unset;
  }

  .public-DraftStyleDefault-block {
    margin: 0;
  }
}

.field--has-error {
  .text-editor__toolbar {
    border-color: $danger;
    border-bottom-color: $hover-bg;
  }
  .text-editor {
    border-color: $danger;
  }
}


@include tabletLayout() {
  .rdw-editor-toolbar {
    .rdw-dropdown-selectedtext {
      & > span {
        max-width: calc(100% - 20px);
        display: inline-block;
        @include text-overflow-ellipsis();
      }
    }
  }

  .rdw-link-wrapper {
    .text-editor__popup {
      left: unset;
      right: 35px;
    }
  }
}
