$inputs-by-languages-input-padding-left: 36px;

.inputs-by-languages {
  display: flex;
  flex-wrap: wrap;

  &__field {
    display: flex;
    position: relative;
    width: 240px;

    &:not(:last-child) {
      margin-right: 16px;
    }
  }

  &__field-icon {
    display: flex;
    font-size: 16px;
    position: absolute;
    border-radius: 8px;
    top: 15px;
    left: 12px;
    z-index: 2;
    overflow: hidden;
  }

  .field {
    width: 100%;
  }

  @include input-text-floating-label-padding($inputs-by-languages-input-padding-left);

}
