$partner-offers-page-filter-footer-max-width: 300px;

.partner-offer-page {
  .wizard {
    display: grid;
    grid-template-columns: 1fr 2fr;

    border-top: 1px solid #EFF1F5;

    &__step {
      border-bottom: 1px solid #EFF1F5;
    }
  }

  .wizard-content__title {
    display: none;
  }

  .offer-page-rule-countries {
    .badge {
      margin: 0 5px 5px 0;
    }
  }

  .wizard-step-list {
    max-width: none;

    &__content {
      padding: 10px 30px 30px 0;
    }
  }

  .wizard-content__container {
    padding: 0 0 0 35px;
  }
}

.partner-offers-page {
  &__filter {
    .filter__content {
      display: flex;
      justify-content: space-between;
    }

    .filter__fields {
      grid-template-columns: 1fr 3fr 1fr;
      width: calc(100% - #{$partner-offers-page-filter-footer-max-width});
    }

    .filter__footer {
      max-width: $partner-offers-page-filter-footer-max-width;
      padding-left: 10px;
    }
  }
}
