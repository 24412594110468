$offer-card-width: 360px;
$offer-condition-goals-column-width: 250px;
$offer-condition-reward-column-width: 140px;
$offer-logo-width: 70px;
$offer-logo-border-width: 1px;
$offer-logo-margin: 13px;
$offer-card-left-width: #{$offer-logo-width + $offer-logo-margin};

.offers-page {
  .filters-button-container {
    display: none;
  }

  .offers-table {
    width: 100%;

    thead {
      display: none;
    }

    &__card-column {
      width: $offer-card-width;
      padding: 12px 20px;
      vertical-align: top;
    }

    &__conditions-column {
      padding: 12px;
      vertical-align: top;

      .empty-data-block__icon {
        display: none;
      }
    }

    &__card-column, &__conditions-column {
      border-bottom-width: 4px;
      border-color: $background-color;
    }

    &__offer {
      &:not(:last-child) {
        border-bottom: 4px solid $background-color;
      }
    }

    &__offer-row {
      display: grid;
      grid-template-columns: $offer-card-width auto;
    }

    &__offer-card {
      position: relative;
      min-height: 100px;
    }

    &__offer-card-header {
      display: flex;
      width: 100%;
    }

    &__offer-is-top{
      position: absolute;
      top: -8px;
      left: 4px;
    }

    &__offer-logo {
      position: relative;
      .symbol {
        border: $offer-logo-border-width solid rgba(63, 66, 84, 0.05);
        border-radius: $border-radius;
      }
      margin-right: $offer-logo-margin;
    }

    &__offer-main-info {
      width: calc(100% - #{$offer-card-left-width});
    }

    &__offer-first-line {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      grid-gap: 4px;
    }

    &__offer-status {
      display: inline-flex;
      height: 16px;

      .badge {
        font-size: 10px;
        line-height: 16px;
        text-transform: uppercase;
        padding: 0 4px;
        border-radius: 2px;
        height: 16px;
        font-weight: 600;
      }
    }

    &__offer-id {
      margin-left: 4px;
      display: flex;
      font-size: 11px;
      line-height: 16px;
      color: $text-gray;
      font-weight: 400;
    }

    &__offer-dropdown {
      position: absolute;
      right: -15px;
      top: -10px;
    }

    &__dropdown-toggle {
      svg path {
        fill: $text-gray;
      }

      &:hover {
        svg path {
          fill: $base-dark;
        }
      }
    }

    &__dropdown-item {
      background: transparent;
      justify-content: flex-start;
    }

    &__offer-id-divider {
      font-size: 18px;
      margin: -2px 5px 0;
    }

    &__offer-name {
      font-size: 19px;
      line-height: 20px;
      margin: 8px 0;
      font-weight: 600;
      word-break: break-word;

      a {
        color: $base-dark;
        text-decoration: none;

        &:hover {
          color: $primary;
          text-decoration: none;
        }
      }

    }

    &__product-name {}

    &__category-name {
      margin-top: 4px;
      line-height: 16px;
    }

    &--partner {
      .offers-table__offer-tags {
        margin-top: 4px;
      }

      .tabs__nav {
        display: none;
      }
    }

    &__offer-info {
      width: 100%;
      padding: 12px 0;

      .titled-text {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 4px;
        width: 100%;

        &__title {
          width: $offer-logo-width;
          font-size: 11px;
          line-height: 16px;
          font-weight: 400;
          color: $text-gray;
          margin-bottom: 0;

          & > span:first-child {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
            display: block;
            min-width: 100%;
          }
        }

        &__text {
          line-height: 16px;
          width: calc(100% - #{$offer-card-left-width});
        }
      }
    }

    &__offer-tags {
      width: 100%;
      font-size: 11px;
      line-height: 16px;
      color: $text-gray;
      font-weight: 400;
      white-space: pre-wrap;
      word-break: break-word;
    }

    &__offer {
      &--inactive {
        .offers-table__card-column {
          background: $table-inner-background;
        };
      }
    }

    &__offer-status-inactive {
      position: absolute;
      left: -20px;
      top: 0;
      z-index: 1;

      .badge {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
      }
    }

    &__conditions-table {
      width: calc(100% + 4px);
      margin: 0 -12px;
    }

    &__more-conditions {
      display: flex;
      align-items: center;
      margin-top: 8px;

      .button {
        margin-right: 8px;
        background: $white;
        border-color: #E1E5ED;
        border-radius: $border-radius;
        font-size: 10px;
        padding: 0 4px;

        &__icon {
          min-height: 16px;
          min-width: 16px;
        }
      }
    }

    .tabs {
      &__nav {
        padding: 0;
        border-bottom: 0;
        margin-left: -12px;
        height: fit-content;
      }

      .counter {
        font-weight: 600;
        font-size: 11px;
        line-height: 16px;
        margin-left: 4px;
      }

      &--hidden {
        .tabs__nav {
          display: none;
        }
      }

      .tabs-ink-bar {
        display: none;
      }

      .tabs-tab-btn {
        font-weight: 400;
        width: max-content;
        line-height: 16px;
        max-height: 16px;
      }

      .tabs-nav-list {
        border-radius: 0;
      }

      .tabs-tab {
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        height: fit-content;
        padding: 2px 8px;
        margin: 0;
        min-height: fit-content;
        color: $base-dark !important;
        border: 1px solid transparent !important;
        border-radius: 0;
        background: transparent;

        &-active {
          border: 1px solid $background-color !important;
          background: $background-color;
        }

        b {
          margin-left: 4px;
        }
      }
    }
  }

  .conditions-table {
    &.table thead {
      display: table-header-group;

      tr th {
        border-top: 1px solid $background-color;
        border-bottom: 1px solid $background-color;
        border-right: none;
        background-color: $background-color;
        font-size: 11px;
        line-height: 16px;
        color: $text-gray;
        text-transform: none;
        font-weight: 400;

        &:first-child {
          border-left: 1px solid $background-color;
        }

        &:last-child {
          border-right: 1px solid $background-color;
        }
      }
    }
  }
}

.offer-page__params-card {
  .tabs__nav {
    padding: 14px 20px 12px 20px;
  }
}
.conditions-tabs__extra {
  display: flex;

  .conditions-tabs__search {
    position: absolute;
    top: 0;
    width: auto;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .conditions-tabs__search-label {
    font-size: 12px;
    line-height: 14px;
    color: #3F4254; 
  }
}

.conditions-table {
  th, td {
    padding: 4px 12px;
    vertical-align: top;
  }

  tbody + tbody {
    border-top: none;
  }

  &.table {
    td {
      border-color: $background-color;
    }

    td:first-child {
      border-left: 1px solid $background-color;
    }

    td:last-child {
      border-right: 1px solid $background-color;
    }
  }

  &__all-countries {
    display: flex;
    align-items: center;

    .icon {
      margin-right: 5px;
      font-size: 16px;
      color: #A2A6B0;
    }
  }

  tbody {
    &:hover {
      tr td {
        background: $table-hover-bg;

        .badge.more-label {
          background: #E1E5ED;
        }
      }
    }
  }

  .countries-flags {
    .badge {
      height: 16px;

      &:not(.more-label) {
        width: 16px;
      }

      .country-label {
        &__icon {
          height: 16px;
          width: 16px;
          min-width: 16px;

          .icon, .icon svg {
            height: auto;
            width: 26px;
          }

          .react-loading-skeleton {
            width: 16px;
            height: 11px;
            border-radius: 2px;
          }
        }
      }
    }
  }

  &__condition {
    &--hidden {
      display: none;
    }
  }

  &__goals-column {
    word-break: break-word;

    &[rowspan] {
      border-right: 1px solid $background-color;
    }
  }
  &__goals-column, &__countries-column {
    width: $offer-condition-goals-column-width;
  }
  &__to-partner-column, &__from-advertiser-column, &__partners-column {
    width: $offer-condition-reward-column-width;
  }

  &__from-advertiser-column {
    width: calc(100% - #{$offer-condition-goals-column-width * 2} - #{$offer-condition-reward-column-width})
  }

  &__partners-column {
    width: calc(100% - #{$offer-condition-goals-column-width * 2} - #{$offer-condition-reward-column-width * 2})
  }

  &--individual {
    .conditions-table__from-advertiser-column {
      width: $offer-condition-reward-column-width;
    }
  }

  &__partners-column {
    &[rowspan] {
      border-left: 1px solid $background-color;
    }
  }

  &__goal {
    &--qualification {
      color: $success;
    }
    &--formula {
      color: $info;
    }
  }

  &__goals-column {
    white-space: pre;
  }

  .individual-badge {
    //color: $base-dark;
    //background: rgba(168, 176, 198, 0.15);
    text-transform: uppercase;
    font-size: 10px;
    line-height: 16px;
  }

  tbody:hover {
    .conditions-table__goals-column {
      &[rowspan] {
        border-right: 1px solid $white;
      }
    }

    .conditions-table__partners-column {
      &[rowspan] {
        border-left: 1px solid $white;
      }
    }

    tr:not(:last-child) td:not(.conditions-table__goals-column) {
      border-bottom: 1px solid $white;
    }
  }

    &__empty {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      align-self: center;
      color: $text-gray;

      a, .link {
        margin-left: 5px;
      }
    }

  &__partners {
    width: fit-content;
    font-size: 12px;
    line-height: 16px;
    padding: 0 4px;

    &:hover {
      background: #E1E5ED;
      border-radius: 10px;
    }
  }
}

.offers-table--partner {
  .conditions-table {
    &__to-partner-column {
      width: auto !important;
    }
  }
}

.offer-create-button {
  a {
    display: flex;
    align-items: center;
    padding: 0;
    font-size: 13px;

    &:not(:last-child) {
      margin-right: 16px;
    }
  }
}

.offer-access-requests-link {
  &_total {
    position: relative;
    min-width: 20px;
    height: 22px;
    line-height: 22px;
    margin-left: 4px;
  }
}

.offer-access-requests-page {
  .offer-partners-request__actions {
    width: auto;
  }

  &__table-actions {
    width: 250px;
  }
}

.offers-table__offer-dropdown-popover {
  z-index: 90;

  .offers-table__offer-dropdown {
    .offers-table__dropdown-item {
      .button__icon {
        margin-right: 8px;
      }
    }
  }
}

@include tabletLayout() {
  .offers-page {
    width: max(min(100%, 100dvw), #{$mobile-min-width});
    max-width: max(min(100%, 100dvw), #{$mobile-min-width});

    .filters-button-container {
      margin-right: 0;
      margin-left: 0;
      padding: 8px 12px;
      display: flex;
      justify-content: flex-end;
    }

    .offer-page__filter {
      display: none;
    }

    .offers-table {
      width: 100%;
      max-width: 100%;

      &__offer {
        width: 100%;
      }

      &__offer-row {
        width: 100%;
      }

      &__card-column {
        padding: 16px 12px 12px 12px;
        overflow: hidden;
      }

      &__conditions-table {
        width: 100%;
        margin: 0;
        max-width: 100%;
        table-layout: fixed;
        overflow-wrap: break-word;
      }

      .conditions-table {
        tr:first-child th:first-child, td:first-child:not(.column--not-first) {
          padding-left: 10px;
        }

        &__goals-column {
          white-space: normal;
          width: auto;
        }

        &__to-partner-column {
          width: auto;
          overflow: hidden;

          & > span {
            display: block;
            white-space: normal;
            max-width: 100%;
          }
        }

        &__countries-column {
          width: unset;
          box-sizing: border-box;

          .countries-flags--stacked {
            min-height: 20px;
            margin-left: 4px;

            .more-cell {
              margin-right: 0;
              flex-wrap: nowrap;
              align-items: center;
            }

            .more-cell__more-badge {
              width: 16px;

              &:after {
                display: block;
                content: '...';
                font-size: 18px;
                margin: -11px 0 0 0.5px;
              }
            }

            .more-cell__more-label {
              display: none;
            }
          }
        }

        .country-label {
          &__icon {
            .icon, .icon svg {
              height: 16px;
              width: 16px;
            }
          }
        }

        &__goal-wrapper, &__goal:not(.conditions-table__goal--formula) {
          display: block;
          @include text-overflow-ellipsis();
        }

        &__partners-column {
          width: 90px;
        }
      }

      &__offer-status-inactive {
        left: 4px;
        top: -8px;
      }

      .tabs__nav {
        margin-left: 0;
      }
    }
  }

  .offer-page-filter .filter__fields {
    grid-template-columns: repeat(3, minmax(15%, 1fr));
  }

  &.layout--ru {
    .offers-page .offers-table__offer-status-inactive {
      left: -6px
    }
  }
}

@include mobileLayout(960px) {
  .offer-page-filter .filter__fields {
    grid-template-columns: repeat(2, minmax(15%, 1fr));

    .boolean-filter {
      margin-top: 0;
    }
  }
}

@include mobileLayout(750px) {
  .offers-page {
    .offer-create-button a:not(:last-child) {
      margin-right: 8px;
    }

    .filter-mobile-button {
      width: 100%;
    }

    .filters-button-container {
      justify-content: flex-start;
    }

    .offers-table {
      &__offer {
        &--inactive {
          .offers-table__conditions-column {
            --table-inactive-color: #e9eaf0;
            background: $table-inner-background;

            .tabs__nav {
              background: $table-inner-background;
            }

            .btn-light {
              background: var(--table-inactive-color);
            }

            .tabs-tab-active {
              border: 1px solid var(--table-inactive-color) !important;
              background: var(--table-inactive-color);
            }

            thead tr th {
              background-color: var(--table-inactive-color);
              border-color: var(--table-inactive-color);
              color: $dark-50;
            }

            tbody tr td {
              border-color: var(--table-inactive-color);
            }
          }
        }
      }

      &__offer-row {
        grid-template-columns: 100%;
      }

      &__card-column {
        width: 100%;
      }

      &__conditions-column {
        padding: 0 12px 16px 12px;

        a {
          &:hover {
            text-decoration: none;
          }
        }
      }

      .tabs .tabs-tab {
        padding: 6px 10px;
      }

      .counter {
        margin-left: 0;
      }
    }

    .offer-conditions:not(.offer-conditions--empty) {
      .conditions-table__empty {
        display: none;
      }
    }
  }

  .offer-page-filter .filter__fields {
    display: flex;
    flex-direction: column;
  }
}
