.header {
  width: 100%;
  padding: $header-padding;

  background-color: $header-bg;

  &.header--breadcrumbs.header--title {
    padding: $header-with-breadcrumbs-title-padding;
    min-height: $header-height;
  }
  &.header--breadcrumbs:not(.header--title) {
    padding: $header-with-breadcrumbs-padding;
  }

  &.header--breadcrumbs:not(.header--title) {
    .breadcrumb-trail {
      margin-bottom: 0;
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
    flex-grow: 1;
    font-size: $header-font-size;
  }

  &__page-data {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__user-box-wrapper {
    display: flex;
    align-content: center;
    margin-left: auto;
  }

  &__go-back-link {
    display: flex;
    align-items: center;

    color: #3F4254;

    &:hover {
      color: #3F4254;
      text-decoration: none;
    }
  }

  &__title {
    font-size: 24px;
    line-height: 28px;
    font-weight: 600;
    white-space: nowrap;
  }

  .user-profile-menu {
    position: relative;
  }

  @media (max-width: $mobile-max-width) {
    .header-menu-wrapper {
      display: block;
      position: initial;
      background: none;
      box-shadow: none;
      width: auto;
    }

    .container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
      padding: 0 15px 0 25px;
    }
  }

  &:not(.header--custom) {
    border-bottom: 4px solid $background-color;
  }
}

.page-header {
  &--bb {
    border-bottom: 4px solid $background-color;
  }
}
