#footer {
  width: 100%;
  padding: $footer-padding;
  font-size: $footer-font-size;
  line-height: $footer-line-height;
  display: flex;
  justify-content: center;
  align-items: center;
  position: static;
  flex-wrap: wrap;
  bottom: 0;
  z-index: 2;

  a {
    margin-left: 0.4rem;
  }

  &.conditions-footer {
    margin-bottom: $conditions-footer-height;
  }

  &.errors-footer {
    position: fixed;
  }
}

.footer {
  &__docs {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 4px;

    a {
      color: $text-gray;
      font-weight: 400;
      font-size: 11px;
      line-height: 16px;
      margin-left: 0 !important;
      margin-right: 0 !important;

      &:not(:last-child) {
        margin-right: 8px !important;
      }
    }
  }
}
