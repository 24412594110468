$invoice-form-modal-max-width: 820px;
$invoice-form-modal-max-height: 90vh;
$invoice-form-third-column-width: 120px;
$invoice-form-fourth-column-width: 150px;
//$invoice-form-first-column-width: 260px;
$invoice-form-second-column-width: 180px;

.invoice-form-modal {
  .modal-dialog {
    min-width: $invoice-form-modal-max-width;
    width: $invoice-form-modal-max-width;
    max-width: $invoice-form-modal-max-width;
  }

  .modal-content {
    max-height: $invoice-form-modal-max-height;
  }

  .modal-header {
    padding-bottom: $modal-padding-y;
  }

  .modal-body {
    max-height: calc(#{$invoice-form-modal-max-height} - 110px);
    padding: 0 0 0 0;
  }

  &__title-id {
    display: flex;
    align-items: center;
    font-size: 21px;
    line-height: 20px;

    .status-cell {
      display: flex;
      align-items: center;
      margin-left: 10px;
    }
  }

  &__title-date {
    display: flex;
    font-size: 13px;
    line-height: 20px;
    font-weight: 400;

    .date-label {
      margin-left: 5px;
    }
  }

  &__title-period {
    display: flex;
    font-size: 13px;
    line-height: 20px;
    font-weight: 400;

    .period-label {
      margin-left: 5px;
    }
  }
}

@mixin invoice-column-width($width) {
  width: $width;
  min-width: $width;
  max-width: $width;
}

.invoice-form {
  max-height: calc(#{$invoice-form-modal-max-height} - 110px - #{$modal-padding-y * 2});
  overflow: auto;
  position: relative;
  @include default-scrollbar(6px, 4px, 0.15);

  &--loading {
    min-height: 500px;
  }

  &__header-wrapper {
    background-color: $white;
    position: sticky;
    top: 0;
    z-index: 4;
    padding: 0 $modal-padding-x;
  }

  &__header {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    border: 1px solid $background-color;
    border-left: none;
    border-right: none;
    padding: 16px 0;
  }

  &__header-part {
    line-height: 16px;

    &:not(:first-child) {
      padding-left: 12px;
    }

    &:not(:last-child) {
      padding-right: 12px;
    }

    &:not(:first-child):not(:last-child) {
      border: 1px solid $background-color;
      border-top: none;
      border-bottom: none;
    }
  }

  table {
    table-layout: fixed !important; /* this keeps your columns with at the defined width */
    width: 100%;        /* a width must be specified */

    display: table;     /* required for table-layout to be used
                          (since this is the default value it is normally not necessary;
                          just included for completeness) */
    overflow: hidden;

    th, td {
      padding: 4px 12px;
      padding-left: 12px !important;
      border-right: none;
    }

    tr {
      width: 100%;
    }

    .table__header-cell {
      text-transform: none;
    }

    td, tr:hover td {
      font-size: 12px;
      line-height: 13px;
      border-bottom: 1px solid $background-color;
      border-color: $background-color;
    }
  }

  &__header-part-title {
    font-weight: 600;
  }

  &__header-part-text {
    font-weight: 400;
  }

  &__first-column {
    //@include invoice-column-width($invoice-form-first-column-width);
    word-break: break-word;
  }

  &__second-column {
    @include invoice-column-width($invoice-form-second-column-width);
  }

  &__third-column {
    text-align: right;
    @include invoice-column-width($invoice-form-third-column-width);
  }

  &__second-third-column {
    @include invoice-column-width($invoice-form-third-column-width + $invoice-form-second-column-width);
  }

  &__conversions-first-row td {
    padding-top: 28px !important;
  }

  &__conversions-first-row-title {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    margin-top: -24px;
    margin-bottom: 8px;
  }

  &__fourth-column {
    text-align: right;
    @include invoice-column-width($invoice-form-fourth-column-width);
  }

  &__column {
    padding: 3px 12px;
    &:first-child {
      @include invoice-column-width($invoice-form-second-column-width + $invoice-form-third-column-width);
    }

    &:last-child {
      @include invoice-column-width($invoice-form-fourth-column-width);
      text-align: right;
    }
  }

  &__revshare-conversions {
    thead {
      display: none;
    }
    margin-top: 11px;
  }

  &__corrections {
    margin-top: 11px;
    thead {
      display: none;
    }

    .invoice-form__icon-button {
      margin-top: 4px;
      margin-bottom: 4px;
    }
  }

  &__total {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
  }

  &__total-parts {
    width: 100%;
    margin: 8px 0;
  }

  &__total-parts-row, &__total-amount-row {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    &:not(:last-child) {
      .invoice-form__column {
        border-bottom: 1px solid $background-color;
      }
    }
  }

  &__total-amount {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    font-size: 13px;
  }

  &__total-amount-row {
    .invoice-form__column {
      padding: 8px 12px;
      background-color: $background-color;

      &:last-child {
        font-size: 15px;
      }
    }

    &:not(:last-child) {
      .invoice-form__column {
        border-bottom: 1px solid $gray-semi-dark;
      }
    }
  }

  &__total-amount-usd {
    margin-top: 4px;
  }

  &__comment-block {
    margin: 8px 0;
    padding: 12px;
    border: 1px solid $background-color;
    border-radius: $border-radius;
    line-height: 16px;
    white-space: pre-wrap;

    &-title {
      font-weight: 600;
    }

    &--edit {
      border: 1px solid $primary;
      background: $control-background-color;
    }

    .textarea {
      padding: 0;
      background: none;
      border: none;
    }
  }

  &__comment-block-add {
    font-weight: 600;
    color: $primary;
    cursor: pointer;
  }

  &__comment-block-actions {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 8px;

    .button {
      margin-left: 8px;
    }
  }

  &__icon-button {
    color: $text-gray;
    margin-left: 8px;
    font-size: 16px;
    cursor: pointer;
  }

  &__rates-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  &__rates {
    padding: 8px 12px;
    min-height: 12px;
    font-size: 11px;
    line-height: 12px;
    color: $text-gray;
    font-weight: 400;
    box-sizing: border-box;
    width: #{$invoice-form-second-column-width + $invoice-form-third-column-width + $invoice-form-fourth-column-width};
  }

  &__rates-separator {
    font-size: 12px;
    line-height: 12px;
    height: 12px;
  }

  &__shadow {
    width: 100%;
    padding-bottom: 12px;
    box-shadow: 0px 2px 10px rgba(46, 62, 154, 0.1);
    z-index: 2;
    position: sticky;
    background: $white;
  }

  &__cover {
    position: absolute;
    display: block;
    height: 10px;
    background: $white;
    width: 100%;
    z-index: 3;
  }

  &__overflow {
    height: 100%;
    padding: 10px $modal-padding-x 32px $modal-padding-x;

    .invoice-form__actions {
      margin-top: 0;
      margin-bottom: 12px;
    }
  }

  &__conversions {
    margin-top: 16px;
    margin-bottom: -32px;

    .card-cell__text, .card-cell__text a {
      font-size: 13px;
      line-height: 16px;
      font-weight: 400;
    }

    .card-cell__sub-text {
      font-size: 11px;
      line-height: 16px;
      font-weight: 400;
    }

    .table-footer {
      padding-bottom: 32px;
    }
  }

  &__conversions-id {
    .card-cell__sub-text {
      color: $base-dark;
    }
  }

  &__conversions-amount {
    &--borderless {
      border-right: none;
    }
  }

  .table td.invoice-form__conversions-actions,
  .table th.invoice-form__conversions-actions {
    padding-right: 0 !important;
    padding-left: 0 !important;
    width: 32px;

    .dropdown-toggle {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      color: $text-light-gray;

      svg path {
        fill: $text-light-gray;
      }

      &::after {
        display: none;
      }
    }

    .dropdown.show .dropdown-toggle,
    .dropdown-toggle:hover {
      color: $base-dark;

      svg path {
        fill: $base-dark;
      }
    }

    .dropdown-menu {
      max-height: 42px;
    }
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;

    .invoice-status-actions {
      display: flex;
      align-items: center;

      .button {
        margin-right: 8px;
        color: $base-dark !important;
        min-width: 90px;
      }
    }

    &-right {
      display: flex;
      align-items: flex-end;

      .button, a {
        min-width: 100px;
        width: 100px;

        &:not(:first-child) {
          margin-left: 8px;
        }
      }

      .button, .button:hover, a:hover {
        text-decoration: none;
      }
    }

    &-left {
      width: calc(100% - 130px);

      .invoice-form__comment-block {
        border: none;
        padding: 0;
        margin: 0;

        &-text {
          margin-top: 4px;
        }
      }
    }
  }

  &__make-prepayment {
    display: block;
    width: 100%;
    color: $primary;
    cursor: pointer;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    margin-top: 4px;
  }

  .rc-table-placeholder {
    display: none;
  }
}

.invoice-prepayment-form-modal, .invoice-correction-form-modal {
  .confirm-modal__btn {
    width: 100%;
  }
}

.invoice-prepayment-form, .invoice-correction-form {
  .field:last-child {
    margin-bottom: 0;
  }

  .field__hint {
    font-size: 12px;
    color: $text-gray;
    line-height: 20px;
    margin-top: 0;
  }

  &__help {
    font-size: 12px;
    margin-top: 16px;
  }
}

.invoices-form {
  .qualifications-form__double-field {
    justify-content: flex-start;
  }

  .qualifications-form__first-field {
    height: 16px;
    min-height: 16px;
    margin-bottom: 8px;
  }
}
