.object-card-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  &__image {
    border-radius: $border-radius;
    border: 1px solid rgba(63, 66, 84, 0.05);
  }

  &__text {
    font-size: $base-font-size;
    line-height: $base-line-height;
    text-align: right;
    color: $text-gray;
  }

  &__sub-text {
    font-size: $base-font-size;
    line-height: $base-line-height;
    text-align: right;
    color: $text-gray;
  }

  &__edit-btn,
  &__delete-btn {
    margin-left: auto;
    margin-top: 10px;
  }
}

.object-card__title {
  margin-top: 40px;
  margin-bottom: 10px;
}

.object-card__link {
  display: inline-block;

  margin: 10px 0;

  font-size: 13px;
  line-height: $line-height-base;
  color: $text-gray;
}

.object-card__sub-state-link {
  display: inline-block;

  font-size: 13px;
  line-height: $line-height-base;
  color: $text-gray;
  max-width: 100%;
}

.object-card__link:hover,
.object-card__sub-state-link:hover {
  color: $text-gray;
}
