$columns-visibility-form-padding: 8px 12px;
$columns-visibility-form-width: 220px;
$custom-table-columns-visibility-form-width: 320px;
$columns-visibility-form-height: 370px;

.columns-visibility-button {
  &.button.btn.btn-light-secondary {
    .button__icon {
      font-size: 16px;
    }
  }
}

.columns-visibility-form {
  background: $white;
  padding: 8px 0;
  width: $columns-visibility-form-width;
  box-shadow: $box-shadow;

  &__title {
    font-weight: 600;
    line-height: 16px;
    padding: $columns-visibility-form-padding;
  }

  &__link {
    font-weight: 600;
    line-height: 16px;
    padding: $columns-visibility-form-padding;
  }

  &__columns {
    overflow-y: auto;
    overflow-x: hidden;
    @include default-scrollbar();
    max-height: $columns-visibility-form-height;
  }

  &__link {
    font-weight: 600;
    padding: $columns-visibility-form-padding;
  }

  &__column {
    label {
      padding: $columns-visibility-form-padding;
      cursor: pointer;
      font-size: 13px;
      line-height: 16px;
      display: flex;
      margin-bottom: 0;

      & > span {
        @include text-overflow-ellipsis();
      }
    }

    .checkbox {
      margin-right: 8px;
    }
  }

  &__button {
    padding: 6px 12px;

    .button {
      width: 100%;
    }
  }

  &.custom-statistics-columns-visibility-form {
    width: $custom-table-columns-visibility-form-width;
  }
}

.columns-visibility-dropdown {
  z-index: #{$modal-z-index - 1};
}
