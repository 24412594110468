.main-nav {
  flex-direction: column;
}

.main-nav-item {
  @include default-transition(color background);

  &__icon {
    margin-right: $nav-link-icon-margin-right;
    @include default-transition(color);
    color: $nav-link-icon-color;

    svg [fill] {
      @include default-transition(fill);
      fill: $nav-link-icon-color;
    }
  }

  &__link {
    display: flex;
    align-items: center;

    font-size: $base-font-size;
    line-height: $base-line-height;
    color: $nav-link-color;

    @include default-transition(background-color color);

    &:hover {
      color: $nav-link-hover-color;
    }

    &--active, &.active {
      color: $nav-link-active-color;
      background-color: $nav-link-active-bg;

      .main-nav-item__icon {
        color: $primary;

        svg [fill] {
          fill: $primary;
        }
      }
    }
  }

  &__label {
    font-weight: 400;
  }

  &:hover {
    .main-nav-item__link {
      background-color: $nav-link-hover-bg-color;
    }

    .main-nav-item__icon {
      color: $primary;

      svg [fill] {
        fill: $primary;
      }
    }
  }

  &__dropdown {
    cursor: pointer;
    display: flex;
    height: 100%;
  }

  &__dropdown-item {
    .icon {
      font-size: 24px;
    }
  }

  &__dropdown-icon {
    margin-left: 5px;
    .icon {
      font-size: 16px;
    }
  }

  &__only-dropdown-icon {
    margin: 0;
  }

  &__dropdown-menu {
    .dropdown-item.button {
      padding: 0;

      & > span {
        width: 100%;
      }
    }
  }
}

.main-nav-custom-item {
  .main-nav-item {
    &__label {
      white-space: nowrap;
    }
  }
}

.main-nav-custom-items-container {
  display: flex;
  flex-grow: 1;
  position: relative;

  &__row {
    display: flex;
    position: absolute;
    height: 100%;
  }
}
