$side-menu-fade-transition-duration: 0.3s;

.side-menu {
  display: block;
  min-height: max(100vh, 100%);
  background: $side-menu-background;
  color: $side-menu-color;
  width: $side-menu-width;
  position: fixed;
  z-index: $side-menu-z-index;
  box-shadow: $box-shadow;
  transform: translateX(-120%);
  transition: transform $side-menu-fade-transition-duration ease;

  &--visible {
    transform: translateX(0%);
  }

  &__brand {
    display: flex;
    align-items: center;
    height: $horizontal-menu-height;
    padding: 0 $side-menu-link-padding-x;
    margin-bottom: $side-menu-margin-down;
    width: 100%;
  }

  &__button {
    @extend .horizontal-menu__aside-toggle;
  }

  &__links {
    @include default-scrollbar();
    overflow-y: auto;
    max-height: calc(100vh - #{$side-menu-margin-down} - #{$horizontal-menu-height});
  }

  &__link {
    display: flex;
    width: 100%;
    margin-bottom: $side-menu-margin-down;

    .menu-icon {
      display: none;
    }

    .nav-item {
      width: 100%;
      background: $side-menu-background;

      .nav-link {
        background: $side-menu-background;
        line-height: 20px;
        padding: $side-menu-link-padding-y $side-menu-link-padding-x;
        font-weight: 500;
        color: $side-menu-color;
        cursor: pointer;
        text-decoration: none !important;
      }

      & > .nav-link.active {
        background: $side-menu-background;
        color: $side-menu-color;
      }

      .icon {
        font-size: 10px;
        padding: 3px;
        margin-left: 4px;
        cursor: pointer;
        transform: rotateX(0deg);
        transition: transform 1s ease-in-out;
      }
    }

    &--expanded {
      .nav-item .icon {
        transform: rotateX(180deg);
      }
    }
  }

  &__inner-links {
    padding-left: $side-menu-link-padding-x;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.15s linear;

    &--visible {
      max-height: 150px;
      animation-direction: normal;
    }
  }

  .main-nav-custom-item .main-nav-item__label {
    white-space: wrap;
    word-break: break-word;
  }
}

.side-menu-backdrop {
  position: fixed;
  overflow: hidden;
  width: max(100vw, 100%);
  height: max(100vh, 100%);
  top: 0;
  left: 0;
  background: $side-menu-backdrop-background;
  z-index: $side-menu-backdrop-z-index;
  visibility: hidden;
  opacity: 0;
  transition: opacity $side-menu-fade-transition-duration ease, visibility $side-menu-fade-transition-duration ease;

  &--visible {
    opacity: 0.3;
    visibility: visible;
  }
}
