.radio-group {
  &__label {
    @extend .field__label;
    margin-bottom: 3px;
    word-break: break-word;
  }

  &__error {
    @extend .field__error;
    margin-top: -6px;
    margin-bottom: 6px;
  }

  &__option {
    display: flex;

    & > label {
      display: flex;
      align-items: flex-start;
      cursor: pointer;
      line-height: 16px;
      margin-bottom: 8px;
      word-break: break-word;

      .radio, .checkbox {
        margin-right: 8px;
      }
    }
  }
}
