.tabbed-text {
  &__header {
    display: flex;
    flex-direction: column;
    justify-content: center;

    margin-bottom: 5px;
  }

  &__title {
    margin-bottom: 8px;
  }

  &__tabs {
    display: flex;
    align-items: center;
  }

  &__tab {
    border: none;
    font-size: 13px;
    line-height: 16px;
    padding: 4px 8px;
    margin-right: 4px;
  }
}
