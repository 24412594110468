.lang-switch {
  display: flex;
  align-items: center;
  height: 100%;

  @include default-transition(background-color);

  &.show {
    background-color: $light-primary;
  }

  &.dropdown:hover {
    @media (hover: hover) {
      background-color: $light-primary;
    }
  }

  .icon {
    border-radius: 10px;
  }

  &__icon {
    margin-right: 13px;
    border-radius: 10px;
  }

  &__toggle {
    height: 100%;
    padding: 0 8px;

    &::after {
      display: none;
    }
  }

  &__item.button.dropdown-item {
    display: flex;
    align-items: center;

    .icon {
      margin-right: 10px;
      border-radius: 10px;
    }
  }
}
