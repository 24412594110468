.input-number {
  --suffix-padding: 0px;
  flex-wrap: nowrap;

  &__clear {
    position: absolute;
    right: 10px;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;

    .icon {
      cursor: pointer;
      padding: 8px;
    }
  }

  &.is-invalid {
    input,
    .input-group-text {
      background-color: rgba(246, 78, 96, 0.05) !important;
      border: 1px solid $danger;
      border-radius: 4px;
    }
  }

  & .rc-input-number {
    width: 100%;
  }

  & input {
    @include control();
    padding-right: calc(var(--suffix-padding) + #{$input-btn-padding-x});
  }

  &--clearable input {
    @include control();
    padding-right: calc(1.5rem + 36px);
  }

  &__suffix {
    display: flex;
    align-items: center;
    font-size: 13px;
    line-height: 16px;
    font-weight: 400;
    position: absolute;
    top: calc(#{$input-btn-padding-y} + 1px);
    left: calc(#{$input-btn-padding-x} + 1px);
    height: 0;
    width: 100%;
  }

  &__suffix-offset {
    visibility: hidden;
    opacity: 0;
    font-size: 13px;
    line-height: 16px;
    font-weight: 400;
    height: 0;
    max-width: calc(100% - var(--suffix-padding) - #{$input-btn-padding-x * 2});
  }

  &__suffix-value {
    margin-left: 5px;
    height: 0;
  }
}

.input-number-wrapper {
  position: relative;
}
