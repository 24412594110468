$full-screen-block-z-index: 50;

.full-screen-block {
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  z-index: $full-screen-block-z-index;
  width: 100vw;
  height: 100vh;
  background: $white;

  &--menu {
    height: calc(100vh - #{$horizontal-menu-height + 1px});
    top: calc(#{$horizontal-menu-height} + 1px);
  }
}
