.partners-picker {
  max-height: 60vh;
  height: 60vh;

  &__container {
    display: flex;
    height: 100%;
  }

  &__search {
    padding-right: 30px;
  }

  &__left {
    width: 50%;
    flex-shrink: 0;

    border-right: 1px solid $hover-bg;
  }

  &__modal {
    .modal-dialog {
      max-width: none;
      width: 800px;
    }
  }

  &__partners-list {
    display: flex;
    flex-direction: column;

    padding-right: 20px;
    margin-top: 20px;
    overflow-y: auto;
    height: calc(100% - 53px);

    overflow-y: auto;
    @include default-scrollbar();
  }

  &__partner {
    display: flex;
    align-items: center;

    padding: 10px 20px;
    border-radius: $border-radius;
    cursor: pointer;
    word-break: break-all;
    @include default-transition();

    &:hover {
      background-color: $hover-bg;
    }

    span {
      margin-left: 10px;
    }
  }

  &__right {
    flex-grow: 1;

    padding-left: 35px;
  }

  &__selected-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    margin-top: 20px;
    overflow-y: auto;
    height: calc(100% - 40px);
    @include default-scrollbar();

    .badge {
      height: auto;
      padding: 6px 8px;
      margin-bottom: 10px;
      color: $base-dark;

      white-space: normal;
      word-break: break-all;

      & > span {
        display: flex;
        align-items: center;
      }

      .icon {
        flex-shrink: 0;
        font-size: 10px;
        padding: 5px;
        margin: -5px;
        margin-left: 5px;
      }
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    width: 450px;
    margin-top: 20px;
  }

  .input-select-container {
    .input-select__control {
      cursor: text;
    }

    .input-select__triangle-icon {
      display: none;
    }

    .input-select__menu {
      z-index: 10 !important;
      box-shadow: none;
    }
  }

  .input-select__menu {
    & > .input-select__no-options {
      display: none;
    }
  }

  div.input-select__placeholder--fixed {
    position: absolute;
  }
}

@include tabletLayout() {
  &.partners-picker__modal {
    .modal-dialog {
      max-width: var(--bs-modal-width);
      width: auto;

      @media (min-width: 520px) {
        margin-right: auto;
        margin-left: auto;
      }
    }

    .modal-header {
      padding: $modal-padding-mobile-y $modal-padding-mobile-x 0;

      .modal__close {
        font-size: 14px;
        z-index: unset;
      }

      .modal-title {
        font-size: 18px;
        line-height: 18px;
      }
    }

    .modal-body {
      padding: $modal-padding-mobile-y $modal-padding-mobile-x;
    }

    .modal-footer {
      padding: 0 $modal-padding-mobile-x $modal-padding-mobile-y $modal-padding-mobile-x;
    }

    .modal-body {
      padding-top: 0;
    }

    .modal__close {
      z-index: 10 !important;
    }

    .tabs__nav {
      border-bottom: 0;
      padding: 0;
      width: 100%;
    }

    .tabs-ink-bar {
      display: none !important;
    }

    .tabs__nav-left {
      width: 100%;
    }

    .tabs-nav {
      margin-right: 0;
      width: 100%;
    }

    .tabs-tab {
      width: 50%;
      padding: 0;
      min-height: 34px;
      background: none !important;
    }

    .tabs-tab-btn {
      display: flex;
      width: 100%;
      justify-content: center;
      line-height: 20px;
      padding: 7px 0;
    }

    .tabs-tab-active {
      .tabs-tab-btn {
        border-bottom: 0;
        box-shadow: 0 2px 0 #4971FF;
      }
    }

    .tabs-content-holder {
      margin-top: 12px;
      max-height: calc(100% - 47px);
      overflow-y: auto;
      height: 100%;

      @include default-scrollbar();
    }

    .partners-picker__container {
      display: block;
      width: 100%;

      .tabs {
        height: 100%;
      }
    }

    .partners-picker__right {
      padding-left: 0;
    }

    .partners-picker__right-title {
      display: none;
    }

    .partners-picker__selected-list {
      margin-top: 0;

      .field-array {
        display: flex;
        flex-direction: column;
      }

      .badge {
        display: block;
        width: fit-content;
      }
    }

    .modal-footer {
      .button {
        width: calc(50% - 8px);
        min-height: 40px;
      }
    }
  }
}
