.admin-page {
  &__profile {
    &--disabled {
      & .card-header {
        background-color: #B9BFCF !important;
      }

      & .card-username {
        color: #B9BFCF;
      }
    }
  }
}

.admin-page-table-filter .filter__fields {
  display: grid;
  grid-template-columns: repeat(4, minmax(15%, 1fr));
  grid-gap: 10px;
}
