.dropzone {
  display: inline-block;
  width: auto;
  min-height: 120px;
  min-width: 120px;

  padding: 0;

  background-color: #F3F6F9;
  border-radius: 8px;
  cursor: pointer;

  &__content {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 120px;
    height: 120px;
  }

  &__btn {
    position: absolute;
    top: -10px;
    right: -10px;

    display: flex;
    align-items: center;
    justify-content: center;

    min-width: 24px;
    min-height: 24px;
    max-width: 24px;
    max-height: 24px;

    font-size: 10px;
    color: #9099B1;

    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 50%;

    transition-property: color, background-color, border-color, box-shadow, -webkit-box-shadow;
    transition-duration: 0.15s, 0.15s, 0.15s, 0.15s, 0.15s;
    transition-timing-function: ease, ease, ease, ease, ease;
    transition-delay: 0s, 0s, 0s, 0s, 0s;

    &:hover {
      box-shadow: 0 9px 16px 0 rgba(24, 28, 50, 0.3);
    }

    & > svg {
      z-index: 1;
    }

    &--delete {
      top: auto;
      bottom: -10px;

      font-size: 13px;
    }
  }
}
