@use 'sass:math';

$formula-margin: 4px;
$formula-part-margin: 8px;
$formula-remove-button-margin: 8px;
$formula-remove-button-width: 34px;
$formula-variables-margin: 25px;
$formula-button-height: 28px;
$formula-name-height: 90px;
$formula-product-variables-margin: 16px;
$formula-product-part-height: 76px;
$formula-product-part-max-width: 500px;
$formula-product-part-padding-x: 8px;
$formula-product-part-padding-y: 4px;
$formula-product-width: 200px;
$modal-header-height: 90px;
$modal-footer-height: 65px;
$modal-padding: 32px;

.custom-statistic-column-form-modal, .formula-form-modal {
  .modal-dialog {
    width: calc(100vw - 200px);
    height: calc(100vh - 100px);
    max-width: none;
    max-height: none;

    .modal-content {
      max-height: 100%;
    }

    .modal-body {
      overflow: hidden;
      padding-bottom: 0;
    }

    .modal-footer {
      button:not(:last-child) {
        margin-right: 2rem;
      }
    }
  }
}

.custom-statistic-column-form, .formula-form {
  color: $base-dark;
  max-height: calc(100vh - 100px - #{$modal-header-height} - #{$modal-footer-height});
  overflow: auto;
  padding-bottom: 5px;
  @include default-scrollbar();

  &__name > .field {
    margin-bottom: 0;
  }

  &__hint-field {
    width: calc(100% - #{$formula-remove-button-width} - #{$formula-remove-button-margin});

    .field__error {
      margin-top: 0;
    }
  }

  &__formula {
    display: flex;
    width: 100%;
    flex-wrap: wrap;

    .field__label-container {
      width: 100%;
    }
  }

  &__formula-content {
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  &__formula-field {
    @include control();

    display: inline-flex;
    width: calc(100% - #{$formula-remove-button-width} - #{$formula-remove-button-margin});
    padding: 0 12px;
    flex-wrap: wrap;
    background: #F6F6F9;
  }

  &__formula-part {
    margin: #{math.div($formula-part-margin, 2)} $formula-part-margin #{math.div($formula-part-margin, 2)} 0;
    height: 32px;
    display: flex;
    align-items: center;
  }

  &__formula-button {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 0;
    height: $formula-button-height;
    width: $formula-button-height;
    font-size: 15px;
    border: none;
    border-radius: 4px;

    &--large {
      font-size: 20px;
    }

    & > span {
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      align-self: center;
      justify-self: center;
    }
  }

  &__formula-error {
    margin-top: -10px;
  }

  &__remove-button {
    display: inline-flex;
    margin-left: $formula-remove-button-margin;
    margin-top: 10px;
    color: $background-color;
    font-size: $formula-remove-button-width;
    background: none;
    cursor: pointer;

    #backspace-cross {
      color: #9099B1;
      fill: #9099B1;
    }

    .icon:disabled, .icon.disabled, .icon[disabled] {
      color: #F6F6F9;
      cursor: default;

      #backspace-cross {
        color: #B9BFCF;
        fill: #B9BFCF;
      }
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;
    width: 100%;

    .custom-statistic-column-form__formula-button, .formula-form__formula-button {
      margin: 0 $formula-margin $formula-margin 0;
    }
  }

  &__keyboard {
    display: flex;
    width: 100%;

    .custom-statistic-column-form__formula-button, .formula-form__formula-button {
      margin: 0 1px $formula-margin 0;
      border-radius: 0;

      &:first-child {
        border-radius: 4px 0 0 4px;
      }

      &:last-child {
        border-radius: 0 4px 4px 0;
      }
    }
  }

  &__keyboard-numbers {
    display: flex;
  }

  &__keyboard-actions {
    display: flex;
    margin-left: 16px;
  }

  &__variables {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    position: relative;
    @include default-scrollbar();
  }

  &__products-variables {
    border: 1px solid $background-color;
    border-left: none;
    border-right: none;
    overflow: auto;
    min-height: 200px;
    @include default-scrollbar();

    &--disabled {
      .wizard-step-list,
      .custom-statistic-column-form__variable-group-label, .formula-form__variable-group-label {
        opacity: 0.4;
      }
    }
  }

  &__variable-group-label--disabled {
    opacity: 0.4;
  }

  .field__label {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
  }

  .field__placeholder {
    display: flex;
    align-items: center;
    font-size: 13px;
    line-height: 16px;
    color: $text-light-gray;
    cursor: default;
    padding: 14px 0;
  }

  .variable-count {
    display: inline-block;
    color: #00B4CD;
  }

  .variable-amount {
    display: inline-block;
    color: #CC61FF;
  }

  .wizard {
    width: 100%;
    height: 100%;
    @include text-overflow-ellipsis();

    .wizard-step-list {
      min-height: 100%;
      max-height: 300px;
      overflow: auto;
      @include default-scrollbar();

      &__content {
        padding: $formula-product-variables-margin $formula-product-variables-margin $formula-product-variables-margin 0;
      }
    }

    @media (max-width: 992px) {
      .wizard-step-list__items {
        margin: 0 !important;
      }
    }


    .wizard-step {
      width: $formula-product-width;
      @include text-overflow-ellipsis();

      &__icon {
        display: none;
      }

      &__label {
        display: block;
        font-size: 12px;
        line-height: 16px;
        color: $base-dark;
        font-weight: normal;
        @include text-overflow-ellipsis();
      }

      &__link {
        padding: 2px 4px;

        &.active {
          background-color: $primary;

          .wizard-step__label {
            color: $white;
          }
        }
      }
    }

    .wizard-content {
      height: 100%;
      overflow: auto;
      width: calc(100% - #{$formula-product-variables-margin * 2} - #{$formula-product-width});
      @include default-scrollbar();

      &__title {
        display: none;
      }

      &__container {
        padding: $formula-product-variables-margin 0 0 $formula-product-variables-margin;
      }

      @media (max-width: 992px) {
        width: calc(100% - #{$formula-product-variables-margin * 2} - #{$formula-product-width}) !important;
      }
    }
  }

  &__variable-group {
    width: 100%;
    margin-bottom: 13px;
  }

  &__variable-group-label {
    display: flex;
    font-size: 12px;
    line-height: 16px;
  }

  &__variable-group-value {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  &__variable-group-label-wrapper {
    width: 100%;
    margin-bottom: 3px;
    display: flex;
    align-items: center;
  }

  &__variable-group-help {
    display: flex;
    align-items: center;
    color: $text-muted;
    font-size: 14px;
    margin-left: 5px;
    cursor: pointer;
  }

  &__clicks {
    .custom-statistic-column-form__variable-group-value, .formula-form__variable-group-value {
      flex-direction: row-reverse;
      justify-content: flex-end;
    }
  }

  &__variable-part {
    display: flex;
    flex-direction: column;
    width: 100%;

    &:not(:last-child) {
      margin-bottom: $formula-margin;
    }
  }

  &__variable-part-label {
    margin-bottom: $formula-margin;
  }

  &__variable-part-value {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  &__variable, &__formula-part--variable {
    padding: 6px 8px;
    border-radius: $border-radius;
    background: $background-color;
    font-size: 12px;
    line-height: 16px;
  }

  &__variable {
    margin: 0 $formula-margin $formula-margin 0;
    cursor: pointer;

    &--disabled {
      cursor: default;
      color: rgba(63, 66, 84, 0.3);
    }
  }

  &__formula-part {
  }

  &__formula-part:not(&__formula-part--product) {
    margin: #{math.div(($formula-product-part-height - 20px), 2)} $formula-part-margin;
    margin-left: 0;
  }
  &__formula-part--variable:not(&__formula-part--product):not(&__formula-part--group) {
    font-weight: 600;
    height: 20px;
    box-sizing: content-box;
    padding: $formula-product-part-padding-y $formula-product-part-padding-x;
    margin: #{math.div(($formula-product-part-height - 20px - ($formula-product-part-padding-y * 2)), 2)} $formula-part-margin;
    margin-left: 0;
  }
  &__formula-part--variable.custom-statistic-column-form__formula-part--statuses:not(&__formula-part--group),
  &__formula-part--variable.formula-form__formula-part--statuses:not(&__formula-part--group), {
    font-weight: 600;
    height: 40px;
    box-sizing: content-box;
    padding: 0;
    margin: #{math.div(($formula-product-part-height - 40px), 2)} $formula-part-margin;
    margin-left: 0;
  }
  &__formula-part--variable.custom-statistic-column-form__formula-part--group,
  &__formula-part--variable.formula-form__formula-part--group {
    height: 40px;
    min-height: 40px;
    box-sizing: content-box;
    padding: 0;
    margin: #{math.div(($formula-product-part-height - 40px), 2)} $formula-part-margin;
    margin-left: 0;

    .custom-statistic-column-form__product-formula-part-variable,
    .formula-form__product-formula-part-variable {
      padding: 4px 8px 0 8px;
    }
  }
  &__formula-part--statuses {
    .custom-statistic-column-form__product-formula-part-variable,
    .formula-form__product-formula-part-variable {
      padding: 4px 8px;
    }

    .input-select__multi-value__label, .input-select__placeholder, .input-select__option {
      font-weight: 400;
    }
  }
  &__formula-part--variable.custom-statistic-column-form__formula-part--goal,
  &__formula-part--variable.formula-form__formula-part--goal {
    height: 60px;
    box-sizing: content-box;
    padding: 0;
    margin: #{math.div(($formula-product-part-height - 60px), 2)} $formula-part-margin;
    margin-left: 0;
  }

  .input-select__menu {
    min-width: 170px;
  }

  &__formula-part--variable{
    background: $white;
  }
  &__formula-part--product {
    padding: 0;
    border-radius: $border-radius;
    overflow: hidden;
    min-height: $formula-product-part-height;
    height: $formula-product-part-height;
  }
  &__formula-part--event {
    padding: 0;
    border-radius: $border-radius;
    overflow: hidden;
    min-height: #{$formula-product-part-height - 20px};
    height: #{$formula-product-part-height - 20px};
    margin-top: 10px;
    margin-bottom: 10px;
  }
  &__formula-part--action, &__formula-part--constant {
    font-size: 24px;
    line-height: 20px;
    height: 20px;
  }
  &__formula-part--action {
    font-size: 28px;
  }
  &__product-formula-part-product {
    font-size: 11px;
    background: $primary;
    color: $white;
    font-weight: 600;
    padding: 0 $formula-product-part-padding-x;
    margin-bottom: $formula-product-part-padding-y;
    @include text-overflow-ellipsis();
  }
  &__product-formula-part-variable {
    padding: 0 $formula-product-part-padding-x;
    @include text-overflow-ellipsis();
  }
  &__product-formula-part-model {
    font-weight: 600;
    padding: 0 $formula-product-part-padding-x $formula-product-part-padding-y $formula-product-part-padding-x;
    display: grid;
    align-items: center;
    grid-template-columns: auto auto;

    & > div {
      @include text-overflow-ellipsis();
    }
  }
  &__product-formula-part-status-delimiter {
    display: none;
  }
  &__product-formula-part-status {
    border-radius: $border-radius $border-radius 0 0;
    overflow: hidden;

    .field {
      margin-bottom: 0;
      background: #F6F6F9;
    }

    .field__control, .input-select__control {
      min-height: 20px;
      height: 20px;
      line-height: 16px;
      border: none !important;
    }

    .input-select__value-container {
      padding: 0 6px !important;
    }

    .input-select__indicators {
      min-height: 10px;
      height: 10px;
      align-self: center;
      padding-right: 8px;
    }

    .input-select__triangle-icon {
      margin: 0;
    }

    .input-select__multi-value__remove {
      display: none;
    }

    .input-select__multi-value__label, .input-select__placeholder {
      padding: 0;
      background: none;
      background-color: #F6F6F9;
      font-size: 12px;
      line-height: 16px;
      color: $base-dark;
      margin-left: 2px;
      margin-right: 2px;
    }

    .input-select__multi-value:not(:first-child) {
      .custom-statistic-column-form__product-formula-part-status-delimiter,
      .formula-form__product-formula-part-status-delimiter {
        display: inline-block;
        color: rgb(120, 130, 161);
        margin-right: 4px;
      }
    }

    .input-select__option--is-selected, .input-select__option--is-focused {
      background-color: #f6f6f9 !important;
    }

    .input-select__control--is-focused {
      border: none;
    }
  }

  &__product-formula-part {
    display: flex;
    flex-direction: column;
    height: 100%;
    font-size: 12px;
    line-height: 16px;
    max-width: $formula-product-part-max-width;

    &--error {
      .custom-statistic-column-form__product-formula-part-product {
        background: $danger;
      }
    }
  }

  &__formula-button, &__variable {
    background: $background-color;

    &:not(:disabled):not(.disabled) {
      &:hover, &:focus {
        background: #E1E5ED;
      }
    }

    &--disabled:not(:disabled):not(.disabled) {
      &:hover, &:focus {
        background: rgb(247, 247, 250);
      }
    }

    &:disabled, &.disabled, &--disabled {
      background: rgb(247, 247, 250);
      color: rgb(163, 163, 174);
    }
  }

  &__products-variables {
    &--only {
      .wizard {
        .wizard-step-list {
          display: none;
        }
        .wizard__content {
          width: 100%;
        }
        .wizard-content__container {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }

  &__output-format {
    margin-top: 10px;

    &--columns {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      & > * {
        width: 49%;
      }
    }
  }

  &__output-type {
    .field__control {
      margin-top: 4px;
    }
  }

  &__product-variables {
    margin-bottom: 0;
  }
}

.custom-statistics-column .table-sortable-column__title-text {
  max-width: 150px;
  word-break: break-word;
}

.custom-statistics-column__column-name {
  display: flex;
  align-items: center;

  &-hint {
    display: flex;
    align-items: center;
    max-height: 12px;

    .icon {
      font-size: 12px;
      margin-left: 4px;
    }
  }
}

.custom-statistics-column__group-column {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}


.custom-statistics-column__group-column-title {
  max-width: 150px;
  word-break: break-word;
}

.custom-statistics-column {
  max-width: 400px;
}

.custom-statistic-column-form-modal, .formula-form-modal {
  .modal__title {
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
  }

  .modal__header {
    border-bottom: none;
    padding: $modal-padding $modal-padding 0 $modal-padding;
  }

  .modal__body {
    padding: #{math.div($modal-padding, 2)} $modal-padding 0 $modal-padding;
  }

  .modal__footer {
    padding: #{math.div($modal-padding, 2)} 0 $modal-padding 0;
    margin: 0 $modal-padding 0 $modal-padding;
    border-top-color: $background-color;
  }
}

.custom-statistic-column-form__variable-group-label, .formula-form__variable-group-label {
  display: inline-block;
}
