.popover {
  z-index: #{$modal-z-index + 1};
  --bs-popover-font-size: #{$base-font-size};
}

.modal + .popover {
  z-index: #{$modal-z-index + 1};
}

.popover-arrow-container {
  z-index: #{$modal-z-index - 1};
}

.popover-arrow {
  color: $tooltip-arrow-color;
}

.react-tiny-popover-container {
  z-index: #{$modal-z-index - 1};
}
