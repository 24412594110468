@mixin default-transition($property-name: all, $timing-function: ease) {
  transition-property: $property-name;
  transition-duration: 0.3s;
  transition-timing-function: $timing-function;
}

@mixin text-overflow-ellipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin exact-sizes($width, $height: $width) {
  width: $width;
  height: $height;
  max-width: $width;
  max-height: $height;
  min-width: $width;
  min-height: $height;
}

@mixin default-scrollbar($width: 2px, $border-width: 2px, $opacity: 0.5) {
  &::-webkit-scrollbar {
    max-width: $width + ($border-width * 2);
    max-height: $width + ($border-width * 2);
  }

  &::-webkit-scrollbar-track {
    background: none;
    outline: none;
  }

  &::-webkit-scrollbar-thumb {
    box-shadow: none;
    background: rgba(63, 66, 84, $opacity);
    border: $border-width solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: $width * 2;
  }
}

@mixin default-scrollbar-with-background() {
  &::-webkit-scrollbar {
    max-width: 4px;
    max-height: 8px;
    background: #ececee;
  }

  &::-webkit-scrollbar-track {
    background: none;
    outline: none;
  }

  &::-webkit-scrollbar-thumb {
    box-shadow: none;
    background: #c9cacf;
    background-clip: padding-box;
    border-radius: 4px;
  }
}

@mixin icon-only-button() {
  & > span:not(.button__icon) {
    display: none;
  }

  font-size: 16px;
  padding: 6px;
}

@mixin control() {
  width: 100%;
  height: auto;
  min-height: $control-min-height;

  padding: $control-padding;

  font-size: $control-font-size;
  line-height: $control-line-height;
  color: $control-color;

  border: $control-border;
  border-radius: $control-border-radius;

  background-color: $control-background-color;
  outline: none;

  @include default-transition();

  &:focus {
    background-color: $control-focus-background-color;
    box-shadow: $control-focus-box-shadow;
    border: $control-focus-border;
  }

  &::placeholder {
    color: $control-placeholder-color;
  }

  &.is-invalid {
    background-color: $control-error-background-color;
    background-image: none;
  }

  &.disabled, &:disabled {
    opacity: 1;
    background: $input-disabled-bg;
    color: $control-disabled-color;
  }
}

@mixin tabletLayout() {
  @media (max-width: $tablet-max-width) {
    .layout--adaptive, .modal--adaptive, .drawer--adaptive, .popover--adaptive {
      @content;
    }
  }
}


@mixin mobileLayout($max-width: $mobile-max-width) {
  @media (max-width: $max-width) {
    .layout--adaptive, .modal--adaptive, .drawer--adaptive, .popover--adaptive {
      @content;
    }
  }
}
