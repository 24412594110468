.modal.activity-check-modal {
  .modal-dialog {
    max-width: fit-content !important;
  }

  .modal__header {
    padding: 0;
    z-index: 1;
  }

  .modal__body {
    font-size: 18px;
    font-weight: 500;
  }

  .modal__footer .button {
    width: 100%;
    margin: 0;
    margin-left: 0 !important;
  }
}

@include mobileLayout() {
  &.activity-check-modal {
    .modal-dialog {
      max-width: calc(max($mobile-min-width, 100dvw) - 1rem) !important;
    }
  }
}
