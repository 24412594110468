.rc-collapse {
  border: none;
  background-color: transparent;

  .rc-collapse-item {
    border: none;

    &:not(:last-child) {
      margin-bottom: 1.4rem;
    }
  }
}

.rc-collapse-header {
  padding: 0 !important;

  color: #9099B1 !important;
  outline: none;

  & .text-arrow {
    order: 10;
    margin-left: 10px;


    &::after {
      right: auto;
      top: -5px;
      left: 0;
    }
  }
}

.rc-collapse-expand-icon {
  order: 10
}

.rc-collapse-content {
  padding: 0;

  & > .rc-collapse-content-box {
    margin: 1rem 0;
  }
}

.rc-collapse__arrow {
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4.5px 0 4.5px 6px;
  border-color: transparent transparent transparent #9099B1;

  transition-property: transform;
  transition-duration: 0.3s;
  transition-timing-function: ease;

  .rc-collapse-item-active & {
    transform: rotate(90deg);
  }
}

