$product-variable-color: #CC61FF;
$coefficient-variable-color: #00B4CD;
;

.product-page {
  .filters-button-container {
    display: none;
  }

  .view-form {
    &__card {
      .card-body {
        padding-top: 0;
      }
    }

    &__data {
      border-left: 1px solid $background-color;
      margin: 0 0 20px 0;
    }
  }

  .tabs .tabs__nav {
    padding-top: 0;
  }

  &__is-private-column {
    max-width: 115px;
    width: 115px;
  }

  &__partners-column {
    max-width: 100px;
    width: 100px;
  }

  &__landing-partners {
    width: fit-content;
    font-size: 12px;
    line-height: 16px;
    padding: 0 4px;

    &:hover {
      background: #E1E5ED;
      border-radius: 10px;
    }
  }

  &__landings-table {
    .date-cell {
      width: max-content;
    }

    .landing-id {
      width: max-content;
    }

    .landing-status {
      width: max-content;
      color: #909090;

      &_active {
        color: #10ab87;
      }
    }
  }

  .product-tab-mobile-button {
    display: none;
  }
}

.product-formula-form {
  &__name {
    max-width: 460px;
    margin-bottom: 16px;
  }

  .formula-form__formula-error {
    margin-bottom: 12px;
  }

  .formula-form__formula-part {
    height: 28px !important;
    margin: 8px 8px 8px 0 !important;
    box-sizing: border-box !important;

    &--coefficient {
      background: $coefficient-variable-color;
      color: $white;
    }

    &--goal {
      background: $product-variable-color;
      color: $white;
    }

    &--parentheses {
      margin: 6px 8px 10px 0 !important;
    }
  }

  &__product-variables {
    .formula-form__variable {
      background: $product-variable-color;
      font-weight: 600;
      color: $white;

      &:hover {
        background: darken($product-variable-color, 10%) !important;
      }

      &--disabled {
        opacity: 0.3;
      }
    }
  }

  &__coefficient-variables {
    .formula-form__variable {
      background: $coefficient-variable-color;
      font-weight: 600;
      color: $white;

      &:hover {
        background: darken($coefficient-variable-color, 5%) !important;
      }

      &--disabled {
        opacity: 0.3;
      }
    }
  }

  &__coefficients-add {
    margin-top: 8px;
    margin-bottom: 16px;
  }

  .formula-form__variable-group-label {
    font-weight: 600;
  }

  .formula-form__remove-button {
    margin-top: 6px;
  }

  &__coefficient-actions {
    display: flex;
    align-items: center;
    height: 32px;
    margin-bottom: 8px;
    font-size: 16px;
    color: $text-gray;

    .icon {
      &:not(.disabled):not(:disabled):not([disabled]) {
        cursor: pointer;
      }

      &.disabled, &:disabled, &[disabled] {
        opacity: 0.5;
      }
    }
  }

  &__coefficient {
    display: flex;
    align-items: flex-start;
    width: 100%;

    .field {
      margin-bottom: 8px;
    }

    .field__label {
      font-weight: 400;
    }

    &:not(:first-child) {
      .field__label-container {
        display: none;
      }
    }

    &:first-child {
      .product-formula-form__coefficient-actions {
        margin-top: 16px;
      }
    }
  }

  &__coefficient-name {
    width: 260px;
    margin-right: 16px;
  }

  &__coefficient-value {
    width: 200px;
    margin-right: 16px;
  }
}

.product-admins {
  max-width: 190px;

  .more-cell__label {
    max-width: 100%;
  }

  .more-cell__other {
    max-width: 100%;
  }

  a {
    display: block;
    @include text-overflow-ellipsis();
  }
}

.landing-form {
  .field--control-Checkbox {
    margin-top: -8px;
  }
}

@include tabletLayout() {
  &.landing-form {
    .landing-form__actions {
      justify-content: space-between;

      .button {
        font-weight: 600;
        min-height: 40px;
        width: calc(50% - 8px);
      }
    }
  }

  .product-page {
    width: 100dvw;
    max-width: 100dvw;

    .table tr:first-child th:first-child, .table td:first-child:not(.column--not-first) {
      padding-left: 12px;
    }

    &__offers-table-column {
      word-break: initial !important;
      min-width: 300px;
    }

    &__landings-table {
      td {
        word-break: initial !important;
      }
    }

    &__events, &__goals-table {
      td {
        word-break: keep-all !important;
        white-space: nowrap !important;
      }
    }

    &__goal-name {
      min-width: 300px;
      white-space: initial !important;
    }

    &__url-column {
      min-width: 350px;
    }

    &__formulas-table {
      td {
        word-break: initial !important;
      }

      th:first-child {
        min-width: 200px;
      }

      th:nth-child(2) {
        min-width: 350px;
      }
    }

    .filters-button-container {
      margin-right: 0;
      margin-left: 0;
      padding: 8px 12px;
      display: flex;
      justify-content: flex-end;
    }

    .page-header {
      border-bottom: 2px solid $background-color;
    }

    .view-form {
      width: 100%;
      flex-wrap: nowrap;
      flex-direction: column;
      border-bottom: 0;

      &__card {
        width: 100%;

        .object-card {
          border: none;
          border-bottom: 2px solid $background-color;
          border-radius: 0;

          &__body {
            padding: 8px 12px;
          }

          &-header {
            &__main-info {
              display: flex;
              flex-wrap: wrap;
              width: calc(100% - 134px);
              justify-content: space-between;
              align-items: start;
            }

            &__main-text {

            }

            &__edit-btn {
              margin-top: 0;
            }

            &__text, &__sub-text {
              text-align: start;
              font-size: 12px;
            }
          }

          &__title-block {
            width: 100%;
          }

          &__title {
            margin-top: 12px;
            margin-bottom: 8px;
          }
        }

        .card-list__item {
          margin-bottom: 12px;
        }
      }

      &__data {
        width: 100%;
        border-left: none;
        margin: 0;

        .tabs__nav {
          padding: 8px 12px;
          overflow-x: auto;
          @include default-scrollbar();
        }

        .table {
          width: 100%;
          overflow-x: auto;
        }
      }
    }

    .tabs-tab-btn {
      white-space: nowrap;
      display: flex;
      align-items: center;
    }

    .tabs-nav {
      margin-right: 0;
    }

    .tabs-tab {
      margin-right: 0;

      &:not(:first-child) {
        margin-left: 8px;
      }
    }

    .tabs-ink-bar {
      display: none;
    }

    .tabs-tab-active {
      .product-tab-mobile-button {
        display: inline-flex;
        height: 24px;
        width: 24px;
        color: $white !important;
        background-color: #7A97FF !important;
        border-color: #7A97FF !important;
        border-radius: 2px;
        box-sizing: content-box;
        margin: -5px -5px -5px 8px;

        .button__icon .icon svg {
          font-size: 22px;
          width: 1em !important;
          height: 1em !important;
        }
      }
    }

    .object-card-header__edit-btn,
    .object-card-header__delete-btn {
      padding: 7px;

      .button__icon + span {
        display: none;
      }
    }
  }

  &.formula-form-modal {
    .formula-form {
      max-height: unset;

      &__formula-field {
        background: $background-color;

        .field__placeholder {
          padding: 8px 0;
        }
      }

      &__formula-part {
        height: auto !important;
        margin: 4px 4px 4px 0 !important;

        &--variable {
          padding: 2px 4px;
          line-height: 16px;
        }

        &--constant {
          font-size: 13px;
        }

        &--action {
          font-size: 16px;
        }

        &--goal {
          color: #CC61FF;
          background: #FFFFFF;
          font-weight: 500;
        }

        &--coefficient {
          color: #00B4CD;
          background: #FFFFFF;
          font-weight: 500;
        }
      }

      &__remove-button {
        margin-top: 0;
      }
    }

    .modal-footer {
      margin: 0;
    }

  }
}


@include mobileLayout(750px) {
  .product-page {
    .filter-mobile-button {
      width: 100%;
    }

    .filters-button-container {
      justify-content: flex-start;
    }
  }
}

@include mobileLayout() {
  .product-page {

  }
  &.formula-form-modal {
    .formula-form {
      &__remove-button {
        margin-top: 0;
        margin-left: auto;
        height: 28px;
        display: flex;
        align-items: center;
      }

      &__formula-field {
        width: 100%;
      }

      &__formula-button {
        width: calc(100% / 11 - 1px);
      }

      &__keyboard {
        flex-direction: column;
      }

      &__keyboard-numbers {
        margin-bottom: 0;
      }

      &__keyboard-actions {
        margin-top: 12px;
        margin-left: 0;
      }
    }
  }

  .product-formula-form {
    &__coefficient {
      .field__label-container label {
        height: 32px;
        display: inline-flex;
        align-items: end;
      }

      &:first-child {
        .product-formula-form__coefficient-actions {
          margin-top: 32px;
        }
      }
    }
  }
}
