.custom-text {
  display: flex;
  width: 100%;
  font-size: $base-font-size;
  line-height: $base-line-height;

  &__help {
    font-size: 16px;
    margin-left: 8px;
    align-items: center;
  }

  @each $name, $color in $theme-colors {
    &-#{$name} {
      background: $color;
    }
  }
}
