$comment-color: #628AF1;
$wallet-card-width: 250px;
$wallet-title-height: 65px;
$wallets-list-wallet-icon-width: 35px;
$wallets-list-wallet-margin: 20px;
$wallets-list-wallet-data-margin: 10px;
$wallets-list-wallet-right-width: 20px;
$withdrawal-add-form-radio-width: 20px;

.wallet-page {
  &__withdrawals-header {
    box-sizing: content-box;
    font-weight: 600;
    font-size: 17px;
    line-height: 20px;
    margin: 12px 20px 0 20px;
  }

  .view-form {
    &__card {
      width: $wallet-card-width;
      margin-top: -4px;
    }

    &__data {
      width: calc(100% - #{$wallet-card-width});
      min-height: 100%;
      background: white;
      border-left: 1px solid $background-color;
    }
  }

  .table-widget {
    height: 100%;

    .table {
      min-height: calc(100% - #{$wallet-title-height} - 34px);
    }
  }

  .card {
    border-radius: 0;
  }
}

.withdrawals-table, .invoices-table {
  @media (max-width: $tablet-max-width) {
    .rc-table-content {
      overflow-x: auto;
    }
  }

  tr:not(.rc-table-measure-row) td {
    height: 48px !important;
    box-sizing: border-box !important;
  }

  &__id-column {
    width: 7rem;
    box-sizing: content-box;
    line-height: 16px;

    .date-label {
      font-size: 12px;
    }
  }

  &__id {
    font-weight: $font-weight-light;

    &--gray {
      color: $text-gray;
    }
  }

  &__wallet {
    display: block;
  }

  &__wallet-payment-system {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-right: 50px;
    line-height: 16px;
  }

  &__wallet-fields {
    display: block;
    width: 100%;
    font-weight: 400;
    color: $text-gray;
    line-height: 16px;
  }

  &__comment-column {
    width: 350px;
    max-width: 350px;
  }

  &__comment {
    display: block;
    @include text-overflow-ellipsis();
  }

  &__wallet-payment-system-icon {
    display: flex;
    align-items: center;
    font-size: 16px;
    margin-right: 5px;
  }

  &__wallet-payment-system-name {
    font-weight: $font-weight-bold;
  }

  &__wallet-fields {
    display: block;
    width: 100%;
    color: $text-gray;
    margin-top: 5px;
  }

  &__wallet-number {
    font-weight: $font-weight-light;
    color: $text-gray;
  }

  &__amount {
    font-weight: $font-weight-bold;
  }

  &__period-column {
    display: flex;
    flex-wrap: wrap;
  }

  &__period {
    white-space: nowrap;
  }

  &__status-cell {
    display: flex;
    align-items: center;
  }

  &__status {
    width: 120px;
    margin-right: 10px;
  }

  &__status-action {
    color: $azure;
    font-size: 13px;

    .icon[disabled] {
      color: $text-light-gray;
      cursor: default;
    }

    .withdrawals-table__remove {
      cursor: pointer;
      font-size: 16px;
    }
  }

  .invoices-table--partner {
    .invoices-table__status-column {
      .badge {
        height: 20px;
        font-size: 11px;
      }
    }
  }

  .date-label {
    font-weight: $font-weight-light;
  }

  &--partner {
    .invoices-table__comment-column {
      width: 600px;
      max-width: 600px;
    }
  }
}

.wallet-card {
  border: none;

  &__header {
    background-color: $base-dark !important;
  }

  &__divider {
    width: 100%;
    margin: 30px 0;
    border-top: 1px solid rgba(239, 241, 245, 0.1);
  }

  &__empty-wallets {
    text-align: center;
    font-weight: normal;
    color: $text-gray;
    padding: 0 0.75rem;
    line-height: $base-line-height;
  }

  &__balance-icon {
    display: flex;
    height: 100%;
    align-items: center;
    margin-right: 1.5rem;
    width: 24px;
  }

  &__balance {
    display: flex;
    flex-direction: column;
  }

  &__balance-info {
    display: flex;
    flex-direction: column;
  }

  &__balance-title {
    font-size: $base-font-size;
    line-height: $base-line-height;
  }

  &__balance-amount {
    font-size: 20px;
    line-height: 30px;
    font-weight: $font-weight-bold;
  }

  &__balances {
    margin: 8px;
    display: none;

    &--opened {
      display: block;
    }
  }

  &__currency-balance {
    display: flex;
    justify-content: space-between;
    padding: 8px 0;
    border-radius: $border-radius;
    cursor: pointer;

    &--border:not(&--opened) {
      &:not(:hover):not(:first-child) {
        border-top: 1px solid #F6F6F9;
      }

      &:not(:hover):last-child {
        border-bottom: 1px solid #F6F6F9;
      }

      &:hover:not(:first-child) {
        border-top: 1px solid transparent;
        border-bottom: none;
      }

      &:hover:last-child {
        border-bottom: 1px solid transparent;
      }
    }

    &:not(&--opened) {
      &:hover {
        padding: 8px 12px;
        background: $light-primary;
      }

      &:not(:hover) {
        .wallet-card__currency-balance-left .icon {
          display: none;
        }

        margin: 0 12px;
      }
    }

    &--opened {
      padding: 8px 12px;
      margin: 0;
      background: #F6F6F9;
      cursor: auto;
      border-bottom: none;

      &:not(:first-child) {
        border-top: 1px solid transparent;
      }

      &:not(:last-child) {
        margin-bottom: 8px;
      }

      .wallet-card__currency-balance-left {
        .icon {
          transform: rotateX(180deg);
        }
      }
    }
  }

  &__currency-balance-left {
    display: flex;
    cursor: pointer;
    font-weight: 600;
    line-height: 16px;
    height: max-content;

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 16px;
      height: 16px;
      font-size: 10px;
      margin-left: 4px;
      transform: rotateX(0deg);

      @include default-transition(transform);
    }
  }

  &__currency-balance-right {
    width: 140px;
    position: relative;
  }

  &__currency-balance-data-short {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    line-height: 16px;
  }

  &__currency-balance-data-full {
    width: 100%;
  }

  &__currency-balance-data-block {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &__currency-balance-data-title {
    font-size: 13px;
    line-height: 16px;
  }

  &__currency-balance-data-value {
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
  }

  &__currency-balance-available {
    .wallet-card__currency-balance-data-value {
      margin-top: 4px;
      font-size: 21px;
      line-height: 24px;
    }
  }

  &__currency-balance-hold {
    color: $text-gray;
  }

  &__add-button {
    position: relative;
    margin-top: 8px;

    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1rem;
      font-weight: $font-weight-bold;

      &.disabled, &:disabled {
        color: $text-light-gray;
        background-color: rgba(185, 191, 207, 0.25);
        border-color: rgba(185, 191, 207, 0);
      }
    }
  }

  &__add-button-option {
    display: flex;
    align-items: center;
    cursor: pointer !important;
    width: 100%;
  }

  &__add-button-option-icon {
    margin-right: 1rem;
    display: flex;
    align-items: center;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  &__add-button-option-label {
    margin-bottom: 0;
    color: $base-dark;
  }

  &__wallets-list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__wallets-list-title {
    font-size: 1.3rem;
    line-height: 2rem;
    font-weight: $font-weight-bold;
  }

  &__wallets-list-add-button {
    margin: 0 20px 30px 20px;
  }

  &__wallet {
    margin-top: 2rem;
  }

  &__payment-method {
    display: flex;
    width: 100%;
    align-items: center;
  }

  &__payment-method-icon {
    display: flex;
    align-items: center;
    margin-right: 1.3rem;

    svg {
      height: 24px;
      width: 24px;
    }
  }

  &__payment-method-name {
    font-size: $base-font-size;
    line-height: $base-line-height;
    font-weight: $font-weight-bold;
  }

  &__payment-method-wallets {
    width: calc(100% + 2px);
    margin: -1px;
    padding-top: 5px;
    margin-bottom: 15px;
    max-height: 395px;
    overflow: auto;

    @include default-scrollbar(4px, 5px);
  }

  &__payment-method-wallet {
    display: block;
    width: calc(100% - #{$wallets-list-wallet-margin * 2});
    padding: 8px 0;
    margin: 0 20px;
    border-bottom: 1px solid #EFF1F5;
    position: relative;
  }

  &__payment-method-wallet-default {
    display: flex;
    width: 100%;
    margin-bottom: 3px;

    .badge {
      padding: 0 5px;
      font-size: 10px;
      font-weight: 600;
      line-height: 16px;
      height: 16px;
      text-transform: uppercase;
      cursor: default;
    }
  }

  &__payment-method-wallet-first-line {
    display: flex;
    width: 100%;
    align-items: center;
    font-size: 13px;
    line-height: 16px;
  }

  &__payment-method-wallet-currency {
    padding-left: 8px;
    font-weight: $font-weight-normal;
  }

  &__payment-method-wallet-data {
    display: flex;
    flex-direction: row;
    margin-right: $wallets-list-wallet-data-margin;
    width: calc(100% - #{$wallets-list-wallet-right-width + $wallets-list-wallet-data-margin});
  }

  &__payment-method-wallet-title {
    font-weight: $font-weight-bold;
  }

  &__payment-method-wallet-number {
    font-weight: $font-weight-light;
    color: $dark-50;
    margin-top: 3px;
  }

  &__payment-method-wallet-right {
    display: flex;
    width: $wallets-list-wallet-right-width;
    margin-right: -10px;
  }

  &__fields {
    display: flex;
    min-width: 100%;
    width: 100%;
    margin-top: 2px;
    color: $text-gray;

    font-size: 13px;
    line-height: 16px;
  }

  &__wallets-list-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 12px 20px 8px 20px;
  }

  &__wallets-list-title-text {
    font-weight: $font-weight-bold;
    font-size: 15px;
    line-height: 16px;
  }

  &__wallets-list-title-button {
    .button {
      width: 24px;
      height: 24px;
    }
  }

  &__payment-method-wallet-actions {
    position: relative;
    margin-left: 5px;

    .dropdown {
      height: 100%;

      > .dropdown-toggle {
        button {
          display: flex;
          align-items: center;
          font-size: 15px;
          height: 20px;
          padding: 0 5px;
          width: 20px;

          &::after {
            display: none;
          }

          svg path {
            fill: $text-light-gray;
          }

          &:hover {
            svg path {
              fill: $base-dark;
            }
          }
        }
      }
    }

    .dropdown-menu.show {
      opacity: 1 !important;
    }
  }
}

.wallet-add-form {
  &__wallet {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 1.5rem;
  }

  &__wallet-icon {
    margin-right: 8px;
    display: flex;
    align-items: center;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  &__wallet-label {
    margin-bottom: 0;
  }
}

.withdrawal-form {
  &__modal {
    .modal-dialog {
      max-width: 340px;
    }

    .modal__body {
      padding-bottom: 32px;
    }
  }

  .wallet-fields {
    color: $base-dark;
  }

  &__amount {
    margin-top: 4px;
  }

  &__payment-system {
    font-size: 15px;
    line-height: 20px;
  }

  &__error {
    margin-top: 20px;
  }

  .button {
    margin-top: 8px;
  }
}

.wallets-card {
  margin-top: 1px;

  .wallet-card__body {
    padding: 0;
  }

  &__empty-data-block {
    margin: 30px 0 20px 0;
  }
}

.wallet-form-modal {
  .modal__body {
    padding-top: 16px;
  }
}

.wallet-form {
  .field:not(:last-child) {
    margin-bottom: 16px;
  }
}

.payments-table {
  overflow-x: auto;

  .payments-actions {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 1rem;
  }
}

.currency-space {
  white-space: normal;
}
