.day-picker {
  &__menu {
    width: 220px;
    padding: 16px;
    box-sizing: content-box;
    display: block;
    background: $white;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
  }

  &__days {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 4px;
  }

  &__day {
    display: flex;
    width: 20px;
    height: 20px;
    justify-content: center;
    align-content: center;
    box-sizing: content-box;
    border-radius: $border-radius;
    padding: 2px;
    cursor: pointer;
    border: 2px solid $white;

    &:focus, &:hover {
      border-color: $primary;
    }

    &--selected {
      color: $white;
      background: $primary;
      border-color: $primary;
    }
  }

  &__help {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    margin-top: 8px;
  }

  &__toggle-input {
    .input-select__placeholder {
      color: $base-dark;
    }
  }
}
