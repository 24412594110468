.empty-data-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  &__main-icon {
    color: #cccccc;
    font-size: 60px;
  }

  &__icon {
    display: flex;
    position: relative;
    width: fit-content;

    &--with-ellipse {
      .empty-data-block__main-icon {
        display: flex;
        position: absolute;
        top: 0;
        left: 16px;
        color: $text-gray;
        opacity: 0.3;
      }
    }

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &__ellipse {
    display: inline-flex;
    color: $background-color;
    font-size: 100px;
  }

  &__texts {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__text {
    font-size: 13px;
    line-height: 25px;
    font-weight: 600;
    text-align: center;
    word-break: break-word;
  }

  &__subtext {
    font-weight: 400;
    margin-top: 8px;
    line-height: 16px;
    text-align: center;
  }
}
