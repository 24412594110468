.icon {
  display: inline-flex;
  overflow: hidden;
  vertical-align: middle;

  &[disabled] {
    color: $text-light-gray;
  }

  svg {
    width: 1em;
    height: 1em;
  }

  @each $name, $color in $colors {
    &--#{$name} {
      color: $color;

      svg * {
        fill: currentColor;
      }
    }
    &__stroke--#{$name} svg * {
      stroke: $color;
    }
  }

  &--currentColor svg * {
    fill: currentColor;
  }

  &--none svg * {
    fill: none;
  }

  &__stroke--none svg * {
    stroke: none;
  }
}
