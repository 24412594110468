.card {
  &__header {
    padding: $card-cap-padding;

    font-size: 20px;
    line-height: 25px;
    font-weight: 600;

    border-color: $card-cap-border-color;
  }

  & > .widget-layout {
    & > .loading {
      margin: -1px 0 0 -1px;
      width: calc(100% + 3px);
      height: calc(100% + 3px);
      border: 1px solid rgba(255,255,255,0);
      border-radius: $border-radius;
    }
  }
}

.card-list {
  & > a {
    text-decoration: none;

    &:hover {
      .card-list__item-value {
        text-decoration: underline;
      }
    }
  }

  &__item {
    display: flex;
    justify-content: space-between;

    margin-bottom: 1.25rem;

    &-label {
      width: min-content;
      font-size: $font-size-base;
      line-height: $line-height-base;
      color: $base-dark;
    }

    &-separator {
      flex-grow: 1;
      align-self: end;

      min-width: 20px;
      height: 1px;
      margin: auto 10px 5px 10px;

      border: 1px dashed $hover-bg;
    }

    &-value {
      align-self: end;
      font-size: $font-size-base;
      line-height: $line-height-base;
      color: $text-muted;
    }
  }
}

@include tabletLayout() {
  .card {
    --bs-card-spacer-y: 12px;
    --bs-card-spacer-x: 12px;
  }
}
