.dropdown-block {
  display: flex;
  flex-direction: column;

  &__title {
    color: $primary;
    border-bottom: 1px dashed;
    cursor: pointer;
    display: inline-flex;
    width: fit-content;
    margin-bottom: 1rem;
  }

  &__content {
    display: block;
    width: 100%;
  }

  &--opened {
    .dropdown-block__title {
      color: $text-gray;
    }
  }
}
