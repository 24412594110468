$preview-card-padding: 16px;
$preview-card-poster-width: 120px;
$preview-card-actions-width: 25px;
$preview-card-actions-margin: 10px;
$preview-cards-filter-actions-width: 300px;

.preview-cards {
  background: $white;
  padding: 16px 20px;

  &:not(:last-child) {
    border-bottom: 4px solid $background-color;
  }

  &__label {
    font-size: 19px;
    line-height: 24px;
    font-weight: 600;
    margin-bottom: 12px;
  }

  &__cards {
    display: grid;
    grid-template-columns: repeat(4, minmax(15%, 1fr));
    grid-gap: 16px;

    @media (max-width: 1500px) {
      grid-template-columns: repeat(3, minmax(10%, 1fr));
    }
  }
}

.preview-card {
  display: flex;
  padding: $preview-card-padding;
  border: 1px solid $background-color;
  border-radius: $border-radius;
  @include default-transition(box-shadow);
  transition-duration: 0.15s;

  &:hover {
    @media (hover: hover) {
      box-shadow: 0 2px 10px rgba(46, 62, 154, 0.1);
    }
  }

  &--clickable {
    cursor: pointer;
  }

  &__left {
    margin-right: $preview-card-padding;
  }

  &__poster {
    width: $preview-card-poster-width;
    height: $preview-card-poster-width;
    color: $white;
    object-fit: contain;

    &--placeholder {
      .symbol {
        object-fit: contain;
        background: $background-color;
      }
    }

    .symbol img {
      object-fit: contain;
    }
  }

  &__right {
    width: calc(100% - #{$preview-card-padding + $preview-card-poster-width});
    height: 100%;
  }

  &__title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    width: 100%;
  }

  &__name {
    display: block;
    font-size: 17px;
    line-height: 24px;
    font-weight: 600;
    max-width: calc(100% - #{$preview-card-actions-width + $preview-card-actions-margin});
    @include text-overflow-ellipsis();

    &:last-child {
      max-width: 100%;
    }

    a, &--link {
      &:not(:hover) {
        color: $base-dark;
      }
    }

    a, &--link {
      &:hover {
        @media (hover: hover) {
          color: $primary;
        }
        @media (hover: none) {
          color: $base-dark;
        }
        text-decoration: none;
        cursor: pointer;
      }
    }
  }

  &__actions {
    width: $preview-card-actions-width;
    height: $preview-card-actions-width;
    margin-left: $preview-card-actions-margin;
  }

  &__dropdown {
    &.dropdown {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &.dropdown {
      .dropdown-toggle.button {
        padding: 5px 6px;
        margin: -2px;

        .icon {
          font-size: 15px;

          path {
            fill: $text-light-gray !important;
          }
        }
      }

      &:hover, &.show {
        .dropdown-toggle.button {
          background: $background-color;

          .icon {
            path {
              fill: $base-dark !important;
            }
          }
        }
      }
    }

    .dropdown-menu {
      .dropdown-item {
        .icon {
          margin-right: 9px;
        }
      }
    }
  }

  &__description {
    line-height: 16px;
    word-break: break-word;
  }
}

@include tabletLayout() {
  .preview-cards {
    padding: 16px 12px;

    &__cards {
      grid-template-columns: repeat(2, minmax(10%, 1fr));
      grid-gap: 8px;
    }

    &__label {
      padding-left: 8px;
    }

    &:not(:last-child) {
      border-bottom: 2px solid $background-color;
    }

    .preview-card {
      padding: 12px;

      &__poster {
        width: 77px;
        height: 77px;

        .symbol {
          height: 100%;
          width: 100%;
        }
      }

      &__left {
        margin-right: 12px;
      }

      &__right {
        width: calc(100% - 89px);
      }
    }
  }
}

@include mobileLayout() {
  .preview-cards {
    &__cards {
      grid-template-columns: repeat(1, minmax(10%, 1fr));
    }
  }
}
