.formula-label {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 13px;
  line-height: 16px;

  &__part {
    display: inline-flex;
    vertical-align: center;

    &--action {
      font-size: 1.38em;
      margin: 0 0.22em;
      height: 0.88em;
      max-height: 0.88em;
    }

    &--parentheses {
      font-size: 1.23em;
      margin: -1px 1px 0;
      height: 1em;
      max-height: 1em;
    }

    &--unary {
      margin: 0;
    }

    &--goal {
      color: #CC61FF;
      min-height: 1.23em;
    }

    &--coefficient {
      color: #00B4CD;
      min-height: 1.23em;
    }
  }
}

.tooltip {
  .formula-label {
    font-size: 12px;
  }
}
