$card-width: 382px;
$form-spacing: 4px;

.form {
  height: 100%;
}

.view-form {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  border-bottom: $form-spacing solid $background-color;

  &__card {
    width: $card-width;
  }

  &__data {
    width: calc(100% - #{$card-width});
    border-left: $form-spacing solid $background-color;

    & > .card {
      border: none;
      border-radius: 0;
    }
  }
}

