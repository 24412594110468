html {
  font-size: $base-font-size;
  line-height: $base-line-height;

  @include default-scrollbar();
}

body {
  background-color: $body-bg;

  a {
    text-decoration: none;
  }
}
