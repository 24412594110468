.input-file {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  padding: 10px;

  &__field {
    display: flex;
    flex-wrap: nowrap;
    position: relative;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &__field-left {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    align-items: center;
    width: 100%;
  }

  &__field-button {
    color: $primary;
    font-weight: 600;
    line-height: 16px;
    padding: 8px 12px;
    cursor: pointer;

    .icon {
      font-size: 16px;
      margin-right: 8px;
    }
  }

  &__field-error {
    color: $danger;
  }

  &__preview {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__preview-icon {
    display: flex;
    max-width: 40px;
    max-height: 40px;
    font-size: 40px;
    margin: -4px 8px -4px 0;
  }

  &__preview-name {
    display: block;
    width: calc(100% - 70px);
    font-size: 13px;
    line-height: 16px;
    font-weight: 600;
    @include text-overflow-ellipsis();
  }

  &__preview-actions {
    width: 20px;
    height: 20px;
  }

  &__preview-action {
    padding: 2px;
    display: flex;
    font-size: 16px;
    cursor: pointer;
  }

  &__dropzone {
    display: none;
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background: $light-primary;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    @include default-transition(opacity);

    &-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px;
      background: $primary;
      border-radius: 50%;
      color: $white;
      font-size: 16px;
      width: 16px;
      height: 16px;
      box-sizing: content-box;
    }

    &-label {
      line-height: 16px;
      font-weight: 600;
      color: $primary;
      margin-left: 8px;
      text-align: center;
    }
  }

  &--drag {
    .input-file__dropzone {
      display: flex;
      opacity: 1;
    }
  }
}
