.two-factor-auth {
  .modal-dialog {
    width: 310px;
  }

  .modal__title {
    text-align: center;
    width: 100%;
  }

  &__hint {
    margin-bottom: 8px;
    text-align: center;
  }

  &__input {
    margin-bottom: 0;

    .field__control {
      margin-bottom: 4px;
    }

    .rc-input-number-input-wrap {
      display: flex;
      justify-content: center;
    }

    .rc-input-number-input {
      height: 60px;
      padding: 14px;
      font-size: 28px;
      line-height: 30px;
      letter-spacing: 10px;
      text-align: center;
    }
  }

  .field__error {
    text-align: center;
  }

  &__timer {
    display: flex;
    justify-content: center;
  }

  &__timer-text {
    text-align: center;
    font-size: 13px;
    line-height: 16px;
    color: $text-gray;
    padding: 0 30px;
  }
}
