$partner-referrals-rows: 4;

.partner-profile {
  &__offers {
    width: 100%;
  }
}

.partner-page {
  &:not(.partner-page--old) {
    background: $background-color;
    padding: 20px;
    width: 100%;
  }

  .profile-page__moderation-panel {
    border-radius: 5px;
    margin-bottom: 10px;
    margin-top: -14px;
    border-bottom: none;
    background-color: #FFD335;

    .profile-page__moderation-actions {
      display: flex;
      flex-direction: row;

      & > button {
        font-weight: 600;

        &.profile-page__activate_button {
          background-color: white;
          color: $primary;
          border-color: transparent;

          &:hover:not(.btn-text):not(:disabled):not(.disabled) {
            background-color: $light-primary;
            color: $primary;
            border-color: transparent;
          }
        }

        &.profile-page__reject_button {
          background-color: transparent;
          color: $base-dark;
          border-color: $base-dark;

          &:hover:not(.btn-text):not(:disabled):not(.disabled) {
            background-color: rgba(255, 255, 255, 0.15);
          }
        }

        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }

  &__wallets, &__postbacks, &__invoices {
    .table {
      max-height: 200px;
      overflow-y: auto;
      overflow-x: hidden;
      @include default-scrollbar();

      td {
        vertical-align: top;
      }
    }
  }

  &__invoices {
    .table {
      max-height: 232px;
    }
  }

  &__postbacks {
    .table {
      max-height: 180px;
    }
  }

  &__wallet-currency-column {
    width: 120px;
    max-width: 120px;
  }

  &__actions-column {
    width: 50px;
    max-width: 50px;
  }

  &__actions {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: flex-start;
  }

  &__balance {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    &--3 {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  &__balance-block {
    display: grid;
    grid-template-rows: max-content max-content;
    padding: 0 20px;

    &:not(:last-child) {
      border-right: 1px solid $background-color;
    }

    &--loading {
      .partner-page__balance-block-value {
        width: 100%;
      }

      .react-loading-skeleton {
        border-radius: $border-radius;
      }

      &:nth-child(1) {
        .partner-page__balance-block-value .react-loading-skeleton {
          width: 70%;
        }
      }

      &:nth-child(2) {
        .partner-page__balance-block-value .react-loading-skeleton {
          width: 50%;
        }
      }

      &:nth-child(3) {
        .partner-page__balance-block-value .react-loading-skeleton {
          width: 90%;
        }
      }

      &:nth-child(4) {
        .partner-page__balance-block-value .react-loading-skeleton {
          width: 30%;
        }
      }

      &:nth-child(5) {
        .partner-page__balance-block-value .react-loading-skeleton {
          width: 60%;
        }
      }
    }
  }

  &__balance-block-title {
    display: flex;
    width: 100%;
    flex-wrap: wrap;

    font-size: 15px;
    line-height: 20px;
    font-weight: 600;

    .icon {
      font-size: 20px;
      margin-left: 6px;
    }
  }

  &__balance-block-main-title {
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    position: relative;

    .icon {
      position: absolute;
      right: -26px;
    }
  }

  &__balance-block-subtitle {
    width: 100%;
    font-size: $base-font-size;
    line-height: 20px;
    font-weight: 400;
    color: #6E7A83;
    margin-bottom: 10px;
  }

  &__balance-block-values {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    min-width: 100%;
    position: relative;
    grid-gap: 8px;
  }

  &__balance-block-value {
    display: inline-block;
    font-size: 18px;
    line-height: 18px;
    font-weight: 600;

    span {
      white-space: break-spaces;
      word-break: break-word;
    }
  }
  &__referrals {
    max-height: calc(#{36px * $partner-referrals-rows} + 20px);
    overflow-y: auto;
    overflow-x: hidden;
    @include default-scrollbar();
  }
}

.partner-card {
  &__card-title {
    padding-bottom: 16px;
  }

  &__manager {
    line-height: 21px;
    padding: 2px 0;
    display: block;

    a {
      display: block;
      @include text-overflow-ellipsis();
    }
  }

  &__note {
    label {
      display: block;
      cursor: pointer;
      color: $text-light-gray;
    }
  }

  &__note-content {
    overflow: hidden;
    white-space: normal;
    word-break: break-word;
  }

  .tags-view-field {
    .more-cell {
      grid-gap: 6px;
    }

    .badge {
      @extend .view-form-new__badge;
      @extend .view-form-new__info-badge;
      margin: 0;
      margin-right: 0 !important;
      margin-bottom: 0 !important;
    }

    .more-cell__more-badge {
      @extend .badge-light-primary;

      b {
        font-weight: 400;
        padding: 0 !important;
      }
    }
  }

  .profile-resend-email__button {
    width: 100%;

    .button__icon {
      display: none;

      & + span {
        margin-left: 0;
      }
    }
  }
}
