$vertical-tabs-width: 200px;

.tab-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  &__icons {
    position: relative;
    font-size: 16px;
    height: 16px;
    display: flex;

    .icon {
      svg {
        height: 16px;
        width: 16px;
      }

      &:not(:last-child) {
        margin-right: 11px;
      }

      &:last-child {
        margin-right: 7px;
      }
    }

    .alert-icon {
      color: $danger !important;

      &:before {
        content: '';
        top: -6px;
        left: -15px;
        height: 28px;
        width: 4px;
        background: $danger;
        display: block;
        position: absolute;
        border-radius: 4px 0px 0px 4px;
      }
    }
  }

  &__text {
    margin-right: 7px;
    z-index: 2;
  }

  &__counter {
    background-color: $white;
    border-radius: 8px;
    color: $base-dark;
    padding: 0 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    z-index: 2;

    @include default-transition(color background);
  }
}

@mixin tabs-buttons-style() {
  .tabs__nav {
    min-height: auto;
    padding: 12px 20px;
  }

  .tabs-tab {
    padding: $tab-button-padding-md;
    min-height: $tab-button-height-md;
    line-height: $tab-button-line-height;
    border-radius: $border-radius;
    margin: 0 8px 0 0;

    div.tabs-tab-btn {
      padding: 0;
      font-weight: 600;
      border-bottom: none;
    }

    .icon {
      font-size: 16px;

      svg {
        width: 16px;
        height: 16px;
      }
    }

    &:not(.tabs-tab-active) {
      color: $base-dark;

      .icon {
        color: $text-gray;
      }

      .tab-label {
        &__counter {
          background: $white;
        }
      }

      &:hover {
        @media (hover: hover) {
          color: $base-dark;
          background: $light-primary;
        }
      }
    }

    &.tabs-tab-active:hover {
      @media (hover: hover) {
        background: $primary;
      }
    }
  }

  &.tabs--lg {
    .tabs-tab {
      padding: $tab-button-padding-lg;
      min-height: $tab-button-height-lg;
    }
  }
  &.tabs--sm {
    .tabs-tab {
      padding: $tab-button-padding-sm;
      min-height: $tab-button-height-sm;
    }
  }
}

.tabs {
  &__nav {
    display: flex;
    align-items: center;
    padding: 0 20px;
    background-color: white;
    min-height: $tab-height;
    border-bottom: $tab-border;
  }

  &__nav-left {
    display: flex;
    flex-direction: row;
  }

  &__nav-left-extra {
    display: flex;
    align-items: center;
    padding-right: 20px;

    .header {
      padding: 0;
    }
  }

  &--border-top {
    border-top: 1px solid #EBEDF3;
  }

  &__extra {
    margin-left: auto;
  }

  &-tabpane {
    &:focus {
      outline: none;
    }
  }

  &-nav {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-right: 30px;
    height: 100%;
    align-items: center;

    &-list {
      display: flex;
      position: relative;
      transition: transform 0.3s;
    }
  }

  &-tab {
    display: flex;
    align-items: center;
    min-height: $tab-height;
    cursor: pointer;
    position: relative;
    font-style: normal;
    color: $tab-color;
    font-weight: $tab-font-weight;
    font-size: $tab-font-size;
    line-height: $tab-line-height;
    margin: $tab-margin;

    &:first-child {
      margin-left: 0;
    }

    &-active {
      color: $tab-active-color;
      border-bottom: $tab-active-border-bottom;
    }

    &-active {
      .tab-label {
        .alert-icon {
          display: none;
        }

        &__counter {
          color: $tab-active-color;
          background: $white;
        }
      }
    }

    &-btn {
      padding: $tab-padding;
      font-weight: $tab-font-weight;

      &:focus {
        outline: none;
      }
    }
  }

  &--width-min {
    .tabs-tab {
      margin: 0 12px;
    }

    .tabs-tab-btn {
      width: min-content;
      text-align: center;
    }
  }

  .conversion_settings_tab {
    width: 105px;
  }

  & .tabs-nav-operations-hidden {
    display: none;
  }

  & .tabs-nav-wrap {
    flex-grow: 1;
  }

  &--buttons {
    .tabs__nav {
      padding: 0 30px 20px 30px;
      height: auto;
    }

    .tabs-tab {
      padding: 8px 15px;
      background: $background-color;
      border-radius: 5px;
      color: $text-gray;
      margin: 0 10px 0 0;
      @include default-transition(color background);

      &-active {
        background: $tab-active-color;
        border-bottom: none;
        color: $white;
      }

      &:not(.tabs-tab-active):hover {
        @media (hover: hover) {
          color: $primary;
        }
      }
    }
  }

  &--buttons {
    @include tabs-buttons-style();
  }

  &--buttons-icon-title {
    @extend .tabs--buttons;

    .tabs-tab {
      padding: $tab-button-title-padding-md;
      min-height: $tab-button-title-height;
      line-height: $tab-button-title-line-height;
      margin: 0 10px 0 0;

      .icon {
        font-size: 20px;

        svg {
          width: 20px;
          height: 20px;
        }
      }

      .tab-label {
        &__counter {
          padding: 0 6px;
          color: $base-dark;
        }
      }
    }

    &.tabs--lg {
      .tabs-tab {
        padding: $tab-button-title-padding-lg;
      }
    }
    &.tabs--sm {
      .tabs-tab {
        padding: $tab-button-title-padding-sm;
      }
    }
  }

  &--buttons-group {
    @extend .tabs--buttons;

    .tabs-tab-btn {
      padding: 0;
    }

    .tabs-tab {
      margin: 0;
      border-radius: 0;
      padding: $tab-button-title-padding-md;

      &:first-child {
        border-top-left-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
      }

      &:last-child {
        border-top-right-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
      }

      &:not(:first-child) {
        .alert-icon {
          &:before {
            border-radius: 0;
            display: none;
          }
        }
      }
    }

    &.tabs--lg {
      .tabs-tab {
        padding: $tab-button-title-padding-lg;
      }
    }
    &.tabs--sm {
      .tabs-tab {
        padding: $tab-button-title-padding-sm;
      }
    }
    &.tabs--md {
      .tabs-tab-active {
        .tab-label--error {
          .tab-label__counter {
            color: $danger;
          }

          &::after {
            z-index: 1;
            display: block;
            position: absolute;
            content: '';
            width: calc(100% + 32px);
            height: calc(100% + 12px);
            left: -16px;
            top: -6px;
            background: $danger;
          }
        }

        &:first-child {
          .tab-label--error {
            &::after {
              border-top-left-radius: 4px;
              border-bottom-left-radius: 4px;
            }
          }
        }
      }
    }

    .tabs-ink-bar {
      position: relative;
      width: 1px !important;
      left: 0 !important;

      &::before {
        content: '';
        display: block;
        height: 100%;
        width: 4px;
        border-top-right-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
        background: $background-color;
        position: absolute;
        left: -2px;
        @include default-transition(background);
      }
    }
    .tabs-tab-active + .tabs-ink-bar {
      &::before {
        background: $primary;
      }
    }
    .tabs-tab:not(.tabs-tab-active):hover + .tabs-ink-bar {
      &::before {
        @media (hover: hover) {
          background: $light-primary;
        }
      }
    }

    .tabs-tab:not(.tabs-tab-active) + .tabs-tab:not(.tabs-tab-active) {
      border-left: 1px solid $white;
    }
    .tabs-tab:not(.tabs-tab-active) + .tabs-tab.tabs-tab-active {
      border-left: 1px solid $primary;
    }
    .tabs-tab.tabs-tab-active + .tabs-tab:not(.tabs-tab-active) {
      border-left: 1px solid $primary;
    }
  }

  &--active-tab-error.tabs--buttons-group {
    .tabs-tab-active + .tabs-ink-bar::before {
      background: $danger;
      left: -1px
    }
  }

  &--tabs {
    .tabs-tab {
      margin: 0;
      padding: 0 12px;


      &:hover {
        @media (hover: hover) {
          background: $light-primary;
        }
      }

      &-btn {

      }
    }

    .tabs-tab-active {
      border-bottom: none;

      .tabs-tab-btn {
        display: flex;
        align-items: center;
        height: 100%;
        border-bottom: 2px solid $primary;
      }
    }
  }


  &--header {
    .tabs__nav {
      padding: 0 20px 16px;
      border-bottom: 4px solid $background-color;
    }
  }
}

.tabs-nav-more {
  display: none;
}

.tabs-left {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  //max-height: calc(100vh - var(--page-offset-top) - 30px);
  //min-height: calc(100vh - var(--page-offset-top) - 30px);

  & > .tabs__nav {
    width: $vertical-tabs-width;
    padding: 0;
    border-bottom: 0;
    border-right: 1px solid $background-color;
    display: flex;
    flex-direction: column;
    //overflow-y: auto;
    overflow-x: hidden;

    & > .tabs__nav-left {
      & > .tabs-nav {
        display: flex;
        flex-direction: column;
        margin-right: 0;

        & > .tabs-nav-wrap {
          display: flex;
          flex-direction: column;

          & > .tabs-nav-list {
            display: flex;
            flex-direction: column;

            & > .tabs-tab {
              width: calc(#{$vertical-tabs-width} - 5px);
              padding: 0 20px;

              & > .tabs-tab-btn {
                padding: 12px 0;
                width: 100%;
                text-align: left;
                font-weight: 500;
                font-size: 13px;
                line-height: 16px;
              }

              &.tabs-tab-active {
                border-left: 2px solid $primary;

                & > .tabs-tab-btn {
                  border-bottom: none;
                }
              }
            }
          }
        }
      }
    }
  }

  & > .tabs-content-holder {
    width: calc(100% - #{$vertical-tabs-width});
    overflow-y: auto;
  }
}

.tabs--mobile.tabs--mobile-inline {
  & > .tabs__nav {
    .tabs__select {
      @extend .input-select--inline-header;

      .input-select__value-container {
        .input-select__single-value {
          @extend .header__title;
        }
      }

      .input-select__menu-portal {
        z-index: #{$modal-z-index - 10};
      }

      .input-select__triangle-icon {
        width: 12px;
        height: 7px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
      }
    }
  }
}

.tabs--mobile:not(.tabs--mobile-inline) {
  & > .tabs__nav {
    .tabs__select {
      .input-select__menu {
        width: max(100%, 250px);
      }
    }
  }
}

.tabs__select-menu {
  min-width: 200px;
}
