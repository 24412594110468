.input-text {
  @include control();

  &.input-text-imitation {
    cursor: pointer;

    &.input-text--focused {
      border: $control-focus-border;
    }
  }
}

.input-text__wrapper {
  width: 100%;
  height: auto;
  position: relative;

  .input-text--clearable {
    padding-right: calc(1.5rem + 36px);
  }

  .input-text__clear {
    position: absolute;
    right: 10px;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;

    .icon {
      cursor: pointer;
      padding: 8px;
    }
  }
}

@mixin input-text-floating-label-padding($padding-left) {
  .input-text__floating-label {
    left: $padding-left;
  }

  .input-text--floating-label {
    padding-left: calc(#{$padding-left} - 2px);
  }

  .input-text--floating-label:not(:placeholder-shown) {
    padding-left: $padding-left;
  }
}

.input-text--floating-label::placeholder {
  visibility: hidden;
  opacity: 0.01;
}

.input-text--floating-label {
  padding-top: 15px;
  padding-bottom: 15px;
  @include default-transition((padding-top, padding-bottom, line-height, padding-left), ease-in-out);
  transition-duration: 0.1s;
}

.input-text--floating-label:not(:placeholder-shown) {
  padding-top: 23px;
  padding-bottom: 7px;
  line-height: 16px;
}

.input-text__floating-label {
  position: absolute;
  color: $text-gray;
  @include default-transition((top, font-size, line-height), ease-in-out);
  transition-duration: 0.1s;
  cursor: text;
}

.input-text:placeholder-shown ~ .input-text__floating-label {
  top: 14px;
  font-size: 13px;
  line-height: 20px;
}

.input-text:not(:placeholder-shown) ~ .input-text__floating-label {
  top: 8px;
  font-size: 11px;
  line-height: 16px;
}

@include input-text-floating-label-padding($input-btn-padding-x);

.input-group:not(.has-validation) > .input-text__wrapper:not(:last-child) > .form-control,{
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-text__wrapper {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
