@font-face {
  font-family: "Montserrat";
  src: url('../fonts/Montserrat/Montserrat-Regular.ttf') format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url('../fonts/Montserrat/Montserrat-Medium.ttf') format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url('../fonts/Montserrat/Montserrat-SemiBold.ttf') format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url('../fonts/Montserrat/Montserrat-Bold.ttf') format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "JetBrainsMono";
  src: url('../fonts/JetBrainsMono/fonts/ttf/JetBrainsMono-Light.ttf') format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "JetBrainsMono";
  src: url('../fonts/JetBrainsMono/fonts/ttf/JetBrainsMono-Regular.ttf') format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "JetBrainsMono";
  src: url('../fonts/JetBrainsMono/fonts/ttf/JetBrainsMono-Bold.ttf') format("truetype");
  font-weight: 600;
  font-style: normal;
}

.monospace {
  font-family: monospace;
}

.monospace-field {
  .field__control {
    @extend .monospace;
  }
}
