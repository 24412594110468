@keyframes fade-animation {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes slide-down {
  from {
    transform: translate(0, -50px);
  }

  to {
    transform: none;
  }
}

@keyframes slide-up {
  from {
    transform: translate(0, 50px);
  }

  to {
    transform: none;
  }
}


@keyframes drawer-slide {
  from {
    transform: translate3d(500px, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}
