.image-viewer {
  opacity: 1 !important;

  .react-viewer-mask {
    @include default-transition(opacity);
    opacity: 0;
  }

  &--visible {

    .react-viewer-mask {
      opacity: 1;
    }
  }
}
