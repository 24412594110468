.tracking-link {
  &__title {
    margin-top: 32px;

    font-size: $base-font-size;
    line-height: $base-line-height;
    font-weight: 600;
    color: $base-dark;
  }

  &__landings {
    &-item {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      padding: 7px 0;

      border-bottom: 1px solid #EFF1F5;
    }
  }

  &__landing-name {
    display: flex;
    align-items: center;
    padding: $input-btn-padding-y 0;
    max-width: calc(100% - 100px);

    .checkbox {
      width: auto;
      max-width: 100%;
      height: auto;

      .form-check-label {
        max-width: 100%;
        overflow: hidden;
        min-height: 22px;
        word-break: break-word;
        margin-bottom: 0;
      }
    }
  }

  a.tracking-link__landing-button {
    text-decoration: none;
  }

  &__add-sub-id {
    margin-top: 30px;
  }

  &__sub-id-control {
    padding: 20px 0;

    border-bottom: 1px solid $background-color;
  }

  &__sub-id-input {
    width: 100%;
    height: 35px;
  }

  &__sub-id:last-child {
    .tracking-link__sub-id-control {
      border-bottom: none;
    }
  }

  &__drawer {
    & .drawer__header {
      box-shadow: 0 10px 30px rgba(63, 66, 84, 0.1);
    }
  }
}
