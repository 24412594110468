.group-append {
  flex-wrap: nowrap;
  & > .input-number {
    width: 1%;
  }

  .input-number {
    flex: 1 1 auto;
    min-width: 0;

    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    input {
      position: relative;

      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .input-number, .input-text__wrapper {
    input {
      width: calc(100% - 1px);
    }
  }

  .input-number-wrapper {
    width: 100%;
  }

  .field--has-error & {
    .input-group-text {
      background-color: $control-error-background-color;
      border: solid 1px $danger;
      border-left: 0;
    }
  }

  &--field {
    .input-group-text {
      padding: 0;
      border: none;
    }

    .field {
      margin-bottom: 0;
    }

    .rc-input-number {
      margin-right: 1px;
    }
  }
}

.field--has-error {
  .group-append {
    &--field {
      .input-select__control {
        background: none;
      }
    }
  }
}

.input-group-text {
  min-height: $control-min-height;
  line-height: $control-line-height;
  border: none;

  .field {
    margin-bottom: 0 !important;

    .input-select__value-container {
      padding: 0;
      padding-left: 10px;
      min-width: 50px;
    }

    .input-select__single-value {
      margin: 0;
    }

    .input-select__control {
      display: flex;
      flex-wrap: nowrap;
      background: $background-color;
      border-radius: 0 $border-radius $border-radius 0;
    }

    .input-select__indicators {
      padding-right: 0;
    }
  }
}
