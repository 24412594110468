div.input-select__control {
  @include control();
}

.input-select {

  &__option-content {
    .field__label {
      font-size: 13px;
      line-height: 19px;
    }

    .field__label-container {
      font-size: 13px;
      line-height: 19px;
    }
  }

  .control.is-invalid &__control {
    border: 1px solid $danger !important;
    background-color: $control-error-background-color;
  }

  &__menu {
    z-index: $input-select-menu-z-index !important;
  }

  &__menu-list-select-all {
    border-bottom: 1px solid $background-color;
  }

  &__search-icon {
    margin: 0 10px;
  }

  &__triangle-icon {
    background-image: url(../../icons/angle-down.svg);
    width: 10px;
    height: 6px;
    margin: 0 10px;
  }

  &__no-options {
    color: rgb(153, 153, 153);
    padding: 8px 12px;
    line-height: 16px;
    text-align: center;
    box-sizing: border-box;
  }

  &__option {
    &--is-selected {
      color: $base-dark !important;
      background-color: $input-select-option-selected-background-color;
    }

    &--is-focused, &:hover {
      color: $base-dark !important;
      background-color: $input-select-option-focused-background-color;
    }

    &--is-disabled {
      color: $dark-50;

      .field__label {
        color: $dark-50;
      }
    }

    &-content {
      display: flex;
      align-items: center;
      max-width: calc(100% - 5px);

      .checkbox {
        margin-right: 12px;
      }

      &-label {
        display: block;
        width: calc(100% - 5px);
        white-space: nowrap;
        @include text-overflow-ellipsis();
      }
    }

    &:active {
      background-color: #F3F6F9 !important;
    }

    .country-label {
      display: flex;
      @include text-overflow-ellipsis();

      &__icon {
        display: inline-flex;
        align-self: center;
      }
    }
  }

  &__next-page-button {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 8px 12px;
  }

  &__menu-list {
    &:not(&--is-multi) {
      .input-select__option {
        &--is-selected {
          box-shadow: $input-select-option-selected-box-shadow;
        }
      }
    }

    &--is-multi {
      &__option-content-label {
        display: block;
        width: calc(100% - 40px);
        @include text-overflow-ellipsis();
      }
    }
  }

  &__multi-container {
    .badge {
      margin-bottom: 10px;
    }
  }

  &__checkbox-option.select__option {
    label {
      margin-bottom: 0;
    }
  }

  &__select-any, &__select-all {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding: 8px 12px;
    cursor: default;
    border-bottom: 1px solid $background-color;

    &:hover {
      background-color: rgb(243, 246, 249) !important;
    }
  }

  &__add-option-error {
    margin-bottom: 5px;
  }

  &__add-option-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: sticky;
    top: 0;
    left: 0;
    padding: 8px 12px;

    button {
      display: flex;
      justify-content: center;
      width: 100%;

      span {
        text-align: center;
        @include text-overflow-ellipsis();
      }
    }
  }

  &--white.input-select__control, &--white .input-select__control {
    background-color: $white;
  }

  &__group-heading {
    font-size: 13px;
    line-height: 16px;
    font-weight: 600;
    color: $base-dark;
    padding: 8px 12px;
  }

  &__option-content-label {
    line-height: 19px;
  }

  &__group-heading-content-label {
    min-height: 19px;
  }

  &__menu-list--selectable-group {
    .input-select__group-heading {
      font-weight: 400;
    }

    .input-select__group {
      .input-select__option {
        &:not(.input-select__group-heading) {
          padding-left: 36px;
        }
      }
    }
  }
}

@mixin input-select-floating-label-padding($padding-left) {
  .input-select__floating-label {
    left: $padding-left;
  }

  .input-select--floating-label {
    .input-select__value-container {
      padding-left: $padding-left;
    }
  }
}

.input-select--floating-label {
  .input-select__value-container {
    display: flex;
    min-height: 46px;
    padding-top: 14px;
    padding-bottom: 14px;
    @include default-transition((padding-top, padding-bottom, line-height, padding-left), ease-in-out);
    transition-duration: 0.1s;
  }

  .input-select__input, .input-select__placeholder, .input-select__single-value {
    position: absolute;
    top: 14px;
    left: $input-btn-padding-x;
    max-width: calc(100% - 20px);
    overflow: hidden;
  }

  .input-select__input {
    margin: 0;
  }

  input.input-select__input {
    top: 0;
    left: 0;
    max-width: 100%;
  }

  .input-select__placeholder, .input-select__single-value {
    margin-left: 0;
  }

  .input-select__control--is-focused .input-select__value-container, .input-select__control--has-value .input-select__value-container {
    padding-top: 23px;
    padding-bottom: 7px;
    line-height: 16px;

    .input-select__input {
      top: 23px;
    }

    .input-select__single-value {
      top: auto;
      left: auto;
    }

    .input-select__placeholder {
      top: 31px;
    }
  }
}

.input-select__floating-label {
  position: absolute;
  color: $text-gray;
  @include default-transition((top, font-size, line-height), ease-in-out);
  transition-duration: 0.1s;
  cursor: text;
  margin-bottom: 0;
}

.input-select__control--is-focused, .input-select__control--has-value {
  .input-select__floating-label {
    top: 8px;
    font-size: 11px;
    line-height: 16px;
  }
}

.input-select__control:not(.input-select__control--is-focused) {
  .input-select__value-container:not(.input-select__control--has-value) {
    .input-select__floating-label {
      top: 14px;
      font-size: 13px;
      line-height: 20px;
    }
  }
}

@include input-select-floating-label-padding($input-btn-padding-x);

div.input-select__control {
  padding: 0;
  min-height: $control-min-height;
  border-radius: $control-border-radius;

  &--is-disabled {
    .input-select__triangle-icon {
      opacity: 0.25;
    }
  }

  &--is-focused {
    border: $control-focus-border;
  }
}

.input-select-container {
  position: relative;
}

.input-select__preview {
  margin-top: 5px;

  .badge {
    padding: 6px 8px;
    font-size: 13px;
    line-height: 16px;
    height: 28px;
    max-width: 100%;
    background-color: $control-background-color !important;

    &.more-label {
      background: none;
      background-color: transparent !important;

      b {
        font-weight: 400;
      }
    }
  }
}

.input-select__value-preview {
  display: flex;
  align-items: center;
  max-width: 100%;
  width: 100%;
  max-height: 100%;
  height: 100%;
}

.input-select__value-preview-label {
  display: block;
  width: calc(100% - 21px);
  @include text-overflow-ellipsis();

  .react-loading-skeleton {
    min-width: 80px;
    border-radius: $border-radius;
  }

  .field__label {
    font-size: 13px;
    line-height: 16px;
  }
}

.input-select__value-preview-remove {
  margin: -3px 0 -3px 6px;
  padding: 3px;
  color: $text-gray;
  font-size: 10px;

  &:hover {
    color: $base-dark;
    cursor: pointer;
  }
}

div.input-select__placeholder {
  color: $control-placeholder-color;
  grid-area: 1/1/2/3;
  margin-left: 2px;
  margin-right: 2px;
  box-sizing: border-box;

  &--fixed {
    position: fixed;
    margin-top: -8px;
  }

  &--currentColor {
    color: $control-color;
  }
}

div.input-select__value-container {
  padding-left: $input-btn-padding-x;
}

div.input-select__indicators {
  padding-right: 3px;
  cursor: pointer;
}

.input-select__indicator ~ .input-select__triangle-icon {
  margin-left: 5px;
}

div.input-select__clear-indicator {
  color: $base-dark;

  &:hover {
    color: inherit;
  }
}

div.input-select__multi-value {
  background: $background-color;
  border-radius: 4px;

  &__label {
    padding: 8px;
  }

  &__remove {
    color: $text-gray;
    font-size: 10px;
    padding-right: 8px;

    &:hover {
      background: #EFF1F5;
      color: currentColor;
      cursor: pointer;
    }
  }
}

div.input-select__single-value--is-disabled {
  color: $control-disabled-color;
}

div.input-select__menu {
  box-shadow: $dropdown-box-shadow;
  border-radius: $control-border-radius;
}

div.input-select__menu-list {
  @include default-scrollbar();
}

.input-select--new-style {
  .input-select {
    &__control {
      min-height: 24px;
    }

    &__value-container {
      padding: 0;
      padding-left: 10px;
    }

    &__indicators {
      padding-right: 6px;
    }
  }
}

.input-select--inline-header {
  .input-select__control, &.input-select__control {
    &, &:focus, &:active, &:hover {
      background: none;
      border: 1px solid transparent;
      min-height: 16px;
    }
  }

  .input-select__single-value {
    margin: 0;
  }

  .input-select__input {
    opacity: 0;
  }

  .input-select__value-container {
    padding: 0;
  }

  .input-select__menu {
    min-width: 200px;
  }
}

.layout > .input-select__menu-portal {
  z-index: #{$modal-z-index + 1};
}
