$product-logo-width: 70px;
$product-logo-border-width: 1px;
$product-logo-margin: 13px;
$product-card-left-width: #{$product-logo-width + $product-logo-margin};

.products-page__note {
  width: 500px;
  max-width: 500px;
}

.products-page-filters .filter__fields {
  display: grid;
  grid-template-columns: repeat(5, minmax(10%, 1fr));
  grid-gap: 10px;
}

.products-page {
  .filters-button-container {
    display: none;
  }

  .product-admins {
    width: 200px;
  }

  .products-table {
    &__card-column {
      width: 100%;
      padding: 12px 20px;
      vertical-align: top;
    }

    &__product {
      &:not(:last-child) {
        border-bottom: 4px solid $background-color;
      }
    }

    &__product-card {
      position: relative;
      min-height: 100px;
    }

    &__product-card-header {
      display: flex;
      width: 100%;
    }

    &__product-logo {
      .symbol {
        border: $product-logo-border-width solid rgba(63, 66, 84, 0.05);
        border-radius: $border-radius;
      }
      margin-right: $product-logo-margin;
    }

    &__product-main-info {
      width: calc(100% - #{$product-card-left-width});
    }

    &__product-first-line {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      grid-gap: 4px;
    }

    &__product-status {
      display: inline-flex;
      height: 16px;

      .badge {
        font-size: 10px;
        line-height: 16px;
        padding: 0 4px;
        border-radius: 2px;
        height: 16px;
        font-weight: 600;
      }
    }

    &__product-id {
      display: flex;
      font-size: 11px;
      line-height: 16px;
      font-weight: 400;
      margin-right: 4px;
    }

    &__product-name {
      font-size: 19px;
      line-height: 20px;
      margin: 8px 0;
      font-weight: 600;
      word-break: break-word;

      a {
        color: $base-dark;
        text-decoration: none;

        &:hover {
          color: $primary;
          text-decoration: none;
        }
      }
    }

    &__note {
      label {
        display: block;
        cursor: pointer;
        color: $text-gray;
        margin-bottom: 0;
        margin-top: 8px;
      }
    }

    &__note-content {
      overflow: hidden;
      white-space: pre-line;
      word-break: break-word;

      &:not(&--full) {
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &__product-info {
      width: 100%;
      padding: 12px 0 0 0;

      .titled-text {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 4px;
        width: 100%;

        .more-cell.more-cell--expandable-dropdown {
          .more-cell__label {
            display: inline-flex;
            align-items: center;
          }

          .dropdown-toggle {
            .button {
              color: $primary;
              background-color: $light-primary;
              font-weight: 600;
              padding: 2px 4px;
              height: 20px;

              b {
                font-weight: 600;
                padding: 0 !important;
              }
            }
          }
        }

        &__title {
          width: $product-logo-width;
          font-size: 11px;
          line-height: 16px;
          font-weight: 400;
          color: $text-gray;
          margin-bottom: 0;

          & > span:first-child {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
            display: block;
            min-width: 100%;
          }
        }

        &__text {
          line-height: 16px;
          width: calc(100% - #{$product-card-left-width});
        }
      }
    }
  }
}

@include tabletLayout() {
  .products-table__admin {
    margin-bottom: 4px;
  }

  .products-page {
    width: max(min(100%, 100dvw), #{$mobile-min-width});
    max-width: max(min(100%, 100dvw), #{$mobile-min-width});

    .filters-button-container {
      margin-right: 0;
      margin-left: 0;
      padding: 8px 12px;
      display: flex;
      justify-content: flex-end;
    }

    .products-page__filters {
      display: none;
    }

    .products-table {
      width: 100%;
      max-width: 100%;

      &__product {
        width: 100%;
        border-bottom-width: 2px;
      }

      &__product-row {
        width: 100%;
      }

      &__card-column {
        padding: 12px;
        overflow: hidden;
      }

      &__product-info {
        .more-cell {
          width: 100%;
          max-width: unset;

          &--expandable-dropdown-exist {

            .more-cell__label {
              max-width: calc(100% - 40px);
            }
          }
        }
      }
    }
  }

  .products-page-filters .filter__fields {
    grid-template-columns: repeat(3, minmax(15%, 1fr));
  }
}

@include mobileLayout(750px) {
  .products-page {
    .filter-mobile-button {
      width: 100%;
    }

    .filters-button-container {
      justify-content: flex-start;
    }
  }

  .products-page-filters .filter__fields {
    display: flex;
    flex-direction: column;
  }
}
