.switch {
  display: inline-flex;
  align-items: center;

  font-size: 1rem;
  line-height: 16px;
  color: $base-dark;

  border-radius: 15px;

  &--with-bg {
    font-size: 13px;
    line-height: 16px;
    padding: 4px 8px;
    border-radius: $border-radius;

    .custom-switch, .form-switch {
      height: 16px;
      min-height: 16px;
    }

    .custom-control-input {
      height: 16px;
    }

    .custom-control-label {
      &::before {
        top: 1.5px;
      }
      &::after {
        top: 3.5px;
      }
    }
  }


  .custom-switch, .form-switch {
    padding-left: 2em;
  }

  .form-check-input {
    margin-top: 2px;
    margin-left: -2.4em;
    cursor: pointer;
  }

  &--disabled {
    opacity: 0.5;
  }

  &:not(.switch--with-bg) {
    font-size: 14px;

    .form-check-input {
      margin-top: 1px;
      margin-left: -2em;
    }

    .switch__label {
      padding-left: 1rem;
    }

    .custom-control-label {
      &::before {
        width: 2.5rem;
        height: 1.5rem;
        top: 0;
        left: calc(-2.5rem + 4px);
        border-radius: 1rem;
      }
      &::after {
        top: calc(0.25rem - 1px);
        left: calc(-2.5rem + 8px);
        height: calc(1.5rem - 6px);
        width: calc(1.5rem - 6px);
        border-radius: 1rem;
      }
    }
  }

  &--lg:not(.switch--with-bg) {
    font-size: 16px;
  }

  &--xl:not(.switch--with-bg) {
    font-size: 20px;
  }

  .switch__label {
    font-size: 1rem;
  }

  .custom-control-label {
    &::before {
      cursor: pointer;
      border-color: #cfd1d9;
    }
    &::after {
      cursor: pointer;
      background-color: #cfd1d9;
    }
  }

  &--sm:not(.switch--with-bg) {
    .custom-control {
      padding-left: 26px;
      margin-right: 1px;
      min-height: 14px;
      height: 14px;
    }
    .custom-control-input {
      height: 14px;
    }
    .custom-control-label {
      &::before {
        width: 24px;
        height: 14px;
        top: 0;
        left: -24px;
        border-radius: 10px;
      }
      &::after {
        top: 2px;
        left: calc(-24px + 2px);
        height: 10px;
        width: 10px;
        border-radius: 1rem;
      }
    }
  }

  @each $name, $color in $default-theme-colors {
    &--#{$name}.switch--with-bg {
      background-color: map-get($switch-colors, $name);

      .custom-control-label::before, .form-check-input {
        background-color: $danger;
        border-color: $danger;
      }

      .custom-control-label::after {
        background-color: $white;
      }

      .custom-control-input:checked ~ .custom-control-label::before, .form-check-input:checked {
        background-color: $color;
        border-color: $color;
      }
    }
  }

  &--light-secondary.switch--with-bg {
    background-color: $background-color;

    .custom-control-label::before {
      background-color: $white;
      border-color: $text-gray;
    }

    .custom-control-label::after {
      background-color: $text-gray;
    }
  }

  &.switch--label-start {
    &:not(.switch--with-bg) {
      .switch__label {
        padding-left: 0;
        padding-right: 1rem;
      }

      .form-switch {
        padding-right: 0;
      }
    }
  }

  &.switch--primary {
    .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
      border-color: rgba(73, 113, 255, 0.5);
    }
  }
}

.switch {
  --switch-white-circle: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23FFFFFF%27/%3e%3c/svg%3e");
  --switch-gray-circle: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%28126, 130, 156, 0.30%29%27/%3e%3c/svg%3e");


  .form-check-input {
    --bs-form-switch-bg: var(--switch-gray-circle);
  }

  &, &:not(.switch--with-bg) {
    .switch__control {
      padding-left: 0;
      padding-right: 8px;
      display: flex;
      align-items: center;
    }

    .form-check-input {
      width: 24px;
      height: 14px;
      border: 1px solid transparent;
      margin-top: 0;
      margin-left: 0;
    }

    .switch__label {
      padding-left: 0;
    }
  }

  &.switch--with-bg {
    .form-check-input {
      --bs-form-switch-bg: var(--switch-white-circle);
    }

    &.switch--light-secondary {
      .form-check-input:not(:checked) {
        --bs-form-switch-bg: var(--switch-gray-circle);
        border-color: rgba(126, 130, 153, 0.3);
      }
    }
  }

  &--lg, &--lg:not(.switch--with-bg) {
    .form-check-input {
      width: 32px;
      height: 20px;
      border: 1px solid transparent;
    }
  }

  &:not(.switch--with-bg) {
    .form-check-input:not(:checked) {
      border-color: #7E82994D;

      &:disabled {
        --bs-form-switch-bg: var(--switch-white-circle);
        background-color: rgba(63, 66, 84, 0.1);
        border-color: transparent;
      }
    }
  }
}

.field--control-Switch.field--label-right {
  .switch, .switch:not(.switch--with-bg) {
    .switch__control {
      padding-right: 0;
    }
  }
}
