.dashboard-grid {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: $dashboard-cell-z-index;
}

.dashboard-cell {
  position: absolute;
  left: calc(#{$widget-cell-width-percent} * var(--dashboard-cell-left));
  top: calc(#{$widget-cell-height} * var(--dashboard-cell-top));
  width: $widget-cell-width-percent;
  height: $widget-cell-height;
  border: 1px solid $danger;
  border-right: 0;
  border-bottom: 0;
  background: $light-danger;
  opacity: 0.3;

  &--dragging-over {
    opacity: 0.6;
  }
}

.dashboard {
  display: flex;
  flex-wrap: wrap;
  position: relative;

  height: calc(#{$widget-cell-height} * var(--dashboard-height));

  &__box {
    width: 100%;
    height: 100%;
  }
}

.dashboard--mode-edit, .dashboard--mode-preview {
  background-size: calc((#{$dashboard-width} - 1px) / #{$dashboard-columns}) $widget-cell-height;
  background-repeat: repeat;
  background-image:
          linear-gradient(to right, $gray-semi-dark 1px, transparent 1px),
          linear-gradient(to bottom, $gray-semi-dark 1px, transparent 1px);
  overflow: hidden;
}

.dashboard--dragging {
  .dashboard-grid {
    z-index: $dashboard-cell-dragging-z-index;
  }
}
