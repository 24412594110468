@mixin statistic-border($direction) {
  background-image: linear-gradient(to $direction, $white 75%, $table-border-color 25%);
  z-index: $statistics-table-column-z-index + 2;
}

$statistics-table-row-margin: 3px;
$statistics-table-gradient-border-width: 4px;
$statistics-table-row-border-width: 1px;

$statistics-table-column-z-index: 10;
$statistics-table-column-position: sticky !important;
$statistics-table-column-box-sizing: content-box;
$statistics-table-column-expand-cell-width: 4rem;
$statistics-table-column-expand-cell-left: 0px;
$statistics-table-column-first-width: 160px;
$statistics-table-selectable-column-first-left: calc(#{$statistics-table-column-expand-cell-left} + 1px + #{$statistics-table-column-expand-cell-width});
$statistics-table-column-first-left: $statistics-table-column-expand-cell-left;
$statistics-table-column-second-width: 160px;
$statistics-table-selectable-column-second-left: calc(#{$statistics-table-selectable-column-first-left} + #{$statistics-table-column-first-width} + 1px + #{$table-td-padding-left});
$statistics-table-column-second-left: calc(#{$statistics-table-column-first-left} + #{$statistics-table-column-first-width} + 2px + #{$table-td-padding-left * 2});
$statistics-table-column-third-width: 160px;
$statistics-table-selectable-column-third-left: calc(#{$statistics-table-selectable-column-second-left} + #{$statistics-table-column-second-width} + 1px + #{$table-td-padding-left * 2});
$statistics-table-column-third-left: calc(#{$statistics-table-column-second-left} + #{$statistics-table-column-second-width} + 1px + #{$table-td-padding-left * 2});
$statistics-table-column-fourth-width: 110px;

$qualifications-form-counter-width: 150px;
$qualifications-form-field-width: 170px;
$qualifications-form-field-margin: 12px;
$qualifications-form-note-field-width: 300px;
$qualifications-form-note-width: $qualifications-form-note-field-width;

@mixin statistics-table-sticky-column() {
  position: $statistics-table-column-position;
  z-index: $statistics-table-column-z-index;
  box-sizing: $statistics-table-column-box-sizing;
}

.statistics-page {

  & > .tabs {
    margin-top: -4px;
  }

  .rc-collapse-content {
    background-color: transparent;
  }

  .input-group--bright {
    .input-group {
      .input-group-append {
        z-index: 2;

        .input-group-text {
          color: $base-dark;
        }

        svg {
          path {
            fill: $base-dark;
          }
        }
      }
    }
  }

  .card {
    border-radius: 0;
  }

  .tabs--buttons .tabs__nav {
    border-bottom: none;
    padding-bottom: 0;
  }

  &__side-links {
    & > *:not(:first-child) {
      margin-left: 30px;
    }
  }

  .logs {
    .tabs__nav {
      margin-top: 10px;
    }

    &__filter-fields {
      display: grid;
      grid-template-columns: minmax(10%, 1fr) minmax(10%, 2fr) minmax(10%, 1fr) minmax(10%, 1fr);
      grid-gap: 20px;
    }
  }

  .sent-logs {
    &__filter-fields {
      grid-template-columns: minmax(10%, 1fr) minmax(10%, 1fr) minmax(10%, 2fr) minmax(10%, 1fr);
    }
  }

  .statistics-page__second-options {
    display: flex;
    align-items: center;

    .right {
      display: flex;
      align-items: center;
    }
  }

  &__common-table table, &.cohort-analysis table {
    width: max-content;
    min-width: 100%;
    overflow-x: auto;

    tbody, tfoot {
      td {
        border-right: $table-border;

        &:first-child {
          border-left: $table-border;
        }
      }
    }
  }

  .rc-table-row-expand-icon-cell {
    padding: 0 1rem;
    left: $statistics-table-column-expand-cell-left;
    @include statistics-table-sticky-column();
    width: 40px;
    box-sizing: border-box;
  }

  .rc-table-checkbox-cell {
    left: $statistics-table-column-expand-cell-left;
    @include statistics-table-sticky-column();
    box-sizing: border-box;
    width: calc(4rem + 1px);
  }

  &__column {
    &--first {
      width: $statistics-table-column-first-width;
      left: $statistics-table-column-first-left;
      @include statistics-table-sticky-column();
    }

    &--second {
      max-width: $statistics-table-column-second-width;
      left: $statistics-table-column-second-left;
      @include statistics-table-sticky-column();
    }

    &--third {
      width: $statistics-table-column-third-width;
      box-sizing: $statistics-table-column-box-sizing;
    }

    &--custom-field {
      width: $statistics-table-column-fourth-width;
      box-sizing: $statistics-table-column-box-sizing;
    }
  }

  .table--selectable {
    .statistics-page__column {
      &--first {
        left: $statistics-table-selectable-column-first-left;
      }

      &--second {
        left: $statistics-table-selectable-column-second-left;
      }
    }
  }

  &__conversions {

    &.rc-table-ping-left {
      .statistics-page__column--second {
        border-right: $table-border !important;

        &:after {
          content: '';
          box-shadow: 0px 10px 30px 0px #3F42541A;
        }
      }

      th.statistics-page__column--second {
        border-right-color: $table-header-border-color !important;
      }
    }

    &:not(.table--selectable) {
      thead {
        th {
          &:first-child:before {
            content: '';
            position: absolute;
            width: $table-spaced-gradient-border-width;
            top: 0;
            margin-top: -1px;
            border: 1px solid $table-border-color;
            background: $table-header-background-light;
            z-index: 2;
            left: 0;
            margin-left: -$table-spaced-gradient-border-width;
            border-right: none;
          }
        }

        tr:first-child {
          th {
            &:first-child:before {
              height: calc(100% + 2px);
              border-radius: $border-radius 0 0 0;
            }
          }
        }

        tr:last-child {
          th {
            &:first-child:before {
              height: calc(100% - #{$table-spaced-row-margin} + 2px);
              border-radius: 0 0 0 $border-radius;
            }
          }
        }
      }

      .statistics-page__column--first {
        padding-left: 20px;
      }
    }

    &.table--selectable {
      .rc-table-checkbox-cell {
        border-right: 0;
      }

      .statistics-page__column--first {
        padding-left: 0;
      }
    }
  }

  .statistics-denied-label {
    color: $danger;
  }

  .table tr[data-row-key$="-denied"] td {
    color: $text-light-gray;
  }

  &__info-group-checkbox label, &__offer-goal label {
    cursor: pointer;
  }

  &__offer-goals-title {
    font-weight: $font-weight-bold;
    margin-bottom: 0.5rem;
    padding-top: 0.5rem;
  }

  &__offer-goal {
    margin-right: 2rem;
    margin-bottom: 1rem;
  }

  &__offer-goals {
    display: flex;
    flex-direction: row;
    margin-bottom: 1.5rem;
  }

  &__options {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 4px 20px;

    .group-by-field {
      display: flex;
      align-items: center;
      white-space: nowrap;
      flex-wrap: wrap;
      grid-gap: 8px;

      .input-select__menu {
        background: $white;
        min-width: 250px;
      }
    }

    & > * {
      margin-bottom: 0;
    }
  }

  &__group-by-select {
    position: relative;

    .input-select__control {
      min-width: 120px;
    }

    .input-select__clear-indicator {
      pointer-events: none;
      opacity: 0;
    }
  }

  &__group-by-clear {
    position: absolute;
    top: 3px;
    right: 40px;
    padding: 8px;
    cursor: pointer;
  }

  &__group-by-delimiter {
    font-size: 16px;
  }

  .input-select__menu {
    z-index: $loading-z-index + 1 !important;
  }

  @mixin statistic-columns-color-block($prefix, $border-color, $color-light) {
    .#{$prefix}-column {
      &__title {
        border: $border-color;
        background-color: $color-light;
        position: relative;
      }

      &--first {
        border-left: $border-color;
        position: relative;
      }

      &--last {
        border-right: $border-color;
      }
    }

    tfoot .#{$prefix}-column {
      border-bottom: $border-color;
    }
  }

  @include statistic-columns-color-block(
                  'offer-conversion',
                  $conversions-border,
                  $conversions-color-light
  );

  @include statistic-columns-color-block(
                  'product-event',
                  $events-border,
                  $events-color-light
  );

  .product-event-column ~ .offer-conversion-column {
    &.offer-conversion-column__title, &.offer-conversion-column--first {
      &:before {
        content: '';
        position: absolute;
        left: -3px;
        top: -2px;
        width: 4px;
        height: calc(100% + 3px);
        background-image: linear-gradient(to right, $events-color 50%, $conversions-color 50%);
      }
    }
  }

  .filter {
    .field:not(.field--has-error) {
      .input-group-append {
        z-index: 1;
      }

      .input-text__wrapper {
        z-index: 2;
      }
    }
  }

  .table {
    table {
      min-width: 100%;
      width: max-content;

      tfoot tr, tr.common-statistics-table__summary {
        background: $table-hover-bg;
        font-weight: $font-weight-bold;

        td {
          background: $table-hover-bg;
          border-color: $gray-semi-dark;
        }

        .country-label__icon {
          display: none;
        }
      }

      thead tr {
        background: $table-hover-bg;;
      }
    }
  }

  .sub-filter-title {
    font-weight: $font-weight-bold;
    color: $text-gray;
    margin: 1rem 0;
  }

  .conversions-deny-button {
    .icon {
      font-size: 11px;
    }
  }

  &__note-column {
    max-width: 350px;

    &.table__cell {
      word-break: break-word;
    }
  }

  &__currency-select {
    display: inline-flex;
    align-items: center;

    &:not(:first-child) {
      padding-left: 10px;
      border-left: 1px solid $table-head-bg;
    }

    .input-select {
      width: 100px !important;
      margin: 0 10px;
    }
  }
}

#cohort_select_product {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;

  .title {
    color: $text-light-gray;
  }
}

.statistics-common-share-modal {
  &__title {
    padding: 0px 8px 0px 8px;
  }

  &__body {
    padding: 5px 8px 15px 8px;
  }

  .table-url-container {
    display: flex;
    align-items: center;
    background-color: #eeeef2;
    border-radius: 4px;
  }

  .table-url {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex-grow: 1;
    padding: 0 5px 0 10px;
  }
}

.statistics-common-table-wrapper {
  min-height: 200px;
}

.statistics-page__table-scheme-options {
  margin-right: 1rem;
}

.qualifications-form {
  height: auto;
  overflow: hidden;
  opacity: 1;
  @include default-transition((height, opacity));

  .input-select__menu {
    min-width: 210px;
  }

  &--hidden {
    height: 0;
    opacity: 0;
    @include default-transition((height, opacity));
  }

  .field {
    margin-bottom: 0;
  }

  .textarea {
    background: $white;
  }

  &__warning {
    display: flex;
    align-items: center;
    background-color: $light-carrot;
    font-size: 12px;
    line-height: 16px;
    width: calc(100% + 40px);
    padding: 4px 20px;
    margin: -10px -20px 8px -20px;

    &-icon {
      display: flex;
      align-items: center;
      color: $carrot;
      font-size: 16px;
      height: 16px;
      width: 16px;
      margin-right: 8px;
    }
  }

  &__form {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    background-color: $gray-semi-dark;
    padding: 10px 20px;
  }

  &__select-all {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    background-color: $background-color;
    padding: 10px 20px;

    span {
      font-weight: 600;
    }
  }

  &__counter {
    width: $qualifications-form-counter-width;
  }

  &__fields {
    display: flex;
    width: calc(100% - #{$qualifications-form-counter-width});
  }

  &__double-field {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: $qualifications-form-field-width;
    margin-right: $qualifications-form-field-margin;
  }

  &__first-field {
    min-height: 16px;
    margin-bottom: 4px;
  }

  &__note-field {
    width: $qualifications-form-note-width;

    .qualifications-form__second-field {
      display: flex;
      align-items: flex-end;
      height: 100%;
    }

    .textarea {
      min-height: 56px;
    }

    .field, .input-text {
      display: block;
      width: $qualifications-form-note-field-width;
    }
  }

  &__button-field {
    width: auto;

    .qualifications-form__second-field {
      display: flex;
      align-items: flex-start;
    }
  }

  &__textarea-field {
    width: $qualifications-form-field-width * 2;
  }

  .textarea {
    &--floating-label {
      resize: none;
      height: 56px !important;
      padding-top: 18px;
      padding-bottom: 18px;

      &:not(:placeholder-shown) {
        padding-top: 23px;
        padding-bottom: 7px;
      }
    }

  }

  .textarea:placeholder-shown ~ .textarea__floating-label {
    top: 17px;
  }

  &__confirm-button {
    display: inline-flex;
  }

  &__delete-button {
    display: inline-flex;
    margin-left: 10px;
  }

  .input-select__control, .input-number input, .input-text:not(.is-invalid) {
    background-color: $white;
  }

  .input-select__control.input-select__control--is-disabled, .input-number input:disabled {
    background-color: rgba(255, 255, 255, 0.5);
  }

  .input-number.is-invalid input {
    background-color: $control-error-background-color !important;
    min-height: #{$control-min-height - 2px};
  }
}

.common-statistics-table {

  table {
    width: max-content !important;

    .common-statistics-table__inner-row {
      td:first-child {
        padding-left: calc(#{$table-td-padding-left} + 20px);
      }

      td {
        background: $table-inner-background;
      }

      &:hover {
        td {
          background: $white;
        }
      }
    }

    $i: 0;
    @while $i <= 5 {
      .wpx-#{$i} {
        width: #{$i}px;
      }

      .common-statistics-table__inner-row-level-#{$i} {
        td:first-child {
          padding-left: calc(#{$table-td-padding-left} + #{20px * $i});
        }
      }

      $i: $i + 1;
    }
  }

  &__group-field {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 400px;

    &-data {
    }

    &-icon {
      position: relative;
      height: 100%;
      margin-left: 10px;
      box-sizing: content-box;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2;
      font-size: 12px;


      .icon, .loading {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        margin: -10px;
        width: 16px;
        min-height: 16px;
        box-sizing: content-box;
      }

      .icon {
        font-size: 12px;
      }

      .loading {
        opacity: 0.75;
        position: relative;
        display: flex;
        max-height: 16px;
        overflow: hidden;
      }

      .spinner {
        width: 24px;
        min-width: 24px;
        height: 24px;
        min-height: 24px;
      }
    }

    &--break {
      word-break: break-word;
    }
  }
}

.common-statistics-table {
  th, td {
    min-width: 130px;
  }

  .rc-table-sticky-holder {
    position: sticky;
    z-index: 3;
  }
}

.idempotency-key-cell {
  width: 250px;

  &__text {
    width: fit-content;
    max-width: 225px;
    font-size: 13px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.statistics-page:not(.customers-page) {
  .filter__preview {
    margin-top: 10px;
    border-top: 4px solid $background-color;
  }
}

// hide Cohort tab if user is partner and schemes is not loaded yet
// for maintaining page refreshing feature
.statistics-tabs {
  &--hide-cohort {
    .tabs-tab:nth-child(4) {
      display: none;

      &.tabs-tab-active + .tabs-tab {
        border-left: 1px solid $white;
      }
    }
  }
}

.statistics-cohort-scheme-tooltip {
  z-index: 100;
}

.statistics-referrals {
  &__header {
    padding: 8px 0;

    h1 {
      margin-bottom: 8px;
    }
  }

  &__link-container {
    display: flex;
    align-items: center;

    p {
      margin: 0;
      margin-right: 8px;
    }

    button {
      margin-bottom: 2px;
    }
  }
}

.modal.qualifications-form-modal {
  .modal-header {
    padding: 0;
    z-index: 1;

    .modal__close {
      font-size: 14px;
    }
  }

  .modal-body {
    padding: 24px;
  }

  .modal-content {
    background: $background-color;
  }

  .qualifications-form__fields {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    grid-gap: 12px;

    .field {
      margin-bottom: 0;
    }
  }

  .qualifications-form__select-all {
    display: none;
  }

  .qualifications-form__warning {
    width: 100%;
    border-radius: $border-radius;
    margin: 0 0 12px 0;
    padding: 4px 12px;

    .qualifications-form__warning-icon {
      margin-right: 12px;
    }
  }

  .qualifications-form__form {
    padding: 0;
    background: $background-color;

    .qualifications-form__counter {
      margin-bottom: 12px;
    }
  }

  .input-number input,
  .input-text,
  .input-select__control {
    background: $white;
    background-color: $white;
  }

  .input-number input.disabled,
  .input-number input:disabled,
  .input-text:disabled,
  .input-text.disabled,
  .input-select__control--is-disabled {
    background: $control-disabled-background-color;
    background-color: $control-disabled-background-color;
  }

  .qualifications-form__double-field {
    width: calc(50% - 6px);
    margin-right: 0;
  }

  .qualifications-form__mobile-full-field {
    width: 100%;

    .field, .input-text {
      width: 100%;
    }
  }
}


@include tabletLayout() {
  .tabs.tabs--header.tabs--mobile {
    .tabs__nav-left-extra {
      display: none;
    }

    .tabs__extra {
      .button {
        &:not(:last-child) {
          margin-right: 8px !important;
        }
      }

      .dropdown:not(:last-child) {
        .button {
          margin-right: 8px !important;
        }
      }
    }

    .tabs__nav {
      .statistics-page__table-scheme-options {
        margin-left: 8px;
        margin-bottom: 0;
      }
    }
  }

  .base-filter-fields {
    grid-template-columns: repeat(3, minmax(15%, 1fr));
  }

  .columns-visibility-form__columns {
    max-height: 200px;
  }

  .sub-filter-title {
    font-weight: 600;
    margin-top: 18px;
    margin-bottom: 12px;
  }

  .statistics-page__options {
    padding: 8px 12px 8px;
    border-top: 4px solid #EFF1F5;
    margin-top: 8px;
    flex-wrap: wrap;

    .group-by-field {
      width: 100%;
      margin-bottom: 8px;
      padding-bottom: 8px;
      border-bottom: 1px solid $background-color;
    }
  }

  .statistics-page {
    .filter__preview {
      display: none;
    }
  }

  .statistics-page__schemes-dropdown {
    width: 100%;
    padding: 0 12px;
    margin-top: 8px;
  }

  .statistics-page__table-scheme-options {
    margin-right: 0;
    margin-bottom: 8px;
  }

  .statistics-page__currency-select:not(:first-child) {
    padding-left: 0;
    border-left: none;
  }

  .logs.page {
    .filters-button-container {
      margin-right: 0;
    }

    .rc-table-actions-bar {
      margin-bottom: 0;
    }
  }

  .logs-refresh-button {
    position: absolute;
    top: -85px;
    right: 12px;
  }

  .qualifications-form__select-all {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $gray-semi-dark;
    padding: 8px 12px;
    grid-gap: 4px;

    .switch__label {
      font-weight: 400;
      padding-right: 8px;
    }

    & > label {
      margin-bottom: 0;
      margin-right: 20px;
    }
  }

  .qualifications-form__counter {
    width: unset;
    display: flex;
    align-items: center;

    & > strong {
      padding: 0 4px;
    }

    .button {
      margin-left: 8px;
    }
  }

  .statistics-schemes-dropdown__actions {
    display: none;
  }

  .statistics-schemes-dropdown__group-add {
    display: none;
  }

  .statistics-page__options {
    .group-by-field {
      .input-select__menu {
        min-width: 150px;
      }
    }
  }

  .customers-page {
    .page-header {
      border-bottom: none;

      .header {
        padding: 8px 12px 4px 12px;
      }
    }

    .statistics-page__table-scheme-options {
      margin-bottom: 0;
      margin-left: 12px;
    }

    .statistics-page__schemes-dropdown {
      margin-top: 0;
    }

    .statistics-page__options {
      flex-wrap: nowrap;
    }

    .group-by-field {
      margin-bottom: 0;
      border-bottom: 0;
      padding-bottom: 0;
    }
  }
}

@include mobileLayout(960px) {
  .base-filter-fields {
    grid-template-columns: repeat(2, minmax(15%, 1fr));
  }
}

@include mobileLayout() {
  .base-filter-fields {
    grid-template-columns: 1fr;
    display: flex;
    flex-direction: column;
    grid-gap: 0;
  }

  .rc-table-cell-fix-sticky,
  .rc-table-cell-fix-left,
  .statistics-page__column--first,
  .statistics-page__column--second,
  .statistics-page__column--third {
    position: unset !important;
  }

  .statistics-page__conversions.rc-table-ping-left {
    .rc-table-checkbox-cell {
      border-right: $table-border;
      width: 57px;
    }

    th.rc-table-checkbox-cell {
      border-right: $table-header-border;
    }

    .statistics-page__column--second {
      border-right: 0 !important;
    }

    tr:hover:not(.table__row--checked) {
      .rc-table-checkbox-cell {
        border-right: $table-header-border;
      }
    }
  }

  .statistics-page__conversions.rc-table-ping-left {
    .rc-table-row:not(.table__row--checked) {
      .rc-table-checkbox-cell.rc-table-cell-row-hover {
        border-right: $table-header-border;
      }
    }
  }

  .promo-code-import-form__actions {
    padding-bottom: 0;
  }

  .customers-page {
    .page-header {
      .header {
        padding: 8px 12px 12px 12px;
      }
    }

    .statistics-page__table-scheme-options {
      margin-left: 0;
    }
  }
}
