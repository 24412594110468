@use 'sass:color';

.button {
  display: flex;
  align-items: center;
  justify-content: center;

  word-break: keep-all;

  &__icon {
    display: flex;
    position: relative;
    min-height: $base-line-height;
    min-width: $base-line-height;
    justify-content: center;
    align-items: center;

    &--right {
      order: 10;
      margin-left: 0;
      margin-right: 2px;

      & + span {
        margin-left: 0;
        margin-right: 10px;
      }
    }

    & + span {
      margin-left: 10px;
    }
  }

  &--square {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 0;

    .button__icon {
      margin-left: 0;
      margin-right: 0;
      font-size: 16px;
    }

    &.btn-sm {
      width: 32px;
      height: 32px;
    }
  }

  &--icon-md {
    .icon {
      font-size: 16px;
    }
  }

  &--bold {
    font-weight: 600;
  }

  &--full-width {
    display: flex;
    width: 100%;
    justify-content: center;
  }
}

@each $name, $color in $colors {
  .btn.btn-#{$name} {
    color: map-get($btn-font-color, $name);
    --bs-btn-active-color: #{map-get($btn-font-color, $name)};
    background-color: $color;
    border-color: $color;
    background-clip: padding-box;
  }

  .btn.btn-light-#{$name} {
    color: map-get($btn-font-color, #{"light-" + $name});
    --bs-btn-active-color: #{map-get($btn-font-color, #{"light-" + $name})};
    background-color: rgba($color, 0.15);
    border-color: rgba($color, 0.15);
    background-clip: padding-box;
  }

  .btn.btn-light-#{$name}:disabled,
  .btn.btn-light-#{$name}.disabled,
  .btn.btn-#{$name}:disabled,
  .btn.btn-#{$name}.disabled {
    color: $text-light-gray;
    background-color: $control-disabled-background-color;
    border-color: $control-disabled-background-color;
    background-clip: padding-box;
    opacity: 1;
  }

  .btn.btn-#{$name} {
    &:not(:disabled):not(.disabled) {
      &:hover {
        color: map-get($btn-font-color, $name);
        background-color: color.adjust($color, $lightness: -5%);
        border-color: color.adjust($color, $lightness: -5%);
      }

      &:active, &:focus {
        color: map-get($btn-font-color, $name);
        background-color: color.adjust($color, $lightness: -5%);
        border-color: color.adjust($color, $lightness: -5%);
      }
    }
  }

  .btn.btn-light-#{$name} {
    &:not(:disabled):not(.disabled) {
      &:hover {
        color: map-get($btn-font-color, #{"light-" + $name});
        background-color: rgba($color, 0.30);
        border-color: rgba($color, 0.30);
      }

      &:active, &:focus {
        color: map-get($btn-font-color, #{"light-" + $name});
        background-color: rgba($color, 0.45);
        border-color: rgba($color, 0.45);
      }
    }
  }
}

.btn.btn-field {
  border: 1px solid $text-light-gray;

  &:not(:disabled):not(.disabled) {
    &:hover, &:focus, &:active {
      border: 1px solid #e6e6e6;
      border-color: #e6e6e6;
      background-color: $background-color;
    }
  }
}

.btn.btn-transparent {
  &, &:active {
    border-color: transparent !important;
  }
}

.btn.btn-link {
  text-decoration: none;
}

.btn:hover {
  text-decoration: none;
}
