$profile-card-item-margin-x: 10px;

.profile-card {
  flex-basis: 100%;
  width: 100%;

  &__header {
    margin: -1px;
  }

  &--admin &__header {
    background-color: #3F4254;
  }

  &--partner &__header {
    background-color: $orange;
  }

  &--advertiser &__header {
    background-color: $green;
  }

  &--registration-not-completed {
    .card__header {
      background-color: $text-gray;
    }
  }

  &--moderated, &--disabled {
    .card__header {
      background-color: $text-light-gray;
    }
  }

  &--disabled {
    .profile-card__title {
      color: $text-light-gray;
    }
  }

  &__statuses {
    margin-bottom: 1.5rem;

    .profile-resend-email {
      margin-top: 1rem;
    }
  }
}

.profile-card-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: $base-font-size;
  line-height: $base-line-height;
  padding: $profile-card-container-padding;
  background-color: #3F4254;
  color: white;
  font-weight: normal;

  &__subheader {
    color: $light;
    opacity: 0.5;
  }
}

.profile-card-image {
  display: inline-block;
  width: 100px;
  height: 100px;

  margin-top: -50px;
  margin-bottom: 20px;

  border: 3px solid white;
  border-radius: 50%;

  overflow: hidden;
}

.profile-card-title {
  margin: 2rem 0 1.5rem 0;

  font-size: $h2-font-size;
  line-height: 35px;
  color: $base-dark;
  font-weight: bold;
}

.profile-card-content {
  padding: $profile-card-container-padding;
  padding-top: 0;
}

.profile-card-list {
  margin-top: 1rem;
}

.profile-card-list-item {
  line-height: 2.75rem;
  color: $base-dark;
  display: flex;
  flex-direction: row;
  margin: 0 #{$profile-card-item-margin-x * -1};
  padding: 0 $profile-card-item-margin-x;
  width: calc(100% + #{$profile-card-item-margin-x * 2});

  border-radius: $border-radius;
  @include default-transition(background-color);

  &--clickable {
    cursor: pointer;

    &:hover {
      background-color: $hover-bg;
    }
  }

  &--disabled {
    color: $text-gray;
  }


  &--loading {
    .svg-icon {
      display: block;
      width: 20px;
    }
  }

  &--flatten {
    line-height: 2rem;
  }

  &__icon {
    margin-right: 10px;
    font-size: 20px;
    position: relative;
    color: #C5C9D3;

    .loading {
      width: 20px;

      .spinner-border {
        border-width: 3px;
      }
    }

    .svg-icon {
      height: 2.75rem;
      display: flex;
      align-items: center;
    }
  }

  &__contact-value {
    color: $link-color;
    line-height: 16px;
    margin-bottom: 8px;
  }

  &__contact-text-value {
    color: $link-color;
    line-height: 16px;
    margin-bottom: 8px;

    label {
      color: $text-gray;
      cursor: pointer;
    }
  }

  &__note {
    line-height: 16px;
    margin-bottom: 8px;
    color: $link-color;

    label {
      color: $text-gray;
      cursor: pointer;
    }
  }

  &__title {
    color: $text-gray;
  }

  &__utm-source {
    color: $link-color;
  }
}
