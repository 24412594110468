.integrations-page {
  .preview-cards {
    padding: 0;

    &__cards {
      grid-template-columns: repeat(3,minmax(15%,1fr));

      @media (max-width: 1500px) {
        grid-template-columns: repeat(2,minmax(10%,1fr));
      }
    }
  }
}

.integration-form {
  .modal-dialog {
    max-width: calc(min(max($mobile-min-width, 100dvw), 700px) - 1rem);
  }

  .modal-body {
    min-height: 150px;
  }

  .preview-card {
    padding: 0;
    border: none;
    box-shadow: none;
    padding-bottom: 16px;
    margin-bottom: 24px;
    border-bottom: 1px solid $background-color;
  }
}
