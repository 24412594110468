.tooltip-cell {
  display: flex;
  align-items: center;
  color: $link-color;

  &__icon {
    margin-right: 10px;
  }

  &__text {
    word-break: keep-all;
  }
}
