$os-type-label-icon-size: 16px;

.os-type-label {
  display: flex;
  align-items: center;
  line-height: 19px;

  &__icon {
    display: flex;
    align-items: center;
    font-size: $os-type-label-icon-size;
    width: $os-type-label-icon-size;
    min-width: $os-type-label-icon-size;
    height: $os-type-label-icon-size;
    margin-right: 8px;

    svg {
      width: $os-type-label-icon-size;
      height: $os-type-label-icon-size;
    }
  }
}

.filter__preview-field {
  .os-type-label {
    display: inline-flex;
  }
}
